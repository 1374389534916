import React from 'react';


const SiteModal = ({ site, handleChange, closeModal, saveItem }) => {
    const types = ['Hill', 'Building', 'Street Level'];
    return (
        <div className="modal fade" id="siteModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Site</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label forhtml="description" className="col-form-label">Description:</label>
                                    <input type="text" className="form-control" id="description" name="description" onChange={handleChange} value={site.description} />
                                </div>
                                <div className="form-group col-sm-12">
                                    <label forhtml="type" className="col-form-label">Type:</label>
                                    <select className={'form-control'} id="type" name="type" onChange={handleChange} value={site.type}>
                                        <option value="0" >--choose--</option>
                                        {

                                            types.map((type, index) => <option key={index} value={type} >{type}</option>)

                                        }
                                    </select>

                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SiteModal;