import React, { Component } from "react";
import Select from "react-select";
import ErrorMessage from "./ErrorMessage";

const optionsDefault = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export default function FormCustomSelect({
  options = optionsDefault,
  title = "title",
  myValue,
  placeholder,
  field,
  form,
  span = "6",
  isMulti = false,
  isApplicant = false,
  onChangeFunction,
}) {
  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      isMulti ? option.map((item) => item.value) : option.value
    );
    if (onChangeFunction) {
      onChangeFunction(option.value, field.name);
    }
  };

  const getValue = () => {
    if (options) {
      let item = isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value?.id);
      let item2 = isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === myValue);
      return item || item2 || "";
    } else {
      return isMulti ? [] : "";
    }
  };

  return (
    <>
      <div
        className={`col-span-full md:col-span-${span} sm:col-span-${span} xl:col-span-${span}`}
      >
        {/* Row */}
        <label className="block uppercase tracking-wide text-xs font-bold my-2">
          {title || "Plot Number"}
        </label>
        {/* End Row */}
        {/* Row */}

        <Select
          name={field.name}
          value={getValue()}
          onChange={onChange}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
        />

        {/* End Row */}
        {/* Row */}
        <ErrorMessage name={field.name} component="div" />
      </div>
    </>
  );
}
