/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function Dropdown({
  showStations,
  showMicrowaveLinks,
  setShowStations,
  setShowMicrowaveLinks,
}) {
  return (
    <Menu as="div" className="relative text-left my-auto">
      <div>
        <Menu.Button
          className="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 
        bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
        focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          Display Options
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="z-10 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1
         ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div>
                  <label className="inline-flex items-center px-4 py-2 ">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      defaultChecked={showStations}
                      onChange={(e) => {
                        console.log(e.target.checked);
                        setShowStations(e.target.checked);
                      }}
                    />
                    <span className="ml-2">Stations</span>
                  </label>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div>
                  <label className="inline-flex items-center px-4 py-2 ">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      defaultChecked={showMicrowaveLinks}
                      onChange={(e) => {
                        console.log(e.target.checked);
                        setShowMicrowaveLinks(e.target.checked);
                      }}
                    />
                    <span className="ml-2">Microwave Links</span>
                  </label>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
