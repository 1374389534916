import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionVerklaring({ setFieldValue }) {
  return (
    <>
      <HeadingForm
        title="14 &nbsp;&nbsp;&nbsp;&nbsp;Verklaring en ondertekening"
        subTitle=""
      />
      <div className="mt-4">
        <p>
          - Ik ben bevoegd en/of gemachtigd om deze aanvraag te ondertekenen.
        </p>
        <p>- Ik verklaar dat dit formulier naar waarheid is ingevuld.</p>
      </div>
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            14.1 Naam ondertekenaar
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="OndertekeningNaam"
            />
          </div>
          <ErrorMessage name="OndertekeningNaam" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            14.2 Datum
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="date"
              name="OndertekeningDatum"
            />
          </div>
          <ErrorMessage name="OndertekeningDatum" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            14.2 Plaats
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="OndertekeningPlaats"
            />
          </div>
          <ErrorMessage name="OndertekeningPlaats" component="div" />
        </div>
      </div>
      {/* End Row */}
     
    </>
  );
}
