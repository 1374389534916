import React from 'react'
import { Link } from 'react-router-dom';

const Forbidden = () => { //
    return (
        <div className="text-center">
            <div className="error mx-auto" data-text="403">403</div>
            <p className="lead text-gray-800 mb-5">Forbidden Page</p>
            <p className="text-gray-500 mb-0">You are not allowed to view this page...</p>
            <Link to="/dashboard-home">← Back to Dashboard</Link>

        </div>
    )
}

export default Forbidden;