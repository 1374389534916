import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";

import { useQuery, useLazyQuery } from "@apollo/client";

import Logo from "../../assets/isp_logo_black.png";

const getCompanyReportQuery = gql`
  query ($companyId: Int) {
    totalCompanyCostPerBandwidth(companyId: $companyId) {
      lineFee
      name
      downlink
      uplink
      totalBandwidth
      unitPrice
      totalGuilders
      totalDollars
    }
    totalCompanyCost(companyId: $companyId) {
      totalGuilders
      totalDollars
    }
  }
`;

export default function CompanyReportCard({ companyId, company, isYearly }) {
  //   const [getReport, { loading, data }] = useLazyQuery(getCompanyReportQuery);
  const { loading, error, data } = useQuery(getCompanyReportQuery, {
    variables: { companyId: parseInt(companyId) },
  });

  useEffect(() => {
    // getReport({ variables: { companyId: parseInt(companyId) } });
  }, [companyId, company]);

  return !loading && data ? (
    <>
      {company != "" && (
        <div
          style={{
            margin: "80px auto",
          }}
        >
          {console.log(data)}
          <div
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "flex-end",
                borderBottom: "solid 1px black",
              }}
              className={`justify-between`}
            >
              <div style={{}}>
                <img alt="" src={Logo} style={{ width: 140, height: "auto" }} />
              </div>

              <div style={{}}>
                <h1>{company.name}</h1>
              </div>
              <div className="" style={{ width: 140, height: "auto" }}></div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                borderBottom: "solid 1px black",
              }}
              className="p-1"
            >
              <div style={{ color: "black", flex: 1 }}>
                <table>
                  <tbody>
                    {/* <tr>
                      <td>Company:</td>
                      <td>{company.name}</td>
                    </tr> */}
                    <tr>
                      <td>C.o.C:</td>
                      <td>{company.coc}</td>
                    </tr>
                    <tr>
                      <td>Address:</td>
                      <td>{company.address}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ color: "black", flex: 1, textAlign: "right" }}>
                Date: {new Date().toDateString()}
              </div>
            </div>
            <div style={{ flex: 14 }}>
              <table
                style={{ width: "100%", color: "black" }}
                className="mb-5 mt-4"
              >
                <thead className="">
                  <tr
                    className={`bg-blue-500 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0`}
                  >
                    <th className="p-2 text-left">Fequency</th>
                    <th>LineFee</th>
                    <th>Down Link</th>
                    <th>Up Link</th>
                    <th>Bandwidth</th>
                    {/* <th>Unit Price</th> */}
                    <th>Price Guilders</th>
                    <th>Price Dollars</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Single Companie Report */}
                  {!loading && data
                    ? data.totalCompanyCostPerBandwidth.map((cost, index) => (
                        <tr key={index}>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.name}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.lineFee}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.downlink}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.uplink}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.totalBandwidth}
                          </td>
                          {/* <td>{new Intl.NumberFormat('nl-US', { style: 'currency', currency: 'ANG' }).format(cost.unitPrice)}</td> */}
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "ANG",
                            }).format(
                              isYearly
                                ? cost.totalGuilders
                                : cost.totalGuilders / 12
                            )}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              isYearly
                                ? cost.totalDollars
                                : cost.totalDollars / 12
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                borderTop: "solid 1px black",
              }}
            >
              <p>
                Totals:&nbsp;&nbsp;-&nbsp;
                {/* Single Company Report */}
                {!loading && data && company.id !== -1
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "ANG",
                    }).format(data.totalCompanyCost[0].totalGuilders)
                  : null}
                &nbsp;-&nbsp;
                {!loading && data && company.id !== -1
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(data.totalCompanyCost[0].totalDollars)
                  : null}
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
}
