import { gql } from "@apollo/client";

const getAntennaeQuery = gql`
  {
    companies {
      id
      name
    }
    services {
      id
      name
      technology {
        id
        name
      }
    }
    bands {
      id
      name
      service {
        id
        name
      }
    }
    technologies {
      id
      name
    }
    stations {
      id
      description
      sectors {
        id
        description
      }
      siteStatus
      siteIDUMTS
      siteIDLTE
    }
    antennaTypes {
      id
      name
      model {
        id
        name
        hasAttachment
        brand {
          id
          name
        }
      }
    }
    models {
      id
      name
      hasAttachment
      brand {
        id
        name
      }
    }
    brands {
      id
      name
    }
    frequencies {
      id
      name
      band {
        id
        name
        service {
          id
          name
        }
      }
    }
    antennae {
      id
      code
      pix4dLink
      description
      serialNumber
      heightAboveGround
      company {
        id
        name
      }
      frequency {
        id
        name
        band {
          id
          name
          service {
            id
            name
            technology {
              id
              name
            }
          }
        }
      }
      antennaType {
        id
        name
        model {
          id
          name
          hasAttachment
          brand {
            id
            name
          }
        }
      }
      maxVSWR
      minVSWR
      directivity
      gain
      efficiency
      erp
      erpVTypeId
      radiationPatern
      radiaPFile
      ANTK
      ANTTA
      STNR
      cellName
      cellId
      cellStatus
      band
      DLUARFCN
      ULUARFCN
      AZIMUTHTRUENORTH
      AZIMUTHMAGNETIC
      MDOWNTILT
      EDOWNTILT
      TILT
      station {
        id
        description
        sectors {
          id
          description
        }
        siteStatus
        siteIDUMTS
        siteIDLTE
      }
    }
  }
`;

const getAntennaQuery = gql`
  query ($id: Int) {
    antenna(id: $id) {
      id
      code
      pix4dLink
      description
      serialNumber
      heightAboveGround
      frequency
      brand {
        id
        name
      }
      model {
        id
        name
        attachment
      }
      antennaType {
        id
        name
      }
      maxVSWR
      minVSWR
      directivity
      gain
      efficiency
      erp
      erpVTypeId
      radiationPatern
      radiaPFile
      ANTK
      ANTTA
      STNR
      cellName
      cellId
      cellStatus
      band
      DLUARFCN
      ULUARFCN
      AZIMUTHTRUENORTH
      AZIMUTHMAGNETIC
      MDOWNTILT
      EDOWNTILT
      TILT
      station {
        id
        description
        sectors {
          id
          description
        }
        siteStatus
        siteIDUMTS
        siteIDLTE
      }
      company {
        id
        name
      }
    }
  }
`;

const addAntennaMutation = gql`
  mutation AddAntenna(
    $code: String!
    $description: String!
    $serialNumber: String!
    $heightAboveGround: Float
    $frequencyId: Int
    $antennaTypeId: Int
    $stationId: Int
    $companyId: Int
    $maxVSWR: Float
    $minVSWR: Float
    $directivity: Int
    $gain: Float
    $efficiency: Float
    $erp: Float
    $erpVTypeId: Int
    $radiationPatern: Float
    $radiaPFile: String
    $ANTK: Float
    $ANTTA: Float
    $STNR: Float
    $cellName: String
    $cellId: Int
    $cellStatus: String
    $band: String
    $DLUARFCN: String
    $ULUARFCN: String
    $AZIMUTHTRUENORTH: String
    $AZIMUTHMAGNETIC: String
    $MDOWNTILT: String
    $EDOWNTILT: String
    $TILT: String
  ) {
    addAntenna(
      code: $code
      description: $description
      serialNumber: $serialNumber
      heightAboveGround: $heightAboveGround
      frequencyId: $frequencyId
      antennaTypeId: $antennaTypeId
      stationId: $stationId
      companyId: $companyId
      maxVSWR: $maxVSWR
      minVSWR: $minVSWR
      directivity: $directivity
      gain: $gain
      efficiency: $efficiency
      erp: $erp
      erpVTypeId: $erpVTypeId
      radiationPatern: $radiationPatern
      radiaPFile: $radiaPFile
      ANTK: $ANTK
      ANTTA: $ANTTA
      STNR: $STNR
      cellName: $cellName
      cellId: $cellId
      cellStatus: $cellStatus
      band: $band
      DLUARFCN: $DLUARFCN
      ULUARFCN: $ULUARFCN
      AZIMUTHTRUENORTH: $AZIMUTHTRUENORTH
      AZIMUTHMAGNETIC: $AZIMUTHMAGNETIC
      MDOWNTILT: $MDOWNTILT
      EDOWNTILT: $EDOWNTILT
      TILT: $TILT
    ) {
      id
      code
    }
  }
`;
const updateAntennaMutation = gql`
  mutation UpdateAntenna(
    $id: Int!
    $code: String!
    $description: String!
    $serialNumber: String!
    $heightAboveGround: Float
    $frequencyId: Int
    $antennaTypeId: Int
    $stationId: Int
    $companyId: Int
    $maxVSWR: Float
    $minVSWR: Float
    $directivity: Int
    $gain: Float
    $efficiency: Float
    $erp: Float
    $erpVTypeId: Int
    $radiationPatern: Float
    $radiaPFile: String
    $ANTK: Float
    $ANTTA: Float
    $STNR: Float
    $cellName: String
    $cellId: Int
    $cellStatus: String
    $band: String
    $DLUARFCN: String
    $ULUARFCN: String
    $AZIMUTHTRUENORTH: String
    $AZIMUTHMAGNETIC: String
    $MDOWNTILT: String
    $EDOWNTILT: String
    $TILT: String
  ) {
    updateAntenna(
      id: $id
      code: $code
      description: $description
      serialNumber: $serialNumber
      heightAboveGround: $heightAboveGround
      frequencyId: $frequencyId
      antennaTypeId: $antennaTypeId
      stationId: $stationId
      companyId: $companyId
      maxVSWR: $maxVSWR
      minVSWR: $minVSWR
      directivity: $directivity
      gain: $gain
      efficiency: $efficiency
      erp: $erp
      erpVTypeId: $erpVTypeId
      radiationPatern: $radiationPatern
      radiaPFile: $radiaPFile
      ANTK: $ANTK
      ANTTA: $ANTTA
      STNR: $STNR
      cellName: $cellName
      cellId: $cellId
      cellStatus: $cellStatus
      band: $band
      DLUARFCN: $DLUARFCN
      ULUARFCN: $ULUARFCN
      AZIMUTHTRUENORTH: $AZIMUTHTRUENORTH
      AZIMUTHMAGNETIC: $AZIMUTHMAGNETIC
      MDOWNTILT: $MDOWNTILT
      EDOWNTILT: $EDOWNTILT
      TILT: $TILT
    ) {
      id
      code
    }
  }
`;

const deleteAntennaMutation = gql`
  mutation DeleteAntenna($id: Int!) {
    deleteAntenna(id: $id) {
      id
      code
    }
  }
`;

export const antennaService = {
  getAntennaeQuery,
  getAntennaQuery,
  addAntennaMutation,
  updateAntennaMutation,
  deleteAntennaMutation,
};
