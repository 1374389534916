import { gql } from "@apollo/client";

const getUserQuery = gql`
  query ($id: Int) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;
const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      role {
        id
        name
      }
      u_company
    }
  }
`;

const getAllUsersQuery = gql`
  {
    users {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
      u_company
    }
    roles {
      id
      name
    }
  }
`;

const addUserMutation = gql`
  mutation AddUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $roleId: Int
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      roleId: $roleId
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

const updateUserMutation = gql`
  mutation UpdateUser(
    $id: Int!
    $firstName: String
    $lastName: String
    $email: String
    $roleId: Int
    $u_company: Int
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      roleId: $roleId
      u_company: $u_company
    ) {
      id
      firstName
      lastName
      email
      u_company
    }
  }
`;

const deleteUserMutation = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const signupMutation = gql`
  mutation Signup(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    signup(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

const signinMutation = gql`
  mutation Signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

const signoutMutation = gql`
  mutation Signout {
    signout {
      message
    }
  }
`;

const requestResetMutation = gql`
  mutation RequestReset($email: String!) {
    requestReset(email: $email) {
      message
    }
  }
`;
const resetPasswordMutation = gql`
  mutation ResetPassword(
    $resetToken: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      resetToken: $resetToken
      password: $password
      confirmPassword: $confirmPassword
    ) {
      id
      email
      firstName
      lastName
    }
  }
`;

const updatePermissionsMutation = gql`
  mutation UpdatePermissions($roles: [Permission], $userId: Int!) {
    updatePermissions(roles: $roles, userId: $userId) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const userService = {
  getUserQuery,
  CURRENT_USER_QUERY,
  getAllUsersQuery,
  addUserMutation,
  updateUserMutation,
  deleteUserMutation,
  signupMutation,
  signinMutation,
  signoutMutation,
  requestResetMutation,
  resetPasswordMutation,
  updatePermissionsMutation,
};
