import React, { useState } from "react";
import Dropdown from "../Layout/Dropdown";
import MobileServicesSelect from "../MobileServicesSelect";
import CompaniesSelect from "../CompaniesSelect";
import Map from "../map/Map";

function LocationMap({ stations, user }) {
  const [service, setService] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(-1);
  const [showStations, setShowStations] = useState(true);
  const [showMicrowaveLinks, setShowMicrowaveLinks] = useState(false);
  return (
    <div className="card shadow mb-4">
      <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold text-primary">Locations</h6>
      </div>
      <div className="card-body">
        <div className="row mb-4">
          {/* Map Options Card */}
          <div className="col-sm-12 col-md-4">
            <Dropdown
              showStations={showStations}
              showMicrowaveLinks={showMicrowaveLinks}
              setShowStations={setShowStations}
              setShowMicrowaveLinks={setShowMicrowaveLinks}
            />
          </div>
          {/* Actions Col */}
          <div className="col-sm-12 col-md-4">
            {user.role.name !== "OPERATOR" && (
              <MobileServicesSelect setSelectedService={setService} />
            )}
          </div>
          {/* End Col */}
          {/* Actions Col */}
          <div className="col-sm-12 col-md-4">
            {user.role.name !== "OPERATOR" && (
              <CompaniesSelect setSelectedCompany={setSelectedCompany} />
            )}
          </div>
          {/* End Col */}
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Map
              stations={
                user.role.name !== "OPERATOR"
                  ? stations || []
                  : (stations &&
                      stations.filter(
                        (s) => s.company.id === user.u_company
                      )) ||
                    []
              }
              selectedService={service}
              showStations={showStations}
              showMicrowaveLinks={showMicrowaveLinks}
              selectedCompany={selectedCompany}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationMap;
