import { gql } from "@apollo/client";

const getContactsByCompanyQuery = gql`
  query ($companyId: Int!) {
    contactsByCompany(companyId: $companyId) {
      id
      firstName
      lastName
      function
      phone
      email
      company {
        id
        name
      }
    }
  }
`;

const addContactMutation = gql`
  mutation AddContact(
    $firstName: String!
    $lastName: String!
    $function: String
    $phone: String
    $email: String
    $companyId: Int!
  ) {
    addContact(
      firstName: $firstName
      lastName: $lastName
      function: $function
      phone: $phone
      email: $email
      companyId: $companyId
    ) {
      id
      firstName
      lastName
      function
      phone
      email
    }
  }
`;

const deleteContactMutation = gql`
  mutation DeleteContact($id: Int!) {
    deleteContact(id: $id) {
      id
    }
  }
`;
const updateContactMutation = gql`
  mutation UpdateContact(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $function: String
    $phone: String
    $email: String
    $companyId: Int!
  ) {
    updateContact(
      id: $id
      firstName: $firstName
      lastName: $lastName
      function: $function
      phone: $phone
      email: $email
      companyId: $companyId
    ) {
      id
      firstName
      lastName
      function
      phone
      email
      updatedBy
    }
  }
`;

export const contactService = {
  getContactsByCompanyQuery,
  addContactMutation,
  deleteContactMutation,
  updateContactMutation,
};
