import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  getFrequenciesQuery,
  updateFrequencyMutation,
  addFrequencyMutation,
  deleteFrequencyMutation,
} from "../../../queries/queries";


import FrequencyModal from "./ModalFrequencies";
import Pagination from "../../Layout/Pagination";

const initialValues = {
  name: "",
  band: "",
  downlinkStart: "",
  downlinkCenter: "",
  downlinkEnd: "",
  uplinkStart: "",
  uplinkCenter: "",
  uplinkEnd: "",
  interval: "",
};

const FrequenciesOverview = () => {
  const { loading, data } = useQuery(getFrequenciesQuery);
  const [frequency, setFrequency] = useState(initialValues);
  const [services, setServices] = useState([]);
  const [bands, setBands] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      setServices(data.services);
      setBands(data.bands);
    }
  }, [loading, data]);

  const [addFrequency] = useMutation(addFrequencyMutation);
  const [updateFrequency] = useMutation(updateFrequencyMutation);
  const [deleteFrequency] = useMutation(deleteFrequencyMutation);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = !loading
    ? data.frequencies.slice(indexOfFirstItem, indexOfLastItem)
    : null;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = !loading ? data.frequencies.length : 0;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "technology") {
      setFrequency({
        ...frequency,
        band: { service: { technology: { id: parseInt(value) } } },
      });
      setServices(
        data.services.filter(
          (service) => service.technology.id === parseInt(value)
        )
      );
    } else if (name === "service") {
      setFrequency({
        ...frequency,
        band: {
          service: {
            id: parseInt(value),
            technology: frequency.band.service.technology,
          },
        },
      });
      setBands(
        data.bands.filter((band) => band.service.id === parseInt(value))
      );
    } else if (name === "band") {
      setFrequency({
        ...frequency,
        band: { id: parseInt(value), service: frequency.band.service },
      });
    } else {
      setFrequency({ ...frequency, [name]: value });
    }
  };  

  const deleteItem = async (e, id) => {
    deleteFrequency({
      variables: {
        id: id,
      },
      refetchQueries: [{ query: getFrequenciesQuery }],
    });
  };

  const saveItem = async (frequency) => {
    frequency.id
      ? await updateFrequency({
          variables: {
            id: frequency.id,
            name: frequency.name,
            downlinkStart: parseFloat(frequency.downlinkStart),
            downlinkCenter: parseFloat(frequency.downlinkCenter),
            downlinkEnd: parseFloat(frequency.downlinkEnd),
            uplinkStart: parseFloat(frequency.uplinkStart),
            uplinkCenter: parseFloat(frequency.uplinkCenter),
            uplinkEnd: parseFloat(frequency.uplinkEnd),
            interval: parseFloat(frequency.interval),
            bandId: frequency.band ? parseInt(frequency.band.id) : 0,
          },
          refetchQueries: [{ query: getFrequenciesQuery }],
        })
      : await addFrequency({
          variables: {
            name: frequency.name,
            downlinkStart: parseFloat(frequency.downlinkStart),
            downlinkCenter: parseFloat(frequency.downlinkCenter),
            downlinkEnd: parseFloat(frequency.downlinkEnd),
            uplinkStart: parseFloat(frequency.uplinkStart),
            uplinkCenter: parseFloat(frequency.uplinkCenter),
            uplinkEnd: parseFloat(frequency.uplinkEnd),
            interval: parseFloat(frequency.interval),
            bandId: frequency.band ? parseInt(frequency.band.id) : 0,
          },
          refetchQueries: [{ query: getFrequenciesQuery }],
        });
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const openModal = (e, frequency) => {
    e.preventDefault();
    setFrequency(frequency);
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownFreqMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownFreqMenuLink"]')
      .classList.remove("show");
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">
            Frequency Band Allocation
          </h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownFreqMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownFreqMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#frequencyModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body table-responsive">
          <div className="row">
            <table className="table table-striped table-sm">
              <thead className="bg-primary text-white">
                <tr>
                  <td>Name</td>
                  <td>Band</td>
                  <td style={{ textAlign: "right" }}>Up Link Start</td>
                  <td style={{ textAlign: "right" }}>Up Link Central</td>
                  <td style={{ textAlign: "right" }}>Up Link End</td>
                  <td style={{ textAlign: "right" }}>Down Link Start</td>
                  <td style={{ textAlign: "right" }}>Down Link Central</td>
                  <td style={{ textAlign: "right" }}>Down Link End</td>
                  <td style={{ textAlign: "right" }}>Interval</td>
                  <td>
                    <span style={{ float: "right" }}>actions</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? null
                  : currentItems.map((freq, index) => {
                      return (
                        <tr key={index}>
                          <td>{freq.name}</td>
                          <td>{freq.band.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 3,
                            }).format(freq.uplinkStart)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 3,
                            }).format(freq.uplinkCenter)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 3,
                            }).format(freq.uplinkEnd)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 3,
                            }).format(freq.downlinkStart)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 3,
                            }).format(freq.downlinkCenter)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {new Intl.NumberFormat("en", {
                              minimumFractionDigits: 3,
                            }).format(freq.downlinkEnd)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {freq.interval}
                          </td>
                          <td>
                            <div style={{ textAlign: "right" }}>
                              <i
                                className="fas fa-fw fa-edit"
                                onClick={(e) => openModal(e, freq)}
                                data-target="#frequencyModal"
                              ></i>
                              <i
                                className="fas fa-fw fa-trash"
                                onClick={(e) =>
                                  window.confirm(
                                    "Are you sure you wish to delete this item?"
                                  ) && deleteItem(e, freq.id)
                                }
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <FrequencyModal
          frequency={frequency}
          technologies={data.technologies}
          services={services}
          bands={bands}
          closeModal={closeModal}
          handleChange={handleChange}
          saveItem={saveItem}
        />
      ) : null}
    </>
  );
};
export default FrequenciesOverview;
