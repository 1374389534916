import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { resetPasswordMutation } from "../queries/queries";
import queryString from "query-string";

const Reset = (props) => {
  const [resetPassword] = useMutation(resetPasswordMutation);
  useEffect(() => {
    document.body.classList.add("bg-gradient-primary");
    document
      .querySelector("#content-wrapper")
      .setAttribute("style", "background-color:transparent");
    return () => {
      document.body.classList.remove("bg-gradient-primary");
    };
  });

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
      })}
      onSubmit={async (fields, { resetForm, setErrors, setSubmitting }) => {
        try {
          const qparam = queryString.parse(props.location.search);
          await resetPassword({
            variables: { resetToken: qparam.resetToken, ...fields },
          });
          const { navigate } = props;
          navigate("/");
        } catch (error) {
          if (error.message.includes("email")) {
            setErrors({ email: error.message.split(":")[1] });
          } else {
            setErrors({ password: error.message.split(":")[1] });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, isSubmitting, handleSubmit }) => {
        return (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div
                  className="card o-hidden border-0 shadow-lg my-5"
                  style={{ width: "30rem", margin: "0 auto" }}
                >
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">
                              Reset Your Password
                            </h1>
                          </div>
                          <Form className="user" onSubmit={handleSubmit}>
                            <div className="form-group">
                              <Field
                                type="password"
                                className={
                                  "form-control form-control-user" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                                id="password"
                                name="password"
                                placeholder="Password"
                                disabled={isSubmitting}
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group">
                              <Field
                                type="password"
                                className={
                                  "form-control form-control-user" +
                                  (errors.confirmPassword &&
                                  touched.confirmPassword
                                    ? " is-invalid"
                                    : "")
                                }
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                disabled={isSubmitting}
                              />
                              <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-user btn-block"
                              disabled={isSubmitting}
                            >
                              Reset Your Password
                            </button>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Reset;
