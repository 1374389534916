import React from 'react';
import { Link } from 'react-router-dom';

const SitebarItem = ({ title, icon, url }) => {
    return (
        <li className="nav-item">
            <Link className="nav-link" to={url}>
                <i className={`fas fa-fw fa-${icon}`}></i>
                <span>{title}</span>
            </Link>
        </li>
    )
};

export default SitebarItem;