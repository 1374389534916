import { gql } from "@apollo/client";

const getFrequenciesStationsQuery = gql`
  {
    frequencies {
      id
      name
      downlinkStart
      downlinkCenter
      downlinkEnd
      uplinkStart
      uplinkCenter
      uplinkEnd
      interval
      service {
        id
        name
      }
    }

    stations {
      id
      description
      latitude
      longitude
      coLocation
      height
      remarks
      contactPerson
      owner
      site {
        id
        description
      }
      phone
      stationType {
        id
        name
        type
      }
      sectors {
        id
        description
      }
      siteStatus
      siteIDUMTS
      siteIDLTE
    }
  }
`;

const getFrequenciesQuery = gql`
  {
    divisions {
      id
      name
    }
    jurisdictions {
      id
      name
    }
    lineFees {
      id
      name
      fbFactor
      technology {
        id
        name
      }
    }
    technologies {
      id
      name
    }
    services {
      id
      name
      technology {
        id
        name
      }
    }
    frequencies {
      id
      name
      downlinkStart
      downlinkCenter
      downlinkEnd
      uplinkStart
      uplinkCenter
      uplinkEnd
      interval
      band {
        id
        name
        service {
          id
          name
          technology {
            id
            name
          }
        }
      }
    }
    bands {
      id
      name
      service {
        id
        name
      }
    }
    allocations {
      id
      frequency
      country {
        id
        name
        code
      }
    }
  }
`;

const getFrequencyQuery = gql`
  query ($id: Int) {
    frequency(id: $id) {
      id
      name
      downlinkStart
      downlinkCenter
      downlinkEnd
      uplinkStart
      uplinkCenter
      uplinkEnd
      interval
      service {
        id
        name
      }
    }
  }
`;

const addFrequencyMutation = gql`
  mutation AddFrequency(
    $name: String
    $downlinkStart: Float
    $downlinkCenter: Float
    $downlinkEnd: Float
    $uplinkStart: Float
    $uplinkCenter: Float
    $uplinkEnd: Float
    $interval: Float
    $bandId: Int
  ) {
    addFrequency(
      name: $name
      downlinkStart: $downlinkStart
      downlinkCenter: $downlinkCenter
      downlinkEnd: $downlinkEnd
      uplinkStart: $uplinkStart
      uplinkCenter: $uplinkCenter
      uplinkEnd: $uplinkEnd
      interval: $interval
      bandId: $bandId
    ) {
      id
      name
    }
  }
`;

const updateFrequencyMutation = gql`
  mutation UpdateFrequency(
    $id: Int!
    $name: String
    $downlinkStart: Float
    $downlinkCenter: Float
    $downlinkEnd: Float
    $uplinkStart: Float
    $uplinkCenter: Float
    $uplinkEnd: Float
    $interval: Float
    $bandId: Int
  ) {
    updateFrequency(
      id: $id
      name: $name
      downlinkStart: $downlinkStart
      downlinkCenter: $downlinkCenter
      downlinkEnd: $downlinkEnd
      uplinkStart: $uplinkStart
      uplinkCenter: $uplinkCenter
      uplinkEnd: $uplinkEnd
      interval: $interval
      bandId: $bandId
    ) {
      id
      name
    }
  }
`;

const deleteFrequencyMutation = gql`
  mutation DeleteFrequency($id: Int!) {
    deleteFrequency(id: $id) {
      id
    }
  }
`;

export const frequencyService = {
  getFrequenciesStationsQuery,
  getFrequenciesQuery,
  getFrequencyQuery,
  addFrequencyMutation,
  updateFrequencyMutation,
  deleteFrequencyMutation,
};
