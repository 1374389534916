import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";

import { COLUMNS } from "./constants";

import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

import TableCard from "./TableCard";

export default function TableStraalVerbindingen({
  users,
  queryData,
  queryPageIndex,
  queryPageSize,
  setPage,
  setLimit,
  setSearch,
  setPageSortBy,
}) {
  //PropsCheck
  console.log({
    TableAddressesPropsCheck: {
      users,
      queryData,
      queryPageIndex,
      queryPageSize,
    },
  });

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => users, [users]);
  const queryPageCount = Math.ceil(queryData.totalItems / queryData.pageSize);

  //ConstantsCheck
  console.log({ TableAddressesConstantsCheck: { queryPageCount } });

  //ConstantVariables
//   const router = useRouter();
  const queryClient = useQueryClient();
  const headerColor = "bg-blue-400";

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    footerGroups,
    rows,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      //   data: statusUsers !== "loading" ? data : [],
      pageCount: queryPageCount,
      initialState: { pageIndex: queryPageIndex, pageSize: queryPageSize },
      manualSortBy: true,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, sortBy } = state;

  React.useEffect(() => {
    setPage(pageIndex);
  }, [pageIndex]);

  React.useEffect(() => {
    setLimit(pageSize);
  }, [pageSize]);

  React.useEffect(() => {
    setPageSortBy(sortBy[0]);
  }, [sortBy]);

  return (
    <>
      <TableCard
        headerColor={headerColor}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        nextPage={nextPage}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        state={state}
        footerGroups={footerGroups}
        rows={rows}
        gotoPage={gotoPage}
        pageCount={pageCount}
        setPageSize={setPageSize}
        prepareRow={prepareRow}
      />
    </>
  );
}
