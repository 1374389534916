import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: inline-block;
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;


const Tooltip = ({ text, children }) => {
    return (
        <Container>{children}
            <span className="tooltiptext">{text}</span>
        </Container>

    )
}

export default Tooltip;