import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  getCompaniesQuery,
  addCompanyMutation,
  getCompanyQuery,
} from "../queries/queries";

import CompanyModal from "../components/PageCompanies/ModalCompany";
import Pagination from "../components/Layout/Pagination";
import { Context } from "../contexts/CompanyContext";

const CompaniesOverview = ({ user }) => {
  const {
    companies,
    countries,
    company,
    setCompany,
    resetCompany,
    setCompanies,
    setCountries,
  } = useContext(Context);

  let navigate = useNavigate();
  const [addCompany] = useMutation(addCompanyMutation);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { loading, data } = useQuery(getCompaniesQuery);

  useEffect(() => {
    if (data) {
      setCompanies(data.companies);
      setCountries(data.countries);
    }
    if (data && user.role.name === "OPERATOR") {
      let company = data.companies.filter((company) => {
        return company.id === user.u_company;
      });
      setCompanies(company);
    }
  }, [data]);

  //Pagination Functions
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalItems = companies.length;
  const currentItems = companies.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      setCompany({ ...company, country: { id: parseInt(value) } });
    } else {
      setCompany({ ...company, [name]: value });
    }
  };

  //Modal Functions
  const saveItem = async (e) => {
    addCompany({
      variables: {
        id: company.id,
        name: company.name,
        address: company.address,
        code: company.code,
        coc: company.coc,
        quickBooksId: company.quickBooksId,
        countryId: company.country ? parseInt(company.country.id) : 0,
        comments: company.comments,
      },
      refetchQueries: [{ query: getCompaniesQuery }],
    });

    closeModal();
  };

  const closeModal = () => {
    resetCompany();
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };

  const openModal = (e) => {
    e.preventDefault();
    const modal = document.querySelector(e.target.dataset.target);

    //null check
    if (!modal) return;

    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };

  // Table functions
  const displayCompanies = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={7} style={{ textAlign: "center" }}>
            companies Loading...
          </td>
        </tr>
      );
    } else {
      return currentItems.map((row, index) => (
        <tr
          key={index}
          onClick={() => navigate(`/companies/${row.id}`)}
          style={{ cursor: "pointer" }}
        >
          <td>{row.name}</td>
          <td>{row.country.name}</td>
          <td>{row.code}</td>
          <td>{row.address}</td>
          <td>{row.coc}</td>
          <td>{row.quickBooksId}</td>
        </tr>
      ));
    }
  };

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Companies</h6>
          <div className="d-flex justify-content-center align-items-center">
            {user.role.name !== "OPERATOR" && (
              <button
                className="btn btn-outline-primary my-2"
                onMouseDown={openModal}
                data-target="#companyModal"
                style={{ fontSize: ".9rem" }}
              >
                <i
                  className="fas fa-plus"
                  style={{ marginRight: "10px", fontSize: ".8rem" }}
                ></i>
                Add New Company
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-hover table-sm">
                <thead className="bg-primary text-white">
                  <tr>
                    <td>Company Name</td>
                    <td>Country</td>
                    <td>PostalCode</td>
                    <td>Address</td>
                    <td>COC</td>
                    <td>QuickbooksID</td>
                  </tr>
                </thead>
                <tbody>{displayCompanies()}</tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <CompanyModal
          company={company}
          closeModal={closeModal}
          countries={countries}
          handleChange={handleChange}
          saveItem={saveItem}
        />
      ) : null}
    </>
  );
};

export default CompaniesOverview;
