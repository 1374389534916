import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useStateValue } from "./formContextProvider";
import success from "../../assets/success.png";
import { useQuery, useMutation } from "@apollo/client";
import { NEXT_URL } from "../../config/index";

import { initialValuesOndertekening } from "./initialValues.js";
import { validationOndertekening } from "./validationSchemaStepper";
import FormSectionVerklaring from "./FormSection14Verklaring";
import { addStraalVerbindingAanvraagMutation } from "../../queries/queries";
import uploadFileToBlob, {
  isStorageConfigured,
} from "../utils/azureStorageUtils";

import ButtonAutofill from "./ButtonAutofill.jsx";

export default function FormStep14({ handleNext, handleReset }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, setState } = useStateValue();
  const [pageStateRouting, setPageStateRouting] = useState("filling");
  const [addAanvraag] = useMutation(addStraalVerbindingAanvraagMutation);

  const saveRequest2 = async (values) => {
    let result = null;
    if (false) {
      console.log("update item");
    } else {
      result = await addAanvraag({ variables: { ...values } });
      console.log({ result });
    }
    if (true) {
      return result;
    } else {
      throw { message: `Oops algu a bai robes: ${result.statusText}` };
    }
  };

  const addItem = async function (data) {
    let result = null;
    result = await fetch(`${NEXT_URL}/api/aanvraagStraalVerbindingen`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return result;
  };

  const saveRequest = async (values) => {
    let result = null;
    const isAddMode = true;

    if (!isAddMode) {
      // result = await updateItem({ ...values }, id);
      // console.log({ result });
    } else {
      result = await addItem({ ...values });
      console.log({ result });
    }
    if (result.ok) {
      return result;
    } else {
      const error = await result.json();
      console.log({ error });
      throw { message: `Oops algu a bai robes: ${result.statusText}` };
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let testing = false;
    setLoading(true);

    //Create NewState
    let processedValues = processValues(values);
    const newState = { ...state, ...processedValues };

    //Create Identity Card File
    const identityCardFile = newState.identityCardFile;
    const fileName = identityCardFile?.name;

    const inputKey = Math.random().toString(36);
    const blobName = `${inputKey}-${fileName}`;
    const fileUrl = `${blobName}`;

    //Create Identity Card Object
    const identityCardObject = { name: fileName, url: fileUrl };
    console.log({ identityCardObject });

    //Update NewState
    newState.identityCardFile = identityCardObject;

    if (testing) {
      alert(JSON.stringify(newState, null, 2));
      console.log(JSON.stringify(newState, null, 2));
      setLoading(false);
      return;
    }
    try {
      if (fileName) {
        const blobsInContainer = await uploadFileToBlob(
          identityCardFile,
          blobName
        );
      }

      setState(newState);
      await saveRequest(newState);
      setPageStateRouting("success");
      resetForm();
      setError(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function processValues(values) {
    let processedValues = {
      ...values,
    };

    return processedValues;
  }

  return (
    <>
      {pageStateRouting === "filling" && (
        <Formik
          initialValues={initialValuesOndertekening}
          validationSchema={validationOndertekening}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, handleChange }) => {
            return (
              <>
                {
                  <Form className="w-full">
                    {error && `${error}`}
                    <FormSectionVerklaring setFieldValue={setFieldValue} />

                    <div className="flex justify-end p-4">
                      <ButtonAutofill setFieldValue={setFieldValue} />
                      <button
                        disabled={loading}
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        {loading ? "Submitting" : "Submit"}
                        <svg
                          className="ml-2 -mr-1 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </Form>
                }
              </>
            );
          }}
        </Formik>
      )}
      {pageStateRouting === "success" && (
        <div>
          <div className="flex flex-col justify-center items-center">
            <h3 className="font-medium leading-tight text-3xl mt-0 mb-2 ">
              Form submited successfully
            </h3>

            <img src={success} className="h-90 w-36 h-36" />
          </div>
          <div className="flex justify-end p-2 pt-4">
            {" "}
            <button
              disabled={loading}
              onClick={() => {
                setPageStateRouting("filling");
                handleReset();
              }}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Go back
            </button>
          </div>
        </div>
      )}
    </>
  );
}
