import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  getBandsQuery,
  updateBandMutation,
  addBandMutation,
  deleteBandMutation,
} from "../../../queries/queries";
import Pagination from "../../Layout/Pagination";
import BandModal from "./ModalBands";

const initialValues = { id: 0, name: "", service: { id: 0 } };

const BandsOverview = () => {
  const [band, setBand] = useState(initialValues);
  const { loading, data } = useQuery(getBandsQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = !loading && data.bands ? data.bands.length : 0;

  const currentItems =
    !loading && data.bands
      ? data.bands.slice(indexOfFirstItem, indexOfLastItem)
      : null;

  const [updateBand] = useMutation(updateBandMutation, {
    variables: {
      id: parseInt(band.id),
      name: band.name,
      serviceId: parseInt(band.service.id),
    },
    refetchQueries: [{ query: getBandsQuery }],
  });
  const [addBand] = useMutation(addBandMutation, {
    variables: {
      name: band.name,
      serviceId: parseInt(band.service.id),
    },
    refetchQueries: [{ query: getBandsQuery }],
  });

  const [deleteBand] = useMutation(deleteBandMutation);

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "service"
      ? setBand({ ...band, service: { id: parseInt(value) } })
      : setBand({ ...band, [name]: value });
  };
  const openModal = (e, band) => {
    setBand(band);
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const deleteItem = async (e, id) => {
    deleteBand({
      variables: {
        id: id,
      },
      refetchQueries: [{ query: getBandsQuery }],
    });
  };

  const saveItem = async () => {
    if (band.service.id === 0) {
      alert("Please Choose a Service!");
    } else {
      band.id ? updateBand() : addBand();
      closeModal();
    }
  };
  const closeModal = () => {
    setBand(band);
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownBandMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownBandMenuLink"]')
      .classList.remove("show");
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">
            Frequency Band Definition
          </h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownBandMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownBandMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#bandModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-condensed table-hover table-sm">
                <thead>
                  <tr className="bg-primary text-white">
                    <td>Name</td>
                    <td>Service</td>
                    <td>
                      <span style={{ float: "right" }}>actions</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3">Loading...</td>
                    </tr>
                  ) : (
                    currentItems.map((band, index) => {
                      return (
                        <tr key={index}>
                          <td>{band.name}</td>
                          <td>{band.service.name}</td>
                          <td>
                            <div style={{ float: "right" }}>
                              <i
                                className="fas fa-fw fa-edit"
                                data-target="#bandModal"
                                onClick={(e) => openModal(e, band)}
                              ></i>
                              <i
                                className="fas fa-fw fa-trash"
                                onClick={(e) =>
                                  window.confirm(
                                    "Are you sure you wish to delete this item?"
                                  )&&
                                  deleteItem(e, band.id)}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                    size="sm"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <BandModal
          band={band}
          services={data.services}
          handleChange={handleChange}
          closeModal={closeModal}
          saveItem={saveItem}
        />
      ) : null}
    </>
  );
};
export default BandsOverview;
