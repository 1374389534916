import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useStateValue } from "./formContextProvider";

import { initalValuesZender } from "./initialValues.js";
import { validationZender } from "./validationSchemaStepper";
import FormSectionLocatieZender from "./FormSection8LocatieZender";
import ButtonAutofill from "./ButtonAutofill.jsx";

export default function FormStep8({ handleNext }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, setState } = useStateValue();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let processedValues = processValues(values);
    setLoading(true);
    if (false) {
      alert(JSON.stringify(processedValues, null, 2));
      console.log(JSON.stringify(processedValues, null, 2));
      setLoading(false);
      return;
    }
    try {
      // await saveRequest(processedValues);
      // setPageStateRouting("success");
      // resetForm();
      // setContext();
      setState({ ...state, ...processedValues });
      console.table(state);
      handleNext();
      setError(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function processValues(values) {
    let processedValues = {
      ...values,
      ZenderCoordinatenOosterlengteGraden1: parseFloat(
        `0.${values.ZenderCoordinatenOosterlengteGraden1}`
      ),
      ZenderCoordinatenOosterlengteGraden2: parseFloat(
        `0.${values.ZenderCoordinatenOosterlengteGraden2}`
      ),
      ZenderCoordinatenNoorderbreedteGraden1: parseFloat(
        `5.${values.ZenderCoordinatenNoorderbreedteGraden1}`
      ),
      ZenderCoordinatenNoorderbreedteGraden2: parseFloat(
        `5.${values.ZenderCoordinatenNoorderbreedteGraden2}`
      ),
    };
    return processedValues;
  }

  return (
    <Formik
      initialValues={initalValuesZender}
      validationSchema={validationZender}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, handleChange }) => {
        return (
          <>
            {
              <Form className="w-full">
                {error && `${error}`}
                <FormSectionLocatieZender setFieldValue={setFieldValue} />

                <div className="flex justify-end p-4">
                  <ButtonAutofill setFieldValue={setFieldValue} />
                  <button
                    disabled={loading}
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {loading ? "Submitting" : "Next"}
                    <svg
                      className="ml-2 -mr-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </Form>
            }
          </>
        );
      }}
    </Formik>
  );
}
