import React, { useEffect, useState } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";

import { REACT_APP_SERVER_URL } from "../../utils/config";
import Modal from "../Layout/Modal";
import LoadingGears from "../LoadingGears";
import { Link } from "react-router-dom";

const status = {
  1: "New",
  2: "Uploading input files",
  3: "Analyzing input images",
  4: "Photogrammetry processing",
  5: "Clipping point cloud",
  7: "Processing completed",
  12: "Extracting video images",
  13: "ERROR - Uploading input files",
  14: "ERROR - Extracting video images",
  15: "ERROR - Analyzing input images",
  16: "ERROR - Photogrammetry processing",
  17: "ERROR - Clipping point cloud",
  19: "ERROR - Not enough valid images",
  20: "Waiting for processing",
  24: "Data purged",
  25: "Camera processing",
  27: "Demo",
};

export default function AssetInfoWindow({
  selectedPlace,
  anchor,
  onCloseClick,
}) {
  const [inspections, setInspections] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  const getInspections = async () => {
    try {
      const requests = selectedPlace.inspections.map((i) =>
        fetch(
          `${REACT_APP_SERVER_URL.replace(
            "/graphql",
            ""
          )}/pix4d/inspections/${i}`
        ).then((res) => res.json())
      );
      return await Promise.all(requests);
    } catch (error) {}
  };

  const getUrl = async (projectId, shareToken) => {
    try {
      return `https://inspect.pix4d.com/embed/${projectId}?shareToken=${shareToken}`;
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = (item) => {
    setLoading(true);
    getUrl(item.project_id, item.token).then((res) => setUrl(res));
    setLoading(false);
    setOpen(true);
  };

  useEffect(() => {
    let isApiSubscribed = true;
    setLoading(true);
    getInspections().then((data) => {
      if (isApiSubscribed) {
        setInspections(data);
      }
    });
    setLoading(false);
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [selectedPlace]);

  return (
    <InfoWindow
      anchor={anchor}
      onCloseClick={onCloseClick}
      data-bs-spy="scroll"
    >
      <div className="">
        <div className="">
          {/* Column */}
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{selectedPlace.name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                {selectedPlace.description}
              </h6>
              <div className="d-flex flex-col">
                {loading || inspections.length === 0 ? (
                  <div className="spinner-border m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {inspections?.map((inspect) => (
                  <a key={inspect.id} onClick={() => handleOpen(inspect)}>
                    {inspect.id}{" "}
                    <span style={{ color: "green" }}>
                      {status[inspect.status]}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <Modal
            id="PIX4D"
            open={open}
            setOpen={setOpen}
            title={selectedPlace.name}
            size="xl"
          >
            {url ? (
              <iframe
                title={selectedPlace.name}
                src={url}
                width="100%"
                height="100%"
                style={{
                  display: "block",
                  width: "100%",
                  height: "75vh",
                }}
              ></iframe>
            ) : (
              <p>loading ...</p>
            )}
          </Modal>
        </div>
      </div>
    </InfoWindow>
  );
}
