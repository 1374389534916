import React from "react";
import ModalViewAttachment from "./ModalViewAttachment";
import ModalViewBlob from "./ModalViewBlob";
import CompanyReportCard from "./CardCompanyReport";

export default function CollapsibleRowCollapsibleRowCompanyReport({
  antenna,
  openPdfModal,
}) {
  //PropsCheck
  console.log({
    CollapsibleRowPCollapsibleRowCompanyReportPropsCheck: {
      antenna,
      openPdfModal,
    },
  });

  const baseUrl = "https://ispstorage143.blob.core.windows.net/identitycards/";

  return (
    <tr id={`collapse${antenna.id}`} className="collapse">
      <td colSpan={5}>
        <table
          className="table table-nostriped table-borderless"
          style={{ width: "100%" }}
        >
          <tbody>
            {/* <tr>
              <td>Akte: </td>
              <td>
                <ModalViewAttachment antenna={antenna} />
                {antenna.antennaType?.model?.hasAttachment && (
                  <i
                    className="fas fa-file-pdf"
                    onClick={(e) =>
                      openPdfModal(e, antenna.antennaType.model.id)
                    }
                  ></i>
                )}
              </td>
            </tr> */}
            <tr>
              <div className="flex justify-center items-center p-10">
                <div className="w-full px-10 shadow-lg">
                  <CompanyReportCard data={antenna} />
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
