import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

import FormCustomSelect from "../FormCustomSelect2";
import FormDropzone from "../FormDropzoneFile";
import FormDropzonee from "../FormDropZonee";

export default function FormSectionNatuurlijkPersoon({
  setFieldValue,
  identityCardFile,
  setIdentityCardFile,
}) {

  const onDropHandler = (files, category, images, setImages) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    files.forEach((file) => {
      console.log({file});
      setImages([file]);
    });

    // files.forEach((file) => {
    //   let img = {};
    //   if (file.type === "application/pdf") {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       img.base64 = event.target.result.split(",")[1];
    //       img.type = event.target.result.split(",")[0];
    //     };
    //     img.name = file.name;
    //     img.categoryId = category;
    //     setImages([...images, img]);
    //     return reader.readAsDataURL(file);
    //   } else {
    //     imageCompression(file, options).then((compressedFile) => {
    //       const reader = new FileReader();
    //       reader.onload = (event) => {
    //         img.base64 = event.target.result.split(",")[1];
    //         img.type = event.target.result.split(",")[0];
    //       };
    //       img.name = compressedFile.name;
    //       img.categoryId = category;

    //       setImages([img, ...images]);
    //       return reader.readAsDataURL(compressedFile);
    //     });
    //   }
    // });
  };

  const onDeleteHandler = (file, category, images, setImages) => {
    setImages(
      images.filter(
        (image) => image.name !== file.name && image.categoryId !== category
      )
    );
  };

  return (
    <>
      <HeadingForm title="3 &nbsp;&nbsp;&nbsp;&nbsp;Vergunning op naam van natuurlijk persoon" />

      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            3.1 Identiteits Nummer
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            name="natuurlijkPersoonBurgerservicenummerInNederland"
            onChange={(e) => {
              setFieldValue(
                "natuurlijkPersoonBurgerservicenummerInNederland",
                e.target.value.toString()
              );
            }}
          />
          <ErrorMessage
            name="natuurlijkPersoonBurgerservicenummerInNederland"
            component="div"
          />
        </div>
        {/* Side information*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="mb-4">
            Geen St. Maarten identiteits nummer? Stuur een kopie van uw
            identiteitsbewijs mee
          </div>
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Col*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            3.2 Identiteits Kaart
          </label>
        </div>
        {/* Form Col*/}
        <Field
          title={"identiteitsKaartFile"}
          name={"identiteitsKaartFile"}
          span={6}
          component={FormDropzonee}
          files={identityCardFile}
          setFiles={setIdentityCardFile}
        />
        {/* <Field
          title={""}
          name={"identiteitsKaartFile"}
          span={6}
          component={FormDropzone}
          onChangeFunction={(value) => {}}
          onDropHandler={onDropHandler}
          onDeleteHandler={onDeleteHandler}
          images={identityCardFile}
          setImages={setIdentityCardFile}
        /> */}
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            3.2 Voorna(a)m(en); voluit
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="natuurlijkPersoonVoornamen"
          />
          <ErrorMessage name="natuurlijkPersoonVoornamen" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            3.3 Voorvoegsel
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="natuurlijkPersoonVoorvoegsel"
          />
          <ErrorMessage name="natuurlijkPersoonVoorvoegsel" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            3.4 Geslachtsnaam
          </label>
        </div>
        {/* Form Col*/}
        <Field
          style={{ backgroundColor: "red" }}
          name={`natuurlijkPersoonGeslachtsnaam`}
          title=" "
          options={[
            { value: "man", label: "man" },
            { value: "vrouw", label: "vrouw" },
          ]}
          component={FormCustomSelect}
          // placeholder="Selektá sekso..."
          isMulti={false}
          // onChangeFunction={async (value) => {
          //   console.log({ value });
          //   setTechnologyId(value);
          // }}
        />
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            3.5 Geboortedatum
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="date"
            name="natuurlijkPersoonGeboortedatum"
          />
          <ErrorMessage name="natuurlijkPersoonGeboortedatum" component="div" />
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
