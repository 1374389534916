import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  getServicesQuery,
  updateServiceMutation,
  addServiceMutation,
} from "../../../queries/queries";

import Pagination from "../../Layout/Pagination";
import ServiceModal from "./ModalServiceTypes";

const initialValues = { id: 0, name: "", technology: { id: 0 } };

const ServicesOverview = () => {
  const [service, setService] = useState(initialValues);
  const { loading, data } = useQuery(getServicesQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = !loading && data.services ? data.services.length : 0;

  const currentItems =
    !loading && data.services
      ? data.services.slice(indexOfFirstItem, indexOfLastItem)
      : null;

  const [updateService] = useMutation(updateServiceMutation, {
    variables: {
      id: parseInt(service.id),
      name: service.name,
      technologyId: parseInt(service.technology.id),
    },
    refetchQueries: [{ query: getServicesQuery }],
  });
  const [addService] = useMutation(addServiceMutation, {
    variables: {
      name: service.name,
      technologyId: parseInt(service.technology.id),
    },
    refetchQueries: [{ query: getServicesQuery }],
  });

  //const [deleteService] = useMutation(deleteServiceMutation);

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "technology"
      ? setService({ ...service, technology: { id: parseInt(value) } })
      : setService({ ...service, [name]: value });
  };
  const openModal = (e, service) => {
    setService(service);
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  // const deleteItem = async (e, id) => {
  //     e.preventDefault();
  //     deleteService({
  //         variables: {
  //             id: parseInt(id)
  //         },
  //         refetchQueries: [{ query: getServicesQuery }]
  //     });
  // }
  const saveItem = async () => {
    if (service.technology.id === 0) {
      alert("Please Choose a Technology");
    } else {
      service.id ? updateService() : addService();
      closeModal();
    }
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownServiceMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownServiceMenuLink"]')
      .classList.remove("show");
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Service Type</h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownServiceMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownServiceMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#serviceModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-sm">
                <thead>
                  <tr className="bg-primary text-white">
                    <td>Name</td>
                    <td>Access Type</td>

                    <td>
                      <span style={{ float: "right" }}>actions</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3">Loading...</td>
                    </tr>
                  ) : (
                    currentItems.map((service, index) => {
                      return (
                        <tr key={index}>
                          <td>{service.name}</td>
                          <td>{service.technology.name}</td>
                          <td>
                            <div style={{ float: "right" }}>
                              <i
                                className="fas fa-fw fa-edit"
                                data-target="#serviceModal"
                                onClick={(e) => openModal(e, service)}
                              ></i>
                              {/* <i className="fas fa-fw fa-trash" onClick={(e) => window.confirm("Are you sure you wish to delete this item?") && deleteItem(e, service.id)}></i> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <ServiceModal
          service={service}
          technologies={data.technologies}
          handleChange={handleChange}
          closeModal={closeModal}
          saveItem={saveItem}
        />
      ) : null}
    </>
  );
};
export default ServicesOverview;
