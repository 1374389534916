import React, {useContext} from 'react';
import SidebarContext from '../../contexts/sidebar.context';


const RoundedCircleSideBarToggle = () =>{
    const { toggleSidebar } = useContext(SidebarContext);
    
    return (
        <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0 m-3" id="sidebarToggle" onClick={toggleSidebar}></button>
      </div>
    )
}

export default RoundedCircleSideBarToggle;


































