import * as Yup from "yup";


export const validationRegulatoryAuthority = Yup.object().shape({
  raTelecommunicationsCoding: Yup.string().nullable().required("This field is required"),
  raTelecommunicationsPoints: Yup.number().nullable().required("This field is required"),
  raAutonomyCoding: Yup.string().nullable().required("This field is required"),
  raAutonomyPoints: Yup.number().nullable().required("This field is required"),
  raAccountabilityCoding: Yup.string().nullable().required("This field is required"),
  raAccountabilityPoints: Yup.number().nullable().required("This field is required"),
  raDiversiviedFundingCoding: Yup.string().nullable().required("This field is required"),
  raDiversiviedFundingPoints: Yup.number().nullable().required("This field is required"),
  raPublicConsultationCoding: Yup.string().nullable().required("This field is required"),
  raPublicConsultationPoints: Yup.number().nullable().required("This field is required"),
  raEnforcementPowerCoding: Yup.string().nullable().required("This field is required"),
  raEnforcementPowerPoints: Yup.number().nullable().required("This field is required"),
  raRegulatorCoding: Yup.string().nullable().required("This field is required"),
  raRegulatorPoints: Yup.number().nullable().required("This field is required"),
  raDisputeResolutionCoding: Yup.string().nullable().required("This field is required"),
  raDisputeResolutionPoints: Yup.number().nullable().required("This field is required"),
  raAppealsDicisionsCoding: Yup.string().nullable().required("This field is required"),
  raAppealsDicisionsPoints: Yup.number().nullable().required("This field is required"),
  raCompetitionAutorithyCoding: Yup.string().nullable().required("This field is required"),
  raCompetitionAutorithyPoints: Yup.number().nullable().required("This field is required"),
});

export const validationRegulatoryMandate = Yup.object().shape({
  rmQualityServiceCoding : Yup.string().nullable().required("This field is required"),
  rmQualityServicePoints: Yup.number().nullable().required("This field is required"),
  rmLicenseCoding: Yup.string().nullable().required("This field is required"),
  rmLicensePoints: Yup.number().nullable().required("This field is required"),
  rmInterconnectionRatesCoding: Yup.string().nullable().required("This field is required"),
  rmInterconnectionRatesPoints: Yup.number().nullable().required("This field is required"),
  rmRadioFrequencyCoding: Yup.string().nullable().required("This field is required"),
  rmRadioFrequencyPoints: Yup.number().nullable().required("This field is required"),
  rmSpectrumMonitoringCoding: Yup.string().nullable().required("This field is required"),
  rmSpectrumMonitoringPoints: Yup.number().nullable().required("This field is required"),
  rmUniversalServiceCoding: Yup.string().nullable().required("This field is required"),
  rmUniversalServicePoints: Yup.number().nullable().required("This field is required"),
  rmBroadcastingCoding: Yup.string().nullable().required("This field is required"),
  rmBroadcastingPoints: Yup.number().nullable().required("This field is required"),
  rmBroadcastingContentCoding: Yup.string().nullable().required("This field is required"),
  rmBroadcastingContentPoints: Yup.number().nullable().required("This field is required"),
  rmInternetContentCoding: Yup.string().nullable().required("This field is required"),
  rmInternetContentPoints: Yup.number().nullable().required("This field is required"),
  rmInformationTechnologyCoding: Yup.string().nullable().required("This field is required"),
  rmInformationTechnologyPoints: Yup.number().nullable().required("This field is required"),
  rmConsumerIssuesCoding: Yup.string().nullable().required("This field is required"),
  rmConsumerIssuesPoints: Yup.number().nullable().required("This field is required"),

});

export const validationRegulatoryRegime = Yup.object().shape({
  rrTypesOfLicencesCoding           : Yup.string().nullable().required("This field is required"),
  rrTypesOfLicencesPoints           : Yup.number().nullable().required("This field is required"),
  rrLicenseExemptCoding             : Yup.string().nullable().required("This field is required"),
  rrLicenseExemptPoints             : Yup.number().nullable().required("This field is required"),
  rrInterconnectionOfferCoding      : Yup.string().nullable().required("This field is required"),
  rrInterconnectionOfferPoints      : Yup.number().nullable().required("This field is required"),
  rrInterconnectionPricesCoding     : Yup.string().nullable().required("This field is required"),
  rrInterconnectionPricesPoints     : Yup.number().nullable().required("This field is required"),
  rrQualityServiceCoding            : Yup.string().nullable().required("This field is required"),
  rrQualityServicePoints            : Yup.number().nullable().required("This field is required"),
  rrInfrastructureMobileCoding      : Yup.string().nullable().required("This field is required"),
  rrInfrastructureMobilePoints      : Yup.number().nullable().required("This field is required"),
  rrInfrastructureMandateCoding     : Yup.string().nullable().required("This field is required"),
  rrInfrastructureMandatePoints     : Yup.number().nullable().required("This field is required"),
  rrColocationMandateCoding         : Yup.string().nullable().required("This field is required"),
  rrColocationMandatePoints         : Yup.number().nullable().required("This field is required"),
  rrUnbundledAccessCoding           : Yup.string().nullable().required("This field is required"),
  rrUnbundledAccessPoints           : Yup.number().nullable().required("This field is required"),
  rrSecondaryTradingCoding          : Yup.string().nullable().required("This field is required"),
  rrSecondaryTradingPoints          : Yup.number().nullable().required("This field is required"),
  rrBandMigrationCoding             : Yup.string().nullable().required("This field is required"),
  rrBandMigrationPoints             : Yup.number().nullable().required("This field is required"),
  rrNumberPortabilityFixedLineCoding: Yup.string().nullable().required("This field is required"),
  rrNumberPortabilityFixedLinePoints: Yup.number().nullable().required("This field is required"),
  rrNumberPortabilityMobileCoding   : Yup.string().nullable().required("This field is required"),
  rrNumberPortabilityMobilePoints   : Yup.number().nullable().required("This field is required"),
  rrIndividualUserVoipCoding        : Yup.string().nullable().required("This field is required"),
  rrIndividualUserVoipPoints        : Yup.number().nullable().required("This field is required"),
  rrNationalPlanCoding              : Yup.string().nullable().required("This field is required"),
  rrNationalPlanPoints              : Yup.number().nullable().required("This field is required"),
});

export const validationCompititionFramework = Yup.object().shape({
  cfLongDistanceFixedLinesCoding: Yup.string().nullable().required("This field is required"),
  cfLongDistanceFixedLinesPoints: Yup.number().nullable().required("This field is required"),
  cfImtServicesCoding: Yup.string().nullable().required("This field is required"),
  cfImtServicesPoints: Yup.number().nullable().required("This field is required"),
  cfCableModemCoding: Yup.string().nullable().required("This field is required"),
  cfCableModemPoints: Yup.number().nullable().required("This field is required"),
  cfLeasedLinesCoding: Yup.string().nullable().required("This field is required"),
  cfLeasedLinesPoints: Yup.number().nullable().required("This field is required"),
  cfInternationalGatewaysCoding: Yup.string().nullable().required("This field is required"),
  cfInternationalGatewaysPoints: Yup.number().nullable().required("This field is required"),
  cfMainFixedLinesCoding: Yup.string().nullable().required("This field is required"),
  cfMainFixedLinesPoints: Yup.number().nullable().required("This field is required"),
  cfLegalConceptSmpCoding: Yup.string().nullable().required("This field is required"),
  cfLegalConceptSmpPoints: Yup.number().nullable().required("This field is required"),
  cfDeterminingSmpCoding: Yup.string().nullable().required("This field is required"),
  cfDeterminingSmpPoints: Yup.number().nullable().required("This field is required"),
  cfForeignParticipationFacilitiesCoding: Yup.string().nullable().required("This field is required"),
  cfForeignParticipationFacilitiesPoints: Yup.number().nullable().required("This field is required"),
  cfForeignParticipationSpectrumCoding: Yup.string().nullable().required("This field is required"),
  cfForeignParticipationSpectrumPoints: Yup.number().nullable().required("This field is required"),
  cfForeignParticipationLocalServicesCoding: Yup.string().nullable().required("This field is required"),
  cfForeignParticipationLocalServicesPoints: Yup.number().nullable().required("This field is required"),
  cfForeignParticipationInternationalServicesCoding: Yup.string().nullable().required("This field is required"),
  cfForeignParticipationInternationalServicesPoints: Yup.number().nullable().required("This field is required"),
  cfForeignParticipationIspCoding: Yup.string().nullable().required("This field is required"),
  cfForeignParticipationIspPoints: Yup.number().nullable().required("This field is required"),
  cfForeignParticipationValueAddedCoding: Yup.string().nullable().required("This field is required"),
  cfForeignParticipationValueAddedPoints: Yup.number().nullable().required("This field is required"), 
});


export const validationNone = Yup.object().shape({})