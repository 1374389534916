import { is } from "date-fns/locale";
import React from "react";
import { Bar } from "react-chartjs-2";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          stepSize: 1000,
        },
      },
    ],
  },
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function BarChartMonthlyCostPerCompany({ data }) {
  //PropsCheck
  console.log({ BarChartMonthlyCostPerCompanyPropsCheck: data });

  //FilterFunctions
  // const getLabels = () => {
  //   data.data.items.map((item) => item.companyName);
  // };
  //Month labels
  const getLabelss = () => {
    return months?.map((item) => item);
  };

  const getData = () => {
    return data.data.items?.map((item) => item);
  };

  const getDataa = function (companyName) {
    const itemArray = [];
    itemArray.push(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    getData().forEach((item) => {
      if (companyName === item.companyName) {
        if (item.createdMonth == 1) {
          itemArray[0] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 2) {
          itemArray[1] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 3) {
          itemArray[2] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 4) {
          itemArray[3] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 5) {
          itemArray[4] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 6) {
          itemArray[5] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 7) {
          itemArray[6] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 8) {
          itemArray[7] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 9) {
          itemArray[8] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 10) {
          itemArray[9] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 11) {
          itemArray[10] = item.totalGuildersAllContractsMonthly || null;
        } else if (item.createdMonth == 12) {
          itemArray[11] = item.totalGuildersAllContractsMonthly || null;
        }
      }
    });
    if (
      itemArray[0] == null &&
      itemArray[1] == null &&
      itemArray[2] == null &&
      itemArray[3] == null &&
      itemArray[4] == null &&
      itemArray[5] == null &&
      itemArray[6] == null &&
      itemArray[7] == null &&
      itemArray[8] == null &&
      itemArray[9] == null &&
      itemArray[10] == null &&
      itemArray[11] == null
    ) {
      return null;
    } else {
      return itemArray;
    }
  };

  console.log("data", getDataa("UTS"));

  const getDatasets = () => {
    const dataset = [
      {
        label: "SMITCOMS",
        data: getDataa("SMITCOMS"),
        backgroundColor: "#416900",
        hidden: true,
      },
      {
        label: "Critical Communication Caribbean (C3)",
        data: getDataa("Critical Communication Caribbean (C3)"),
        backgroundColor: "#64bc9a",
        hidden: true,
      },
      {
        label: "Antelecom",
        data: getDataa("Antelecom"),
        backgroundColor: "#bb51db",
        hidden: true,
      },
      {
        label: "Wireless Telecommunication Network (WTN)",
        data: getDataa("Wireless Telecommunication Network (WTN)"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "Coral Telecom",
        data: getDataa("Coral Telecom"),
        backgroundColor: "#c99fb0",
        hidden: true,
      },
      {
        label: "Radcomm",
        data: getDataa("Radcomm"),
        backgroundColor: "#39395e",
        hidden: true,
      },
      {
        label: "New Technology Group (NTG)",
        data: getDataa("New Technology Group (NTG)"),
        backgroundColor: "#434925",
        hidden: true,
      },
      {
        label: "TelCell",
        data: getDataa("TelCell"),
        backgroundColor: "#f4dda6",
        hidden: true,
      },
      {
        label: "Netstar",
        data: getDataa("Netstar"),
        backgroundColor: "#c95918",
        hidden: true,
      },
      {
        label: "Caribbean Broadcasting System",
        data: getDataa("Caribbean Broadcasting System"),
        backgroundColor: "#8e202b",
        hidden: true,
      },
      {
        label: "Dimisio",
        data: getDataa("Dimisio"),
        backgroundColor: "#634201",
        hidden: true,
      },
      {
        label: "Media One Corparation",
        data: getDataa("Media One Corparation"),
        backgroundColor: "#010f14",
        hidden: true,
      },
      {
        label: "Laser 101",
        data: getDataa("Laser 101"),
        backgroundColor: "#606366",
        hidden: true,
      },
      {
        label: "Oissis 96.3",
        data: getDataa("Oissis 96.3"),
        backgroundColor: "#eac6f4",
        hidden: true,
      },
      {
        label: "Lighthouse Broadcsting Network",
        data: getDataa("Lighthouse Broadcsting Network"),
        backgroundColor: "#ffb07c",
        hidden: true,
      },
      {
        label: "Sint Maarten Broadcasting Network",
        data: getDataa("Sint Maarten Broadcasting Network"),
        backgroundColor: "#db1359",
        hidden: true,
      },
      {
        label: "The Voice of Sint Maarten, PJD3",
        data: getDataa("The Voice of Sint Maarten, PJD3"),
        backgroundColor: "#37f92c",
        hidden: true,
      },
      {
        label: "Hi5 Broadcasting",
        data: getDataa("Hi5 Broadcasting"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "James Broadcasting",
        data: getDataa("James Broadcasting"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "Sint Maarten Government",
        data: getDataa("Sint Maarten Government"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "4 true Communications",
        data: getDataa("4 true Communications"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "UTS",
        data: getDataa("UTS"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "Wilson Broadcasting",
        data: getDataa("Wilson Broadcasting"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "Free Spectrum",
        data: getDataa("Free Spectrum"),
        backgroundColor: "#4169E1",
        hidden: true,
      },
      {
        label: "Delagaf ",
        data: getDataa("Delagaf "),
        backgroundColor: "#4169E1",
        hidden: true,
      },
    ];

    return dataset;
  };

  const getMonthlyCostPerCompanyData = {
    labels: getLabelss(),
    datasets: getDatasets()
  };

  return <Bar options={options} data={getMonthlyCostPerCompanyData} />;
}
