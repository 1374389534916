import { gql } from "@apollo/client";

const getAntennaTypesQuery = gql`
  {
    antennaTypeLookUpList {
      id
      name
    }
    models {
      id
      name
    }
    antennaTypes {
      id
      name
      model {
        id
        name
        attachment
      }
    }
  }
`;
const getAntennaTypeLookUpListQuery = gql`
  {
    antennaTypeLookUpList {
      id
      name
    }
  }
`;

const getAntennaTypeQuery = gql`
  query ($id: Int) {
    antennaTypes(id: $id) {
      id
      name
    }
  }
`;

const getAntennaTypeLookUpQuery = gql`
  query ($id: Int) {
    antennaTypeLookUp(id: $id) {
      id
      name
    }
  }
`;

const addAntennaTypeMutation = gql`
  mutation AddAntennaType($name: String!, $modelId: Int) {
    addAntennaType(name: $name, modelId: $modelId) {
      id
      name
    }
  }
`;

const updateAntennaTypeMutation = gql`
  mutation UpdateAntennaType($id: Int!, $name: String!, $modelId: Int) {
    updateAntennaType(id: $id, name: $name, modelId: $modelId) {
      id
      name
    }
  }
`;

const deleteAntennaTypeMutation = gql`
  mutation DeleteAntennaType($id: Int!) {
    deleteAntennaType(id: $id) {
      id
    }
  }
`;

export const antennaTypeService = {
  getAntennaTypesQuery,
  getAntennaTypeLookUpListQuery,
  getAntennaTypeQuery,
  getAntennaTypeLookUpQuery,
  addAntennaTypeMutation,
  updateAntennaTypeMutation,
  deleteAntennaTypeMutation,
};
