import React from "react";
import { withRouter } from "../../utils/withRouter";
import { useMutation, useQuery } from "@apollo/client";

import { signoutMutation, CURRENT_USER_QUERY } from "../../queries/queries";

const toggleDropdownMenu = () => {
  document
    .querySelector('[aria-labelledby="userDropdown"]')
    .classList.toggle("show");
};

const closeDropdown = () => {
  document
    .querySelector('[aria-labelledby="userDropdown"]')
    .classList.remove("show");
};

const toggleSidebar = () => {
  document.querySelector("#page-top").classList.toggle("sidebar-toggled");
  document.querySelector("#accordionSidebar").classList.toggle("toggled");
};

const Navbar = ({ location, navigate }) => {
  const { loading, data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "network-only",
  });
  const [signout] = useMutation(signoutMutation);

  const handleSignOut = async () => {
    await signout();
    navigate("/login");
  };

  if (
    location.pathname !== "/login" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/reset" &&
    location.pathname !== "/"
  ) {
    if (loading)
      return (
        <div></div>
        // <div className="spinner-border m-5" role="status">
        //   <span className="sr-only">Loading...</span>
        // </div>
      );
    if (data && data.me)
      return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-md-none rounded-circle mr-3"
            type="button"
            onClick={toggleSidebar}
          >
            <i className="fa fa-bars"></i>
          </button>

          <ul className="navbar-nav ml-auto">
            <div className="topbar-divider d-none d-sm-block"></div>

            <li className="nav-item dropdown no-arrow">
              <button
                className="button-link"
                id="userDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleDropdownMenu}
                onBlur={closeDropdown}
              >
                <span className="mr-2 d-none d-lg-inline text-gray-600 small inline-block">
                  {data ? data.me.firstName + " " + data.me.lastName : null}
                  <i className="fas fa-sort-down mx-2"></i>
                </span>
                <img
                  className="img-profile rounded-circle inline-block"
                  src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
                  alt="img"
                />
              </button>
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown"
              >
                <button className="dropdown-item" onMouseDown={handleSignOut}>
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </button>
              </div>
            </li>
          </ul>
        </nav>
      );
  }
  return null;
};

export default withRouter(Navbar);
