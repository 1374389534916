import React from "react";
import { useQuery } from "@apollo/client";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/Layout/Navbar";
import LoadingTelecom from "./components/LoadingTelecom";
import LoadingGears from "./components/LoadingGears";

import "./App.css";

import FormPageStraalVerbinding from "./pages/FormPageStraalVerbinding";
import FormPageEquipment from "./pages/FormPageEquipment";

import Sidebar from "./components/sidebar/Sidebar";

import CompanyReport from "./components/PageFinancialCompanyReport/CompanyReport";
import MonthlyReport from "./components/PageFinancialMonthlyReport/MonthlyReport";

import TransmissionEquipment from "./pages/PageSettingsAntennaAttributes";
import FrequencyAllocationOverview from "./pages/PageSettingsFrequencyAllocation";
import TransmissionFacility from "./pages/PageSettingsTransmissionFacility";
import CountryOverView from "./pages/PageSettingsCountries";
import UsersOverview from "./pages/PageSettingsUsers";
import LineFee from "./pages/PageSettingsLineFees";

import { useLocation } from "react-router-dom";

import { CURRENT_USER_QUERY } from "./queries/queries";
import Footer from "./components/Layout/Footer";
import OverviewPage3DInspect from "./pages/PageInspector";
import DashboardFrequency from "./pages/PageFrequencies";
import DashboardPage from "./pages/PageDashboard";
import Login from "./pages/FormPageLogin";
import CompanyView from "./pages/PageCompany";
import ForgotPassword from "./pages/FormPageForgotPassword";
import Register from "./pages/FormPageUser";
import Reset from "./pages/FormPagePassword";
import StationsOverview from "./pages/PageStations";
import Forbidden from "./pages/PageForbidden";
import CompaniesOverview from "./pages/PageCompanies";
import AntennaeOverview from "./pages/PageEquipments2";
import LandingPage from "./pages/PageHome";
import PageStraalVerbindingen from "./pages/PageStraalVerbindingen";
import PageCompanyReports from "./pages/PageCompanyReports";
import PageAnalyticsFinancial from "./pages/PageAnalyticsFinancial";
import FormPageRegulatoryTracker from "./pages/FormPageRegulatoryTracker";
import PageInspections from "./pages/PageInspections";

const PrivateRoute = ({ component: Component, roles, location }) => {
  const { loading, data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      // <div className="spinner-border m-5" role="status">
      //   <span className="sr-only">Loading...</span>
      // </div>
      <LoadingGears />
    );

  if (data && data.me) {
    if (
      roles?.includes(data?.me?.role?.name) ||
      data?.me?.role?.name === "ADMIN"
    ) {
      return <Component user={data.me} />;
    } else {
      if (data?.me?.role?.name === "INTAKE") {
        return <Navigate to="/formPageStraalVerbinding" replace />;
      }
      return <Navigate to="/forbidden" replace />;
    }
  } else {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
};

const App = () => {
  const { loading, data } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "network-only",
  });

  const location = useLocation();
  const pathname = location.pathname;

  if (loading) {
    return <LoadingTelecom />;
  }

  if (!data && !data.me) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <>
      {pathname !== "/3DEditor" && <Sidebar role={data.me?.role?.name} />}
      <div id="content-wrapper" className="flex flex-col">
        <div id="content">
          {pathname !== "/3DEditor" && <Navbar />}
          <Routes>
            <Route
              path="/dashboard-overall"
              element={
                <PrivateRoute
                  component={DashboardPage}
                  roles={["TECHNICAL", "FINANCE", "OPERATOR"]}
                  location={location}
                />
              }
            />
            <Route
              path="/dashboard-frequency"
              element={
                <PrivateRoute
                  component={DashboardFrequency}
                  roles={["OPERATOR", "TECHNICAL"]}
                  location={location}
                />
              }
            />
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/forbidden" element={<Forbidden />} />
            <Route
              path="/companies"
              element={
                <PrivateRoute
                  component={CompaniesOverview}
                  roles={["TECHNICAL", "OPERATOR"]}
                  location={location}
                />
              }
            />
            <Route
              path="/companies/:id"
              element={
                <PrivateRoute
                  component={CompanyView}
                  roles={["TECHNICAL", "OPERATOR"]}
                  location={location}
                />
              }
            />
            <Route
              path="/company-report"
              element={
                <PrivateRoute
                  component={CompanyReport}
                  roles={["FINANCE"]}
                  location={location}
                />
              }
            />
            <Route
              path="/monthly-report"
              element={
                <PrivateRoute
                  component={MonthlyReport}
                  roles={["FINANCE"]}
                  location={location}
                />
              }
            />
            <Route
              path="/frequencies"
              element={
                <PrivateRoute
                  component={FrequencyAllocationOverview}
                  location={location}
                />
              }
            />
            <Route
              path="/transmisson-facility"
              element={
                <PrivateRoute
                  component={TransmissionFacility}
                  location={location}
                />
              }
            />
            <Route
              path="/transmitter"
              element={
                <PrivateRoute
                  component={StationsOverview}
                  roles={["TECHNICAL", "OPERATOR"]}
                  location={location}
                />
              }
            />
            <Route
              path="/inspections"
              element={
                <PrivateRoute
                  component={PageInspections}
                  roles={["OPERATOR", "TECHNICAL"]}
                  location={location}
                />
              }
            />
            <Route
              path="/users-overview"
              element={
                <PrivateRoute component={UsersOverview} location={location} />
              }
            />
            <Route
              path="/transmission-equipment"
              element={
                <PrivateRoute
                  component={TransmissionEquipment}
                  roles={["TECHNICAL"]}
                  location={location}
                />
              }
            />
            <Route
              path="/equipments"
              element={
                <PrivateRoute
                  component={AntennaeOverview}
                  roles={["TECHNICAL", "OPERATOR"]}
                  location={location}
                />
              }
            />
            <Route
              path="/country-overview"
              element={<PrivateRoute component={CountryOverView} />}
            />
            <Route
              path="/line-fees-overview"
              element={<PrivateRoute component={LineFee} />}
              location={location}
            />
            <Route
              path="/3DEditor"
              element={
                <PrivateRoute
                  component={OverviewPage3DInspect}
                  roles={["TECHNICAL", "OPERATOR"]}
                  location={location}
                />
              }
            />
            <Route
              path="/FormPageEquipment"
              element={
                <PrivateRoute
                  component={FormPageEquipment}
                  roles={["TECHNICAL", "OPERATOR"]}
                  location={location}
                />
              }
            />
            <Route
              path="/FormPageEquipmentEdit/:id"
              element={
                <PrivateRoute
                  component={FormPageEquipment}
                  roles={["TECHNICAL", "OPERATOR"]}
                  location={location}
                />
              }
              roles={["TECHNICAL", "OPERATOR"]}
            />
            <Route
              path="/PageStraalVerbindingen"
              element={
                <PrivateRoute
                  component={PageStraalVerbindingen}
                  roles={["TECHNICAL", "OPERATOR", "INTAKE"]}
                  location={location}
                />
              }
            />
            <Route
              path="/PageCompanyReports"
              element={
                <PrivateRoute
                  component={PageCompanyReports}
                  roles={["TECHNICAL", "OPERATOR", "FINANCE"]}
                  location={location}
                />
              }
            />
            <Route
              path="/PageAnalyticsFinancial"
              element={
                <PrivateRoute
                  component={PageAnalyticsFinancial}
                  roles={["TECHNICAL", "OPERATOR", "FINANCE"]}
                  location={location}
                />
              }
            />
            <Route
              path="/formPageStraalVerbinding"
              element={<FormPageStraalVerbinding />}
            />
            <Route
              path="/FormPageRegulatoryTracker"
              element={<FormPageRegulatoryTracker />}
            />
          </Routes>
        </div>

        {pathname !== "/3DEditor" && <Footer />}
      </div>
    </>
  );
};

export default App;
