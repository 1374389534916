import React from 'react'

export default function HeadingForm({title, subTitle}) {
  return (
    <>
      <div className="mt-4 text-xl font-bold leading-normal mb-2 text-gray-700">
        {title}
      </div>
      <div className="mt-2 ml-10 leading-normal mb-2 text-gray-600">
        {subTitle}
      </div>
      <div className="mt-10 mb-2 border-b-2 border-gray-200"></div>
    </>
  );
}
