import { gql } from "@apollo/client";

const getAttachmentQuery = gql`
  query ($id: Int) {
    attachment(id: $id) {
      id
      name
      base64
    }
  }
`;

export const attachmentService = {
  getAttachmentQuery,
};
