import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  getTechnologiesQuery,
  updateTechnologyMutation,
  addTechnologyMutation,
  deleteTechnologyMutation,
} from "../../../queries/queries";

import Pagination from "../../Layout/Pagination";
import TechnologyModal from "./ModalTechnologies";

const initialValues = { id: 0, name: "" };

const TechnologiesOverview = () => {
  const [technology, setTechnology] = useState(initialValues);
  const { loading, data } = useQuery(getTechnologiesQuery);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems =
    !loading && data.technologies ? data.technologies.length : 0;

  const currentItems =
    !loading && data.technologies
      ? data.technologies.slice(indexOfFirstItem, indexOfLastItem)
      : null;

  const [updateTechnology] = useMutation(updateTechnologyMutation, {
    variables: {
      id: parseInt(technology.id),
      name: technology.name,
    },
    refetchQueries: [{ query: getTechnologiesQuery }],
  });
  const [addTechnology] = useMutation(addTechnologyMutation, {
    variables: {
      name: technology.name,
    },
    refetchQueries: [{ query: getTechnologiesQuery }],
  });
  const [deleteTechnology] = useMutation(deleteTechnologyMutation);

  const deleteItem = async (e, id) => {
    deleteTechnology({
      variables: {
        id,
      },
      refetchQueries: [{ query: getTechnologiesQuery }],
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTechnology({ ...technology, [name]: value });
  };
  const openModal = (e, technology) => {
    setTechnology(technology);
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const saveItem = async () => {
    technology.id ? updateTechnology() : addTechnology();
    closeModal();
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownTechnologyMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownTechnologyMenuLink"]')
      .classList.remove("show");
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">
            Access Technology Type
          </h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownTechnologyMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownTechnologyMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#technologyModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-condensed table-hover table-sm">
                <thead>
                  <tr className="bg-primary text-white">
                    <td>name</td>
                    <td>
                      <span style={{ float: "right" }}>actions</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="2">Loading...</td>
                    </tr>
                  ) : (
                    currentItems.map((technology, index) => {
                      return (
                        <tr key={index}>
                          <td>{technology.name}</td>
                          <td>
                            <div style={{ float: "right" }}>
                              <i
                                className="fas fa-fw fa-edit"
                                data-target="#technologyModal"
                                onClick={(e) => openModal(e, technology)}
                              ></i>
                              <i
                                className="fas fa-fw fa-trash"
                                onClick={(e) =>
                                  window.confirm(
                                    "Are you sure you wish to delete this item?"
                                  ) && deleteItem(e, technology.id)
                                }
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TechnologyModal
        technology={technology}
        handleChange={handleChange}
        closeModal={closeModal}
        saveItem={saveItem}
      />
    </>
  );
};
export default TechnologiesOverview;
