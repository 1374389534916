import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import SidebarContext from '../../contexts/sidebar.context';
import LogoSmall from './logo-icon-DataAxis.png';
import Logo from './logo-DataAxis.png';

const imgLogoStyle = {
    obectFit: 'cover',
    width: 185
}

const imgSmLogoStyle = {
    obectFit: 'cover',
    width: 52
}

const SidebarBrand = () => {
    const { toggled } = useContext(SidebarContext);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [width])
    return (
        <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard-overall">
            <div className="sidebar-brand-icon">
                <img style={width > 768 && !toggled ? imgLogoStyle : imgSmLogoStyle} src={width > 768 && !toggled ? Logo : LogoSmall} alt="logo" />
            </div>
        </Link>
    )
}

export default SidebarBrand;