import React from 'react';


import Country from './../components/PageSettings/PageSettingsCountries/Countries';
import CountryAllocation from './../components/PageSettings/PageSettingsCountries/CountryAllocation';

const CountryOverView = () => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6">
                    <Country />
                </div>
                <div className="col-lg-6">
                    <CountryAllocation />
                </div>
            </div>
        </div>
    )

}

export default CountryOverView;