import { allocationService } from "../services/allocationService";
import { antennaService } from "../services/antennaService";
import { antennaTypeLookUpService } from "../services/antennaTypeLookUpService";
import { antennaTypeService } from "../services/antennaTypeService";
import { attachmentService } from "../services/attachmentService";
import { bandService } from "../services/bandService";
import { brandService } from "../services/brandService";
import { companyService } from "../services/companyService";
import { contactService } from "../services/contactService";
import { contractPdfService } from "../services/contractPdfService";
import { contractService } from "../services/contractService";
import { countryService } from "../services/countryService";
import { frequencyService } from "../services/frequencyService";
import { lineFeeService } from "../services/lineFeeService";
import { modelService } from "../services/modelService";
import { serviceService } from "../services/serviceService";
import { siteService } from "../services/siteService";
import { stationService } from "../services/stationService";
import { stationTypeService } from "../services/stationTypeService";
import { straalVerbindingAanvraagService } from "../services/straalVerbindingAanvraagService";
import { technologyService } from "../services/technologyService";
import { towerTypeService } from "../services/towerTypeService";
import { userService } from "../services/userService";

//userService
const CURRENT_USER_QUERY = userService.CURRENT_USER_QUERY;
const getUserQuery = userService.getUserQuery;
const getAllUsersQuery = userService.getAllUsersQuery;
const signupMutation = userService.signupMutation;
const signinMutation = userService.signinMutation;
const signoutMutation = userService.signoutMutation;
const requestResetMutation = userService.requestResetMutation;
const resetPasswordMutation = userService.resetPasswordMutation;
const updatePermissionsMutation = userService.updatePermissionsMutation;
const addUserMutation = userService.addUserMutation;
const updateUserMutation = userService.updateUserMutation;
const deleteUserMutation = userService.deleteUserMutation;
//companyService
const getCompaniesQuery = companyService.getCompaniesQuery;
const getCompanyQuery = companyService.getCompanyQuery;
const addCompanyMutation = companyService.addCompanyMutation;
const updateCompanyMutation = companyService.updateCompanyMutation;
const deleteCompanyMutation = companyService.deleteCompanyMutation;
//contractSercie
const getContractQuery = contractService.getContractQuery;
const getContractsQuery = contractService.getContractsQuery;
const getContractsByCompanyQuery = contractService.getContractsByCompanyQuery;
const addContractMutation = contractService.addContractMutation;
const updateContractMutation = contractService.updateContractMutation;
const deleteContractMutation = contractService.deleteContractMutation;
//contractPdfService
const getContractPdfsQuery = contractPdfService.getContractPdfsQuery;
const getContractPdfsByContractQuery =
  contractPdfService.getContractPdfsByContractQuery;
const addContractPdfMutation = contractPdfService.addContractPdfMutation;
//attachmentService
const getAttachmentQuery = attachmentService.getAttachmentQuery;
//frequencyService
const getFrequenciesQuery = frequencyService.getFrequenciesQuery;
const getFrequencyQuery = frequencyService.getFrequencyQuery;
const addFrequencyMutation = frequencyService.addFrequencyMutation;
const updateFrequencyMutation = frequencyService.updateFrequencyMutation;
const deleteFrequencyMutation = frequencyService.deleteFrequencyMutation;
const getFrequenciesStationsQuery =
  frequencyService.getFrequenciesStationsQuery;
//stationService
const getStationsQuery = stationService.getStationsQuery;
const getStationQuery = stationService.getStationQuery;
const addStationMutation = stationService.addStationMutation;
const updateStationMutation = stationService.updateStationMutation;
const deleteStationMutation = stationService.deleteStationMutation;
//stationTypeService
const getStationTypesQuery = stationTypeService.getStationTypesQuery;
const getStationTypeQuery = stationTypeService.getStationTypeQuery;
const addStationTypeMutation = stationTypeService.addStationTypeMutation;
const updateStationTypeMutation = stationTypeService.updateStationTypeMutation;
const deleteStationTypeMutation = stationTypeService.deleteStationTypeMutation;
//siteService
const getSitesQuery = siteService.getSitesQuery;
const getSiteQuery = siteService.getSiteQuery;
const addSiteMutation = siteService.addSiteMutation;
const updateSiteMutation = siteService.updateSiteMutation;
const deleteSiteMutation = siteService.deleteSiteMutation;
//bandService
const getBandsQuery = bandService.getBandsQuery;
const getBandQuery = bandService.getBandQuery;
const addBandMutation = bandService.addBandMutation;
const updateBandMutation = bandService.updateBandMutation;
const deleteBandMutation = bandService.deleteBandMutation;
//technologyService
const getTechnologiesQuery = technologyService.getTechnologiesQuery;
const getTechnologyQuery = technologyService.getTechnologyQuery;
const addTechnologyMutation = technologyService.addTechnologyMutation;
const updateTechnologyMutation = technologyService.updateTechnologyMutation;
const deleteTechnologyMutation = technologyService.deleteTechnologyMutation;
//serviceService
const getServicesQuery = serviceService.getServicesQuery;
const getServiceQuery = serviceService.getServiceQuery;
const addServiceMutation = serviceService.addServiceMutation;
const updateServiceMutation = serviceService.updateServiceMutation;
const deleteServiceMutation = serviceService.deleteServiceMutation;
//brandService
const getBrandsQuery = brandService.getBrandsQuery;
const getBrandQuery = brandService.getBrandQuery;
const addBrandMutation = brandService.addBrandMutation;
const updateBrandMutation = brandService.updateBrandMutation;
const deleteBrandMutation = brandService.deleteBrandMutation;
//modelService
const getModelsQuery = modelService.getModelsQuery;
const getmodelQuery = modelService.getmodelQuery;
const addModelMutation = modelService.addModelMutation;
const updateModelMutation = modelService.updateModelMutation;
const deleteModelMutation = modelService.deleteModelMutation;
//allocationService
const getAllocationQuery = allocationService.getAllocationQuery;
const getAllocationsQuery = allocationService.getAllocationsQuery;
const addAllocationMutation = allocationService.addAllocationMutation;
const updateAllocationMutation = allocationService.updateAllocationMutation;
const deleteAllocationMutation = allocationService.deleteAllocationMutation;
//antennaService
const getAntennaeQuery = antennaService.getAntennaeQuery;
const getAntennaQuery = antennaService.getAntennaQuery;
const addAntennaMutation = antennaService.addAntennaMutation;
const updateAntennaMutation = antennaService.updateAntennaMutation;
const deleteAntennaMutation = antennaService.deleteAntennaMutation;
//antennaTypeService
const getAntennaTypesQuery = antennaTypeService.getAntennaTypesQuery;
const getAntennaTypeQuery = antennaTypeService.getAntennaTypeQuery;
const addAntennaTypeMutation = antennaTypeService.addAntennaTypeMutation;
const updateAntennaTypeMutation = antennaTypeService.updateAntennaTypeMutation;
const deleteAntennaTypeMutation = antennaTypeService.deleteAntennaTypeMutation;
//antennaTypeLookUpService
const getAntennaTypeLookUpListQuery =
  antennaTypeLookUpService.getAntennaTypeLookUpListQuery;
const getAntennaTypeLookUpQuery =
  antennaTypeLookUpService.getAntennaTypeLookUpQuery;
const addAntennaTypeLookUpMutation =
  antennaTypeLookUpService.addAntennaTypeLookUpMutation;
const updateAntennaTypeLookUpMutation =
  antennaTypeLookUpService.updateAntennaTypeLookUpMutation;
const deleteAntennaTypeLookUpMutation =
  antennaTypeLookUpService.deleteAntennaTypeLookUpMutation;
//towerTypeService
const getTowerTypesQuery = towerTypeService.getTowerTypesQuery;
const getTowerTypeQuery = towerTypeService.getTowerTypeQuery;
const addTowerTypeMutation = towerTypeService.addTowerTypeMutation;
const updateTowerTypeMutation = towerTypeService.updateTowerTypeMutation;
const deleteTowerTypeMutation = towerTypeService.deleteTowerTypeMutation;
//lineFeeService
const getLineFeesQuery = lineFeeService.getLineFeesQuery;
const getLineFeeQuery = lineFeeService.getLineFeeQuery;
const addLineFeeMutation = lineFeeService.addLineFeeMutation;
const updateLineFeeMutation = lineFeeService.updateLineFeeMutation;
const deleteLineFeeMutation = lineFeeService.deleteLineFeeMutation;
//countryService
const addCountryMutation = countryService.addCountryMutation;
const updateCountryMutation = countryService.updateCountryMutation;
const deleteCountryMutation = countryService.deleteCountryMutation;
const getCountriesQuery = countryService.getCountriesQuery;
//contactService
const getContactsByCompanyQuery = contactService.getContactsByCompanyQuery;
const addContactMutation = contactService.addContactMutation;
const updateContactMutation = contactService.updateContactMutation;
const deleteContactMutation = contactService.deleteContactMutation;
//straalVerbindingAanvraagService
const addStraalVerbindingAanvraagMutation =
  straalVerbindingAanvraagService.addStraalVerbindingAanvraagMutation;

export {
  CURRENT_USER_QUERY,
  getUserQuery,
  getAllUsersQuery,
  getCompaniesQuery,
  getCountriesQuery,
  getCompanyQuery,
  getContactsByCompanyQuery,
  getContractQuery,
  getContractsQuery,
  getContractsByCompanyQuery,
  getContractPdfsQuery,
  getContractPdfsByContractQuery,
  getAttachmentQuery,
  getFrequenciesQuery,
  getFrequencyQuery,
  getStationsQuery,
  getStationQuery,
  getFrequenciesStationsQuery,
  getStationTypesQuery,
  getStationTypeQuery,
  getSitesQuery,
  getSiteQuery,
  getBandsQuery,
  getBandQuery,
  getTechnologiesQuery,
  getTechnologyQuery,
  getServicesQuery,
  getServiceQuery,
  getBrandsQuery,
  getBrandQuery,
  getModelsQuery,
  getmodelQuery,
  getAllocationQuery,
  getAllocationsQuery,
  getAntennaTypesQuery,
  getAntennaTypeQuery,
  getAntennaTypeLookUpListQuery,
  getAntennaTypeLookUpQuery,
  getAntennaeQuery,
  getAntennaQuery,
  getTowerTypesQuery,
  getTowerTypeQuery,
  getLineFeesQuery,
  getLineFeeQuery,
  signupMutation,
  signinMutation,
  signoutMutation,
  requestResetMutation,
  resetPasswordMutation,
  updatePermissionsMutation,
  addUserMutation,
  updateUserMutation,
  deleteUserMutation,
  addCompanyMutation,
  updateCompanyMutation,
  deleteCompanyMutation,
  addCountryMutation,
  updateCountryMutation,
  addContactMutation,
  deleteCountryMutation,
  updateContactMutation,
  deleteContactMutation,
  addContractMutation,
  updateContractMutation,
  deleteContractMutation,
  addContractPdfMutation,
  addTechnologyMutation,
  updateTechnologyMutation,
  deleteTechnologyMutation,
  addServiceMutation,
  updateServiceMutation,
  deleteServiceMutation,
  addFrequencyMutation,
  updateFrequencyMutation,
  deleteFrequencyMutation,
  addStationMutation,
  updateStationMutation,
  deleteStationMutation,
  addStationTypeMutation,
  updateStationTypeMutation,
  deleteStationTypeMutation,
  addSiteMutation,
  updateSiteMutation,
  deleteSiteMutation,
  addBandMutation,
  updateBandMutation,
  deleteBandMutation,
  addBrandMutation,
  updateBrandMutation,
  deleteBrandMutation,
  addModelMutation,
  updateModelMutation,
  deleteModelMutation,
  addAntennaTypeMutation,
  updateAntennaTypeMutation,
  deleteAntennaTypeMutation,
  addAntennaTypeLookUpMutation,
  updateAntennaTypeLookUpMutation,
  deleteAntennaTypeLookUpMutation,
  addAntennaMutation,
  updateAntennaMutation,
  deleteAntennaMutation,
  addTowerTypeMutation,
  updateTowerTypeMutation,
  deleteTowerTypeMutation,
  addAllocationMutation,
  updateAllocationMutation,
  deleteAllocationMutation,
  addLineFeeMutation,
  updateLineFeeMutation,
  deleteLineFeeMutation,
  addStraalVerbindingAanvraagMutation,
};
