import React from "react";
import { Link } from "react-router-dom";
import "./sidebar-accordion-item.styles.css";

const SitebarAccordionItem = ({ title, subTitle, icon, items = [] }) => {
  const toggleAccordion = (e) => {
    e.preventDefault();
    const container = document.querySelector(e.target.dataset.target);
    const btn = e.target;
    btn.classList.toggle("collapsed");
    container.classList.toggle("show");
  };

  const fixedTitle = title.replace(/\s/g, "");
  return (
    <li className="nav-item">
      <button
        className="nav-link collapsed"
        onClick={toggleAccordion}
        data-toggle="collapse"
        data-target={`#collapse${fixedTitle}`}
        aria-expanded="true"
        aria-controls={`collapse${fixedTitle}`}
      >
        <i
          className={`fas fa-fw fa-${icon}`}
          style={{ pointerEvents: "none" }}
        ></i>
        <span style={{ pointerEvents: "none" }}>{title}</span>
      </button>
      <div
        id={`collapse${fixedTitle}`}
        className="collapse"
        style={{ display: "block" }}
        aria-labelledby={`heading${fixedTitle}`}
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          <h6 className="collapse-header">{subTitle ? subTitle : title}</h6>
          {items.map((item, index) => (
            <Link key={index} className="collapse-item" to={item.url}>
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </li>
  );
};

export default SitebarAccordionItem;
