import { gql } from "@apollo/client";

const getStationTypesQuery = gql`
  {
    stationTypes {
      id
      name
      type
    }
  }
`;

const getStationTypeQuery = gql`
  query ($id: Int) {
    stationType(id: $id) {
      id
      name
      type
    }
  }
`;

const addStationTypeMutation = gql`
  mutation AddStationType($name: String!, $type: String) {
    addStationType(name: $name, type: $type) {
      id
      name
      type
    }
  }
`;

const updateStationTypeMutation = gql`
  mutation UpdateStationType($id: Int!, $name: String!, $type: String) {
    updateStationType(id: $id, name: $name, type: $type) {
      id
      name
      type
    }
  }
`;

const deleteStationTypeMutation = gql`
  mutation DeleteStationType($id: Int!) {
    deleteStationType(id: $id) {
      id
    }
  }
`;

export const stationTypeService = {
  getStationTypesQuery,
  getStationTypeQuery,
  addStationTypeMutation,
  updateStationTypeMutation,
  deleteStationTypeMutation,
};
