import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionVergunning({setAanNaamVan}) {
  const [isLessThan5Years, setIslessThan5Years] = useState(false);

  return (
    <>
      <HeadingForm title="1 &nbsp;&nbsp;&nbsp;&nbsp;Vergunning Aanvragen" />
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            1.1 Relatienummer
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            name="Relatienummer"
          />
          <ErrorMessage name="Relatienummer" component="div" />
        </div>

        {/* Side information*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          Heeft u nog geen relatienummer bij BTP St Maarten? Vul dan niets in
        </div>
      </div>
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            1.2 Vergunnings Periode
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="Vergunningsperiode"
                value="5 Jaar"
                onClick={() => {
                  setIslessThan5Years(false);
                }}
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                5 Jaar
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="Vergunningsperiode"
                value="Korter dan 5 jaar"
                onClick={() => {
                  setIslessThan5Years(true);
                }}
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Korter dan 5 jaar
              </label>
            </div>
            <ErrorMessage name="Vergunningsperiode" component="div" />
            {isLessThan5Years && (
              <>
                <div className="grid grid-cols-12 gap-4">
                  <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
                    Van
                  </div>{" "}
                  <div className=" col-span-full md:col-span-8 sm:col-span-8 xl:col-span-8">
                    <Field
                      className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      name="VergunningsperiodeVan"
                    />
                    <ErrorMessage
                      name="VergunningsperiodeVan"
                      component="div"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-4">
                  <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
                    Tot
                  </div>{" "}
                  <div className=" col-span-full md:col-span-8 sm:col-span-8 xl:col-span-8">
                    <Field
                      className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      name="VergunningsperiodeTot"
                    />
                    <ErrorMessage
                      name="VergunningsperiodeTot"
                      component="div"
                    />
                  </div>
                </div>
              </>
            )}
          </fieldset>
        </div>

        {/* Side information*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="mb-4">
            De vergunning wordt na 5 jaar van rechtswege verlengd
          </div>
          <div>
            De vergunning wordt ingetrokken op de opgegeven datum en kan niet
            worden verlengd.
          </div>
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            1.3 U vraagt de vergunning aan op naam van een
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="VergunningPersoonType"
                value="Rechtspersoon"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                onClick={() => setAanNaamVan("Rechtspersoon")}
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Rechtspersoon
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="VergunningPersoonType"
                value="Natuurlijk persoon"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                onClick={() => setAanNaamVan("Natuurlijk persoon")}
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Natuurlijk persoon
              </label>
            </div>
          </fieldset>
          <ErrorMessage name="VergunningPersoonType" component="div" />
        </div>

        {/* Side information*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="mb-4">Ga door naar vraag 2 en sla vraag 3 over</div>
          <div>Ga door naar vraag 3</div>
        </div>
      </div>
    </>
  );
}
