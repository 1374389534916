import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useStateValue } from "./formContextProvider";

import { initialValuesAntenne } from "./initialValues.js";
import { validationAntenne } from "./validationSchemaStepper";
import FormSectionAntenneGegevens from "./FormSection9AntenneGegevens";
import ButtonAutofill from "./ButtonAutofill.jsx";

export default function FormStep9({
  handleNext,
  dataCompanies,
  dataTechnologies,
  dataServices,
  dataFrequencies,
  dataContracts,
}) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, setState } = useStateValue();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const testing = false;
    
    let processedValues = processValues(values);

    setLoading(true);
    if (testing) {
      alert(JSON.stringify(processedValues, null, 2));
      console.log(JSON.stringify(processedValues, null, 2));
      setLoading(false);
      return;
    }
    try {
      // await saveRequest(processedValues);
      // setPageStateRouting("success");
      // resetForm();
      // setContext();
      setState({ ...state, ...processedValues });
      console.table(state);
      handleNext();
      setError(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function processValues(values) {
    let processedValues = {
      ...values,
      AntenneOvereenkomstigLijstMetAntenneprofielen2:
        values.AntenneOvereenkomstigLijstMetAntenneprofielen2 == "Ja" ? 1 : 0,
      AntenneOvereenkomstigLijstMetAntenneprofielen1:
        values.AntenneOvereenkomstigLijstMetAntenneprofielen1 == "Ja" ? 1 : 0,
    };
    console.log({ processedValues });
    return processedValues;
  }

  return (
    <Formik
      initialValues={initialValuesAntenne}
      validationSchema={validationAntenne}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, handleChange }) => {
        return (
          <>
            {
              <Form className="w-full">
                {error && `${error}`}
                <FormSectionAntenneGegevens
                  setFieldValue={setFieldValue}
                  dataCompanies={dataCompanies}
                  dataTechnologies={dataTechnologies}
                  dataServices={dataServices}
                  dataFrequencies={dataFrequencies}
                  dataContracts={dataContracts}
                />

                <div className="flex justify-end p-4">
                  <ButtonAutofill setFieldValue={setFieldValue} />
                  <button
                    disabled={loading}
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {loading ? "Submitting" : "Next"}
                    <svg
                      className="ml-2 -mr-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </Form>
            }
          </>
        );
      }}
    </Formik>
  );
}
