import { gql } from "@apollo/client";

const getLineFeesQuery = gql`
  {
    lineFees {
      id
      name
      price
      fbFactor
      technology {
        id
        name
      }
    }
    technologies {
      id
      name
    }
  }
`;
const getLineFeeQuery = gql`
  query ($id: Int!) {
    lineFees(id: $id) {
      id
      name
      price
      fbFactor
      technology {
        id
        name
      }
    }
  }
`;

const addLineFeeMutation = gql`
  mutation AddLineFee(
    $name: String!
    $price: Float
    $fbFactor: Float
    $technologyId: Int
  ) {
    addLineFee(
      name: $name
      price: $price
      fbFactor: $fbFactor
      technologyId: $technologyId
    ) {
      id
      name
    }
  }
`;
const updateLineFeeMutation = gql`
  mutation UpdateLineFee(
    $id: Int!
    $name: String!
    $price: Float
    $fbFactor: Float
    $technologyId: Int
  ) {
    updateLineFee(
      id: $id
      name: $name
      price: $price
      fbFactor: $fbFactor
      technologyId: $technologyId
    ) {
      id
      name
    }
  }
`;
const deleteLineFeeMutation = gql`
  mutation DeleteLineFee($id: Int!) {
    deleteLineFee(id: $id) {
      id
    }
  }
`;

export const lineFeeService = {
  getLineFeesQuery,
  getLineFeeQuery,
  addLineFeeMutation,
  updateLineFeeMutation,
  deleteLineFeeMutation,
};
