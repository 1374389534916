import React from 'react';
import FrequenciesOverview from './../components/PageSettings/PageSettingsFrequencyAttributes/FrequencyBandAllocations';
import ServicesOverview from './../components/PageSettings/PageSettingsFrequencyAttributes/Services';
import TechnologiesOverview from './../components/PageSettings/PageSettingsFrequencyAttributes/AccessTypes';
import BandsOverview from './../components/PageSettings/PageSettingsFrequencyAttributes/FrequencyBandDefinitions';

const FrequencyAllocationOverView = () => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-4">
                    <TechnologiesOverview />
                </div>
                <div className="col-lg-4">
                    <ServicesOverview />
                </div>
                <div className="col-lg-4">
                    <BandsOverview />
                </div>
                <div className="col-lg-12">
                    <FrequenciesOverview />
                </div>
            </div>
        </div>
    )

}

export default FrequencyAllocationOverView;