import { gql } from "@apollo/client";

const getBrandsQuery = gql`
  {
    brands {
      id
      name
    }
  }
`;

const getBrandQuery = gql`
  query ($id: Int) {
    brand(id: $id) {
      id
      name
    }
  }
`;

const addBrandMutation = gql`
  mutation AddBrand($name: String!) {
    addBrand(name: $name) {
      id
      name
    }
  }
`;

const updateBrandMutation = gql`
  mutation UpdateBrand($id: Int!, $name: String!) {
    updateBrand(id: $id, name: $name) {
      id
      name
    }
  }
`;

const deleteBrandMutation = gql`
  mutation DeleteBrand($id: Int!) {
    deleteBrand(id: $id) {
      id
    }
  }
`;

export const brandService = {
  getBrandsQuery,
  getBrandQuery,
  addBrandMutation,
  updateBrandMutation,
  deleteBrandMutation,
};
