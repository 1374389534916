import { gql } from "@apollo/client";

const getModelsQuery = gql`
  {
    brands {
      id
      name
    }
    models {
      id
      name
      hasAttachment
      brand {
        id
        name
      }
    }
  }
`;

const getmodelQuery = gql`
  query ($id: Int) {
    model(id: $id) {
      id
      name
      hasAttachment
      attachment
    }
  }
`;

const addModelMutation = gql`
  mutation AddModel(
    $name: String!
    $brandId: Int
    $hasAttachment: Boolean
    $attachment: String
  ) {
    addModel(
      name: $name
      brandId: $brandId
      hasAttachment: $hasAttachment
      attachment: $attachment
    ) {
      id
      name
    }
  }
`;

const updateModelMutation = gql`
  mutation UpdateModel(
    $id: Int!
    $name: String!
    $brandId: Int
    $hasAttachment: Boolean
    $attachment: String
  ) {
    updateModel(
      id: $id
      name: $name
      brandId: $brandId
      hasAttachment: $hasAttachment
      attachment: $attachment
    ) {
      id
      name
    }
  }
`;

const deleteModelMutation = gql`
  mutation DeleteModel($id: Int!) {
    deleteModel(id: $id) {
      id
    }
  }
`;

export const modelService = {
  getModelsQuery,
  getmodelQuery,
  addModelMutation,
  updateModelMutation,
  deleteModelMutation,
};
