import React, { useState, useEffect } from "react";

import { NEXT_URL } from "../config/index";
import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

import TableCompanyReports from "../components/PageCompanyReports/TableCompanyReports";


export default function PageStraalVerbindingen(props) {
  //PropsCheck
  console.log({ PageStraalVerbindingenPropsCheck: { props } });

  //StateVariables
  const [companyId, setCompanyId] = useState(-1);

  //Constants
  const user = props.user;

  //ConstantVariables
  //   const router = useRouter();
  const queryClient = useQueryClient();

  //StateVariables
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSearch, setPageSearch] = useState("");
  const [pageSortBy, setPageSortBy] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [pageYear, setPageYear] = useState(null);
  const [selected, setSelected] = useState(-1);

  //Constants
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  //FetchFunctions
  const fetchReportsByYearNMonth = async () => {
    return axios.get(
      `${NEXT_URL}/api/companyFinancialReports/byYearNMonth?year=${currentYear}&month=${currentMonth}`
    );
  };

  const fetchCompanyReports = async (
    pageIndex,
    pageSize,
    pageSortBy,
    pageSearch,
    pageYear
  ) => {
    const sortByString = pageSortBy?.id
      ? `sortBy=${pageSortBy.id}&sortDesc=${pageSortBy.desc}&`
      : "";
    const searchString = pageSearch ? `search=${pageSearch}` : "";
    const limitString = `&limit=${pageSize}`;
    const pageString = `&page=${pageIndex + 1}`;
    const yearString = pageYear ? `&year=${pageYear}` : "";

    return axios.get(
      `${NEXT_URL}/api/companyFinancialReports?${searchString}${sortByString}${limitString}${pageString}${yearString}`
    );
  };

  //QueryFunctions
  const { data: dataCompanyReports, status: statusCompanyReports } = useQuery(
    [`CompanyReports`, pageIndex, pageSize, pageSortBy, pageSearch, pageYear],
    () =>
      fetchCompanyReports(
        pageIndex,
        pageSize,
        pageSortBy,
        pageSearch,
        pageYear
      ),
    {
      keepPreviousData: true,
      staleTime: Infinity,
      enabled: true,
    }
  );

  //QueryFunctions
  const {
    data: dataReportByYearNMonths,
    status: statusReportByYearNMonths,
    error: errorReportByYearNMonths,
  } = useQuery([`CompanyReportReportsByYearNMonth`], fetchReportsByYearNMonth, {
    keepPreviousData: true,
    refetchOnMount: true,
    onSuccess: (data) => {
     console.log("Fetched")
    },
  });

  useEffect(() => {
    console.log("invalidating query");
    queryClient.invalidateQueries(
      "ManagmentAddresses",
      pageIndex,
      pageSize,
      pageSortBy,
      pageSearch,
      pageYear
    );
  }, [pageSearch, pageIndex, pageSize, pageSortBy, pageYear]);

  //QueryResultsCheck
  console.log({
    PageStraalVerbindingenQueryResultsCheck: { dataCompanyReports },
  });

  return (
    <>
      <div className="card mb-4 mx-3">
        {/* Card Row */}
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">
            Company Financial Reports
          </h6>
          <div className="d-flex justify-content-center align-items-center"></div>
        </div>
        {/* End Card Row */}
        {/* Card Row */}
        <div className="card-body">
          <div className="">
            {true && (
              <div>
                {statusCompanyReports === "loading" && <div>Loading...</div>}
                {statusCompanyReports === "error" && (
                  <div>Error: Couldn't fetch</div>
                )}
                {statusCompanyReports === "success" && (
                  <TableCompanyReports
                    users={dataCompanyReports.data.items}
                    queryData={dataCompanyReports.data}
                    statusCompanyReports={statusCompanyReports}
                    queryPageIndex={pageIndex}
                    queryPageSize={pageSize}
                    setPage={setPageIndex}
                    setLimit={setPageSize}
                    setSearch={setPageSearch}
                    setPageSortBy={setPageSortBy}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {/* End Card Row */}
      </div>
    </>
  );
}
