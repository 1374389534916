import React from "react";
import ModalPdfContracts from "./ModalPdfContracts";
import { getContractPdfsByContractQuery } from "../../queries/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import FormUploadPDF from "./FormUploadPDF";
import ModalPdf from "./ModalPdf";

export default function RowCollapsibleContract({ contractId }) {
  const { loading, data } = useQuery(getContractPdfsByContractQuery, {
    variables: { contractId: parseInt(contractId) },
  });

  // const data = {}
  // const loading = {}

  console.table(data?.contractPdfsByContract);

  return (
    !loading &&
    data && (
      <>
        <tr id={`collapse${contractId}`} className="collapse">
          <td colSpan={16}>
            <div className="rounded overflow-hidden shadow-sm m-2 p-2 px-4">
              <FormUploadPDF contractId={contractId} />
            </div>
            <table
              className="table table-nostriped table-borderless"
              style={{ width: "100%" }}
            >
              <thead className="bg-white">
                <tr>
                  <td>Name</td>
                  <td>File</td>
                </tr>
              </thead>
              <tbody>
                {data.contractPdfsByContract.map((contractPdf, index) => {
                  return (
                    <tr key={index}>
                      <td>{contractPdf.name}</td>
                      {/* <ModalPdfContracts contractPdf={contractPdf} /> */}
                      <ModalPdf contractPdf={contractPdf} />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>
      </>
    )
  );
}
