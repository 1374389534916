import React from 'react';


const AllocationModal = ({ allocation, countries, handleChange, closeModal, saveItem }) => {
    return (
        <div className="modal fade" id="allocateModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Allocate Country</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label forhtml="frequency" className="col-form-label">Frequency:</label>
                                    <input type="text" className="form-control" id="frequency" name="frequency" onChange={(e) => handleChange(e)} value={allocation.frequency ? allocation.frequency : ''} />
                                </div>
                                <div className="form-group col-sm-12">
                                    <label forhtml="country" className="col-form-label">Country:</label>
                                    <select className="form-control" id="country" name="country" onChange={e => handleChange(e)} value={allocation.country ? allocation.country.id : ''}>
                                        <option value="0" >--choose--</option>
                                        {

                                            countries.map((country, index) => <option key={index} value={country.id} >{country.name}</option>)

                                        }
                                    </select>

                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllocationModal;