import React, { Fragment } from "react";
import RowCollapsibleContract from "./RowCollapsibleContract";

export default function RowAccordionContract({
  contract,
  index,
  toggleAccordion,
  selectedModel,
  setSelectedModel,
  openModal,
  deleteItem,
  openPdfModal,
  setContractwithParsedDate,
}) {
  return (
    <Fragment key={index}>
      <tr
        onClick={toggleAccordion}
        data-target={`#collapse${contract.id}`}
        style={{ cursor: "pointer" }}
      >
        <td>{contract.name}</td>
        <td>{new Date(parseInt(contract.startDate)).toLocaleDateString()}</td>
        <td>{new Date(parseInt(contract.endDate)).toLocaleDateString()}</td>
        <td style={{ textAlign: "right" }}>
          {new Intl.NumberFormat("en", {
            minimumFractionDigits: 3,
          }).format(contract.uplinkLow)}
        </td>
        <td style={{ textAlign: "right" }}>
          {new Intl.NumberFormat("en", {
            minimumFractionDigits: 3,
          }).format(contract.uplinkMid)}
        </td>
        <td style={{ textAlign: "right" }}>
          {new Intl.NumberFormat("en", {
            minimumFractionDigits: 3,
          }).format(contract.uplinkHigh)}
        </td>
        <td style={{ textAlign: "right" }}>
          {new Intl.NumberFormat("en", {
            minimumFractionDigits: 3,
          }).format(contract.downlinkLow)}
        </td>
        <td style={{ textAlign: "right" }}>
          {new Intl.NumberFormat("en", {
            minimumFractionDigits: 3,
          }).format(contract.downlinkMid)}
        </td>
        <td style={{ textAlign: "right" }}>
          {new Intl.NumberFormat("en", {
            minimumFractionDigits: 3,
          }).format(contract.downlinkHigh)}
        </td>
        <td>{contract.division?.name}</td>
        <td>{contract.frequency ? contract.frequency.name : null}</td>
        <td>{contract.lineFee ? contract.lineFee.name : null}</td>
        <td>{contract.jurisdiction?.name}</td>
        {/* <td>
          {contract.attachments.length > 0
            ? contract.attachments.map((a) => (
                <div key={a.id}>
                  <i
                    className="fas fa-file-pdf"
                    title={a.name}
                    onClick={(e) => {
                      openPdfModal(e, a);
                    }}
                  ></i>
                </div>
              ))
            : ""}
        </td> */}
        <td>
          <i
            className="fas fa-fw fa-edit"
            onClick={(e) => {
              setContractwithParsedDate(contract);
              openModal(e);
            }}
            data-target="#contractModal"
          ></i>
          <i
            className="fas fa-fw fa-trash"
            onClick={(e) =>
              window.confirm("Are you sure you wish to delete this item?") &&
              deleteItem(e, contract.id)
            }
          ></i>
        </td>
      </tr>
      <RowCollapsibleContract
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        contractId={contract.id}
      />
    </Fragment>
  );
}
