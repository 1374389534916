export let colors = {
  smitComs: "#3E2573",
  criticalComunicationsCaribbean: "#FAE80C",
  coralTelecom: "#D70612",
  telCell: "#E4681C",
  radComm: "#032458",
  freeSpectrum: "#0066A2",
  newTechnologyGroup: "#D0443C",
  netStar: "#2F3194",
  antelecom: "#97B1D7",
  wirelessTelecommunicationNetwork: "#3C88C2",
  caribbeanBroadcastingSystem: "#097478",
  uts: "#1994D1",
  CaribbeanBroadcastingSystem: "#0af521",
  Dimisio: "#5db9bd",
  MediaOneCorparation: "#c60eac",
  Laser101: "#c76818",
  Oissis: "#485b5e",
  LighthouseBroadcstingNetwork: "#741b27",
  SintMaartenBroadcastingNetwork: "#3f7111",
  TheVoiceofSintMaarten: "#996ce5",
  Hi5Broadcasting: "#c89475",
  JamesBroadcasting: "#d92013",
  SintMaartenGovernment: "#593fa1",
  trueCommunications: "#a9991c",
  WilsonBroadcasting: "#41b579",
};
