import React, { createContext, useContext, useState } from "react";

const ItemContext = createContext();

export const ItemProvider = ({ children }) => {
  const [state, setState] = useState({});

  const data = { state, setState };
  return <ItemContext.Provider value={data}>{children}</ItemContext.Provider>;
};

export const useStateValue = () => useContext(ItemContext);
