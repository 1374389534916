import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Provider } from "./contexts/CompanyContext";
import "./index.css";
import App from "./App";

import { QueryClientProvider, QueryClient } from "react-query";
import { REACT_APP_SERVER_URL } from "./utils/config";

const queryClient = new QueryClient();
const httpLink = createHttpLink({
  uri: REACT_APP_SERVER_URL,
  credentials: "include",
  withCredentials: true,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      return (
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        ),
        alert(message)
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  credentials: "include",
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache(),
});
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <App />
        </Provider>
      </QueryClientProvider>
    </ApolloProvider>
  </BrowserRouter>
);
