import React from "react";
import {
  getFrequencyBandsList,
  getMicrowaveLinkLines,
  getLinksCoordinatesList,
  handlePolyLineClick,
  getMultiLines,
  lineSymbol,
  animateLine,
} from "./microwaveLinks.js";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";

export default function MicrowaveLinkInfoWindow({
  onCloseClick,
  position,
  selectedLine,
  multiLines,
}) {
  console.log({ selectedLine });

  return (
    <InfoWindow
      position={position}
      onCloseClick={onCloseClick}
      data-bs-spy="scroll"
    >
      <div className="list-group">
        <div
          className="list-group-item list-group-item"
          style={{ background: selectedLine.color }}
        >
          <h5 style={{ color: "#FFF" }}>
            {console.log(selectedLine)}
            Microwave Link - Frequency Band : {selectedLine.frequencyBand}
          </h5>
        </div>
        {getMultiLines(multiLines)}
        <div className="list-group-item list-group-item-secondary">
          <div className="d-flex justify-content-between">
            <h5>
              between : {selectedLine.between[0]} - {selectedLine.between[1]}
            </h5>
          </div>
        </div>
        {/* Equipment */}
        {selectedLine.antennae && (
          <div className="h-60 overflow-y-auto">
            {selectedLine.antennae?.map((antenna, index) => {
              return (
                <div className="list-group-item" key={index}>
                  <div className="d-flex justify-content-between">
                    <strong>Company:</strong>{" "}
                    <span>{antenna.company?.name}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Frequency:</strong> <span>{antenna.code}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Freq.Band:</strong>{" "}
                    <span>{antenna.frequency?.band?.name}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Service:</strong>{" "}
                    <span>{antenna.frequency?.band?.service?.name}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <strong>Technogy:</strong>{" "}
                    <span>
                      {antenna.frequency?.band?.service?.technology?.name}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <strong>Location:</strong>{" "}
                    <span>{antenna.locationDescription}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </InfoWindow>
  );
}
