import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionBijlage({ setFieldValue }) {
  return (
    <>
      <HeadingForm
        title="13 &nbsp;&nbsp;&nbsp;&nbsp;De volgende bijlagen zijn bijgevoegd"
        subTitle="Bijlagen kunt u toevoegen zodra u digitaal heeft ondertekend en de e-mail toepassing is geopend"
      />
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            13.1 Bijlagen bij beantwoorde vragen
          </label>
        </div>

        {/* Column 2*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="flex items-center mb-4">
            <Field
              type="checkbox"
              name="BijlagenKopieInschrijvingBuitenlandseKvK"
              onChange={(e) => {
                setFieldValue(
                  "BijlagenKopieInschrijvingBuitenlandseKvK",
                  +e.target.checked
                );
              }}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <label className="text-sm font-medium text-gray-900 ml-2 block">
              Kopie inschrijving buitenlandse KvK (2.3)
            </label>
          </div>
          <div className="flex items-center mb-4">
            <Field
              type="checkbox"
              name="BijlagenKopieIdentificatiebewijs"
              onChange={(e) => {
                setFieldValue(
                  "BijlagenKopieIdentificatiebewijs",
                  +e.target.checked
                );
              }}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <label className="text-sm font-medium text-gray-900 ml-2 block">
              Kopie identificatiebewijs (3.1)
            </label>
          </div>
          <div className="flex items-center mb-4">
            <Field
              type="checkbox"
              name="BijlagenPlattegrondAntennelocatie"
              onChange={(e) => {
                setFieldValue(
                  "BijlagenPlattegrondAntennelocatie",
                  +e.target.checked
                );
              }}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <label className="text-sm font-medium text-gray-900 ml-2 block">
              Plattegrond antennelocatie (8.5 / 10.5)
            </label>
          </div>
          <div className="flex items-center mb-4">
            <Field
              type="checkbox"
              name="BijlagenTechnischeParametersAntenne"
              onChange={(e) => {
                setFieldValue(
                  "BijlagenTechnischeParametersAntenne",
                  +e.target.checked
                );
              }}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <label className="text-sm font-medium text-gray-900 ml-2 block">
              Technische parameters antenne (9.2)
            </label>
          </div>
          <div className="flex items-center mb-4">
            <Field
              type="checkbox"
              name="BijlagenTechnischeParametersReflectorAntenne"
              onChange={(e) => {
                setFieldValue(
                  "BijlagenTechnischeParametersReflectorAntenne",
                  +e.target.checked
                );
              }}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <label className="text-sm font-medium text-gray-900 ml-2 block">
              Technische parameters reflector antenne (11.2)
            </label>
          </div>
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            13.2 Aanvraag wordt ondertekend namens de vergunninghouder
          </label>
        </div>

        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="flex items-center mb-4">
            <Field
              type="checkbox"
              name="MachtigingVertegenwoordigingDoorDerden"
              onChange={(e) => {
                setFieldValue(
                  "MachtigingVertegenwoordigingDoorDerden",
                  +e.target.checked
                );
              }}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <label className="text-sm font-medium text-gray-900 ml-2 block">
              Machtiging vertegenwoordiging door derden
            </label>
          </div>
        </div>
        {/* Column 3 */}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="mb-4">U vindt het formulier op onze website.</div>
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            13.3 Extra toelichting bij de aanvraag
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="ExtraToelichting"
          />
          Geef hierboven met steekwoorden aan wat de inhoud van de bijlage met
          toelichting bevat.
          <ErrorMessage name="ExtraToelichting" component="div" />
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
