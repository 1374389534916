import React, { useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import {
  getCompanyQuery,
  getContractsByCompanyQuery,
  getContactsByCompanyQuery,
} from "../queries/queries";

import { Context } from "../contexts/CompanyContext";

import CompanyCard from "./../components/PageCompany/TableCardCompany";
import ContactPersonsCard from "./../components/PageCompany/TableCardContactPerson";
import ContractsCard from "./../components/PageCompany/TableCardContracts";
import { useParams } from "react-router-dom";

const CompanyView = ({ user }) => {
  let { id: companyId } = useParams();

  const { setContracts, setContacts, setCompany } = useContext(Context);

  const { loading, data } = useQuery(getCompanyQuery, {
    variables: { id: +companyId },
  });
  const { loading: loadingContracts, data: dataContracts } = useQuery(
    getContractsByCompanyQuery,
    { variables: { companyId: parseInt(companyId) } }
  );
  const { loading: loadingContacts, data: dataContacts } = useQuery(
    getContactsByCompanyQuery,
    { variables: { companyId: parseInt(companyId) } }
  );

  useEffect(() => {
    if (data) {
      setCompany(data.company);
    }
    if (dataContracts) {
      setContracts(dataContracts.contractsByCompany);
    }
    if (dataContacts) {
      setContacts(dataContacts.contactsByCompany);
    }
  }, [data, dataContracts, dataContacts]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          {loading ? (
            <div className="spinner-border m-5" role="status">
              <span className="sr-only">Loading company</span>
            </div>
          ) : (
            <CompanyCard user={user} />
          )}
        </div>
        <div className="col-lg-6">
          {loadingContacts ? (
            <div className="spinner-border m-5" role="status">
              <span className="sr-only">Loading contacts</span>
            </div>
          ) : (
            <ContactPersonsCard user={user} />
          )}
        </div>
        <div className="col-lg-12">
          {loadingContracts ? (
            <div className="spinner-border m-5" role="status">
              <span className="sr-only">Loading contracts</span>
            </div>
          ) : (
            <ContractsCard user={user} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyView;
