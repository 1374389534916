import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup"; 

const FrequencyModal = ({
  frequency,
  technologies,
  services,
  bands,
  handleChange,
  closeModal,
  saveItem,
}) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={frequency}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
       /*  band: Yup.string().nullable().required("Band is required"), */
      })}
      onSubmit={
        (fields, { setErrors, setSubmitting }) => {
          try {
            setSubmitting(true);
            saveItem(fields); 
            closeModal();
          } catch (error) {
            if (error.message.includes("band")) {
              setErrors({ band: error.band.split(":")[1] });
            }
            setErrors({ general: error.message.split(":")[1] });
          } finally {
            setSubmitting(false);
          }
        }
       }
    >
      {({ values, errors, touched, isSubmitting,  handleSubmit }) => {
        return (
          <div
            className="modal fade"
            id="frequencyModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header bg-primary text-white">
                  <h5 className="modal-title" id="modalLabel">
                    Frequency
                  </h5>
                  <button
                    type="button"
                    className="close text-white"
                    data-dismiss="modal"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label forhtml="freq_name" className="col-form-label">
                          Name:
                        </label>
                        <Field
                          type="text"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                          id="freq_name"
                          name="name"
                          onChange={handleChange}
                          value={values.name || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Row */}
                      {values.stationType?.id !== "2004" && (
                     /*    <div className="grid grid-cols-12 gap-6 pt-1 pb-4 w-full">
                         
                            id="technology"
                            name={`technology`}
                            title="Technology:"
                            span="12"
                            options={technologies?.map((company, index) => {
                               console.log("company(tech)",company.name) 
                              return {
                                value: company.id,
                                label: company.name,
                              };
                            })}
                            component={FormCustomSelect}
                            placeholder="--choose--"
                            isMulti={false}
                            onChangeFunction={async (value, name) => {
                              const e = {
                                target: { name: name, value: value },
                              };
                              handleChange(e);
                            }}
                          />
                        </div> */
                        
                        //
                        <div className="form-group col-md-6">
                        <label forhtml="technology" className="col-form-label">
                        Technology:
                        </label>
                        <select
                          className={
                            "form-control" +
                            (errors.technology && touched.technology
                              ? " is-invalid"
                              : "")
                          }
                          id="technology"
                          name="technology"
                          onChange={handleChange}
                          value={
                            values.technologies
                             /*  ? values.frequency.band
                                ? values.frequency.band.service
                                  ? values.frequency.band.service.technology
                                    ? values.frequency.band.service.technology
                                        .id
                                    : ""
                                  : ""
                                : ""
                              : "" */
                          }
                        >
                          <option value="">--choose--</option>
                          {technologies.map((technology, index) => (
                            <option key={index} value={technology.id}>
                              {technology.name}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="technology"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      )}
                      {/* End Row */}
                      
                      {/* Form Row */}
                      {values.stationType?.id !== "2004" && (
                       /*  <div className="grid grid-cols-12 gap-6 pt-1 pb-4 w-full">
                         
                           <Field
                            id="service"
                            name={`service`}
                            title="Service:"
                            span="6"
                             options={services?.map((company, index) => {
                              return {
                                value: company.id,
                                label: company.name,
                              };
                            })} 
                            component={FormCustomSelect}
                            placeholder="--choose--"
                            isMulti={false}
                            onChangeFunction={async (value, name) => {
                              const e = {
                                target: { name: name, value: value },
                              };
                              handleChange(e);
                            }}
                          /> 
                        
                      </div> */
                      
                      <div className="form-group col-md-6 ">
                        <label forhtml="service" className="col-form-label">
                          Service:
                        </label>
                        <select
                          className={
                            "form-control" +
                            (errors.service && errors.service
                              ? " is-invalid"
                              : "")
                          }
                          name="service"
                          onChange={handleChange}
                          value={
                            values.services
                            /*   ? values.frequency.band
                                ? values.frequency.band.service
                                  ? values.frequency.band.service.id
                                  : ""
                                : ""
                              : "" */
                          }
                         /*  disabled={
                            !frequency.band.service.technology.id
                          } */
                        >
                          <option value="">--choose--</option>
                          {/* values.band.frequency &&
                          values.frequency.band && */
                          services
                            ? services.map((service, index) => (
                                <option key={index} value={service.id}>
                                  {service.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <ErrorMessage
                          name="service"
                          component="div"
                          className="invalid-feedback"
                        />
                        
                          {/* Form Col*/}
                          {/* <Field
                            id="band"
                            name={`band`}
                            title="Band:"
                            span="6"
                            options={bands?.map((company, index) => {
                              return {
                                value: company.id,
                                label: company.name,
                              };
                            })}
                            value={values.band.id}
                            component={FormCustomSelect}
                            placeholder="--choose--"
                            isMulti={false}
                            onChangeFunction={async (value, name) => {
                              console.log("onchabge",value)
                              const e = {
                                target: { name: name, value: value },
                              };
                              handleChange(e);
                             
                            }}
                          /> */}
                        </div>
                      )}
                      {/* End Row */}

                      <>
                      <div className="form-group col-md-6">
                        <label forhtml="frequency" className="col-form-label">
                          Bands:
                        </label>
                        <select
                          className={
                            "form-control" +
                            (errors.frequency && touched.frequency
                              ? " is-invalid"
                              : "")
                          }
                          name="band"
                          onChange={handleChange}
                          value={values.band ? values.band.id : ""}
                          /* disabled={!contract.frequency.band.service.id} */
                        >
                          <option value="">--choose--</option>
                          {bands
                            ? bands.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <ErrorMessage
                          name="frequency"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      
                      </>

             
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_uplinkStart"
                          className="col-form-label"
                        >
                          UL-Start Frequency:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.uplinkStart && touched.uplinkStart
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_uplinkStart"
                          name="uplinkStart"
                          onChange={handleChange}
                          value={values.uplinkStart || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="uplinkStart"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_downlinkStart"
                          className="col-form-label"
                        >
                          DL-Start Frequency:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.downlinkStart && touched.downlinkStart
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_downlinkStart"
                          name="downlinkStart"
                          onChange={handleChange}
                          value={values.downlinkStart || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="downlinkStart"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_uplinkCenter"
                          className="col-form-label"
                        >
                          UL-Center Frequency:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.uplinkCenter && touched.uplinkCenter
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_uplinkCenter"
                          name="uplinkCenter"
                          onChange={handleChange}
                          value={values.uplinkCenter || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="uplinkCenter"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_downlinkCenter"
                          className="col-form-label"
                        >
                          DL-Center Frequency:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.downlinkCenter && touched.downlinkCenter
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_downlinkCenter"
                          name="downlinkCenter"
                          onChange={handleChange}
                          value={values.downlinkCenter || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="downlinkCenter"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_uplinkEnd"
                          className="col-form-label"
                        >
                          UL-End Frequency:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.uplinkEnd && touched.uplinkEnd
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_uplinkEnd"
                          name="uplinkEnd"
                          onChange={handleChange}
                          value={values.uplinkEnd || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="uplinkEnd"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_downlinkEnd"
                          className="col-form-label"
                        >
                          DL-End Frequency:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.downlinkEnd && touched.downlinkEnd
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_downlinkEnd"
                          name="downlinkEnd"
                          onChange={handleChange}
                          value={values.downlinkEnd || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="downlinkEnd"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_dataSpeed"
                          className="col-form-label"
                        >
                          Data Speed:
                        </label>
                        <Field
                          type="text"
                          className={
                            "form-control" +
                            (errors.dataSpeed && touched.dataSpeed
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_dataSpeed"
                          name="dataSpeed"
                          onChange={handleChange}
                          value={values.dataSpeed || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="dataSpeed"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_unitPrice"
                          className="col-form-label"
                        >
                          Unit Price:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.unitPrice && touched.unitPrice
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_unitPrice"
                          name="unitPrice"
                          onChange={handleChange}
                          value={values.unitPrice || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="unitPrice"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* Form Col */}
                      <div className="form-group col-md-6">
                        <label
                          forhtml="freq_interval"
                          className="col-form-label"
                        >
                          Interval:
                        </label>
                        <Field
                          type="number"
                          className={
                            "form-control" +
                            (errors.interval && touched.interval
                              ? " is-invalid"
                              : "")
                          }
                          id="freq_interval"
                          name="interval"
                          onChange={handleChange}
                          value={values.interval || ""}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="interval"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
             {/* {console.log("ModalFreq_VALUES", values)} */}       
          </div>
        );
      }}
    </Formik>
  );
};

export default FrequencyModal;
