import { gql } from "@apollo/client";

const addAntennaTypeLookUpMutation = gql`
  mutation AddAntennaTypeLookUp($name: String!) {
    addAntennaTypeLookUp(name: $name) {
      id
      name
    }
  }
`;

const getAntennaTypeLookUpListQuery = gql`
  {
    antennaTypeLookUpList {
      id
      name
    }
  }
`;

const updateAntennaTypeLookUpMutation = gql`
  mutation UpdateAntennaTypeLookUp($id: Int!, $name: String!) {
    updateAntennaTypeLookUp(id: $id, name: $name) {
      id
      name
    }
  }
`;

const deleteAntennaTypeLookUpMutation = gql`
  mutation DeleteAntennaTypeLookUp($id: Int!) {
    deleteAntennaTypeLookUp(id: $id) {
      id
    }
  }
`;

export const antennaTypeLookUpService = {
  getAntennaTypeLookUpListQuery,
  addAntennaTypeLookUpMutation,
  updateAntennaTypeLookUpMutation,
  deleteAntennaTypeLookUpMutation,
};
