import React, { useState } from "react";
import HeadingForm from "./HeadingForm";
import RowRegulatoryTracker from "./RowRegulatoryTracker";

export default function FormSection2RegulatoryMandate() {
  return (
    <>
      <HeadingForm title="2 &nbsp;&nbsp;&nbsp;&nbsp;Regulatory mandate" />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <label className="block uppercase tracking-wide text-xs font-bold my-2"></label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Max Score
          </label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Coding
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            0 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            1 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2 pts
          </label>
        </div>
      </div>
      {/* End Row 3*/}
      <RowRegulatoryTracker
        title="11 Traditional mandate: entity in charge of quality of service obligations measures and service quality monitoring"
        nameCoding={"rmQualityServiceCoding"}
        namePoints={"rmQualityServicePoints"}
      />
      <RowRegulatoryTracker
        title="12 Traditional mandate: entity in charge of licensing"
        nameCoding={"rmLicenseCoding"}
        namePoints={"rmLicensePoints"}
      />
      <RowRegulatoryTracker
        title="13 Traditional mandate: entity in charge of interconnection rates and price regulation"
        nameCoding={"rmInterconnectionRatesCoding"}
        namePoints={"rmInterconnectionRatesPoints"}
      />
      <RowRegulatoryTracker
        title="14 Spectrum: Entity in charge of radio frequency allocation and assignment"
        nameCoding={"rmRadioFrequencyCoding"}
        namePoints={"rmRadioFrequencyPoints"}
      />
      <RowRegulatoryTracker
        title="15 Entity in charge of Spectrum Monitoring and Enforcement"
        nameCoding={"rmSpectrumMonitoringCoding"}
        namePoints={"rmSpectrumMonitoringPoints"}
      />
      <RowRegulatoryTracker
        title="16 Entity in charge of universal service/access"
        nameCoding={"rmUniversalServiceCoding"}
        namePoints={"rmUniversalServicePoints"}
      />
      <RowRegulatoryTracker
        title="17 New mandate: entity in charge of broadcasting (radio and TV transmission)"
        nameCoding={"rmBroadcastingCoding"}
        namePoints={"rmBroadcastingPoints"}
      />
      <RowRegulatoryTracker
        title="18 New mandate: entity in charge of broadcasting content"
        nameCoding={"rmBroadcastingContentCoding"}
        namePoints={"rmBroadcastingContentPoints"}
      />
      <RowRegulatoryTracker
        title="19 New mandate: entity in charge of Internet content"
        nameCoding={"rmInternetContentCoding"}
        namePoints={"rmInternetContentPoints"}
      />
      <RowRegulatoryTracker
        title="20 New mandate: entity in charge of IT"
        nameCoding={"rmInformationTechnologyCoding"}
        namePoints={"rmInformationTechnologyPoints"}
      />
      <RowRegulatoryTracker
        title="21 Consumer issues: entity responsible for comparative tariff information, consumer education and handling consumer complaints"
        nameCoding={"rmConsumerIssuesCoding"}
        namePoints={"rmConsumerIssuesPoints"}
      />
    </>
  );
}
