import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition.js";

// import FormPayment from "./FormPayment";

export default function ModalConfirmDeletion({
  examBundleRequestId,
  loading,
  submitRef,
  submitDisabled,
  deleteItem,
  id,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const trigger = useRef(null);
  const modalContent = useRef(null);

  const [uploadedPayment, setUploadedPayment] = useState(false);
  //   const modalInput = useRef(null);

  // close on click outside
  // useEffect(() => {
  //   const clickHandler = ({ target }) => {
  //     if (
  //       !modalOpen ||
  //       modalContent.current.contains(target) ||
  //       trigger.current.contains(target)
  //     )
  //       return;
  //     setModalOpen(false);
  //   };
  //   document.addEventListener("click", clickHandler);
  //   return () => document.removeEventListener("click", clickHandler);
  // });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;

      setModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Trigger Button */}
      <button
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
        disabled={loading || submitDisabled}
        ref={trigger}
        onClick={() => {
          setModalOpen(true);
        }}
        type="button"
      >
        Delete
      </button>
    

      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      />

      {/* Modal dialog */}
      <Transition
        id="add-parcel-modal"
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-44 mb-20 justify-center transform px-4 sm:px-6"
        role="dialog"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          className="bg-white overflow-hidden h-80 xl:mx-96 md:mx-32 rounded shadow-lg w-full"
          ref={modalContent}
        >
          <div
            className="h-full w-full"
            // onFocus={() => setModalOpen(true)}
            // onBlur={() => setModalOpen(false)}
          >
            <div className="relative h-full">
              <div className="bg-white rounded shadow-lg border flex flex-col overflow-hidden relative h-full ">
                {/* Modal Header */}
                <div className="py-3 px-4 text-xl border-b font-medium flex justify-between text-gray-600 bg-white w-full">
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6  mr-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Are You Sure?
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setModalOpen(false);
                    }}
                    className="fill-current  font-3xl font-bold"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="px-12 py-6 flex-grow text-gray-600 overflow-y-auto max-h-[600px]">
                  {/* {modalBody} */}
                  <p>
                  You are about to delete this equipment item
                  </p>
                  {/* <FormPayment
                    setModalOpen={setModalOpen}
                    submitRef={submitRef}
                    closeRef={closeRef}
                    examBundleRequestId={examBundleRequestId}
                    setUploadedPayment={setUploadedPayment}
                  /> */}
                </div>

                {/* Modal Footer */}
                <div className="p-4 px-8 border-t">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className=" bg-transparent hover:bg-gray-600 text-teal-600 font-semibold 
                        hover:text-gray py-2 px-4 mr-2 border border-teal-600 hover:border-transparent 
                        rounded-md "
                      onClick={() => {
                        setModalOpen(false);
                        // closeRef.current.click();
                      }}
                    >
                      No
                    </button>
                    {!uploadedPayment && (
                      <button
                        type="button"
                        className=" bg-red-400 hover:bg-red-500 text-white rounded px-4 py-2 "
                        onClick={(e) => {
                          e.preventDefault();
                          setModalOpen(false);
                          console.log("Deleteing", id);
                          deleteItem(id);
                        }}
                      >
                        Yes, Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}

