import React, { useContext } from "react";
import ContactModal from "./ModalContactPerson";
import { useMutation } from "@apollo/client";
import {
  getContactsByCompanyQuery,
  addContactMutation,
  updateContactMutation,
  deleteContactMutation,
} from "../../queries/queries";
import { Context } from "../../contexts/CompanyContext";

const ContactPersonsCard = ({ user }) => {
  const { contacts, company, contact, setContact, resetContact } = useContext(Context);
  const companyId = company.id
  const [addContact] = useMutation(addContactMutation, {
    variables: {
      firstName: contact.firstName,
      lastName: contact.lastName,
      function: contact.function,
      phone: contact.phone,
      email: contact.email,
      companyId: companyId,
    },
    refetchQueries: [
      {
        query: getContactsByCompanyQuery,
        variables: { companyId: companyId },
      },
    ],
  });
  const [updateContact] = useMutation(updateContactMutation, {
    variables: {
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      function: contact.function,
      phone: contact.phone,
      email: contact.email,
      companyId: companyId,
    },
    refetchQueries: [
      {
        query: getContactsByCompanyQuery,
        variables: { companyId: companyId },
      },
    ],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const deleteItem = async (id) => {
    console.log(id)
    await deleteContactMutation({
      variables: {
        id: parseInt(id),
      },
      refetchQueries: [
        {
          query: getContactsByCompanyQuery,
          variables: { companyId: companyId},
        },
      ],
    });
  };

  const saveItem = async (e) => {
    if (contact.id) {
      updateContact();
    } else {
      addContact();
    }
    closeModal();
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const openModal = (e) => {
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownContactMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownContactMenuLink"]')
      .classList.remove("show");
  };

  return (
    <React.Fragment>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Contact Person</h6>
          {user.role.name !== "OPERATOR" && (
            <div className="dropdown no-arrow">
              <button
                className="button-link"
                id="dropdownContactMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleDropdownMenu}
                onBlur={closeDropdown}
              >
                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                aria-labelledby="dropdownContactMenuLink"
                x-placement="bottom-end"
                style={{
                  position: "absolute",
                  willChange: "transform",
                  top: 0,
                  left: 0,
                  transform: "translate3d(-156px, 19px, 0px)",
                }}
              >
                <div className="dropdown-header">Actions:</div>
                <button
                  className="dropdown-item"
                  onMouseDown={(e) => {
                    resetContact();
                    openModal(e);
                  }}
                  data-target="#contactModal"
                >
                  New
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="card-body">
          <table className="table table-striped table-sm">
            <thead>
              <tr className="bg-primary text-white">
                <td>Firstname</td>
                <td>Lastname</td>
                <td>Function</td>
                <td>Phone</td>
                <td>Email</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => {
                return (
                  <tr key={contact.id}>
                    <td>{contact.firstName}</td>
                    <td>{contact.lastName}</td>
                    <td>{contact.function}</td>
                    <td>{contact.phone}</td>
                    <td>{contact.email}</td>
                    <td>
                      <i
                        className="fas fa-fw fa-edit"
                        onClick={(e) => {
                          setContact(contact);
                          openModal(e);
                        }}
                        data-target="#contactModal"
                      ></i>
                      <i
                        className="fas fa-fw fa-trash"
                        onClick={(e) =>
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          ) && deleteItem(e, contact.id)
                        }
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <ContactModal
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        contact={contact}
        saveItem={saveItem}
      />
    </React.Fragment>
  );
};
export default ContactPersonsCard;
