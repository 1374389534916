import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionFacturatie({ setFieldValue }) {
  return (
    <>
      <HeadingForm title="12 &nbsp;&nbsp;&nbsp;&nbsp;Facturatie" />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            12.1 Contactpersoon of afdeling
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="FacturatieContactpersoonOfAfdeling"
          />
          <ErrorMessage
            name="FacturatieContactpersoonOfAfdeling"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            12.2 Factuuradres als dit afwijkt van het adres onder 3 of 4
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="FacturatieFactuuradres"
          />
          <ErrorMessage name="FacturatieFactuuradres" component="div" />
        </div>
      </div>
      {/* End Row */}
     
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            12.4 Plaats
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="FacturatiePlaats"
          />
          <ErrorMessage name="FacturatiePlaats" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            12.5 Land
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="FacturatieLand"
          />
          <ErrorMessage name="FacturatieLand" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            12.6 Intern ordernummer
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            name="FacturatieInternOrdernummer"
          />
          <ErrorMessage name="FacturatieInternOrdernummer" component="div" />
        </div>
        {/* Side information*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="mb-4">
            BTP St. Maarten kan een ordernummer op de factuur plaatsen.
            Hiervoor zijn maximaal 30 posities beschikbaar.
          </div>
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            12.7 Betalingswijze
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="FacturatieBetalingswijze"
                value="Automatische incasso"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Automatische incasso; vul ook het S€PA formulier in
              </label>
            </div>
            {/* <div className="hidden items-center mb-4">
              <Field
                type="radio"
                name="FacturatieBetalingswijze"
                value="Acceptgiroformulier"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Acceptgiroformulier
              </label>
            </div> */}
          </fieldset>
          <ErrorMessage name="FacturatieBetalingswijze" component="div" />
        </div>

        {/* Column 3 */}
        {/* <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="mb-4">U vindt het formulier op onze website.</div>
        </div> */}
      </div>
      {/* End Row */}
    </>
  );
}
