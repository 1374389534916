export const initialValuesRegulatoryAuthority = {

  raTelecommunicationsCoding: null,
  raTelecommunicationsPoints: null,
  raAutonomyCoding: null,
  raAutonomyPoints: null,
  raAccountabilityCoding: null,
  raAccountabilityPoints: null,
  raDiversiviedFundingCoding: null,
  raDiversiviedFundingPoints: null,
  raPublicConsultationCoding: null,
  raPublicConsultationPoints: null,
  raEnforcementPowerCoding: null,
  raEnforcementPowerPoints: null,
  raRegulatorCoding: null,
  raRegulatorPoints: null,
  raDisputeResolutionCoding: null,
  raDisputeResolutionPoints: null,
  raAppealsDicisionsCoding: null,
  raAppealsDicisionsPoints: null,
  raCompetitionAutorithyCoding: null,
  raCompetitionAutorithyPoints: null

};

export const initialValuesRegulatoryMandate = {
  rmQualityServiceCoding: null,
  rmQualityServicePoints:null, 
  rmLicenseCoding: null,
  rmLicensePoints:null, 
  rmInterconnectionRatesCoding: null,
  rmInterconnectionRatesPoints:null,
  rmRadioFrequencyCoding: null,
  rmRadioFrequencyPoints:null,
  rmSpectrumMonitoringCoding: null,
  rmSpectrumMonitoringPoints:null,
  rmUniversalServiceCoding: null,
  rmUniversalServicePoints:null,
  rmBroadcastingCoding: null,
  rmBroadcastingPoints:null,
  rmBroadcastingContentCoding: null,
  rmBroadcastingContentPoints:null,
  rmInternetContentCoding: null,
  rmInternetContentPoints:null,
  rmInformationTechnologyCoding: null,
  rmInformationTechnologyPoints:null,
  rmConsumerIssuesCoding: null,
  rmConsumerIssuesPoints:null,
};
export const initialValuesRegulatoryRegime = {
  rrTypesOfLicencesCoding           : null,
  rrTypesOfLicencesPoints           : null,
  rrLicenseExemptCoding             : null,
  rrLicenseExemptPoints             : null,
  rrInterconnectionOfferCoding      : null,
  rrInterconnectionOfferPoints      : null,
  rrInterconnectionPricesCoding     : null,
  rrInterconnectionPricesPoints     : null,
  rrQualityServiceCoding            : null,
  rrQualityServicePoints            : null,
  rrInfrastructureMobileCoding      : null,
  rrInfrastructureMobilePoints      : null,
  rrInfrastructureMandateCoding     : null,
  rrInfrastructureMandatePoints     : null,
  rrColocationMandateCoding         : null,
  rrColocationMandatePoints         : null,
  rrUnbundledAccessCoding           : null,
  rrUnbundledAccessPoints           : null,
  rrSecondaryTradingCoding          : null,
  rrSecondaryTradingPoints          : null,
  rrBandMigrationCoding             : null,
  rrBandMigrationPoints             : null,
  rrNumberPortabilityFixedLineCoding: null,
  rrNumberPortabilityFixedLinePoints: null,
  rrNumberPortabilityMobileCoding   : null,
  rrNumberPortabilityMobilePoints   : null,
  rrIndividualUserVoipCoding        : null,
  rrIndividualUserVoipPoints        : null,
  rrNationalPlanCoding              : null,
  rrNationalPlanPoints              : null
};

export const initialValuesCompititionFramework = {
  cfLongDistanceFixedLinesCoding: null,
  cfLongDistanceFixedLinesPoints: null,
  cfImtServicesCoding: null,
  cfImtServicesPoints: null,
  cfCableModemCoding: null,
  cfCableModemPoints: null,
  cfLeasedLinesCoding: null,
  cfLeasedLinesPoints: null,
  cfInternationalGatewaysCoding: null,
  cfInternationalGatewaysPoints: null,
  cfMainFixedLinesCoding: null,
  cfMainFixedLinesPoints: null,
  cfLegalConceptSmpCoding: null,
  cfLegalConceptSmpPoints: null,
  cfDeterminingSmpCoding: null,
  cfDeterminingSmpPoints: null,
  cfForeignParticipationFacilitiesCoding: null,
  cfForeignParticipationFacilitiesPoints: null,
  cfForeignParticipationSpectrumCoding: null,
  cfForeignParticipationSpectrumPoints: null,
  cfForeignParticipationLocalServicesCoding: null,
  cfForeignParticipationLocalServicesPoints: null,
  cfForeignParticipationInternationalServicesCoding: null,
  cfForeignParticipationInternationalServicesPoints: null,
  cfForeignParticipationIspCoding: null,
  cfForeignParticipationIspPoints: null,
  cfForeignParticipationValueAddedCoding: null,
  cfForeignParticipationValueAddedPoints: null,

}


export const initialValues = {
  ...initialValuesRegulatoryAuthority,
  ...initialValuesRegulatoryMandate,
  ...initialValuesRegulatoryRegime,
  ...initialValuesCompititionFramework,
};