import React from 'react';


const BandModal = ({ band, services, handleChange, closeModal, saveItem }) => {
    return (
        <div className="modal fade" id="bandModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Band</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label forhtml="band_name" className="col-form-label">Name:</label>
                                    <input type="text" className="form-control" id="band_name" name="name" onChange={(e) => handleChange(e)} value={band.name} />
                                </div>
                                <div className="form-group col-sm-12">
                                    <label forhtml="band_service" className="col-form-label">Service:</label>
                                    <select className="form-control" id="band_service" name="service" onChange={e => handleChange(e)} value={band.service ? band.service.id : ''}>
                                        <option value="0" >--choose--</option>
                                        {

                                            services.map((service, index) => <option key={index} value={service.id} >{service.name}</option>)

                                        }
                                    </select>

                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BandModal;