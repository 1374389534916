import React from 'react';
import AntennaTypesOverview from '../components/PageSettings/PageSettingsAntennaAtributes/AntennaTypes';
import AntennaTypeLookUpOverview from '../components/PageSettings/PageSettingsAntennaAtributes/AntennaTypeLookups';
import BrandsOverview from './../components/PageSettings/PageSettingsAntennaAtributes/Brands';
import ModelsOverview from './../components/PageSettings/PageSettingsAntennaAtributes/Models';


const TransmissionEquipment = () => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6">
                    <BrandsOverview />
                </div>
                <div className="col-lg-6">
                    <ModelsOverview />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <AntennaTypeLookUpOverview />
                </div>
                <div className="col-lg-6">
                    <AntennaTypesOverview />
                </div>
            </div>
        </div>
    )

}

export default TransmissionEquipment;