import React from "react";
import { colors } from "../../utils/constants";

export default function FrequencyColorLegenda() {
  console.log("colors", colors.smitComs);
  return (
    <div className="m-2 mt-4 mb-5">
      <span
        style={{ backgroundColor: colors.smitComs }}
        className={`inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default`}
      >
        smitComs
      </span>
      <span
        style={{ backgroundColor: colors.criticalComunicationsCaribbean }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        criticalComunicationsCaribbean
      </span>
      <span
        style={{ backgroundColor: colors.coralTelecom }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        coralTelecom
      </span>
      <span
        style={{ backgroundColor: colors.telCell }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        telCell
      </span>
      <span
        style={{ backgroundColor: colors.freeSpectrum }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        freeSpectrum
      </span>
      <span
        style={{ backgroundColor: colors.newTechnologyGroup }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        newTechnologyGroup
      </span>
      <span
        style={{ backgroundColor: colors.netStar }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        netStar
      </span>
      <span
        style={{ backgroundColor: colors.antelecom }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        antelecom
      </span>
      <span
        style={{ backgroundColor: colors.wirelessTelecommunicationNetwork }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        wirelessTelecommunicationNetwork
      </span>
      <span
        style={{ backgroundColor: colors.caribbeanBroadcastingSystem }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        caribbeanBroadcastingSystem
      </span>
      <span
        style={{ backgroundColor: colors.radComm }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        radComm
      </span>
      <span
        style={{ backgroundColor: colors.uts }}
        className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white rounded-full opacity-20 hover:opacity-90 cursor-default"
      >
        uts
      </span>
    </div>
  );
}
