import { gql } from "@apollo/client";

const addStraalVerbindingAanvraagMutation = gql`
  mutation AddStraalVerbindingAanvraag(
    $Relatienummer: Int
    $Vergunningsperiode: String
    $VergunningsperiodeVan: String
    $VergunningsperiodeTot: String
    $VergunningPersoonType: String
    $rechtspersoonStatutaireNaam: String
    $rechtspersoonHandelsnaam: String
    $rechtspersoonKVKLocatie: String
    $rechtspersoonKvK_nummer: String
    $rechtspersoonVestigingsnummer: String
    $natuurlijkPersoonBurgerservicenummerInNederland: String
    $natuurlijkPersoonVoornamen: String
    $natuurlijkPersoonVoorvoegsel: String
    $natuurlijkPersoonGeslachtsnaam: String
    $natuurlijkPersoonGeboortedatum: String
    $WoonVestigingsadresStraat: String
    $WoonVestigingsadresHuisnummer: String
    $WoonVestigingsadresHuisnummertoevoeging: String
    $WoonVestigingsadresPostcode: String
    $WoonVestigingsadresPlaats: String
    $WoonVestigingsadresLand: String
    $CorrespondentieStraatOfPostbus: String
    $CorrespondentieHuisOfPostbusnummer: String
    $CorrespondentieHuisnummertoevoeging: String
    $CorrespondentiePostcode: String
    $CorrespondentiePlaats: String
    $CorrespondentieLand: String
    $Contactpersoon: String
    $Telefoonnummer: String
    $mailadres: String
    $URLVanUwWebsite: String
    $StraalverbindingLinkIDAanvrager: String
    $StraalverbindingSoortVerbinding: String
    $StraalverbindingInclusiefPassieveReflector: Int
    $StraalverbindingModulatiesoort: String
    $StraalverbindingPadlengte: Float
    $StraalverbindingFabricaat_typeZendontvanger: String
    $StraalverbindingFrequentiebandOndergrens: Float
    $StraalverbindingFrequentiebandBovengrens: Float
    $StraalverbindingBandbreedte: Float
    $StraalverbindingDatasnelheid: Float
    $StraalverbindingAutomaticTransmittedPowerControl: Int
    $StraalverbindingAdaptieveModulator: Int
    $StraalverbindingGewensteBeschikbaarheid: Float
    $ZenderStationsnaam1: String
    $ZenderStationsnaam2: String
    $ZenderStraat1: String
    $ZenderStraat2: String
    $ZenderPostcodeEnPlaats1: String
    $ZenderPostcodeEnPlaats2: String
    $ZenderCoordinatenOosterlengteGraden1: Float
    $ZenderCoordinatenOosterlengteGraden2: Float
    $ZenderCoordinatenOosterlengteMinuten1: Float
    $ZenderCoordinatenOosterlengteMinuten2: Float
    $ZenderCoordinatenOosterlengteSeconden1: Float
    $ZenderCoordinatenOosterlengteSeconden2: Float
    $ZenderCoordinatenNoorderbreedteGraden1: Float
    $ZenderCoordinatenNoorderbreedteGraden2: Float
    $ZenderCoordinatenNoorderbreedteMinuten1: Float
    $ZenderCoordinatenNoorderbreedteMinuten2: Float
    $ZenderCoordinatenNoorderbreedteSeconden1: Float
    $ZenderCoordinatenNoorderbreedteSeconden2: Float
    $AntenneFabricaatType1: String
    $AntenneFabricaatType2: String
    $AntenneOvereenkomstigLijstMetAntenneprofielen1: Float
    $AntenneOvereenkomstigLijstMetAntenneprofielen2: Float
    $AntennePolarisatie1: String
    $AntennePolarisatie2: String
    $Antennehoogte1: Float
    $Antennehoogte2: Float
    $AntenneHoofdstraalrichting1: Float
    $AntenneHoofdstraalrichting2: Float
    $AntenneKabelVerliezen1: Float
    $AntenneKabelVerliezen2: Float
    $AntenneOpeningshoek1: Float
    $AntenneOpeningshoek2: Float
    $AntenneGain1: Float
    $AntenneGain2: Float
    $AntenneZendfrequentie1: Float
    $AntenneZendfrequentie2: Float
    $AntenneZendvermogen1: Float
    $AntenneZendvermogen2: Float
    $PassieveReflectorStationsnaam: String
    $PassieveReflectorStraat: String
    $PassieveReflectorPostcodeEnPlaats: String
    $PassieveReflectorCoordinatenOosterlengteGraden: Float
    $PassieveReflectorCoordinatenOosterlengteMinuten: Float
    $PassieveReflectorCoordinatenOosterlengteSeconden: Float
    $PassieveReflectorCoordinatenNoorderbreedteGraden: Float
    $PassieveReflectorCoordinatenNoorderbreedteMinuten: Float
    $PassieveReflectorCoordinatenNoorderbreedteSeconden: Float
    $PassieveReflectorAntenneFabricaatType1: String
    $PassieveReflectorAntenneFabricaatType2: String
    $PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen1: Int
    $PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen2: Int
    $PassieveReflectorAntennePolarisatie1: String
    $PassieveReflectorAntennePolarisatie2: String
    $PassieveReflectorAntenneAntennehoogte1: Float
    $PassieveReflectorAntenneAntennehoogte2: Float
    $PassieveReflectorAntenneHoofdstraalrichting1: Float
    $PassieveReflectorAntenneHoofdstraalrichting2: Float
    $PassieveReflectorAntenneKabelverliezen1: Float
    $PassieveReflectorAntenneKabelverliezen2: Float
    $PassieveReflectorAntenneOpeningshoek1: Float
    $PassieveReflectorAntenneOpeningshoek2: Float
    $PassieveReflectorAntenneGain1: Float
    $PassieveReflectorAntenneGain2: Float
    $FacturatieContactpersoonOfAfdeling: String
    $FacturatieFactuuradres: String
    $FacturatiePostcode: String
    $FacturatiePlaats: String
    $FacturatieLand: String
    $FacturatieInternOrdernummer: Int
    $FacturatieBetalingswijze: String
    $BijlagenKopieInschrijvingBuitenlandseKvK: Int
    $BijlagenKopieIdentificatiebewijs: Int
    $BijlagenPlattegrondAntennelocatie: Int
    $BijlagenTechnischeParametersAntenne: Int
    $BijlagenTechnischeParametersReflectorAntenne: Int
    $MachtigingVertegenwoordigingDoorDerden: Int
    $ExtraToelichting: String
    $OndertekeningNaam: String
    $OndertekeningDatum: String
    $OndertekeningPlaats: String
    $OndertekeningHandtekening: String
  ) {
    addStraalVerbindingAanvraag(
      Relatienummer: $Relatienummer
      Vergunningsperiode: $Vergunningsperiode
      VergunningsperiodeVan: $VergunningsperiodeVan
      VergunningsperiodeTot: $VergunningsperiodeTot
      VergunningPersoonType: $VergunningPersoonType
      rechtspersoonStatutaireNaam: $rechtspersoonStatutaireNaam
      rechtspersoonHandelsnaam: $rechtspersoonHandelsnaam
      rechtspersoonKVKLocatie: $rechtspersoonKVKLocatie
      rechtspersoonKvK_nummer: $rechtspersoonKvK_nummer
      rechtspersoonVestigingsnummer: $rechtspersoonVestigingsnummer
      natuurlijkPersoonBurgerservicenummerInNederland: $natuurlijkPersoonBurgerservicenummerInNederland
      natuurlijkPersoonVoornamen: $natuurlijkPersoonVoornamen
      natuurlijkPersoonVoorvoegsel: $natuurlijkPersoonVoorvoegsel
      natuurlijkPersoonGeslachtsnaam: $natuurlijkPersoonGeslachtsnaam
      natuurlijkPersoonGeboortedatum: $natuurlijkPersoonGeboortedatum
      WoonVestigingsadresStraat: $WoonVestigingsadresStraat
      WoonVestigingsadresHuisnummer: $WoonVestigingsadresHuisnummer
      WoonVestigingsadresHuisnummertoevoeging: $WoonVestigingsadresHuisnummertoevoeging
      WoonVestigingsadresPostcode: $WoonVestigingsadresPostcode
      WoonVestigingsadresPlaats: $WoonVestigingsadresPlaats
      WoonVestigingsadresLand: $WoonVestigingsadresLand
      CorrespondentieStraatOfPostbus: $CorrespondentieStraatOfPostbus
      CorrespondentieHuisOfPostbusnummer: $CorrespondentieHuisOfPostbusnummer
      CorrespondentieHuisnummertoevoeging: $CorrespondentieHuisnummertoevoeging
      CorrespondentiePostcode: $CorrespondentiePostcode
      CorrespondentiePlaats: $CorrespondentiePlaats
      CorrespondentieLand: $CorrespondentieLand
      Contactpersoon: $Contactpersoon
      Telefoonnummer: $Telefoonnummer
      mailadres: $mailadres
      URLVanUwWebsite: $URLVanUwWebsite
      StraalverbindingLinkIDAanvrager: $StraalverbindingLinkIDAanvrager
      StraalverbindingSoortVerbinding: $StraalverbindingSoortVerbinding
      StraalverbindingInclusiefPassieveReflector: $StraalverbindingInclusiefPassieveReflector
      StraalverbindingModulatiesoort: $StraalverbindingModulatiesoort
      StraalverbindingPadlengte: $StraalverbindingPadlengte
      StraalverbindingFabricaat_typeZendontvanger: $StraalverbindingFabricaat_typeZendontvanger
      StraalverbindingFrequentiebandOndergrens: $StraalverbindingFrequentiebandOndergrens
      StraalverbindingFrequentiebandBovengrens: $StraalverbindingFrequentiebandBovengrens
      StraalverbindingBandbreedte: $StraalverbindingBandbreedte
      StraalverbindingDatasnelheid: $StraalverbindingDatasnelheid
      StraalverbindingAutomaticTransmittedPowerControl: $StraalverbindingAutomaticTransmittedPowerControl
      StraalverbindingAdaptieveModulator: $StraalverbindingAdaptieveModulator
      StraalverbindingGewensteBeschikbaarheid: $StraalverbindingGewensteBeschikbaarheid
      ZenderStationsnaam1: $ZenderStationsnaam1
      ZenderStationsnaam2: $ZenderStationsnaam2
      ZenderStraat1: $ZenderStraat1
      ZenderStraat2: $ZenderStraat2
      ZenderPostcodeEnPlaats1: $ZenderPostcodeEnPlaats1
      ZenderPostcodeEnPlaats2: $ZenderPostcodeEnPlaats2
      ZenderCoordinatenOosterlengteGraden1: $ZenderCoordinatenOosterlengteGraden1
      ZenderCoordinatenOosterlengteGraden2: $ZenderCoordinatenOosterlengteGraden2
      ZenderCoordinatenOosterlengteMinuten1: $ZenderCoordinatenOosterlengteMinuten1
      ZenderCoordinatenOosterlengteMinuten2: $ZenderCoordinatenOosterlengteMinuten2
      ZenderCoordinatenOosterlengteSeconden1: $ZenderCoordinatenOosterlengteSeconden1
      ZenderCoordinatenOosterlengteSeconden2: $ZenderCoordinatenOosterlengteSeconden2
      ZenderCoordinatenNoorderbreedteGraden1: $ZenderCoordinatenNoorderbreedteGraden1
      ZenderCoordinatenNoorderbreedteGraden2: $ZenderCoordinatenNoorderbreedteGraden2
      ZenderCoordinatenNoorderbreedteMinuten1: $ZenderCoordinatenNoorderbreedteMinuten1
      ZenderCoordinatenNoorderbreedteMinuten2: $ZenderCoordinatenNoorderbreedteMinuten2
      ZenderCoordinatenNoorderbreedteSeconden1: $ZenderCoordinatenNoorderbreedteSeconden1
      ZenderCoordinatenNoorderbreedteSeconden2: $ZenderCoordinatenNoorderbreedteSeconden2
      AntenneFabricaatType1: $AntenneFabricaatType1
      AntenneFabricaatType2: $AntenneFabricaatType2
      AntenneOvereenkomstigLijstMetAntenneprofielen1: $AntenneOvereenkomstigLijstMetAntenneprofielen1
      AntenneOvereenkomstigLijstMetAntenneprofielen2: $AntenneOvereenkomstigLijstMetAntenneprofielen2
      AntennePolarisatie1: $AntennePolarisatie1
      AntennePolarisatie2: $AntennePolarisatie2
      Antennehoogte1: $Antennehoogte1
      Antennehoogte2: $Antennehoogte2
      AntenneHoofdstraalrichting1: $AntenneHoofdstraalrichting1
      AntenneHoofdstraalrichting2: $AntenneHoofdstraalrichting2
      AntenneKabelVerliezen1: $AntenneKabelVerliezen1
      AntenneKabelVerliezen2: $AntenneKabelVerliezen2
      AntenneOpeningshoek1: $AntenneOpeningshoek1
      AntenneOpeningshoek2: $AntenneOpeningshoek2
      AntenneGain1: $AntenneGain1
      AntenneGain2: $AntenneGain2
      AntenneZendfrequentie1: $AntenneZendfrequentie1
      AntenneZendfrequentie2: $AntenneZendfrequentie2
      AntenneZendvermogen1: $AntenneZendvermogen1
      AntenneZendvermogen2: $AntenneZendvermogen2
      PassieveReflectorStationsnaam: $PassieveReflectorStationsnaam
      PassieveReflectorStraat: $PassieveReflectorStraat
      PassieveReflectorPostcodeEnPlaats: $PassieveReflectorPostcodeEnPlaats
      PassieveReflectorCoordinatenOosterlengteGraden: $PassieveReflectorCoordinatenOosterlengteGraden
      PassieveReflectorCoordinatenOosterlengteMinuten: $PassieveReflectorCoordinatenOosterlengteMinuten
      PassieveReflectorCoordinatenOosterlengteSeconden: $PassieveReflectorCoordinatenOosterlengteSeconden
      PassieveReflectorCoordinatenNoorderbreedteGraden: $PassieveReflectorCoordinatenNoorderbreedteGraden
      PassieveReflectorCoordinatenNoorderbreedteMinuten: $PassieveReflectorCoordinatenNoorderbreedteMinuten
      PassieveReflectorCoordinatenNoorderbreedteSeconden: $PassieveReflectorCoordinatenNoorderbreedteSeconden
      PassieveReflectorAntenneFabricaatType1: $PassieveReflectorAntenneFabricaatType1
      PassieveReflectorAntenneFabricaatType2: $PassieveReflectorAntenneFabricaatType2
      PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen1: $PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen1
      PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen2: $PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen2
      PassieveReflectorAntennePolarisatie1: $PassieveReflectorAntennePolarisatie1
      PassieveReflectorAntennePolarisatie2: $PassieveReflectorAntennePolarisatie2
      PassieveReflectorAntenneAntennehoogte1: $PassieveReflectorAntenneAntennehoogte1
      PassieveReflectorAntenneAntennehoogte2: $PassieveReflectorAntenneAntennehoogte2
      PassieveReflectorAntenneHoofdstraalrichting1: $PassieveReflectorAntenneHoofdstraalrichting1
      PassieveReflectorAntenneHoofdstraalrichting2: $PassieveReflectorAntenneHoofdstraalrichting2
      PassieveReflectorAntenneKabelverliezen1: $PassieveReflectorAntenneKabelverliezen1
      PassieveReflectorAntenneKabelverliezen2: $PassieveReflectorAntenneKabelverliezen2
      PassieveReflectorAntenneOpeningshoek1: $PassieveReflectorAntenneOpeningshoek1
      PassieveReflectorAntenneOpeningshoek2: $PassieveReflectorAntenneOpeningshoek2
      PassieveReflectorAntenneGain1: $PassieveReflectorAntenneGain1
      PassieveReflectorAntenneGain2: $PassieveReflectorAntenneGain2
      FacturatieContactpersoonOfAfdeling: $FacturatieContactpersoonOfAfdeling
      FacturatieFactuuradres: $FacturatieFactuuradres
      FacturatiePostcode: $FacturatiePostcode
      FacturatiePlaats: $FacturatiePlaats
      FacturatieLand: $FacturatieLand
      FacturatieInternOrdernummer: $FacturatieInternOrdernummer
      FacturatieBetalingswijze: $FacturatieBetalingswijze
      BijlagenKopieInschrijvingBuitenlandseKvK: $BijlagenKopieInschrijvingBuitenlandseKvK
      BijlagenKopieIdentificatiebewijs: $BijlagenKopieIdentificatiebewijs
      BijlagenPlattegrondAntennelocatie: $BijlagenPlattegrondAntennelocatie
      BijlagenTechnischeParametersAntenne: $BijlagenTechnischeParametersAntenne
      BijlagenTechnischeParametersReflectorAntenne: $BijlagenTechnischeParametersReflectorAntenne
      MachtigingVertegenwoordigingDoorDerden: $MachtigingVertegenwoordigingDoorDerden
      ExtraToelichting: $ExtraToelichting
      OndertekeningNaam: $OndertekeningNaam
      OndertekeningDatum: $OndertekeningDatum
      OndertekeningPlaats: $OndertekeningPlaats
      OndertekeningHandtekening: $OndertekeningHandtekening
    ) {
      id
    }
  }
`;

export const straalVerbindingAanvraagService = {
  addStraalVerbindingAanvraagMutation,
};
