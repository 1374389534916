import { gql } from "@apollo/client";

const getSitesQuery = gql`
  {
    sites {
      id
      description
      type
    }
  }
`;

const getSiteQuery = gql`
  query ($id: Int) {
    site(id: $id) {
      id
      description
      type
    }
  }
`;

const addSiteMutation = gql`
  mutation AddSite($description: String!, $type: String) {
    addSite(description: $description, type: $type) {
      id
      description
      type
    }
  }
`;

const updateSiteMutation = gql`
  mutation UpdateSite($id: Int!, $description: String!, $type: String) {
    updateSite(id: $id, description: $description, type: $type) {
      id
      description
      type
    }
  }
`;

const deleteSiteMutation = gql`
  mutation DeleteSite($id: Int!) {
    deleteSite(id: $id) {
      id
    }
  }
`;

export const siteService = {
  getSitesQuery,
  getSiteQuery,
  addSiteMutation,
  updateSiteMutation,
  deleteSiteMutation,
};
