import React from "react";
import Select from "react-select";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const getServicesQuery = gql`
  query {
    services {
      id
      name
    }
  }
`;

const MobileServicesSelect = ({ setSelectedService }) => {
  const { data, loading } = useQuery(getServicesQuery);

  return (
    <Select
      isLoading={loading}
      id="services"
      name="services"
      className="form-select rounded px-2 text-secondary w-full"
      onChange={(option) => {
        setSelectedService(option.value);
      }}
      placeholder={"Mobile Services"}
      options={
        data &&
        data.services &&
        data.services.map((service) => ({
          label: service.name,
          value: service.id,
        }))
      }
    />
  );
};

export default MobileServicesSelect;
