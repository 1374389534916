import React from "react";
import DashboardCard from "./DashboardCard";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const getDashboardQuery = gql`
  query {
    totalActiveBroadcasters {
      total
    }
  }
`;
const BroadcastersCard = () => {
  const { data, loading } = useQuery(getDashboardQuery, {
    fetchPolicy: "network-only",
  });
  return (
    <DashboardCard
      title="Active Broadcasters"
      data={loading ? <p>Loading...</p> : data.totalActiveBroadcasters.total}
      border="border-left-warning"
      icon="comments"
    />
  );
};

export default BroadcastersCard;
