import React, { useState } from "react";
import HeadingForm from "./HeadingForm";
import RowRegulatoryTracker from "./RowRegulatoryTracker";

export default function FormSection1RegulatoryAuthority() {

  
  return (
    <>
      <HeadingForm title="1 &nbsp;&nbsp;&nbsp;&nbsp;Regulatory Authority" />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <label className="block uppercase tracking-wide text-xs font-bold my-2"></label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Max Score
          </label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Coding
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            0 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            1 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2 pts
          </label>
        </div>
      </div>
      {/* End Row 3*/}
      <RowRegulatoryTracker
        title="1 Separate Telecommunications/ICT regulator"
        nameCoding={"raTelecommunicationsCoding"}
        namePoints={"raTelecommunicationsPoints"}
      />
      <RowRegulatoryTracker
        title="2 Autonomy in decision-making"
        nameCoding={"raAutonomyCoding"}
        namePoints={"raAutonomyPoints"}
      />
      <RowRegulatoryTracker
        title="3 Accountability"
        nameCoding={"raAccountabilityCoding"}
        namePoints={"raAccountabilityPoints"}
      />
      <RowRegulatoryTracker
        title="4 Percentage of diversified funding"
        nameCoding={"raDiversiviedFundingCoding"}
        namePoints={"raDiversiviedFundingPoints"}
      />
      <RowRegulatoryTracker
        title="5 Public consultations mandatory before decisions"
        nameCoding={"raPublicConsultationCoding"}
        namePoints={"raPublicConsultationPoints"}
      />
      <RowRegulatoryTracker
        title="6 Enforcement power"
        nameCoding={"raEnforcementPowerCoding"}
        namePoints={"raEnforcementPowerPoints"}
      />
      <RowRegulatoryTracker
        title="7 Sanctions or penalties imposed by regulator"
        nameCoding={"raRegulatorCoding"}
        namePoints={"raRegulatorPoints"}
      />
      <RowRegulatoryTracker
        title="8 Dispute resolution mechanism"
        nameCoding={"raDisputeResolutionCoding"}
        namePoints={"raDisputeResolutionPoints"}
      />
      <RowRegulatoryTracker
        title="9 Appeals to decisions"
        nameCoding={"raAppealsDicisionsCoding"}
        namePoints={"raAppealsDicisionsPoints"}
      />
      <RowRegulatoryTracker
        title="10 Existence of compitition autority"
        nameCoding={"raCompetitionAutorithyCoding"}
        namePoints={"raCompetitionAutorithyPoints"}
      />
    </>
  );
}
