import React from 'react'

const MonthlyReport = () => {
    return (
        <div>
            Monthly report
        </div>
    )
}

export default MonthlyReport;