import React, { useState } from "react";
import HeadingForm from "./HeadingForm";
import RowRegulatoryTracker from "./RowRegulatoryTracker";

export default function FormSection4CompItitionFramework() {
  return (
    <>
      <HeadingForm title="4 &nbsp;&nbsp;&nbsp;&nbsp;Compitition Framework" />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <label className="block uppercase tracking-wide text-xs font-bold my-2"></label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Max Score
          </label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Coding
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            0 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            1 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2 pts
          </label>
        </div>
      </div>
      {/* End Row 3*/}
      <RowRegulatoryTracker
        title="37 Level of competition in local and long distance fixed line service"
        nameCoding={"cfLongDistanceFixedLinesCoding"}
        namePoints={"cfLongDistanceFixedLinesPoints"}
      />
      <RowRegulatoryTracker
        title="38 Level of competition in IMT services"
        nameCoding={"cfImtServicesCoding"}
        namePoints={"cfImtServicesPoints"}
      />
      <RowRegulatoryTracker
        title="39 Level of competition in cable modem, DSL, fixed wireless broadband"
        nameCoding={"cfCableModemCoding"}
        namePoints={"cfCableModemPoints"}
      />
      <RowRegulatoryTracker
        title="40 Level of competion in leased lines"
        nameCoding={"cfLeasedLinesCoding"}
        namePoints={"cfLeasedLinesPoints"}
      />
      <RowRegulatoryTracker
        title="41 Level of competion in International Gateways"
        nameCoding={"cfInternationalGatewaysCoding"}
        namePoints={"cfInternationalGatewaysPoints"}
      />
      <RowRegulatoryTracker
        title="42 Status of the main fixed line operator"
        nameCoding={"cfMainFixedLinesCoding"}
        namePoints={"cfMainFixedLinesPoints"}
      />
      <RowRegulatoryTracker
        title="43 Legal concept of domunace or SMp"
        nameCoding={"cfLegalConceptSmpCoding"}
        namePoints={"cfLegalConceptSmpPoints"}
      />
      <RowRegulatoryTracker
        title="44 Criteria used in determining dominance or SMP"
        nameCoding={"cfDeterminingSmpCoding"}
        namePoints={"cfDeterminingSmpPoints"}
      />
      <RowRegulatoryTracker
        title="45 Foreign participation/ownership in facilities-based operators"
        nameCoding={"cfForeignParticipationFacilitiesCoding"}
        namePoints={"cfForeignParticipationFacilitiesPoints"}
      />
      <RowRegulatoryTracker
        title="46 Foreign participation/ownership in spectrum-based operators"
        nameCoding={"cfForeignParticipationSpectrumCoding"}
        namePoints={"cfForeignParticipationSpectrumPoints"}
      />
      <RowRegulatoryTracker
        title="47 Foreign participation/ownership in local services operators/long-distance service operators"
        nameCoding={"cfForeignParticipationLocalServicesCoding"}
        namePoints={"cfForeignParticipationLocalServicesPoints"}
      />
      <RowRegulatoryTracker
        title="33 number portability available to consumers and required from fixed-line operators"
        nameCoding={"rrNumberPortabilityFixedLineCoding"}
        namePoints={"rrNumberPortabilityFixedLinePoints"}
      />
      <RowRegulatoryTracker
        title="48 Foreign participation/ownership in international service operators"
        nameCoding={"cfForeignParticipationInternationalServicesCoding"}
        namePoints={"cfForeignParticipationInternationalServicesPoints"}
      />
      <RowRegulatoryTracker
        title="49 Foreign participation/ownership in Internet Service Providers (ISPs)"
        nameCoding={"cfForeignParticipationIspCoding"}
        namePoints={"cfForeignParticipationIspPoints"}
      />
      <RowRegulatoryTracker
        title="50 Foreign participation/ownership in value-added service providers"
        nameCoding={"cfForeignParticipationValueAddedCoding"}
        namePoints={"cfForeignParticipationValueAddedPoints"}
      />
    </>
  );
}
