import React from "react";
import Pix4dMap from "../components/map/Pix4dMap";

const PageInspections = () => {
  return (
    <>
      <div className="container-fluid">
        {/* Title */}
        <div className="row">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Inspections</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-12">
            <div className="card shadow mb-4">
              <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Locations</h6>
              </div>
              <div className="card-body" style={{ padding: "20px" }}>
                <div className="row">
                  <div className="col-sm-12">
                    <Pix4dMap />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageInspections;
