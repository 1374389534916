import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { signupMutation } from "../queries/queries";

const Register = (props) => {
  const [signup] = useMutation(signupMutation);
  useEffect(() => {
    document.body.classList.add("bg-gradient-primary");
    document
      .querySelector("#content-wrapper")
      .setAttribute("style", "background-color:transparent");
    return () => {
      document.body.classList.remove("bg-gradient-primary");
    };
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string()
          .email("Email is invalid")
          .required("Email is required"),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
      })}
      onSubmit={async (fields, { setErrors, setSubmitting }) => {
        try {
          await signup({ variables: fields });
          const { navigate } = props;
          navigate("/login");
        } catch (error) {
          if (error.message.includes("email")) {
            setErrors({ email: error.message.split(":")[1] });
          } else {
            setErrors({ password: error.message.split(":")[1] });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, isSubmitting, handleSubmit }) => {
        return (
          <div className="container">
            <div
              className="card o-hidden border-0 shadow-lg my-5"
              style={{ width: "30rem", margin: "0 auto" }}
            >
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          Create an Account!
                        </h1>
                      </div>
                      <Form className="user" onSubmit={handleSubmit}>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <Field
                              name="firstName"
                              type="text"
                              className={
                                "form-control form-control-user" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="First Name"
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-sm-6">
                            <Field
                              name="lastName"
                              type="text"
                              className={
                                "form-control form-control-user" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Last Name"
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <Field
                            name="email"
                            type="text"
                            className={
                              "form-control form-control-user" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Email Address"
                            disabled={isSubmitting}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <Field
                              name="password"
                              type="password"
                              className={
                                "form-control form-control-user" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Password"
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-sm-6">
                            <Field
                              name="confirmPassword"
                              type="password"
                              className={
                                "form-control form-control-user" +
                                (errors.confirmPassword &&
                                touched.confirmPassword
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Confirm Password"
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary btn-user btn-block"
                            disabled={isSubmitting}
                          >
                            Register Account
                          </button>
                        </div>
                      </Form>
                      <hr />
                      <div className="text-center">
                        <Link className="small" to="/forgot-password">
                          Forgot Password?
                        </Link>
                      </div>
                      <div className="text-center">
                        <Link className="small" to="/login">
                          Already have an account? Login!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Register;
