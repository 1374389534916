import React, { useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import {
  getModelsQuery,
  getmodelQuery,
  updateModelMutation,
  addModelMutation,
  deleteModelMutation,
} from "../../../queries/queries";

import Pagination from "../../Layout/Pagination";
import Modal from "../../Layout/Modal";
import ModelModal from "./ModalModel";

const initialValues = { id: 0, name: "", brand: { id: 0, name: "" } };

const ModelsOverview = () => {
  const [model, setModel] = useState(initialValues);
  const [open, setOpen] = useState(false);
  const [inputKey, setInputKey] = useState("");
  const [selectedModel, setSelectedModel] = useState(null);
  const { loading, data } = useQuery(getModelsQuery, {
    fetchPolicy: "network-only",
  });
  const [getModel, getModelResults] = useLazyQuery(getmodelQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = !loading && data.models ? data.models.length : 0;

  const currentItems =
    !loading && data.models
      ? data.models.slice(indexOfFirstItem, indexOfLastItem)
      : null;

  const [updatemodel] = useMutation(updateModelMutation, {
    variables: {
      id: parseInt(model.id),
      name: model.name,
       hasAttachment: model.hasAttachment? model.hasAttachment : false,
      attachment: model.attachment ? model.attachment:null,
      brandId: parseInt(model.brand.id),
    },
    refetchQueries: [{ query: getModelsQuery }],
    awaitRefetchQueries: true,
  });
  const [addmodel] = useMutation(addModelMutation, {
    variables: {
      name: model.name,
      hasAttachment: model.hasAttachment,
      attachment: model.attachment,
      brandId: parseInt(model.brand.id),
    },
    refetchQueries: [{ query: getModelsQuery }],
    awaitRefetchQueries: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "brand"
      ? setModel({ ...model, brand: { id: parseInt(value) } })
      : setModel({ ...model, [name]: value });
  };

  const handleImageReset = () => {
    setModel({ ...model, hasAttachment: false, attachment: "" });
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  };
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        setModel({
          ...model,
          hasAttachment: true,
          attachment: event.target.result,
        });
      };

      return reader.readAsDataURL(file);
    }
  };
  const openModal = (e, model) => {
    setModel(model);
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const [deleteModel] = useMutation(deleteModelMutation);

  const deleteItem = async (id) => {
    try {
      await deleteModel({
        variables: {
          id: parseInt(id),
        },
        refetchQueries: [{ query: getModelsQuery }],
      });
    } catch (error) {}
  };
  const saveItem = async () => {
    model.id ? updatemodel() : addmodel();
    closeModal();
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownmodelMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownmodelMenuLink"]')
      .classList.remove("show");
  };

  const openPdfModal = (e, model) => {
    e.preventDefault();
    getModel({ variables: { id: model.id } });
    setOpen(!open);
  };

  return loading ? (
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Models</h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownmodelMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownmodelMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#modelModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-condensed table-hover table-sm">
                <thead>
                  <tr className="bg-primary text-white">
                    <td>Model name</td>
                    <td>Brand name</td>
                    <td>pdf</td>
                    <td style={{ float: "right" }}>actions</td>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((model, index) => {
                      return (
                        <tr key={index}>
                          <td>{model.name}</td>
                          <td>{model.brand ? model.brand.name : "None"}</td>
                          <td>
                            {model.hasAttachment ? (
                              <i
                                className="fas fa-file-pdf"
                                onClick={(e) => openPdfModal(e, model)}
                              ></i>
                            ) : (
                              ""
                            )}
                          </td>
                          <td style={{ float: "right" }}>
                            <i
                              className="fas fa-fw fa-edit"
                              data-target="#modelModal"
                              onClick={(e) => openModal(e, model)}
                            ></i>
                            <i
                              className="fas fa-fw fa-trash"
                              onClick={() =>
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                ) && deleteItem(model.id)
                              }
                            ></i>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2">No models available</td>
                    </tr>
                  )}
                </tbody>
              </table>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                    size="sm"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModelModal
        model={model}
        brands={data.brands}
        handleImageChange={handleImageChange}
        handleImageReset={handleImageReset}
        inputKey={inputKey}
        handleChange={handleChange}
        closeModal={closeModal}
        saveItem={saveItem}
      />
      <Modal
        id="pdfModal"
        open={open}
        setOpen={setOpen}
        title={getModelResults?.data?.model?.name}
      >
        <iframe
          title={getModelResults?.data?.model?.name}
          src={getModelResults?.data?.model?.attachment}
          width="100%"
          height="560px"
        ></iframe>
      </Modal>
    </>
  );
};
export default ModelsOverview;
