import React, { useState, useEffect } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";

import {
  handlePolyLineClick,
  getFrequencyBandsList,
  getMicrowaveLinkLines,
  getLinksCoordinatesList,
  lineSymbol,
  animateLine,
} from "./microwaveLinks.js";
import {
  loadHandler,
  markerLoadHandler,
  markerClickHandler,
} from "./mapHandlers";

import StationInfoWindow from "./StationInfoWindow.jsx";
import MicrowaveLinkInfoWindow from "./MicrowaveLinkInfoWindow.jsx";

const Map = ({
  stations,
  selectedService,
  selectedCompany = "-1",
  showStations,
  showMicrowaveLinks,
}) => {
  //MapStateVariables
  const [center, setCenter] = useState({ lat: 18.03039, lng: -63.044781 });
  const [zoom, setZoom] = useState(12);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const [multiLines, setMultiLines] = useState([]);
  const [linePosition, setLinePosition] = useState(null);
  const [microWaveInfoOpen, setMicroWaveInfoOpen] = useState(false);

  //MapUtils
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
  });
  const getFilteredStationsMarkers = () => {
    const filtered = getFilteredStations();
    const markers = getStationsMarkers(filtered);

    return markers;
  };

  const getFilteredStations = () => {
    var filter = [];

    //SelectedService Filter
    stations.forEach((station) => {
      station.antennas.forEach((antenna) => {
        if (Number(selectedService) === antenna.frequency?.band.service.id) {
          if (!filter.find((s) => s.id === station.id)) {
            filter.push(station);
          }
        }
      });
    });

    //SelectedCompany Filter
    if (parseInt(selectedCompany) !== -1) {
      filter = filter.filter(
        (station) => station.company.id === parseInt(selectedCompany)
      );
    }

    return filter;
  };

  const getStationsMarkers = (stations) => {
    return stations.map((station) => (
      <Marker
        key={station.id}
        position={{ lat: station.latitude, lng: station.longitude }}
        onLoad={(marker) => markerLoadHandler(marker, station, setMarkerMap)}
        onClick={(event) =>
          markerClickHandler(
            event,
            station,
            setSelectedPlace,
            infoOpen,
            setInfoOpen,
            zoom,
            setZoom,
            setCenter
          )
        }
        icon={{
          url:
            station.stationType.name === "Tower"
              ? "/images/icons/antenas_icon.gif"
              : "/images/icons/building_icon.png",
          scaledSize: { width: 50, height: 36 },
        }}
      />
    ));
  };

  //MicrowaveLinksFilterFunctions
  const getFilteredMicrowaveLinksCards = () => {
    const filtered = getFilteredMicrowaveLinks();
    const lines = getMicrowaveLinkCards(filtered);
    return lines;
  };

  const getFilteredMicrowaveLinks = () => {
    const filteredStations = getFilteredStations();
    let frequencyBandsList = { names: [], frequencies: [] };
    let frequencyLinksCoordinates = [];

    // debugger;

    filteredStations.forEach((station) => {
      //Create Object to hold all equipment under specific frequency name
      frequencyBandsList = getFrequencyBandsList(station, frequencyBandsList);
    });

    //Create list of coordinates under specific frequency band, object: [{color, coordinates: [{}], frequencyBand}]
    frequencyLinksCoordinates = getLinksCoordinatesList(
      frequencyBandsList,
      frequencyLinksCoordinates
    );

    //Create lines and add under specific frequency band, object: [{color, coordinates, frequencyBand, lines: []}]
    const convertedFrequencyLinksCoordinates = getMicrowaveLinkLines(
      frequencyLinksCoordinates
    );

    return convertedFrequencyLinksCoordinates;
  };

  //MicrowaveLinksUIFunctions
  const getMicrowaveLinkCards = (microWaveLinks) => {
    return microWaveLinks.map((frequencyLine) =>
      frequencyLine.lines.map((line, index) => (
        <div key={index}>
          {/* Transparent wide polyline for clicking */}
          <Polyline
            key={index}
            path={line.coordinates}
            visible={false}
            onLoad={(poly) => {
              poly.visible = true;
            }}
            onClick={(e) => {
              handlePolyLineClick(
                e,
                line,
                setSelectedLine,
                setMultiLines,
                microWaveLinks,
                microWaveInfoOpen,
                setMicroWaveInfoOpen,
                setLinePosition
              );
            }}
            geodesic={true}
            options={{
              strokeColor: line.color,
              strokeOpacity: 0.01,
              strokeWeight: 20,
            }}
          />

          {/* Main Poly Line */}
          <Polyline
            key={Math.random()}
            path={line.coordinates}
            visible={false}
            onLoad={(poly) => {
              poly.visible = true;
              animateLine(poly);
            }}
            geodesic={true}
            options={{
              strokeColor: line.color,
              strokeOpacity: 0,
              strokeWeight: 2,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
            }}
          />
        </div>
      ))
    );
  };
  
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback((map) =>
    loadHandler({ map: map, assets: getFilteredStations(), center })
  );

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }
  return isLoaded ? (
    <>
      <GoogleMap
        onLoad={onLoad}
        center={center}
        zoom={zoom}
        onUnmount={onUnmount}
        mapContainerStyle={{
          height: "70vh",
          width: "100%",
        }}
      >
        {/* Lines for Microwave Links */}
        {showMicrowaveLinks &&
          parseInt(selectedService) === 1002 &&
          getFilteredMicrowaveLinksCards()}

        {/* Markers for Stations */}
        {showStations && stations && getFilteredStationsMarkers()}

        {/*Info Window for Stations */}
        {infoOpen && selectedPlace && (
          <StationInfoWindow
            selectedPlace={selectedPlace}
            anchor={markerMap[selectedPlace.id]}
            onCloseClick={() => setInfoOpen(false)}
          />
        )}

        {/* Microwave Links Info Window */}
        {microWaveInfoOpen && selectedLine && linePosition && (
          <MicrowaveLinkInfoWindow
            position={linePosition.position}
            onCloseClick={() => setMicroWaveInfoOpen(false)}
            selectedLine={selectedLine}
            multiLines={multiLines}
          />
        )}
      </GoogleMap>
    </>
  ) : (
    <p>loading...</p>
  );
};

export default React.memo(Map);
