import { gql } from "@apollo/client";

const getContractQuery = gql`
  query ($id: Int) {
    contract(id: $id) {
      id
      name
      startDate
      endDate
      uplinkLow
      uplinkMid
      uplinkHigh
      downlinkLow
      downlinkMid
      downlinkHigh
      attachments {
        id
        name
      }
      company {
        id
        name
      }
      division {
        id
        name
      }
      lineFee {
        id
        name
      }
      jurisdiction {
        id
        name
      }
      frequency {
        id
        name
        downlinkStart
        downlinkCenter
        downlinkEnd
        uplinkStart
        uplinkCenter
        uplinkEnd
        interval
        band {
          id
          name
          service {
            id
            name
            technology {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const getContractsQuery = gql`
  {
    contracts {
      id
      name
      startDate
      endDate
      uplinkLow
      uplinkMid
      uplinkHigh
      downlinkLow
      downlinkMid
      downlinkHigh
      attachments {
        id
        name
      }
      company {
        id
        name
      }
      division {
        id
        name
      }
      lineFee {
        id
        name
      }
      frequency {
        id
        name
        downlinkStart
        downlinkCenter
        downlinkEnd
        uplinkStart
        uplinkCenter
        uplinkEnd
        interval
        band {
          id
          name
          service {
            id
            name
            technology {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const getContractsByCompanyQuery = gql`
  query ($companyId: Int!) {
    contractsByCompany(companyId: $companyId) {
      id
      name
      startDate
      endDate
      uplinkLow
      uplinkMid
      uplinkHigh
      downlinkLow
      downlinkMid
      downlinkHigh
      attachments {
        id
        name
      }
      company {
        id
        name
      }
      division {
        id
        name
      }
      lineFee {
        id
        name
      }
      jurisdiction {
        id
        name
      }
      frequency {
        id
        name
        downlinkStart
        downlinkCenter
        downlinkEnd
        uplinkStart
        uplinkCenter
        uplinkEnd
        interval
        band {
          id
          name
          service {
            id
            name
            technology {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const addContractMutation = gql`
  mutation AddContract(
    $companyId: Int
    $name: String!
    $startDate: String
    $endDate: String
    $uplinkLow: Float
    $uplinkMid: Float
    $uplinkHigh: Float
    $downlinkLow: Float
    $downlinkMid: Float
    $downlinkHigh: Float
    $attachments: [AttachmentInput]
    $divisionId: Int
    $frequencyId: Int
    $lineFeeId: Int
    $jurisdictionId: Int
  ) {
    addContract(
      companyId: $companyId
      name: $name
      startDate: $startDate
      endDate: $endDate
      uplinkLow: $uplinkLow
      uplinkMid: $uplinkMid
      uplinkHigh: $uplinkHigh
      downlinkLow: $downlinkLow
      downlinkMid: $downlinkMid
      downlinkHigh: $downlinkHigh
      attachments: $attachments
      divisionId: $divisionId
      frequencyId: $frequencyId
      lineFeeId: $lineFeeId
      jurisdictionId: $jurisdictionId
    ) {
      id
    }
  }
`;

const updateContractMutation = gql`
  mutation UpdateContract(
    $id: Int!
    $name: String!
    $companyId: Int!
    $startDate: String
    $endDate: String
    $uplinkLow: Float
    $uplinkMid: Float
    $uplinkHigh: Float
    $downlinkLow: Float
    $downlinkMid: Float
    $downlinkHigh: Float
    $attachments: [AttachmentInput]
    $divisionId: Int
    $frequencyId: Int
    $lineFeeId: Int
    $jurisdictionId: Int
  ) {
    updateContract(
      id: $id
      name: $name
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      uplinkLow: $uplinkLow
      uplinkMid: $uplinkMid
      uplinkHigh: $uplinkHigh
      downlinkLow: $downlinkLow
      downlinkMid: $downlinkMid
      downlinkHigh: $downlinkHigh
      attachments: $attachments
      divisionId: $divisionId
      frequencyId: $frequencyId
      lineFeeId: $lineFeeId
      jurisdictionId: $jurisdictionId
    ) {
      id
    }
  }
`;

const deleteContractMutation = gql`
  mutation DeleteContract($id: Int!) {
    deleteContract(id: $id) {
      id
    }
  }
`;

export const contractService = {
  getContractQuery,
  getContractsQuery,
  getContractsByCompanyQuery,
  addContractMutation,
  updateContractMutation,
  deleteContractMutation
};
