import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import { NEXT_URL } from "../config/index";
import FormEquipment from "../components/FormPageEquipment/FormEquipment";
import LoadingGears from "../components/LoadingGears";
import { useParams } from "react-router-dom";

export default function FormPageEquipment(props) {
  
  //Constants
  let { id: id } = useParams();
  // const { id } = match.params;
  const isAddMode = !id;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const fetchCompanies = async () => {
    return axios.get(`${NEXT_URL}/api/companies`);
  };

  const fetchTechnologies = async () => {
    return axios.get(`${NEXT_URL}/api/technologies`);
  };

  const fetchServices = async () => {
    return axios.get(`${NEXT_URL}/api/services`);
  };

  const fetchFrequencies = async () => {
    return axios.get(`${NEXT_URL}/api/frequencies`);
  };

  const fetchBrands = async () => {
    return axios.get(`${NEXT_URL}/api/brands`);
  };

  const fetchModels = async () => {
    return axios.get(`${NEXT_URL}/api/models`);
  };

  const fetchAntennaTypes = async () => {
    return axios.get(`${NEXT_URL}/api/antennaTypes`);
  };

  const fetchTransmitterSites = async () => {
    return axios.get(`${NEXT_URL}/api/stations`);
  };

  const fetchContracts = async () => {
    return axios.get(`${NEXT_URL}/api/contracts`);
  };

  const fetchAntenna = async () => {
    return axios.get(`${NEXT_URL}/api/antennas/${id || -1}`);
  };
  
  const {
    data: dataCompanies,
    status: statusCompanies,
    error: errorCompanies,
    isPreviousData: isPreviousDataCompanies,
  } = useQuery([`Companies`], fetchCompanies, {
    keepPreviousData: true,
    refetchOnMount: true,
  });

  const {
    data: dataTechnologies,
    status: statusTechnologies,
    error: errorTechnologies,
    isPreviousData: isPreviousDataTechnologies,
  } = useQuery([`Technologies`], fetchTechnologies, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataServices,
    status: statusServices,
    error: errorServices,
    isPreviousData: isPreviousDataServices,
  } = useQuery([`Services`], fetchServices, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataFrequencies,
    status: statusFrequencies,
    error: errorFrequencies,
    isPreviousData: isPreviousDataFrequencies,
  } = useQuery([`Frequencies`], fetchFrequencies, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataBrands,
    status: statusBrands,
    error: errorBrands,
    isPreviousData: isPreviousDataBrands,
  } = useQuery([`Brands`], fetchBrands, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataModels,
    status: statusModels,
    error: errorModels,
    isPreviousData: isPreviousDataModels,
  } = useQuery([`Models`], fetchModels, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataAntennaTypes,
    status: statusAntennaTypes,
    error: errorAntennaTypes,
    isPreviousData: isPreviousDataAntennaTypes,
  } = useQuery([`AntennaTypes`], fetchAntennaTypes, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataTransmitterSites,
    status: statusTransmitterSites,
    error: errorTransmitterSites,
    isPreviousData: isPreviousDataTransmitterSites,
  } = useQuery([`TransmitterSites`], fetchTransmitterSites, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataContracts,
    status: statusContracts,
    error: errorContracts,
    isPreviousData: isPreviousDataContracts,
  } = useQuery([`Contracts`], fetchContracts, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataAntenna,
    status: statusAntenna,
    error: errorAntenna,
    isFetching: isFetchingAntenna,
    isPreviousData: isPreviousDataAntenna,
  } = useQuery([`FormPageEquipmentAntenna`], fetchAntenna, {
    refetchOnMount: true,
  });

  if (
    statusCompanies === "loading" ||
    statusTechnologies === "loading" ||
    statusServices === "loading" ||
    statusBrands === "loading" ||
    statusModels === "loading" ||
    statusAntennaTypes === "loading" ||
    statusTransmitterSites === "loading" ||
    statusContracts === "loading" ||
    statusFrequencies === "loading"
  ) {
    return <LoadingGears />;
    // return <div className="min-h-screen">Loading...</div>;
  }

  console.log(dataAntenna);
  if (statusCompanies === "error") {
    return <div>Error: Couldn't fetch data</div>;
  }

  return (
    <div className="min-h-screen mx-10 my-10">
      {isAddMode && (
        <div className="bg-white p-10">
          <h1 className="text-gray-500 text-3xl font-medium tracking-wide">
            Add Equipment
          </h1>
          <FormEquipment
            dataCompanies={dataCompanies}
            dataTechnologies={dataTechnologies}
            dataServices={dataServices}
            dataFrequencies={dataFrequencies}
            dataBrands={dataBrands}
            dataModels={dataModels}
            dataAntennaTypes={dataAntennaTypes}
            dataTransmitterSites={dataTransmitterSites}
            dataContracts={dataContracts}
            dataUser={props.user}
            formValues={null}
            isAddMode={true}
            id={id}
          />
        </div>
      )}
      {!isAddMode && (
        <>
          {statusAntenna === "loading" || isFetchingAntenna ? (
            <LoadingGears />
          ) : (
            <div className="bg-white p-10">
              <h1 className="text-gray-500 text-3xl font-medium tracking-wide">
                Edit Equipment
              </h1>
              <FormEquipment
                dataCompanies={dataCompanies}
                dataTechnologies={dataTechnologies}
                dataServices={dataServices}
                dataFrequencies={dataFrequencies}
                dataBrands={dataBrands}
                dataModels={dataModels}
                dataAntennaTypes={dataAntennaTypes}
                dataTransmitterSites={dataTransmitterSites}
                dataContracts={dataContracts}
                dataUser={props.user}
                formValues={dataAntenna?.data[0]}
                isAddMode={false}
                id={id}
              />
            </div>
          )}
          {statusAntenna === "error" && statusAntenna}
        </>
      )}
    </div>
  );
}
