import React, { useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Link } from "react-router-dom";

import { NEXT_URL, REACT_APP_DEV_MODE } from "../../config/index";
import { useMutation, useQueryClient } from "react-query";
import { useQuery } from "react-query";
import axios from "axios";

import ModalConfirmDeletion from "./ModalConfirmDeletion";
import CollapsibleRowEquipment from "./CollapsibleRowEquipment2";
import { COLUMNS } from "./constants";

export default function TableAntenna({
  dataAntennas,
  companyId,
  tableName = "Equipment",
  dataCompanies,
}) {
  //PropsCheck
  console.log({ TableAntennaPropsCheck: { dataAntennas } });


  //Constants
  const queryClient = useQueryClient();
 
  const deleteItem = (id) => {
    return axios.delete(`${NEXT_URL}/api/antennas/${id}`)
  };

     const {
       mutate: deleteItem2,
       isLoading,
       isError,
     } = useMutation(deleteItem, {
       onSuccess: () => {
         queryClient.invalidateQueries("PageEquipmentsAntennas");
       },
     });

  const filterRows = (rows) => {
    // let filteredRows = rows;
    if (companyId === -1) {
        return rows
    }
    let filteredRows = rows.filter(function (row) {
      return row.companyId === companyId;
    });
    return filteredRows;
  };

   const toggleAccordion = (e) => {
     e.preventDefault();
     const container = document.querySelector(
       e.target.parentNode.dataset.target
     );
     const row = e.target.parentNode;
     row.classList?.toggle("collapsed");
     container?.classList.toggle("show");
   };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "Actions",
        Cell: ({ row }) => (
          <div>
            {/* {console.log({row})} */}
            {/* <button
              className="mx-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
              onClick={() => {
                console.log({ id: row.values.id });
              }}
            >
              Edit
            </button> */}
            
            <Link
              to={{
                pathname: `/FormPageEquipmentEdit/${row.original.id}`,
              }}
              className="mx-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
            >
              Edit
            </Link>
            <ModalConfirmDeletion deleteItem={deleteItem2} id={row.original.id} />
          </div>
        ),
      },
    ]);
  };

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => filterRows(dataAntennas.data), [dataAntennas, companyId]);
  //   const router = useRouter();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    footerGroups,
    rows,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    tableHooks
  );

  //StateVariables
  const { pageIndex, pageSize } = state;

  return (
    <>
      {/* TableCard */}
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden  ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {/* Table */}
              <table
                className="min-w-full divide-y divide-gray-300"
                {...getTableProps()}
              >
                {/* TableHead */}
                <thead className="bg-blue-400 text-white">
                  {headerGroups.map((headerGroup, index) => (
                    // TableRow
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        // TableHeader
                        <th
                          key={index}
                          scope="col"
                          className={`${
                            index === 0 &&
                            "cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white-900 sm:pl-6"
                          } ${
                            index !== 0 &&
                            "cursor-pointer px-3 py-3.5 text-left text-sm font-semibold text-white-900"
                          }`}
                          {...column.getHeaderProps({
                            ...column.getSortByToggleProps(),
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          })}
                        >
                          {column.render("Header")}
                          <span className="inline">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 inline-block ml-2"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 inline-block ml-2"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )
                            ) : (
                              headerGroup.headers.length !== index + 1 && <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 inline-block ml-2"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                              </svg>
                            )}
                          </span>
                        </th>
                        // End TableHeader
                      ))}
                    </tr>
                    // End TableRow
                  ))}
                </thead>
                {/* End TableHead */}
                {/* TableBody */}
                <tbody className="bg-white" {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <>
                        <tr
                          key={index}
                          onClick={toggleAccordion}
                          data-target={`#collapse${row.original.id}`}
                          style={{ cursor: "pointer" }}
                          className={`${
                            index % 2 === 0 ? undefined : "bg-gray-50"
                          } cursor-pointer hover:bg-gray-100`}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell, index) => {
                            return (
                              // TableData
                              <td
                                key={index}
                                className={`${
                                  index === 0 &&
                                  "whitespace-nowrap py-4 text-ellipsis pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                } ${
                                  index !== 0 &&
                                  "whitespace-nowrap px-3 text-ellipsis py-4 text-sm text-gray-600"
                                }`}
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                        <CollapsibleRowEquipment antenna={row.original} />
                      </>
                    );
                  })}
                </tbody>
              </table>
              {/* PaginationSection */}
              <div className="mt-5 mb-3 mr-5 flex justify-end">
                <div className="flex gap-2 items items-center">
                  <button
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>{" "}
                  <button
                    onClick={() => previousPage()}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>{" "}
                  <button
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>{" "}
                  <button
                    className="relative mr-4 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 inline"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>{" "}
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    | Go to page:{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                      style={{ width: "50px" }}
                    />
                  </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {[10, 25, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
