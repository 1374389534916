//Step1
export const getFrequencyBandsList = (station, prevList) => {
  let frequencyBandsList = prevList;
  station.antennas.forEach((antenna) => {
    antenna.site = station.site;
    antenna.locationDescription = station.description;

    //
    if (!frequencyBandsList.names.includes(antenna.code)) {
      frequencyBandsList.names.push(antenna.code);

      let equipment = {
        locationName: station.description,
        coordinates: {
          lat: station.latitude,
          lng: station.longitude,
          locationName: station.description,
        },
        antenna: antenna,
      };

      let frequencyEquipmentList = {
        name: antenna.code,
        band: antenna.frequency.name,
        equipmentList: [equipment],
      };

      frequencyBandsList.frequencies.push(frequencyEquipmentList);
    } else {
      let equipment = {
        locationName: station.description,
        coordinates: {
          lat: station.latitude,
          lng: station.longitude,
        },
        antenna: antenna,
      };
      let frequencyRef = frequencyBandsList.frequencies.find(
        (f) => f.name === antenna.code
      );
      frequencyRef.equipmentList.push(equipment);
    }
  });
  console.log({frequencyBandsList});
  return frequencyBandsList;
};

//Step2
export const getLinksCoordinatesList = (
  frequencyBandsList,
  prevCoordinates
) => {
  let frequencyLinksCoordinates = prevCoordinates;

  frequencyBandsList.frequencies.forEach((frequency) => {
    const colors = getColors();
    let randomColor = colors[Math.floor(Math.random() * colors.length)];
    // let antennae = frequency.equipmentList.map(e => e.antenna)
    let frequencyLine = {
      color: randomColor,
      coordinates: [],
      frequencyName: frequency.name,
      frequencyBand: frequency.band,
      antennae: [],
      lines: [],
      locationNames: [],
    };
    frequency.equipmentList.forEach((equipment) => {
      frequencyLine.coordinates.push(equipment.coordinates);
      frequencyLine.locationNames.push(equipment.locationName);
      frequencyLine.antennae.push(equipment.antenna)
    });

    // debugger;
    frequencyLinksCoordinates.push(frequencyLine);
  });
  console.log({frequencyLinksCoordinates});
  return frequencyLinksCoordinates;
};

//Step3
export const getMicrowaveLinkLines = (prevCoordinates) => {
  let frequencyLinksCoordinates = prevCoordinates;
  frequencyLinksCoordinates.forEach((frequency) => {
    let line = {
      coordinates: [],
      between: [],
      color: "",
      frequencyName: "",
      frequencyBand: "",
      antennae: [],
    };

    for (let i = 0; i < frequency.coordinates.length; i++) {
      line.coordinates.push(frequency.coordinates[i]);
      line.between.push(frequency.locationNames[i]);
      if (i < frequency.coordinates.length - 1) {
        line.coordinates.push(frequency.coordinates[i + 1]);
        line.between.push(frequency.locationNames[i + 1]);
      } else {
        line.coordinates.push(frequency.coordinates[0]);
        line.between.push(frequency.locationNames[0]);
      }
      line.color = frequency.color;
      line.frequencyName = frequency.frequencyName;
      line.frequencyBand = frequency.frequencyBand;
      
      //Filter
      frequency.antennae.forEach((a) => {
        if (
          line.between.includes(
            a.locationDescription
          ) && line.frequencyBand == a.frequency.band.name
        ) {
          line.antennae.push(a);
        }
      });

      
      frequency.lines.push(line);


      //reset new line
      line = {
        coordinates: [],
        between: [],
        color: "",
        frequencyName: "",
        frequencyBand: "",
        antennae: [],
      };
    }
  });
  console.log({frequencyLinksCoordinates});
  return frequencyLinksCoordinates;
};

export const handlePolyLineClick = (
  e,
  line,
  setSelectedLine,
  setMultiLines,
  microWaveLinks,
  microWaveInfoOpen,
  setMicroWaveInfoOpen,
  setLinePosition
) => {
  setSelectedLine(line);

  //look for more lines on same route
  let multiLines = [];
  microWaveLinks.forEach((frequency) => {
    let frequencyCovered = false;
    frequency.lines.forEach((line2) => {
      if (
        line2.between.includes(line.between[0]) &&
        line2.between.includes(line.between[1]) &&
        line2 != line &&
        line2.between[0] != line2.between[1] &&
        !frequencyCovered
      ) {
        multiLines.push(line2);
        frequencyCovered = true;
      }
    });
  });

  setMultiLines(multiLines);

  if (microWaveInfoOpen) {
    setMicroWaveInfoOpen(false);
  }

  setLinePosition({ position: e.latLng });
  setMicroWaveInfoOpen(true);
};

export const getMultiLines = (multiLines) => {
  if (multiLines.length <= 0) return;
  return multiLines.map((line) => (
    <div
      key={Math.random()}
      className="list-group-item list-group-item my-2"
      style={{ background: line.color }}
    >
      <h5 style={{ color: "#FFF" }}>
        Microwave Link - Frequency : {line.frequencyBand}
      </h5>
    </div>
  ));
};

export const lineSymbol = {
  path: "M 10 80 C 40 10, 65 10, 95 80 S 150 150, 180 80",
  rotation: 90,
  strokeWeight: 2,
  strokeOpacity: 0.5,
  scale: 0.05,
};

export function animateLine(line) {
  let count = 0;

  window.setInterval(() => {
    count = (count + 1) % 200;

    const icons = line.get("icons");

    icons[0].offset = count / 2 + "%";
    line.set("icons", icons);
  }, 1200);
}

function getColors() {
  return ["#FF0000"];
}
