import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { requestResetMutation } from "../queries/queries";

const ForgotPassword = () => {
  const [requestReset, { loading, error, called }] =
    useMutation(requestResetMutation);
  useEffect(() => {
    document.body.classList.add("bg-gradient-primary");
    document
      .querySelector("#content-wrapper")
      .setAttribute("style", "background-color:transparent");
    return () => {
      document.body.classList.remove("bg-gradient-primary");
    };
  });

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Email is invalid")
          .required("Email is required"),
      })}
      onSubmit={async (fields, { resetForm, setErrors, setSubmitting }) => {
        try {
          await requestReset({ variables: fields });
          resetForm();
        } catch (error) {
          setErrors({ email: error.message.split(":")[1] });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, isSubmitting, status, handleSubmit }) => {
        return (
          <div
            className="container"
            style={{ width: "30rem", margin: "0 auto" }}
          >
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-2">
                              Forgot Your Password?
                            </h1>
                            <p className="mb-4">
                              Just enter your email address below and we'll send
                              you a link to reset your password!
                            </p>
                            {!loading && !error && called ? (
                              <p className="text-gray-900 mb-4">Email Sent!</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <Form className="user" onSubmit={handleSubmit}>
                            <div className="form-group">
                              <Field
                                type="email"
                                className={
                                  "form-control form-control-user" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                id="email"
                                name="email"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                disabled={isSubmitting}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <button
                              className="btn btn-primary btn-user btn-block"
                              disabled={isSubmitting}
                              type="submit"
                            >
                              Reset Password
                            </button>
                          </Form>
                          <hr />
                          <div className="text-center">
                            <Link className="small" to="/register">
                              Create an Account!
                            </Link>
                          </div>
                          <div className="text-center">
                            <Link className="small" to="/login">
                              Already have an account? Login!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}{" "}
    </Formik>
  );
};

export default ForgotPassword;
