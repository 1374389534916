import { format } from "date-fns";

export const COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    disableFilters: true,
    sticky: "left",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Relatienummer",
    Footer: "Naam Eigenaar",
    accessor: "Relatienummer",
    sticky: "left",
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
  {
    Header: "Vergunningsperiode ",
    Footer: "Kadastraalnummer",
    accessor: "Vergunningsperiode",
    sticky: "left",
    maxWidth: 190,
    minWidth: 190,
    width: 190,
  },
  //   {
  //     Header: "Telefoonnummer",
  //     Footer: "Waarde",
  //     accessor: "bedrag",
  //     sticky: "left",
  //     maxWidth: 140,
  //     minWidth: 140,
  //     width: 140,
  //   },
  {
    Header: "mailadres",
    Footer: "Naam Eigenaar",
    accessor: "mailadres",
    sticky: "left",
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
  {
    Header: "FacturatieInternOrdernummer",
    Footer: "Naam Eigenaar",
    accessor: "FacturatieInternOrdernummer",
    sticky: "left",
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
  {
    Header: "FacturatieBetalingswijze",
    Footer: "Naam Eigenaar",
    accessor: "FacturatieBetalingswijze",
    sticky: "left",
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
];
