import React from "react";
import ModalViewAttachment from "./ModalViewAttachment";

export default function CollapsibleRowEquipment({ antenna, openPdfModal }) {
  //PropsCheck
  console.log({ CollapsibleRowEquipmentPropsCheck: { antenna, openPdfModal } });

  return (
    <tr id={`collapse${antenna.id}`} className="collapse">
      <td colSpan={8}>
        <table
          className="table table-nostriped table-borderless"
          style={{ width: "100%" }}
        >
          <tbody>
            <tr>
              <td>Company:</td>
              <td> {antenna.company?.name || "-"}</td>
              <td>Site: </td>
              <td>{antenna.station?.description || "-"}</td>
            </tr>
            <tr>
              <td>Frequency: </td>
              <td>{antenna?.contract?.name}</td>
              <td>Description: </td>
              <td>{antenna.description}</td>
            </tr>
            <tr>
              <td>SerialNumber:</td>
              <td>{antenna.serialNumber || "-"}</td>
              <td>Height Above Ground:</td>
              <td> {antenna.heightAboveGround || "-"}</td>
            </tr>
            <tr>
              <td>Brand: </td>
              <td>{antenna.antennaType?.model?.brand?.name || "-"}</td>
              <td>Model: </td>
              <td>
                <ModalViewAttachment antenna={antenna} />
                {antenna.antennaType?.model?.hasAttachment && (
                  <i
                    className="fas fa-file-pdf"
                    onClick={(e) =>
                      openPdfModal(e, antenna.antennaType.model.id)
                    }
                  ></i>
                )}
              </td>
            </tr>
            <tr>
              <td>Access Type:</td>
              <td> {antenna.technology?.name || "-"}</td>

              <td>Service:</td>
              <td> {antenna.service?.name || "-"}</td>
            </tr>
            <tr>
              <td>Frequency Band:</td>
              <td> {antenna.frequency?.name || "-"}</td>
              <td>AntennaType:</td>
              <td> {antenna.antennaType ? antenna.antennaType.name : ""}</td>
            </tr>
            <tr>
              <td>Polarization Settings: </td>
              <td>{antenna.polarizationSettingsId || "-"}</td>
              <td>Polarization Type: </td>
              <td>{antenna.polarizationTypeId || "-"}</td>
            </tr>
            <tr>
              <td>Max VSWR: </td>
              <td>{antenna.maxVSWR || "-"}</td>
              <td>Min VSWR: </td>
              <td>{antenna.minVSWR || "-"}</td>
            </tr>
            <tr>
              <td>Directivity: </td>
              <td>{antenna.directivity || "-"}</td>
              <td>Gain: </td>
              <td>{antenna.gain || "-"}</td>
            </tr>
            <tr>
              <td>Efficiency: </td>
              <td>{antenna.efficiency || "-"}</td>
              <td>ERP: </td>
              <td>{antenna.erp || "-"}</td>
            </tr>
            <tr>
              <td>erpVType: </td>
              <td>{antenna.erpVTypeId || "-"}</td>
              <td>radiationPatern: </td>
              <td>{antenna.radiationPatern || "-"}</td>
            </tr>
            <tr>
              <td>radiaPFile: </td>
              <td>{antenna.radiaPFile || "-"}</td>
              <td>ANTK: </td>
              <td>{antenna.ANTK || "-"}</td>
            </tr>
            <tr>
              <td>ANTTA</td>
              <td>{antenna.ANTTA || "-"}</td>
              <td>STNR: </td>
              <td>{antenna.STNR || "-"}</td>
            </tr>
            <tr>
              <td>Cell Name: </td>
              <td>{antenna.cellName || "-"}</td>
              <td>Cell Id: </td>
              <td>{antenna.cellId || "-"}</td>
            </tr>
            <tr>
              <td>Cell Status: </td>
              <td>{antenna.cellStatus || "-"}</td>
              <td>Band: </td>
              <td>{antenna.band || "-"}</td>
            </tr>
            <tr>
              <td>DL UARFCN</td>
              <td>{antenna.DLUARFCN || "-"}</td>
              <td>UL UARFCN: </td>
              <td>{antenna.ULUARFCN || "-"}</td>
            </tr>
            <tr>
              <td>AZIMUTH TRUE NORTH </td>
              <td>{antenna.AZIMUTHTRUENORTH || "-"}</td>
              <td>AZIMUTH MAGNETIC: </td>
              <td>{antenna.AZIMUTHMAGNETIC || "-"}</td>
            </tr>
            <tr>
              <td>M-DOWNTILT: </td>
              <td>{antenna.MDOWNTILT || "-"}</td>
              <td>E-DOWNTILT: </td>
              <td>{antenna.EDOWNTILT || "-"}</td>
            </tr>
            <tr>
              <td>TILT</td>
              <td>{antenna.TILT || "-"}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
