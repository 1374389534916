import React from 'react';


const ContactModal = ({ contact, handleChange, closeModal, saveItem }) => {

    return (
        <div className="modal fade" id="contactModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Contact</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label forhtml="firstName" className="col-form-label">Firstname:</label>
                                <input type="text" className="form-control" id="firstName" name="firstName" onChange={(e) => handleChange(e)} value={contact.firstName || ''} />
                                <label forhtml="lastName" className="col-form-label">Lastname:</label>
                                <input type="text" className="form-control" id="lastName" name="lastName" onChange={(e) => handleChange(e)} value={contact.lastName || ''} />
                                <label forhtml="function" className="col-form-label">Function:</label>
                                <input type="text" className="form-control" id="function" name="function" onChange={(e) => handleChange(e)} value={contact.function || ''} />
                                <label forhtml="phone" className="col-form-label">Phone:</label>
                                <input type="text" className="form-control" id="phone" name="phone" onChange={(e) => handleChange(e)} value={contact.phone || ''} />
                                <label forhtml="coc" className="col-form-label">Email:</label>
                                <input type="text" className="form-control" id="email" name="email" onChange={(e) => handleChange(e)} value={contact.email || ''} />
                            </div>
                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactModal;