import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  getAntennaTypesQuery,
  updateAntennaTypeMutation,
  addAntennaTypeMutation,
  deleteAntennaTypeMutation,
} from "../../../queries/queries";

import Pagination from "../../Layout/Pagination";
import AntennaTypeModal from "./ModalAntennaType";

const initialValues = { id: 0, name: "", model: { id: 0 } };

const AntennaTypesOverview = () => {
  const [antennaType, setantennaType] = useState(initialValues);
  const { loading, data } = useQuery(getAntennaTypesQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems =
    !loading && data.antennaTypes ? data.antennaTypes.length : 0;

  const currentItems =
    !loading && data.antennaTypes
      ? data.antennaTypes.slice(indexOfFirstItem, indexOfLastItem)
      : null;

  const [updateAntennaType] = useMutation(updateAntennaTypeMutation, {
    variables: {
      id: parseInt(antennaType.id),
      name: antennaType.name,
      modelId: parseInt(antennaType.model.id),
    },
    refetchQueries: [{ query: getAntennaTypesQuery }],
  });
  const [addAntennaType] = useMutation(addAntennaTypeMutation, {
    variables: {
      name: antennaType.name,
      modelId: parseInt(antennaType.model.id),
    },
    refetchQueries: [{ query: getAntennaTypesQuery }],
  });

  const [deleteAntennaType] = useMutation(deleteAntennaTypeMutation);

  const deleteItem = async (id) => {
    try {
      await deleteAntennaType({
        variables: {
          id: parseInt(id),
        },
        refetchQueries: [{ query: getAntennaTypesQuery }],
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "model"
      ? setantennaType({ ...antennaType, model: { id: parseInt(value) } })
      : setantennaType({ ...antennaType, [name]: value });
  };
  const openModal = (e, antennaType) => {
    setantennaType(antennaType);
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const saveItem = async () => {
    antennaType.id ? updateAntennaType() : addAntennaType();
    closeModal();
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownantennaTypeMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownantennaTypeMenuLink"]')
      .classList.remove("show");
  };

  return loading ? (
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Antenna Types</h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownantennaTypeMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownantennaTypeMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#antennaTypeModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-condensed table-hover table-sm">
                <thead>
                  <tr className="bg-primary text-white">
                    <td>Antenna Type</td>
                    <td>Model number</td>
                    <td style={{ float: "right" }}>actions</td>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((antennaType, index) => {
                      console.log({ antennaType });
                      return (
                        <tr key={index}>
                          <td>{antennaType.name}</td>
                          <td>
                            {antennaType.model ? antennaType.model.name : null}
                          </td>
                          <td style={{ float: "right" }}>
                            <i
                              className="fas fa-fw fa-edit"
                              data-target="#antennaTypeModal"
                              onClick={(e) => openModal(e, antennaType)}
                            ></i>
                            <i
                              className="fas fa-fw fa-trash"
                              onClick={(e) =>
                                window.confirm(
                                  "Are you sure you want to delete this item?"
                                ) && deleteItem(antennaType.id)
                              }
                            ></i>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2">No Antenna Types available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                    size="sm"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AntennaTypeModal
        antennaType={antennaType}
        models={data.models}
        antennaTypeLookUpList={data.antennaTypeLookUpList}
        handleChange={handleChange}
        closeModal={closeModal}
        saveItem={saveItem}
      />
    </>
  );
};
export default AntennaTypesOverview;
