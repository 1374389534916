const environment = process.env.NODE_ENV.toString();
let isDevelopment = process.env.NODE_ENV.toString() === "development";
console.log({ environment });

isDevelopment = true;

export const REACT_APP_SERVER_URL = isDevelopment
  ? process.env.REACT_APP_SERVER_DEV_URL
  : process.env.REACT_APP_SERVER_PROD_URL;

console.log({ isDevelopment });
console.log({ REACT_APP_SERVER_URL });
