import React from 'react';


const AntennaTypeModal = ({ antennaType, models, antennaTypeLookUpList, handleChange, closeModal, saveItem }) => {
    return (
        <div className="modal fade" id="antennaTypeModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">AntennaType</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group col-sm-12">
                                <label forhtml="antennaType_name" className="col-form-label">Antenna type:</label>
                                <select className="form-control" id="antennaType_name" name="name" onChange={handleChange} value={antennaType.name || ''}>
                                    <option value="0" >--choose--</option>
                                    {
                                        antennaTypeLookUpList?.map((type, index) => <option key={index} value={type.name} >{type.name}</option>)

                                    }
                                </select>

                            </div>

                            <div className="form-group col-sm-12">
                                <label forhtml="antennaType_model" className="col-form-label">model number:</label>
                                <select className="form-control" id="antennaType_model" name="model" onChange={handleChange} value={antennaType.model ? (antennaType.model.id ? antennaType.model.id : '') : ''}>
                                    <option value="0" >--choose--</option>
                                    {

                                        models ? models.map((model, index) => <option key={index} value={model.id} >{model.name}</option>) : null

                                    }
                                </select>

                            </div>
                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AntennaTypeModal;