import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useStateValue } from "./formContextProvider";

import { initialValuesCompititionFramework } from "./initialValues.js";
import { validationCompititionFramework } from "./validationSchemaStepper";
import FormSection4CompititionFramework from "./FormSection4CompititionFramework";
import ButtonAutofill from "./ButtonAutofill.jsx";

export default function FormStep4({ handleNext, handleBack }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, setState } = useStateValue();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let testing = false;

    const cfTotal =
      parseInt(values.cfLongDistanceFixedLinesPoints) +
      parseInt(values.cfImtServicesPoints) +
      parseInt(values.cfCableModemPoints) +
      parseInt(values.cfLeasedLinesPoints) +
      parseInt(values.cfInternationalGatewaysPoints) +
      parseInt(values.cfMainFixedLinesPoints) +
      parseInt(values.cfLegalConceptSmpPoints) +
      parseInt(values.cfDeterminingSmpPoints) +
      parseInt(values.cfForeignParticipationFacilitiesPoints) +
      parseInt(values.cfForeignParticipationSpectrumPoints) +
      parseInt(values.cfForeignParticipationLocalServicesPoints) +
      parseInt(values.cfForeignParticipationInternationalServicesPoints) +
      parseInt(values.cfForeignParticipationIspPoints) +
      parseInt(values.cfForeignParticipationValueAddedPoints);

    values.cfBenchmark = cfTotal;

    let processedValues = values;
    setLoading(true);
    if (testing) {
      alert(JSON.stringify(processedValues, null, 2));
      console.log(JSON.stringify(processedValues, null, 2));
      setLoading(false);
      return;
    }
    try {
      // await saveRequest(processedValues);
      // setPageStateRouting("success");
      // resetForm();
      // setContext();
      setState({ ...state, ...processedValues });
      handleNext();
      setError(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValuesCompititionFramework}
      validationSchema={validationCompititionFramework}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, handleChange }) => {
        return (
          <>
            {
              <Form className="w-full">
                {error && `${error}`}
                <FormSection4CompititionFramework />

                <div className="flex justify-end p-4  gap-6">
                  <ButtonAutofill setFieldValue={setFieldValue} />

                  <button
                    disabled={loading}
                    onClick={handleBack}
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 -mr-1 w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                    Previous
                  </button>

                  <button
                    disabled={loading}
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {loading ? "Submitting" : "Next"}
                    <svg
                      className="ml-2 -mr-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </Form>
            }
          </>
        );
      }}
    </Formik>
  );
}
