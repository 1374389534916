import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Context } from "../../contexts/CompanyContext";

const ContractModal = ({
    data,
  closeModal,
  saveItem,
  handleChange,
  handleImageChange,
  handleImageReset,
  inputKey,
  loading,
  removePdf,
}) => {
  const { company, contract, setContract } = useContext(Context);
  const frequency = contract
    ? contract.frequency
      ? data?.frequencies.find((f) => f.id === contract.frequency.id)
      : null
    : null;
    if (!data) return 'loading';
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={contract}
      validationSchema={Yup.object().shape({
        name: Yup.string().nullable().required("Name is required"),
        frequency: Yup.object().required("Frequency is required"),
        startDate: Yup.string().required("StartDate is required"),
        endDate: Yup.string().required("EndDate is required"),
        // uplinkLow: Yup.number().when("frequency.band.service", {
        //   is: (val) => val.id === 3,
        //   then: Yup.number().notRequired().nullable(),
        //   otherwise: Yup.number()
        //     .required("Downlink Low is required")
        //     .min(frequency ? frequency.uplinkStart : 0),
        // }),
        // uplinkHigh: Yup.number().when("frequency.band.service", {
        //   is: (val) => val.id === 3,
        //   then: Yup.number().notRequired().nullable(),
        //   otherwise: Yup.number()
        //     .required("Downlink Low is required")
        //     .max(frequency ? frequency.uplinkEnd : 10000),
        // }),
        // downlinkLow: Yup.number().when("frequency.band.service", {
        //   is: (val) => val.id === 3,
        //   then: Yup.number().when("frequency", (frequency) => {
        //     return data?.frequencies.find((f) => f.id === frequency.id).name === "FM"
        //       ? Yup.number().notRequired().nullable()
        //       : Yup.number()
        //           .required("Downlink Low is required")
        //           .max(frequency ? frequency.downlinkStart : 0);
        //   }),
        //   otherwise: Yup.number().notRequired().nullable(),
        // }),
        // downlinkMid: Yup.number()
        //     .when('frequency.band.service', (frequency, schema, val) => {
        //         const result = allocations.find(a => a.frequency === parseFloat(val.value));
        //         console.log(allocations, val, result)
        //         return (frequencies.find(f => f.id === frequency.id).name === "FM" && (result.country.id !== company.country.id)) ?
        //             Yup.number().required('Downlink Mid is required').oneOf(allocations, `allready used by ${result ? result.country.name : ''}`)
        //             : Yup.number().notRequired()
        //     }),
        // downlinkHigh: Yup.number().when("frequency.band.service", {
        //   is: (val) => val.id === 3, // alternatively: (val) => val == true
        //   then: Yup.number().when("frequency", (frequency) => {
        //     return data?.frequencies.find((f) => f.id === frequency.id).name === "FM"
        //       ? Yup.number().notRequired().nullable()
        //       : Yup.number()
        //           .required("Downlink High is required")
        //           .max(frequency ? frequency.downlinkEnd : 10000);
        //   }),
        //   otherwise: Yup.number().notRequired().nullable(),
        // }),
      })}
      onSubmit={(fields, { setErrors, setSubmitting }) => {
        try {
          setSubmitting(true);
          saveItem(fields);
          closeModal();
        } catch (error) {
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, handleSubmit }) => {
        return (
          <div
            className="modal fade"
            id="contractModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="modalLabel">
                    Contract
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Form>
                    <div className="form-row">
                      <label forhtml="name" className="col-form-label">
                        Name:
                      </label>
                      <div className="form-group col-md-12">
                        <Field
                          type="text"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                          name="name"
                          id="name"
                          value={values.name || ""}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label forhtml="startDate" className="col-form-label">
                          Start Date:
                        </label>
                        <DatePicker
                          name="startDate"
                          selected={(values && values.startDate) || ""}
                          onChange={(value) =>
                            setContract({
                              ...values,
                              startDate: new Date(value),
                            })
                          }
                          className="form-control"
                        />
                        <ErrorMessage
                          name="startDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label forhtml="endDate" className="col-form-label">
                          End Date:
                        </label>
                        <DatePicker
                          name="endDate"
                          selected={(values && values.endDate) || ""}
                          onChange={(value) =>
                            setContract({ ...values, endDate: new Date(value) })
                          }
                          className="form-control"
                        />
                        <ErrorMessage
                          name="endDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label forhtml="technology" className="col-form-label">
                          Division:
                        </label>
                        <select
                          className={
                            "form-control" +
                            (errors.division && touched.division
                              ? " is-invalid"
                              : "")
                          }
                          id="division"
                          name="division"
                          onChange={handleChange}
                          value={values.division ? values.division.id : ""}
                        >
                          <option value="">--choose--</option>
                          {data?.divisions.map((division, index) => (
                            <option key={index} value={division.id}>
                              {division.name}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="division"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label forhtml="technology" className="col-form-label">
                          Access Type:
                        </label>
                        <select
                          className={
                            "form-control" +
                            (errors.technology && touched.technology
                              ? " is-invalid"
                              : "")
                          }
                          id="technology"
                          name="technology"
                          onChange={handleChange}
                          value={
                            values.frequency
                              ? values.frequency.band
                                ? values.frequency.band.service
                                  ? values.frequency.band.service.technology
                                    ? values.frequency.band.service.technology
                                        .id
                                    : ""
                                  : ""
                                : ""
                              : ""
                          }
                        >
                          <option value="">--choose--</option>
                          {data?.technologies.map((technology, index) => (
                            <option key={index} value={technology.id}>
                              {technology.name}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="technology"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label forhtml="service" className="col-form-label">
                          Service:
                        </label>
                        <select
                          className={
                            "form-control" +
                            (errors.service && errors.service
                              ? " is-invalid"
                              : "")
                          }
                          name="service"
                          onChange={handleChange}
                          value={
                            values.frequency
                              ? values.frequency.band
                                ? values.frequency.band.service
                                  ? values.frequency.band.service.id
                                  : ""
                                : ""
                              : ""
                          }
                          disabled={
                            !contract.frequency.band.service.technology.id
                          }
                        >
                          <option value="">--choose--</option>
                          {values.frequency &&
                          values.frequency.band &&
                          values.frequency.band.service
                            ? data?.services.map((service, index) => (
                                <option key={index} value={service.id}>
                                  {service.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <ErrorMessage
                          name="service"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label forhtml="frequency" className="col-form-label">
                          Frequency:
                        </label>
                        <select
                          className={
                            "form-control" +
                            (errors.frequency && touched.frequency
                              ? " is-invalid"
                              : "")
                          }
                          name="frequency"
                          onChange={handleChange}
                          value={values.frequency ? values.frequency.id : ""}
                          disabled={!contract.frequency.band.service.id}
                        >
                          <option value="">--choose--</option>
                          {values.frequency
                            ? data?.frequencies.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <ErrorMessage
                          name="frequency"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      {contract.division.id === 2 ? (
                        <>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="downlinkLow"
                              className="col-form-label"
                            >
                              Downlink Low:
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.downlinkLow && touched.downlinkLow
                                  ? " is-invalid"
                                  : "")
                              }
                              name="downlinkLow"
                              value={values.downlinkLow || ""}
                              onChange={handleChange}
                              disabled={
                                isSubmitting || frequency?.id
                                  ? data?.frequencies.find(
                                      (f) => f.id === frequency.id
                                    ).name === "FM"
                                  : null
                              }
                            />
                            <ErrorMessage
                              name="downlinkLow"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="downlinkMid"
                              className="col-form-label"
                            >
                              Downlink Mid
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.downlinkMid && touched.downlinkMid
                                  ? " is-invalid"
                                  : "")
                              }
                              name="downlinkMid"
                              value={values.downlinkMid || ""}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="downlinkMid"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="downlinkHigh"
                              className="col-form-label"
                            >
                              Downlink High:
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.downlinkHigh && touched.downlinkHigh
                                  ? " is-invalid"
                                  : "")
                              }
                              name="downlinkHigh"
                              value={values.downlinkHigh || ""}
                              onChange={handleChange}
                              disabled={
                                isSubmitting || frequency?.id
                                  ? data?.frequencies.find(
                                      (f) => f.id === frequency.id
                                    ).name === "FM"
                                  : null
                              }
                            />
                            <ErrorMessage
                              name="downlinkHigh"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="uplinkLow"
                              className="col-form-label"
                            >
                              Uplink low:
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.uplinkLow && touched.uplinkLow
                                  ? " is-invalid"
                                  : "")
                              }
                              name="uplinkLow"
                              value={values.uplinkLow || ""}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="uplinkLow"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="uplinkMid"
                              className="col-form-label"
                            >
                              Uplink mid:
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.uplinkMid && touched.uplinkMid
                                  ? " is-invalid"
                                  : "")
                              }
                              name="uplinkMid"
                              value={values.uplinkMid || ""}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="uplinkMid"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="uplinkHigh"
                              className="col-form-label"
                            >
                              Uplink High:
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.uplinkHigh && touched.uplinkHigh
                                  ? " is-invalid"
                                  : "")
                              }
                              name="uplinkHigh"
                              value={values.uplinkHigh || ""}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="uplinkHigh"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="downlinkLow"
                              className="col-form-label"
                            >
                              Downlink Low:
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.downlinkLow && touched.downlinkLow
                                  ? " is-invalid"
                                  : "")
                              }
                              name="downlinkLow"
                              value={values.downlinkLow}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="downlinkLow"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="downlinkMid"
                              className="col-form-label"
                            >
                              Downlink Mid
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.downlinkMid && touched.downlinkMid
                                  ? " is-invalid"
                                  : "")
                              }
                              name="downlinkMid"
                              value={values.downlinkMid || ""}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="downlinkMid"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label
                              forhtml="downlinkHigh"
                              className="col-form-label"
                            >
                              Downlink High:
                            </label>
                            <Field
                              type="number"
                              className={
                                "form-control" +
                                (errors.downlinkHigh && touched.downlinkHigh
                                  ? " is-invalid"
                                  : "")
                              }
                              name="downlinkHigh"
                              value={values.downlinkHigh}
                              onChange={handleChange}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage
                              name="downlinkHigh"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </>
                      )}

                      <div className="form-group col-md-6">
                        <label forhtml="lineFee" className="col-form-label">
                          LineFee:
                        </label>
                        <select
                          className="form-control"
                          id="lineFee"
                          name="lineFee"
                          onChange={(e) => handleChange(e)}
                          value={contract.lineFee ? contract.lineFee.id : ""}
                        >
                          <option value="0">--choose--</option>
                          {data?.lineFees
                            ? data?.lineFees
                                .filter(
                                  (line) =>
                                    line.technology.id ===
                                    contract.frequency?.band?.service
                                      ?.technology.id
                                )
                                .map((lineFee, index) => (
                                  <option key={index} value={lineFee.id}>
                                    {lineFee.name}
                                  </option>
                                ))
                            : null}
                        </select>
                        <ErrorMessage
                          name="lineFee"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {
                        //check if technology is point to point
                        contract.frequency?.band?.service?.technology.id ===
                        1 ? (
                          <div className="form-group col-md-6">
                            <label
                              forhtml="jurisdiction"
                              className="col-form-label"
                            >
                              Juristdiction:
                            </label>
                            <select
                              className="form-control"
                              id="jurisdiction"
                              name="jurisdiction"
                              onChange={(e) => handleChange(e)}
                              value={
                                contract?.jurisdiction
                                  ? contract.jurisdiction.id
                                  : ""
                              }
                            >
                              <option value="0">--choose--</option>
                              {data?.jurisdictions
                                ? data?.jurisdictions.map((lineFee, index) => (
                                    <option key={index} value={lineFee.id}>
                                      {lineFee.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <ErrorMessage
                              name="jurisdiction"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        ) : null
                      }
                      <div className="form-group col-sm-12">
                        <label forhtml="attachments" className="col-form-label">
                          attachments:
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="attachments"
                          key={inputKey || ""}
                          name="attachments"
                          onChange={handleImageChange}
                          style={{ border: 0 }}
                          multiple
                        />
                      </div>
                      {contract.attachments.length > 0 && !loading && (
                        <div className="form-group col-sm-12">
                          <ul>
                            {contract.attachments.map((a, i) => {
                              a.__typename && delete a.__typename;
                              return (
                                <li key={i}>
                                  {a.name}
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      height: 20,
                                      marginLeft: 10,
                                      padding: "0 5px",
                                    }}
                                    onClick={(e) => removePdf(e, i)}
                                  >
                                    remove
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      <ErrorMessage
                        name="general"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ContractModal;
