import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import FormCustomSelect from "../FormCustomSelect";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  band: Yup.string().nullable().required("Band is required"),
});

export default function FormStation({
  station,
  companies,
  sites,
  towerTypes,
  stationTypes,
  handleChange,
  handleSectorChange,
  handleRemoveClick,
  handleAddClick,
  closeModal,
  saveItem,
}) {
  const handleSubmit = (values, { setErrors, setSubmitting }) => {
    let testing = true;
    if (testing) {
      alert(JSON.stringify(values, null, 2));
      console.log(JSON.stringify(values, null, 2));
    //   setLoading(false);
      return;
    }

    // try {
    //   setSubmitting(true);
    //   saveItem(values);
    //   closeModal();
    // } catch (error) {
    //   if (error.message.includes("band")) {
    //     setErrors({ band: error.band.split(":")[1] });
    //   }
    //   setErrors({ general: error.message.split(":")[1] });
    // } finally {
    //   setSubmitting(false);
    // }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={station}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting, handleSubmit }) => {
          // console.log(values.sectors.length)
          // values.sectors.length === 0 &&
          //   values.sectors.push({ description: "" });

          return (
            <Form>
              <div className="form-row w-full">
                {/* Form Row */}
                <div className="form-group col-md-12">
                  <label forhtml="description" className="col-form-label">
                    Facility Name:
                  </label>
                  <Field
                    type="text"
                    className={
                      "form-control" +
                      (errors.description && touched.description
                        ? " is-invalid"
                        : "")
                    }
                    id="description"
                    name="description"
                    onChange={(e) => handleChange(e)}
                    value={values.description}
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="form-group col-md-6">
                  <label forhtml="latitude" className="col-form-label">
                    Latitude:
                  </label>
                  <Field
                    type="number"
                    className={
                      "form-control" +
                      (errors.latitude && touched.latitude ? " is-invalid" : "")
                    }
                    id="latitude"
                    name="latitude"
                    onChange={(e) => handleChange(e)}
                    value={values.latitude}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label forhtml="longitude" className="col-form-label">
                    Longitude:
                  </label>
                  <input
                    type="number"
                    className={
                      "form-control" +
                      (errors.longitude && touched.longitude
                        ? " is-invalid"
                        : "")
                    }
                    id="longitude"
                    name="longitude"
                    onChange={(e) => handleChange(e)}
                    value={values.longitude}
                  />
                </div>
                {/* End Row */}
                <div className="form-group col-md-6">
                  <label forhtml="height" className="col-form-label">
                    Height above sea level:
                  </label>
                  <input
                    type="number"
                    className={
                      "form-control" +
                      (errors.height && touched.height ? " is-invalid" : "")
                    }
                    id="height"
                    name="height"
                    onChange={(e) => handleChange(e)}
                    value={values.height}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label forhtml="sector" className="col-form-label">
                    Sector:
                  </label>
                  {values.sectors.map((sector, index) => {
                    sector.__typename && delete sector.__typename;
                    return (
                      <div className="form-row" key={index}>
                        <div className="form-group col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            id={`sector${index}`}
                            name="description"
                            onChange={(e) => handleSectorChange(e, index)}
                            value={sector?.description || ""}
                          />
                        </div>
                        <div
                          className={`form-group ${
                            values.sectors.length !== 1 ? "col-md-2" : false
                          }`}
                        >
                          {values.sectors.length !== 1 && (
                            <button
                              className="form-control"
                              onClick={() => handleRemoveClick(index)}
                            >
                              -
                            </button>
                          )}
                        </div>
                        <div className="form-group col-md-2">
                          {values.sectors.length - 1 === index && (
                            <button
                              className="form-control"
                              onClick={handleAddClick}
                            >
                              +
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* <div className="form-group col-md-6">
                  <label forhtml="company" className="col-form-label">
                    Company:
                  </label>
                  <select
                    className={
                      "form-control" +
                      (errors.company && touched.company ? " is-invalid" : "")
                    }
                    id="company"
                    name="company"
                    onChange={handleChange}
                    value={values.company ? values.company.id : ""}
                    disabled={isSubmitting}
                  >
                    <option value="0">--choose--</option>
                    {companies
                      ? companies.map((company, index) => (
                          <option key={index} value={company.id}>
                            {company.name}
                          </option>
                        ))
                      : null}
                  </select>

                  <ErrorMessage
                    name="company"
                    component="div"
                    className="invalid-feedback"
                  />
                </div> */}

                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-1 pb-4 w-full">
                  {/* Form Col*/}
                  <Field
                    id="company"
                    name={`company`}
                    title="Company:"
                    options={companies?.map((company, index) => {
                      return { value: company.id, label: company.name };
                    })}
                    component={FormCustomSelect}
                    placeholder="--choose--"
                    isMulti={false}
                    onChangeFunction={async (value, name) => {
                      const e = { target: { name: name, value: value } };
                      // console.log({ e });
                      handleChange(e);
                    }}
                  />
                  {/* Form Col*/}
                  <Field
                    id="stationType"
                    name={`stationType`}
                    title="Facility:"
                    options={stationTypes?.map((company, index) => {
                      return { value: company.id, label: company.name };
                    })}
                    component={FormCustomSelect}
                    placeholder="--choose--"
                    isMulti={false}
                    onChangeFunction={async (value, name) => {
                      const e = { target: { name: name, value: value } };
                      // console.log({ e });
                      handleChange(e);
                    }}
                  />
                </div>
                {/* End Row */}

                {/* Form Row */}
                {values.stationType?.id !== "2004" && (
                  <div className="grid grid-cols-12 gap-6 pt-1 pb-4 w-full">
                    {/* Form Col*/}
                    <Field
                      id="site"
                      name={`site`}
                      title="Site:"
                      options={sites?.map((company, index) => {
                        return {
                          value: company.id,
                          label: company.description,
                        };
                      })}
                      component={FormCustomSelect}
                      placeholder="--choose--"
                      isMulti={false}
                      onChangeFunction={async (value, name) => {
                        const e = { target: { name: name, value: value } };
                        // console.log({ e });
                        handleChange(e);
                      }}
                    />
                    {/* Form Col*/}
                    <Field
                      id="towerType"
                      name={`towerType`}
                      title="Tower Type:"
                      options={towerTypes?.map((company, index) => {
                        return { value: company.id, label: company.name };
                      })}
                      component={FormCustomSelect}
                      placeholder="--choose--"
                      isMulti={false}
                      onChangeFunction={async (value, name) => {
                        const e = { target: { name: name, value: value } };
                        // console.log({ e });
                        handleChange(e);
                      }}
                    />
                  </div>
                )}
                {/* End Row */}

                {/* <div className="form-group col-md-6">
                  <label forhtml="stationType" className="col-form-label">
                    Facility:
                  </label>
                  <select
                    className={
                      "form-control" +
                      (errors.stationType && touched.stationType
                        ? " is-invalid"
                        : "")
                    }
                    id="stationType"
                    name="stationType"
                    onChange={handleChange}
                    value={values.stationType ? values.stationType.id : ""}
                    disabled={isSubmitting}
                  >
                    <option value="0">--choose--</option>
                    {stationTypes
                      ? stationTypes.map((type, index) => (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <ErrorMessage
                    name="stationType"
                    component="div"
                    className="invalid-feedback"
                  />
                </div> */}

                {values.stationType?.id !== "2004" && (
                  <>
                    {/* <div className="form-group col-md-6">
                      <label forhtml="site" className="col-form-label">
                        Site:
                      </label>
                      <select
                        className={
                          "form-control" +
                          (errors.site && touched.site ? " is-invalid" : "")
                        }
                        id="site"
                        name="site"
                        onChange={handleChange}
                        value={values.site || ""}
                        disabled={isSubmitting}
                      >
                        <option value="0">--choose--</option>
                        {sites
                          ? sites.map((site, index) => (
                              <option key={index} value={site.id}>
                                {site.description}
                              </option>
                            ))
                          : null}
                      </select>
                      <ErrorMessage
                        name="site"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div> */}
                    {/* <div className="form-group col-md-6">
                      <label forhtml="towerType" className="col-form-label">
                        Tower type:
                      </label>
                      <select
                        className={
                          "form-control" +
                          (errors.towerType && touched.towerType
                            ? " is-invalid"
                            : "")
                        }
                        id="towerType"
                        name="towerType"
                        onChange={handleChange}
                        value={values.towerType || ""}
                        disabled={isSubmitting}
                      >
                        <option value="0">--choose--</option>
                        {towerTypes?.map((type, index) => (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        name="towerType"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div> */}
                  </>
                )}

                <div className="form-group col-md-6">
                  <label forhtml="description" className="col-form-label">
                    Site Status:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="siteStatus"
                    name="siteStatus"
                    onChange={handleChange}
                    value={values.siteStatus || ""}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label forhtml="description" className="col-form-label">
                    Site ID UMTS:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="siteIDUMTS"
                    name="siteIDUMTS"
                    onChange={handleChange}
                    value={values.siteIDUMTS || ""}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label forhtml="description" className="col-form-label">
                    Site ID LTE:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="siteIDLTE"
                    name="siteIDLTE"
                    onChange={(e) => handleChange(e)}
                    value={values.siteIDLTE || ""}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label forhtml="remarks" className="col-form-label">
                    Remarks:
                  </label>
                  <Field
                    as="textarea"
                    type="text"
                    className={
                      "form-control" +
                      (errors.remarks && touched.remarks ? " is-invalid" : "")
                    }
                    id="remarks"
                    name="remarks"
                    onChange={(e) => handleChange(e)}
                    value={values.remarks || ""}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
