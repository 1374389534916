import React, { useState, useEffect } from "react";
import { withRouter } from "../../utils/withRouter";

import SidebarItem from "./SidebarItem";
import RoundedCircleSideBarToggle from "./SideBarToggle";
import SidebarContext from "../../contexts/sidebar.context";
import SidebarBrand from "./SidebarBrand";
import SitebarAccordionItem from "./SidebarAccordion";

const Sidebar = ({ location, role }) => {
  const [toggled, setToggled] = useState(true);

  const toggleSidebar = () => {
    setToggled(!toggled);
    document.querySelector("#accordionSidebar").classList.toggle("toggled");
    document.querySelector("body").classList.toggle("sidebar-toggled");
    if (toggled) {
      document
        .querySelector("#accordionSidebar")
        .classList.contains(".collapse") &&
        document.querySelector("#accordionSidebar").classList.add("hide");
    }
  };

  useEffect(() => {
    if (document.querySelector("#accordionSidebar")) {
      setToggled(
        document
          .querySelector("#accordionSidebar")
          .classList.contains("toggled")
      );
    }
  }, [location]);

  //Intake User Sidebar
  if (
    location.pathname !== "/login" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/reset" &&
    location.pathname !== "/" &&
    role === "INTAKE"
  ) {
    return (
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <SidebarContext.Provider value={{ toggled }}>
          <SidebarBrand />
        </SidebarContext.Provider>
        {/* SidebarItems */}
        <hr className="sidebar-divider " />

        <SidebarItem
          title="Straal Verbindingen"
          icon="list"
          url="/PageStraalVerbindingen"
        />

        <SidebarItem
          title="Regulatory Tracker"
          icon="list"
          url="/FormPageRegulatoryTracker"
        />

        <hr className="sidebar-divider my-0" />
        <SidebarContext.Provider value={{ toggled, toggleSidebar }}>
          <RoundedCircleSideBarToggle />
        </SidebarContext.Provider>
      </ul>
    );
  }

  //Admin User Sidebar
  if (
    location.pathname !== "/login" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/reset" &&
    location.pathname !== "/" &&
    role !== null
  ) {
    return (
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <SidebarContext.Provider value={{ toggled }}>
          <SidebarBrand />
        </SidebarContext.Provider>
        {/* SidebarItems */}
        <hr className="sidebar-divider " />
        <div className="sidebar-heading">Dashboard</div>
        {role !== "OPERATOR" && (
          <SidebarItem title="Overall" icon="home" url="/dashboard-overall" />
        )}

        <SidebarItem
          title="Frequency"
          icon="wave-square"
          url="/dashboard-frequency"
        />

        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Interface</div>
        <SidebarItem title="Company" icon="briefcase" url="/companies" />
        <SidebarItem
          title="Transmitter Site"
          icon="wrench"
          url="/transmitter"
        />
        <SidebarItem title="Equipment" icon="signal" url="/equipments" />
        <SidebarItem
          title="Straal Verbindingen"
          icon="list"
          url="/PageStraalVerbindingen"
        />
        <SidebarItem title="Inspections" icon="wrench" url="/inspections" />
        {/* <SidebarItem
          title="Straal Verbinding Form"
          icon="list"
          url="/formPageStraalVerbinding"
        /> */}
        {role !== "OPERATOR" && (
          <SitebarAccordionItem
            title="Financial"
            icon="dollar-sign"
            items={[
              { title: "Financial Analytics", url: "/PageAnalyticsFinancial" },
              { title: "Reports Overview", url: "/PageCompanyReports" },
              { title: "Company Report", url: "/company-report" },
              // { title: "Monthly Report", url: "/monthly-report" },
            ]}
          />
        )}

        {role !== "OPERATOR" && (
          <SitebarAccordionItem
            title="Settings"
            subTitle="Admin"
            icon="cog"
            items={[
              {
                title: "Trans. Facility Attributes",
                url: "/transmisson-facility",
              },
              { title: "Frequency Attributes", url: "/frequencies" },
              { title: "Antenna Attributes", url: "/transmission-equipment" },
              { title: "Country Attributes", url: "/country-overview" },
              { title: "Users", url: "/users-overview" },
              { title: "Line Fees", url: "/line-fees-overview" },
            ]}
          />
        )}
        <hr className="sidebar-divider my-0" />
        <SidebarContext.Provider value={{ toggled, toggleSidebar }}>
          <RoundedCircleSideBarToggle />
        </SidebarContext.Provider>
      </ul>
    );
  }
  return null;
};

export default withRouter(Sidebar);
