import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
const modalRoot = document.getElementById("modal-root");

const Modal = ({
  id,
  open,
  setOpen,
  title,
  onSave,
  children,
  size = "large",
}) => {
  const closeModal = useCallback(() => {
    const modal = document.querySelector(".modal.show");
    if (modal) {
      const backdrop = document.querySelector(".modal-backdrop");
      modal.classList.remove("show");
      backdrop.classList.remove("show");

      window.setTimeout(() => {
        modal.style.display = "none";
        backdrop.remove();
        //document.querySelector('.nav-md').classList.remove('modal-open')
      }, 300);
      setOpen(false);
    }
  }, [setOpen]);
  const openModal = useCallback(() => {
    const modal = document.querySelector("#" + id);
    //const container = document.querySelector('.nav-md');
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop", "fade");
    document.body.appendChild(backdrop);
    modal.style.display = "block";
    backdrop.classList.add("show");

    modal.removeAttribute("aria-hidden");
    modal.setAttribute("aria-modal", true);
    void modal.offsetHeight;
    window.setTimeout(() => {
      //container.classList.add('modal-open')
      modal.classList.add("show");
    }, 300);
    setOpen(true);
  }, [id, setOpen]);

  useEffect(() => {
    if (open) {
      openModal();
    } else {
      closeModal();
    }
  }, [closeModal, open, openModal]);

  return ReactDOM.createPortal(
    <div
      id={id}
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      style={{ display: "none" }}
      aria-modal="true"
    >
      <div className={`modal-dialog ${size === "" ? "modal-lg" : "modal-xl"}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              {title}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
            {onSave ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={onSave}
              >
                Save changes
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;
