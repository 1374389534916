import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import ErrorMessage from "../FormStraalVerbindingPage/ErrorMessage";
import * as Yup from "yup";
import FileUploader from "../FileUploader";
import {
  addContractPdfMutation,
  getContractPdfsQuery,
  getContractPdfsByContractQuery,
} from "../../queries/queries";

import success from "../../assets/success.png";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  attachment: Yup.string().required("Required"),
});

const initialValues = { name: "", description: "", attachment: "" };

export default function FormUploadPDF({ contractId }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageStateRouting, setPageStateRouting] = useState("filling");
  const [addItem] = useMutation(addContractPdfMutation, {
    refetchQueries: [
      {
        query: getContractPdfsByContractQuery,
        variables: { contractId: parseInt(contractId) },
      },
    ],
  });

  function processValues(values) {
    let processedValues = {
      contractId: parseInt(contractId),
      ...values,
    };
    return processedValues;
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    let processedValues = processValues(values);
    let testing = false;

    if (testing) {
      alert(JSON.stringify(processedValues, null, 2));
      console.log(JSON.stringify(processedValues, null, 2));
      setLoading(false);
      return;
    }
    try {
      await saveRequest(processedValues);
      setPageStateRouting("success");
      resetForm();
      setError(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveRequest = async (values) => {
    let result = null;

    if (false) {
      console.log("update item");
    } else {
      result = await addItem({ variables: { ...values } });
      console.log({ result });
    }
    if (true) {
      return result;
    } else {
      throw { message: `Oops algu a bai robes: ${result.statusText}` };
    }
  };

  return (
    <>
      {pageStateRouting === "filling" && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
                    {/* Row */}
                    <div className="grid grid-cols-12 gap-6 ">
                      {/* Col */}
                      <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
                        <label className="block uppercase tracking-wide text-xs font-bold my-2">
                          Name
                        </label>
                        <Field
                          className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          name="name"
                        />
                        <ErrorMessage name="name" component="div" />
                      </div>
                    </div>
                    {/* Row */}
                    <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                      {/* Col */}
                      <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
                        <label className="block uppercase tracking-wide text-xs font-bold my-2">
                          Description
                        </label>
                        <Field
                          className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          name="description"
                        />
                        <ErrorMessage name="description" component="div" />
                      </div>
                    </div>
                  </div>
                  {/* Form Col*/}
                  <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
                    <FileUploader
                      id="file"
                      name="attachment"
                      setFieldValue={setFieldValue}
                    />
                    <ErrorMessage name="attachment" component="div" />
                  </div>
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="flex justify-end p-4">
                  <button
                    disabled={loading}
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {loading ? "Uploading" : "Upload"}
                    <svg
                      className="ml-2 -mr-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/* End Row */}
              </Form>
            );
          }}
        </Formik>
      )}
      {pageStateRouting === "success" && (
        <div>
          <div className="flex flex-col justify-center items-center">
            <h3 className="font-medium leading-tight text-3xl mt-0 mb-2 ">
              PDF uploaded successfully
            </h3>

            <img src={success} className="h-90 w-36 h-36" />
          </div>
          <div className="flex justify-end p-2 pt-4">
            {" "}
            <button
              disabled={loading}
              onClick={() => setPageStateRouting("filling")}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Go back
            </button>
          </div>
        </div>
      )}
    </>
  );
}
