"use client";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { Spinner, progressWidths } from "./shared";

function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

export const FileUploader = ({ setFieldValue, name }) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const onUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    setIsLoading(true);
    if (file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFieldValue(name, event.target.result);
      };
      setIsLoading(false);
      return reader.readAsDataURL(file);
    } else {
      imageCompression(file, options).then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          setFieldValue(name, event.target.result);
        };
        setIsLoading(false);
        return reader.readAsDataURL(compressedFile);
      });
    }
  };
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length) {
      setFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      );
      getBase64(acceptedFiles[0], (result) => {
        setFieldValue(name, result);
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
  });
  return (
    <div
      {...getRootProps()}
      className={`mt-2 flex flex-col items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 text-center ${
        isDragActive ? "bg-blue-600/10" : ""
      }`}
    >
      {file ? (
        file.type === "application/pdf" ? (
          <iframe src={file.preview} height="100%" width="100%"></iframe>
        ) : (
          <img
            src={file.preview}
            alt="preview"
            style={{ objectFit: "contain", height: "130px" }}
          />
        )
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className={"mx-auto block h-12 w-12 align-middle text-gray-400"}
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M5.5 17a4.5 4.5 0 0 1-1.44-8.765a4.5 4.5 0 0 1 8.302-3.046a3.5 3.5 0 0 1 4.504 4.272A4 4 0 0 1 15 17H5.5Zm3.75-2.75a.75.75 0 0 0 1.5 0V9.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0l-3.25 3.5a.75.75 0 1 0 1.1 1.02l1.95-2.1v4.59Z"
              clipRule="evenodd"
            ></path>
          </svg>
          <label
            htmlFor="file-upload"
            className={
              "relative mt-4 flex w-64 cursor-pointer items-center justify-center text-sm font-semibold leading-6 text-gray-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
            }
          >
            {!isLoading ? `Choose files or drag and drop` : `Loading...`}
            <input className="sr-only" {...getInputProps()} />
          </label>
        </>
      )}
      {file && (
        <button
          className={`relative mt-4 p-2 flex w-full items-center justify-center overflow-hidden rounded-md text-white after:transition-[width] after:duration-500 ${
            isLoading
              ? `bg-blue-400 after:absolute after:left-0 after:h-full after:bg-blue-600 ${progressWidths[progress]}`
              : "bg-blue-600"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setFile(null);
          }}
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : `Remove ${file.name}`}
        </button>
      )}
    </div>
  );
};
