import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import FormField from "../FormField";
import FormCustomSelect from "../FormCustomSelect2";
import success from "../../assets/success.png";

import { NEXT_URL, REACT_APP_DEV_MODE } from "../../config/index";
import { useMutation, useQueryClient } from "react-query";

const validationSchema = Yup.object().shape({
  company: Yup.string().required("Required"),
  technology: Yup.string().required("Required"),
  service: Yup.string().required("Required"),
  frequency: Yup.string().required("Required"),
  // code: Yup.string().required("Required"),
  // description: Yup.string().required("Required"),
  // serialNumber: Yup.string().required("Required"),
  heightAboveGround: Yup.number().required("Required"),
  brand: Yup.string().required("Required"),
  model: Yup.string().required("Required"),
  antennaType: Yup.string().required("Required"),
  station: Yup.string().required("Required"),
  // maxVSWR: Yup.number().required("Required"),
  // minVSWR: Yup.number().required("Required"),
  cellName: Yup.string().required("Required"),
  cellId: Yup.number().required("Required"),
  // cellStatus: Yup.string().required("Required"),
  // band: Yup.string().required("Required"),
  DLUARFCN: Yup.string().required("Required"),
  ULUARFCN: Yup.string().required("Required"),
  AZIMUTHTRUENORTH: Yup.string().required("Required"),
  AZIMUTHMAGNETIC: Yup.string().required("Required"),
  MDOWNTILT: Yup.string().required("Required"),
  EDOWNTILT: Yup.string().required("Required"),
  TILT: Yup.string().required("Required"),
});

const initialValues = {
  //   id: "",
  company: "",
  // frequency: { band: { service: { technology: {} } } },
  technology: "",
  service: "",
  frequency: "",
  code: "",
  description: "",
  serialNumber: "",
  heightAboveGround: "",
  brand: "",
  model: "",
  antennaType: "",
  station: "",
  maxVSWR: "",
  minVSWR: "",
  cellName: "",
  cellId: "",
  cellStatus: "",
  band: "",
  DLUARFCN: "",
  ULUARFCN: "",
  AZIMUTHTRUENORTH: "",
  AZIMUTHMAGNETIC: "",
  MDOWNTILT: "",
  EDOWNTILT: "",
  TILT: "",
};

export default function FormEquipment({
  dataCompanies,
  dataTechnologies,
  dataServices,
  dataFrequencies,
  dataBrands,
  dataModels,
  dataAntennaTypes,
  dataTransmitterSites,
  dataContracts,
  dataUser,
  formValues,
  isAddMode = true,
  id,
}) {
  //StateVariables
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageStateRouting, setPageStateRouting] = useState("filling");

  //Constants
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const devMode = process.env.REACT_APP_DEV_MODE === "true";
  const formValuesFormatted = isAddMode
    ? null
    : {
        id: formValues?.id,
        company: formValues?.companyId || "",
        technology: formValues?.technologyId || "",
        service: formValues?.serviceId || "",
        frequency: formValues?.frequencyId || "",
        code: formValues?.code ? Number(formValues?.code) : "",
        description: formValues?.description || "",
        serialNumber: formValues?.serialNumber || "",
        heightAboveGround: formValues?.heightAboveGround || "",
        brand: formValues?.brandId || "",
        model: formValues?.modelId || "",
        antennaType: formValues?.antennaTypeId || "",
        station: formValues?.stationId || "",
        maxVSWR: formValues?.maxVSWR || "",
        minVSWR: formValues?.minVSWR || "",
        cellName: formValues?.cellName || "",
        cellId: formValues?.cellId || "",
        cellStatus: formValues?.cellStatus || "",
        band: formValues?.band || "",
        DLUARFCN: formValues?.DLUARFCN || "",
        ULUARFCN: formValues?.ULUARFCN || "",
        AZIMUTHTRUENORTH: formValues?.AZIMUTHTRUENORTH || "",
        AZIMUTHMAGNETIC: formValues?.AZIMUTHMAGNETIC || "",
        MDOWNTILT: formValues?.MDOWNTILT || "",
        EDOWNTILT: formValues?.EDOWNTILT || "",
        TILT: formValues?.TILT || "",
      };

  //StateVariables
  const [companyId, setCompanyId] = useState(formValues?.companyId || -1);
  const [technologyId, setTechnologyId] = useState(
    formValues?.technologyId || -1
  );
  const [serviceId, setServiceId] = useState(formValues?.serviceId || -1);
  const [modelId, setModelId] = useState(formValues?.modelId || -1);

  const getFilteredTechnologies = (dataTechnologies) => {
    return dataTechnologies;
  };

  const getFilteredServices = (dataServices) => {
    const filteredServices = dataServices.data.filter(
      (service) => service.technology.id === parseInt(technologyId)
    );
    return filteredServices;
  };

  const getFilteredFrequencies = (dataFrequencies) => {
    const filteredFrequencies = dataFrequencies.data.filter(
      (frequency) => frequency.band.service.id === parseInt(serviceId)
    );
    return filteredFrequencies;
  };

  const getFilteredFrequencyCodes = (dataContracts) => {
    const filteredFrequencies = dataContracts.data.filter(
      (contract) => contract.companyId === parseInt(companyId)
    );
    return filteredFrequencies;
  };

  const getFilteredBrands = (dataTechnologies) => {
    return dataTechnologies;
  };

  const getFilteredModels = (dataTechnologies) => {
    return dataTechnologies;
  };

  const getFilteredAntennaTypes = (dataAntennaTypes) => {
    const filteredAntennaTypes = dataAntennaTypes.data.filter(
      (type) => type.model.id === parseInt(modelId)
    );
    return filteredAntennaTypes;
  };
  const getFilteredTransmitterSites = (dataTechnologies) => {
    return dataTechnologies;
  };

  const addItem = async function (data) {
    let result = null;
    result = await fetch(`${NEXT_URL}/api/antennas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return result;
  };

  const updateItem = async function (data, id) {
    let result = null;
    result = await fetch(`${NEXT_URL}/api/antennas/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return result;
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let testing = false;

    values.updatedBy = dataUser.email;

    setLoading(true);
    if (testing) {
      alert(JSON.stringify(values, null, 2));
      setLoading(false);
      return;
    }
    try {
      await saveRequest(values);
      setPageStateRouting("success");
      queryClient.invalidateQueries("PageEquipmentsAntennas");
      resetForm();
      setError(false);
    } catch (error) {
      console.log({ error });
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const saveRequest = async (values) => {
    let result = null;

    if (!isAddMode) {
      result = await updateItem({ ...values }, id);
      console.log({ result });
    } else {
      result = await addItem({ ...values });
      console.log({ result });
    }
    if (result.ok) {
      return result;
    } else {
      const error = await result.json();
      console.log({ error });
      throw { message: `Oops algu a bai robes: ${result.statusText}` };
    }
  };

  return (
    <>
      {pageStateRouting === "filling" && (
        <Formik
          initialValues={formValuesFormatted || initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                {error && `${error}`}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`company`}
                    title="Company"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={dataCompanies.data.map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    onChangeFunction={async (value) => {
                      setCompanyId(value);
                      console.log({ value });
                    }}
                  />
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`technology`}
                    title="Access Type (Technology)"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredTechnologies(dataTechnologies).data.map(
                      (item) => {
                        return { value: item.id, label: item.name };
                      }
                    )}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    onChangeFunction={async (value) => {
                      console.log({ value });
                      setTechnologyId(value);
                    }}
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`service`}
                    title="Service"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredServices(dataServices).map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    onChangeFunction={async (value) => {
                      setServiceId(value);
                      console.log({ value });
                    }}
                  />
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`frequency`}
                    title="Frequency Band"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredFrequencies(dataFrequencies).map(
                      (item) => {
                        return { value: item.id, label: item.name };
                      }
                    )}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    // onChangeFunction={async (value) => {
                    //   console.log({ value });
                    // }}
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`code`}
                    title="Frequency Code"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredFrequencyCodes(dataContracts).map(
                      (item) => {
                        return { value: item.id, label: item.name };
                      }
                    )}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    // onChangeFunction={async (value) => {
                    //   console.log({ value });
                    // }}
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"description"}
                    title={"Description"}
                    span={6}
                    type="text"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"serialNumber"}
                    title={"Serial Number"}
                    span={6}
                    type="text"
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"heightAboveGround"}
                    title={"Height Above Ground"}
                    span={6}
                    type="number"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`brand`}
                    title="Brand"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredBrands(dataBrands).data.map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    // onChangeFunction={async (value) => {
                    //   console.log({ value });
                    // }}
                  />
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`model`}
                    title="Model"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredModels(dataModels).data.map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    onChangeFunction={async (value) => {
                      setModelId(value);
                      console.log({ value });
                    }}
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`antennaType`}
                    title="Antenna Type"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredAntennaTypes(dataAntennaTypes).map(
                      (item) => {
                        return { value: item.id, label: item.name };
                      }
                    )}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    // onChangeFunction={async (value) => {
                    //   console.log({ value });
                    // }}
                  />
                  {/* Form Col*/}
                  <Field
                    style={{ backgroundColor: "red" }}
                    name={`station`}
                    title="Transmitter Site (Station)"
                    // options={[
                    //   { value: "man", label: "maskulino" },
                    //   { value: "vrouw", label: "femenino" },
                    // ]}
                    options={getFilteredTransmitterSites(
                      dataTransmitterSites
                    ).data.map((item) => {
                      return {
                        value: item.id,
                        label: item.description,
                      };
                    })}
                    component={FormCustomSelect}
                    // placeholder="Selektá sekso..."
                    isMulti={false}
                    // onChangeFunction={async (value) => {
                    //   console.log({ value });
                    // }}
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"maxVSWR"}
                    title={"Max VSWR"}
                    span={6}
                    type="number"
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"minVSWR"}
                    title={"Min VSWR"}
                    span={6}
                    type="number"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"cellName"}
                    title={"Cell Name"}
                    span={6}
                    type="text"
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"cellId"}
                    title={"Cell Id"}
                    span={6}
                    type="number"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"cellStatus"}
                    title={"Cell Status"}
                    span={6}
                    type="text"
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"band"}
                    title={"Band"}
                    span={6}
                    type="text"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"DLUARFCN"}
                    title={"DL UARFCN"}
                    span={6}
                    type="text"
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"ULUARFCN"}
                    title={"UL UARFCN"}
                    span={6}
                    type="text"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"AZIMUTHTRUENORTH"}
                    title={"AZIMUTH TRUENORTH"}
                    span={6}
                    type="text"
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"AZIMUTHMAGNETIC"}
                    title={"AZIMUTH MAGNETIC"}
                    span={6}
                    type="text"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"MDOWNTILT"}
                    title={"M-DOWNTILT"}
                    span={6}
                    type="text"
                  />
                  {/* Form Col*/}
                  <FormField
                    name={"EDOWNTILT"}
                    title={"E-DOWNTILT"}
                    span={6}
                    type="text"
                  />
                </div>
                {/* End Row */}
                {/* Form Row */}
                <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
                  {/* Form Col*/}
                  <FormField
                    name={"TILT"}
                    title={"TILT"}
                    span={6}
                    type="text"
                  />
                </div>
                {/* End Row */}
                {/* Bottom Row */}
                <div className="flex justify-end p-4">
                  {devMode && (
                    <button
                      onClick={() => {
                        console.log("autofillActive");
                        const autoFillValues = {
                          company: 3,
                          technology: 1,
                          service: 1002,
                          frequency: 2003,
                          code: 2003,
                          description: "Test",
                          serialNumber: "Test",
                          heightAboveGround: 2,
                          brand: 1,
                          model: 1,
                          antennaType: 1,
                          station: 4002,
                          maxVSWR: 123.123,
                          minVSWR: 13.213,
                          cellName: "Test",
                          cellId: 23,
                          cellStatus: "Test",
                          band: "Test",
                          DLUARFCN: "Test",
                          ULUARFCN: "Test",
                          AZIMUTHTRUENORTH: "Test",
                          AZIMUTHMAGNETIC: "Test",
                          MDOWNTILT: "Test",
                          EDOWNTILT: "Test",
                          TILT: "Test",
                          updatedBy: "admin",
                        };
                        const fields = [
                          "company",
                          "technology",
                          "service",
                          "frequency",
                          "code",
                          "description",
                          "serialNumber",
                          "heightAboveGround",
                          "brand",
                          "model",
                          "antennaType",
                          "station",
                          "maxVSWR",
                          "minVSWR",
                          "cellName",
                          "cellId",
                          "cellStatus",
                          "band",
                          "DLUARFCN",
                          "ULUARFCN",
                          "AZIMUTHTRUENORTH",
                          "AZIMUTHMAGNETIC",
                          "MDOWNTILT",
                          "EDOWNTILT",
                          "TILT",
                        ];
                        setCompanyId(autoFillValues.company);
                        setTechnologyId(autoFillValues.technology);
                        setServiceId(autoFillValues.service);
                        setModelId(autoFillValues.model);
                        fields.forEach((field) =>
                          setFieldValue(field, autoFillValues[field], false)
                        );
                      }}
                      disabled={loading}
                      type="button"
                      className="text-white mr-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Developer: Autofill
                    </button>
                  )}
                  <button
                    disabled={loading}
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {isAddMode && <>{loading ? "Submitting" : "Save"}</>}
                    {!isAddMode && (
                      <>{loading ? "Submitting" : "Confirm Changes"}</>
                    )}
                    <svg
                      className="ml-2 -mr-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/* End Bottom Row */}
              </Form>
            );
          }}
        </Formik>
      )}
      {pageStateRouting === "success" && (
        <div>
          <div className="flex flex-col justify-center items-center">
            <h3 className="font-medium leading-tight text-3xl mt-0 mb-2 text-gray-500">
              {`Equipment ${isAddMode ? "Added" : "Updated"} Successfully`}
            </h3>

            <div className="w-48 h-48 relative">
              {/* <Image
                src={success}
                layout="fill"
                objectFit="cover"
                objectPosition={"50% 57%"}
                alt=""
              /> */}
              <img src={success} />;
            </div>
          </div>
          <div className="flex justify-end p-2 mt-10">
            <button
              disabled={loading}
              onClick={() => navigate("/equipments")}
              type="submit"
              className="text-white mr-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              See All
            </button>
            <button
              disabled={loading}
              onClick={() => setPageStateRouting("filling")}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Add More
            </button>
          </div>
        </div>
      )}
    </>
  );
}
