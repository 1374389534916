import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  addLineFeeMutation,
  updateLineFeeMutation,
  deleteLineFeeMutation,
  getLineFeesQuery,
} from "../queries/queries";

import Pagination from "./../components/Layout/Pagination";
import LineFeeModal from "../components/PageSettings/PageSettingsLineFees/ModalLineFees";

const initialValues = {
  id: 0,
  technology: { id: 0, name: "" },
  name: "",
  price: 0,
  fbFactor: 0,
};

const LineFee = () => {
  const { loading, data } = useQuery(getLineFeesQuery);
  const [addLineFee] = useMutation(addLineFeeMutation);
  const [updateLineFee] = useMutation(updateLineFeeMutation);
  const [deleteLineFee] = useMutation(deleteLineFeeMutation);
  const [technologies, setTechnologies] = useState([]);
  const [lineFees, setLineFees] = useState([]);
  const [lineFee, setLineFee] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = lineFees.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = lineFees.length;

  useEffect(() => {
    if (!loading && data) {
      setLineFees(data.lineFees);
      setTechnologies(data.technologies);
    }
  }, [loading, data]);

  const deleteItem = (id) => {
    deleteLineFee({
      variables: {
        id,
      },
      refetchQueries: [{ query: getLineFeesQuery }],
    });
  };

  const saveItem = () => {
    lineFee.id
      ? updateLineFee({
          variables: {
            id: lineFee.id,
            name: lineFee.name,
            price: parseFloat(lineFee.price),
            fbFactor: parseFloat(lineFee.fbFactor),
            technologyId: parseInt(lineFee.technology.id),
          },
          refetchQueries: [{ query: getLineFeesQuery }],
        })
      : addLineFee({
          variables: {
            id: lineFee.id,
            name: lineFee.name,
            price: parseFloat(lineFee.price),
            fbFactor: parseFloat(lineFee.fbFactor),
            technologyId: parseInt(lineFee.technology.id),
          },
          refetchQueries: [{ query: getLineFeesQuery }],
        });
    closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "technology") {
      setLineFee({ ...lineFee, technology: { id: parseInt(value) } });
    } else {
      setLineFee({ ...lineFee, [name]: value });
    }
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const openModal = (e, lineFee) => {
    setLineFee(lineFee);
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };

  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownLineFeeMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownLineFeeMenuLink"]')
      .classList.remove("show");
  };

  return (
    <div className="card mb-4">
      <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold text-primary">Line Fees</h6>
        <div className="dropdown no-arrow">
          <button
            className="button-link"
            id="dropdownLineFeeMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={toggleDropdownMenu}
            onBlur={closeDropdown}
          >
            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
            aria-labelledby="dropdownLineFeeMenuLink"
            x-placement="bottom-end"
            style={{
              position: "absolute",
              willChange: "transform",
              top: 0,
              left: 0,
              transform: "translate3d(-156px, 19px, 0px)",
            }}
          >
            <div className="dropdown-header">Actions:</div>
            <button
              className="dropdown-item"
              onMouseDown={(e) => openModal(e, initialValues)}
              data-target="#lineFeeModal"
            >
              New
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-striped table-condensed table-hover table-sm">
              <thead>
                <tr className="bg-primary text-white">
                  <td>name</td>
                  <td>price</td>
                  <td>fbFactor</td>
                  <td>Technology</td>
                  <td>
                    <span style={{ float: "right" }}>actions</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  currentItems.map((lineFee, index) => {
                    return (
                      <tr key={index}>
                        <td>{lineFee.name}</td>
                        <td>{lineFee.price}</td>
                        <td>{lineFee.fbFactor}</td>
                        <td>{lineFee.technology.name}</td>
                        <td>
                          <div style={{ float: "right" }}>
                            <i
                              className="fas fa-fw fa-edit"
                              data-target="#lineFeeModal"
                              onClick={(e) => openModal(e, lineFee)}
                            ></i>
                            <i
                              className="fas fa-fw fa-trash"
                              onClick={() =>
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                ) && deleteItem(lineFee.id)
                              }
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="dataTable_paginate"
            >
              {totalItems > itemsPerPage ? (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <LineFeeModal
        lineFee={lineFee}
        technologies={technologies}
        handleChange={handleChange}
        closeModal={closeModal}
        saveItem={saveItem}
      />
    </div>
  );
};

export default LineFee;
