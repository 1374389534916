import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { NEXT_URL } from "../config/index";
import Select from "react-select";
import { Link } from "react-router-dom";

import TableAntenna from "../components/PageEquipmentAntennas/TableAntenna";
import LoadingGears from "../components/LoadingGears";

export default function PageEquipments2({ user }) {
  //StateVariables
  const [companyId, setCompanyId] = useState(-1);

  const fetchAntennas = async () => {
    return axios.get(`${NEXT_URL}/api/antennas`);
  };

  const fetchCompanies = async () => {
    return axios.get(`${NEXT_URL}/api/companies`);
  };

  const {
    data: dataAntennas,
    status: statusAntennas,
    error: errorAntennas,
    isPreviousData: isPreviousDataAntennas,
  } = useQuery([`PageEquipmentsAntennas`], fetchAntennas, {
    keepPreviousData: true,
    refetchOnMount: true,
  });

  const {
    data: dataCompanies,
    status: statusCompanies,
    error: errorCompanies,
    isPreviousData: isPreviousDataCompanies,
  } = useQuery([`PageEquipmentsCompanies`], fetchCompanies, {
    keepPreviousData: true,
    refetchOnMount: true,
  });

  if (statusAntennas === "loading" || statusCompanies === "loading") {
    return <LoadingGears />;
  }

  if (statusAntennas === "error" || statusCompanies === "error") {
    return <div>Error: Could not fetch</div>;
  }

  //QueryResultsCheck
  console.log({ PageEquipments2QueryResultsCheck: { dataAntennas } });

  return (
    <>
      <div className="card mb-4 mx-3">
        {/* Card Row */}
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Equipments</h6>
          <div className="d-flex justify-content-center align-items-center">
            {/* Actions Col */}
            <div className="w-96 z-10">
              {user.role.name !== "OPERATOR" && (
                <Select
                  className="form-select rounded p-1 px-2 text-secondary m-2 "
                  //   value={-1}
                  onChange={(option) => {
                    setCompanyId(option.value);
                  }}
                  placeholder={"All Equipment"}
                  options={(() => {
                    const companies = dataCompanies.data.map((company) => {
                      return { label: company.name, value: company.id };
                    });
                    // return [{ label: "All Equipment", value: 0 }];
                    return [
                      { label: "All Equipment", value: -1 },
                      ...companies,
                    ];
                  })()}
                />
              )}
            </div>
            {/* End Col */}
            {/* <button
                className="btn btn-outline-primary my-2"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#antennaModal"
                style={{ fontSize: ".9rem" }}
              >
                <i
                  className="fas fa-plus"
                  style={{ marginRight: "10px", fontSize: ".8rem" }}
                ></i>
                Add New Equipment Old
              </button> */}
            {user.role.name !== "OPERATOR" && (
              <div>
                <Link
                  to={{
                    pathname: "/FormPageEquipment",
                  }}
                  className="btn btn-outline-primary my-2"
                >
                  <i
                    className="fas fa-plus"
                    style={{ marginRight: "10px", fontSize: ".8rem" }}
                  ></i>
                  Add New Equipment
                </Link>
              </div>
            )}
          </div>
        </div>
        {/* End Card Row */}
        {/* Card Row */}
        <div className="card-body">
          <div className="">
            {/* Antennas Table */}
            <TableAntenna
              dataAntennas={dataAntennas}
              dataCompanies={dataCompanies}
              companyId={companyId}
            />
          </div>
        </div>
        {/* End Card Row */}
      </div>
    </>
  );
}
