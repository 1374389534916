import React from "react";
import { useLocation } from 'react-router-dom';

const Footer = () => {
    let location = useLocation()
    if (location.pathname !== '/login' &&
        location.pathname !== '/register' &&
        location.pathname !== '/forgot-password' &&
        location.pathname !== '/reset' &&
        location.pathname !== '/') {
        return (
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright © 2022 ictlabw.cw | CW</span>
                    </div>

                </div>
            </footer>
        )
    }
    else return null;
}

export default Footer;