import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Map from "../components/map/Map";
import Pix4dMap from "../components/map/Pix4dMap";
import Dropdown from "./../components/Layout/Dropdown";
import DashboardCard from "./../components/PageDashboard/DashboardCard";
import LicenseCard from "./../components/PageDashboard/LicenseCard";
import FrequencyBands from "../components/PageDashboard/FrequencyBands";
import TelecomProvidersCard from "../components/PageDashboard/TelecomProvidersCard";
import BroadcastersCard from "../components/PageDashboard/BroadcastersCard";
import MobileServicesSelect from "../components/MobileServicesSelect";
import CompaniesSelect from "../components/CompaniesSelect";

const getStationsQuery = gql`
  query {
    stations {
      id
      description
      latitude
      longitude
      height
      remarks
      company {
        id
        name
      }
      site {
        id
        description
      }
      stationType {
        id
        name
        type
      }
      towerType {
        id
        name
      }
      sectors {
        id
        description
      }
      sketchfabModels {
        name
        modelUID
      }
      siteStatus
      siteIDUMTS
      siteIDLTE
      antennas {
        id
        code
        pix4dLink
        description
        company {
          name
        }
        frequency {
          name
          band {
            name
            service {
              id
              name
              technology {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const DashboardPage = ({ user }) => {
  //PageStateVariables
  const navigate = useNavigate();

  if (user.role.name === "OPERATOR") {
    navigate("/dashboard-frequency");
  }

  //MapStateVariables
  const [selectedCompany, setSelectedCompany] = useState(-1);
  const [showStations, setShowStations] = useState(true);
  const [showMicrowaveLinks, setShowMicrowaveLinks] = useState(false);
  const [selectedService, setSelectedService] = useState(1);

  //QueryStateVariables
  const { data, loading } = useQuery(getStationsQuery);
  // const { data: bandsQueryData, loading: bandsQueryLoading } =
  //   useQuery(getBandsQuery);

  return (
    <div className="container-fluid">
      {/* Title */}
      <div className="row">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>
      </div>

      {/* DashboardCards */}
      <div className="row">
        <DashboardCard
          title="Earnings (Monthly)"
          data="$40,000"
          border="border-left-primary"
          icon="calendar"
        />
        <LicenseCard />
        <TelecomProvidersCard />
        <BroadcastersCard />
      </div>

      {/* Map && Frequency component */}
      <div className="row">
        <div className="col-lg-12 mb-12">
          <div className="card shadow mb-4">
            <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">Locations</h6>
              {/* Map Options Card */}
            </div>
            <div className="card-body" style={{ padding: "20px" }}>
              <div className="row mb-4">
                <div className="col-sm-12 col-md-4">
                  {/* Display Filter Options Dropdown */}
                  <Dropdown
                    showStations={showStations}
                    showMicrowaveLinks={showMicrowaveLinks}
                    setShowStations={setShowStations}
                    setShowMicrowaveLinks={setShowMicrowaveLinks}
                  />
                </div>
                {/* Actions Col */}
                <div className="col-sm-12 col-md-4">
                  {user.role.name !== "OPERATOR" && (
                    <MobileServicesSelect
                      setSelectedService={setSelectedService}
                    />
                  )}
                </div>
                {/* End Col */}
                {/* Actions Col */}
                <div className="col-sm-12 col-md-4">
                  {user.role.name !== "OPERATOR" && (
                    <CompaniesSelect setSelectedCompany={setSelectedCompany} />
                  )}
                </div>
                {/* End Col */}
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <Map
                    stations={data?.stations || []}
                    selectedService={selectedService}
                    selectedCompany={selectedCompany}
                    showStations={showStations}
                    showMicrowaveLinks={showMicrowaveLinks}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <h6 className="my-2 mb-5 font-weight-bold text-secondary">
                    Frequency Bands
                  </h6>
                  <div className="overflow-auto" style={{ height: "25rem" }}>
                    <FrequencyBands selectedService={selectedService} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
