import React from "react";

export default function ButtonAutofill({ setFieldValue }) {
  let devMode = process.env.REACT_APP_DEV_MODE === "true";

  return (
    <>
      {devMode && (
        <button
          onClick={() => {
            console.log("autofillActive");
            const autoFillValues = {
              // FromStep1
              raTelecommunicationsCoding: "Test",
              raTelecommunicationsPoints: "2",
              raAutonomyCoding: "Test",
              raAutonomyPoints: "2",
              raAccountabilityCoding: "Test",
              raAccountabilityPoints: "2",
              raDiversiviedFundingCoding: "Test",
              raDiversiviedFundingPoints: "2",
              raPublicConsultationCoding: "Test",
              raPublicConsultationPoints: "2",
              raEnforcementPowerCoding: "Test",
              raEnforcementPowerPoints: "2",
              raRegulatorCoding: "Test",
              raRegulatorPoints: "2",
              raDisputeResolutionCoding: "Test",
              raDisputeResolutionPoints: "2",
              raAppealsDicisionsCoding: "Test",
              raAppealsDicisionsPoints: "2",
              raCompetitionAutorithyCoding: "Test",
              raCompetitionAutorithyPoints: "2",
              raBenchmark: 20,

              //FormStep2
              rmQualityServiceCoding: "Test",
              rmQualityServicePoints: "2",
              rmLicenseCoding: "Test",
              rmLicensePoints: "2",
              rmInterconnectionRatesCoding: "Test",
              rmInterconnectionRatesPoints: "2",
              rmRadioFrequencyCoding: "Test",
              rmRadioFrequencyPoints: "2",
              rmSpectrumMonitoringCoding: "Test",
              rmSpectrumMonitoringPoints: "2",
              rmUniversalServiceCoding: "Test",
              rmUniversalServicePoints: "2",
              rmBroadcastingCoding: "Test",
              rmBroadcastingPoints: "2",
              rmBroadcastingContentCoding: "Test",
              rmBroadcastingContentPoints: "2",
              rmInternetContentCoding: "Test",
              rmInternetContentPoints: "2",
              rmInformationTechnologyCoding: "Test",
              rmInformationTechnologyPoints: "2",
              rmConsumerIssuesCoding: "Test",
              rmConsumerIssuesPoints: "2",
              rmBenchmark: 22,

              //Formstep3
              rrTypesOfLicencesCoding           : "Test",
              rrTypesOfLicencesPoints           : "2",
              rrLicenseExemptCoding             : "Test",
              rrLicenseExemptPoints             : "2",
              rrInterconnectionOfferCoding      : "Test",
              rrInterconnectionOfferPoints      : "2",
              rrInterconnectionPricesCoding     : "Test",
              rrInterconnectionPricesPoints     : "2",
              rrQualityServiceCoding            : "Test",
              rrQualityServicePoints            : "2",
              rrInfrastructureMobileCoding      : "Test",
              rrInfrastructureMobilePoints      : "2",
              rrInfrastructureMandateCoding     : "Test",
              rrInfrastructureMandatePoints     : "2",
              rrColocationMandateCoding         : "Test",
              rrColocationMandatePoints         : "2",
              rrUnbundledAccessCoding           : "Test",
              rrUnbundledAccessPoints           : "2",
              rrSecondaryTradingCoding          : "Test",
              rrSecondaryTradingPoints          : "2",
              rrBandMigrationCoding             : "Test",
              rrBandMigrationPoints             : "2",
              rrNumberPortabilityFixedLineCoding: "Test",
              rrNumberPortabilityFixedLinePoints: "2",
              rrNumberPortabilityMobileCoding   : "Test",
              rrNumberPortabilityMobilePoints   : "2",
              rrIndividualUserVoipCoding        : "Test",
              rrIndividualUserVoipPoints        : "2",
              rrNationalPlanCoding              : "Test",
              rrNationalPlanPoints              : "2",
              rrBenchmark                       : 30,

              //Formstep4
              cfLongDistanceFixedLinesCoding: "Test",
              cfLongDistanceFixedLinesPoints: "2",
              cfImtServicesCoding: "Test",
              cfImtServicesPoints: "2",
              cfCableModemCoding: "Test",
              cfCableModemPoints: "2",
              cfLeasedLinesCoding: "Test",
              cfLeasedLinesPoints: "2",
              cfInternationalGatewaysCoding: "Test",
              cfInternationalGatewaysPoints: "2",
              cfMainFixedLinesCoding: "Test",
              cfMainFixedLinesPoints: "2",
              cfLegalConceptSmpCoding: "Test",
              cfLegalConceptSmpPoints: "2",
              cfDeterminingSmpCoding: "Test",
              cfDeterminingSmpPoints: "2",
              cfForeignParticipationFacilitiesCoding: "Test",
              cfForeignParticipationFacilitiesPoints: "2",
              cfForeignParticipationSpectrumCoding: "Test",
              cfForeignParticipationSpectrumPoints: "2",
              cfForeignParticipationLocalServicesCoding: "Test",
              cfForeignParticipationLocalServicesPoints: "2",
              cfForeignParticipationInternationalServicesCoding: "Test",
              cfForeignParticipationInternationalServicesPoints: "2",
              cfForeignParticipationIspCoding: "Test",
              cfForeignParticipationIspPoints: "2",
              cfForeignParticipationValueAddedCoding: "Test",
              cfForeignParticipationValueAddedPoints: "2",
              cfBenchmark: 28,
            };

            const fields = [
              "raTelecommunicationsCoding",
              "raTelecommunicationsPoints",
              "raAutonomyCoding",
              "raAutonomyPoints",
              "raAccountabilityCoding",
              "raAccountabilityPoints",
              "raDiversiviedFundingCoding",
              "raDiversiviedFundingPoints",
              "raPublicConsultationCoding",
              "raPublicConsultationPoints",
              "raEnforcementPowerCoding",
              "raEnforcementPowerPoints",
              "raRegulatorCoding",
              "raRegulatorPoints",
              "raDisputeResolutionCoding",
              "raDisputeResolutionPoints",
              "raAppealsDicisionsCoding",
              "raAppealsDicisionsPoints",
              "raCompetitionAutorithyCoding",
              "raCompetitionAutorithyPoints",
              "raBenchmark",

              "rmQualityServiceCoding",
              "rmQualityServicePoints",
              "rmLicenseCoding",
              "rmLicensePoints",
              "rmInterconnectionRatesCoding",
              "rmInterconnectionRatesPoints",
              "rmRadioFrequencyCoding",
              "rmRadioFrequencyPoints",
              "rmSpectrumMonitoringCoding",
              "rmSpectrumMonitoringPoints",
              "rmUniversalServiceCoding",
              "rmUniversalServicePoints",
              "rmBroadcastingCoding",
              "rmBroadcastingPoints",
              "rmBroadcastingContentCoding",
              "rmBroadcastingContentPoints",
              "rmInternetContentCoding",
              "rmInternetContentPoints",
              "rmInformationTechnologyCoding",
              "rmInformationTechnologyPoints",
              "rmConsumerIssuesCoding",
              "rmConsumerIssuesPoints",
              "rmBenchmark",

              "rrTypesOfLicencesCoding",
              "rrTypesOfLicencesPoints",
              "rrLicenseExemptCoding",
              "rrLicenseExemptPoints",
              "rrInterconnectionOfferCoding",
              "rrInterconnectionOfferPoints",
              "rrInterconnectionPricesCoding",
              "rrInterconnectionPricesPoints",
              "rrQualityServiceCoding",
              "rrQualityServicePoints",
              "rrInfrastructureMobileCoding",
              "rrInfrastructureMobilePoints",
              "rrInfrastructureMandateCoding",
              "rrInfrastructureMandatePoints",
              "rrColocationMandateCoding",
              "rrColocationMandatePoints",
              "rrUnbundledAccessCoding",
              "rrUnbundledAccessPoints",
              "rrSecondaryTradingCoding",
              "rrSecondaryTradingPoints",
              "rrBandMigrationCoding",
              "rrBandMigrationPoints",
              "rrNumberPortabilityFixedLineCoding",
              "rrNumberPortabilityFixedLinePoints",
              "rrNumberPortabilityMobileCoding",
              "rrNumberPortabilityMobilePoints",
              "rrIndividualUserVoipCoding",
              "rrIndividualUserVoipPoints",
              "rrNationalPlanCoding",
              "rrNationalPlanPoints",
              "rrBenchmark",

              "cfLongDistanceFixedLinesCoding",
              "cfLongDistanceFixedLinesPoints",
              "cfImtServicesCoding",
              "cfImtServicesPoints",
              "cfCableModemCoding",
              "cfCableModemPoints",
              "cfLeasedLinesCoding",
              "cfLeasedLinesPoints",
              "cfInternationalGatewaysCoding",
              "cfInternationalGatewaysPoints",
              "cfMainFixedLinesCoding",
              "cfMainFixedLinesPoints",
              "cfLegalConceptSmpCoding",
              "cfLegalConceptSmpPoints",
              "cfDeterminingSmpCoding",
              "cfDeterminingSmpPoints",
              "cfForeignParticipationFacilitiesCoding",
              "cfForeignParticipationFacilitiesPoints",
              "cfForeignParticipationSpectrumCoding",
              "cfForeignParticipationSpectrumPoints",
              "cfForeignParticipationLocalServicesCoding",
              "cfForeignParticipationLocalServicesPoints",
              "cfForeignParticipationInternationalServicesCoding",
              "cfForeignParticipationInternationalServicesPoints",
              "cfForeignParticipationIspCoding",
              "cfForeignParticipationIspPoints",
              "cfForeignParticipationValueAddedCoding",
              "cfForeignParticipationValueAddedPoints",
              "cfBenchmark",
            ];

            fields.forEach((field) =>
              setFieldValue(field, autoFillValues[field], false)
            );
            // setState({...state, ...autoFillValues})
          }}
          //   disabled={loading}
          type="button"
          className="text-white mr-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Developer: Autofill
        </button>
      )}
    </>
  );
}
