import React, { useState, useEffect, useMemo } from "react";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import { REACT_APP_SERVER_URL } from "../../utils/config";
import {
  loadHandler,
  markerLoadHandler,
  markerClickHandler,
} from "./mapHandlers";

import AssetInfoWindow from "./AssetInfoWindow";

import LoadingGears from "../LoadingGears";

const Pix4dMap = () => {
  //MapStateVariables
  const [assets, setAssets] = useState([]);
  const [center, setCenter] = useState({ lat: 12.20189, lng: -68.262383 });
  const [zoom, setZoom] = useState(12);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAssets = async () => {
    try {
      setLoading(true);
      const assets = await fetch(
        `${REACT_APP_SERVER_URL.replace("/graphql", "")}/pix4d/assets`
      ).then((res) => res.json());
      setLoading(false);
      setAssets(assets);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      fetchAssets();
    }

    return () => {
      ignore = true;
    };
  }, []);
  //MapUtils
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
  });

  const getAssetsMarkers = (assets) => {
    return assets.map((asset) => (
      <Marker
        key={asset.id}
        position={{
          lat: parseFloat(asset.latitude),
          lng: parseFloat(asset.longitude),
        }}
        onLoad={(marker) => markerLoadHandler(marker, asset, setMarkerMap)}
        onClick={(event) =>
          markerClickHandler(
            event,
            asset,
            setSelectedPlace,
            infoOpen,
            setInfoOpen,
            zoom,
            setZoom,
            setCenter
          )
        }
        icon={{
          url:
            asset.type === 1
              ? "/images/icons/building_icon.png"
              : "/images/icons/antenas_icon.gif",
          scaledSize: { width: 50, height: 36 },
        }}
      />
    ));
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }
  return assets.length > 0 ? (
    <>
      <GoogleMap
        onLoad={(map) =>
          loadHandler({ map: map, assets: assets, center: center })
        }
        center={center}
        zoom={zoom}
        mapContainerStyle={{
          height: "70vh",
          width: "100%",
        }}
      >
        {assets.length ? getAssetsMarkers(assets) : null}

        {/*Info Window for Stations */}
        {infoOpen && selectedPlace && (
          <AssetInfoWindow
            selectedPlace={selectedPlace}
            anchor={markerMap[selectedPlace.id]}
            onCloseClick={() => setInfoOpen(false)}
          />
        )}
      </GoogleMap>
    </>
  ) : (
    <LoadingGears />
  );
};

export default Pix4dMap;
