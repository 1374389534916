import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

import FormCustomSelect from "../FormCustomSelect2";

export default function FormSectionAntenneGegevens({
  setFieldValue,
  dataCompanies,
  dataTechnologies,
  dataServices,
  dataFrequencies,
  dataContracts,
}) {
  //StateVariables
  const [companyId, setCompanyId] = useState(-1);
  const [companyId2, setCompanyId2] = useState(-1);
  const [technologyId, setTechnologyId] = useState(-1);
  const [technologyId2, setTechnologyId2] = useState(-1);
  const [serviceId, setServiceId] = useState(-1);
  const [serviceId2, setServiceId2] = useState(-1);

  //FilterFunctions
  const getFilteredTechnologies = (dataTechnologies) => {
    return dataTechnologies;
  };
  const getFilteredFrequencies = (dataFrequencies) => {
    const filteredFrequencies = dataFrequencies?.data.filter(
      (frequency) => frequency.band.service.id === parseInt(serviceId)
    );
    return filteredFrequencies;
  };
  const getFilteredFrequencies2 = (dataFrequencies) => {
    const filteredFrequencies = dataFrequencies?.data.filter(
      (frequency) => frequency.band.service.id === parseInt(serviceId2)
    );
    return filteredFrequencies;
  };
  const getFilteredFrequencyCodes = (dataContracts) => {
    const filteredFrequencies = dataContracts?.data?.filter(
      (contract) => contract.companyId === parseInt(companyId)
    );
    return filteredFrequencies;
  };
  const getFilteredFrequencyCodes2 = (dataContracts) => {
    const filteredFrequencies = dataContracts?.data?.filter(
      (contract) => contract.companyId === parseInt(companyId2)
    );
    return filteredFrequencies;
  };
    const getFilteredServices = (dataServices) => {
      const filteredServices = dataServices.data.filter(
        (service) => service.technology.id === parseInt(technologyId)
      );
      return filteredServices;
    };
      const getFilteredServices2 = (dataServices) => {
        const filteredServices = dataServices.data.filter(
          (service) => service.technology.id === parseInt(technologyId2)
        );
        return filteredServices;
      };

  return (
    <>
      <HeadingForm title="9 &nbsp;&nbsp;&nbsp;&nbsp;Antennegegevens" />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.1 Fabricaat/Type
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="AntenneFabricaatType1"
            />
          </div>
          <ErrorMessage name="AntenneFabricaatType1" component="div" />
        </div>

        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="AntenneFabricaatType2"
            />
          </div>
          <ErrorMessage name="AntenneFabricaatType2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.2 Company
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneCompanyId1`}
            title=" "
            options={dataCompanies?.data.map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            isMulti={false}
            onChangeFunction={async (value) => {
              setCompanyId(value);
              console.log({ value });
            }}
          />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneCompanyId2`}
            title=" "
            options={dataCompanies?.data.map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            isMulti={false}
            onChangeFunction={async (value) => {
              setCompanyId2(value);
              console.log({ value });
            }}
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.3 Access Type
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneAccessTypeId1`}
            title=" "
            options={getFilteredTechnologies(dataTechnologies)?.data.map(
              (item) => {
                return { value: item.id, label: item.name };
              }
            )}
            component={FormCustomSelect}
            isMulti={false}
            onChangeFunction={async (value) => {
              console.log({ value });
              setTechnologyId(value);
            }}
          />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneAccessTypeId2`}
            title=" "
            options={getFilteredTechnologies(dataTechnologies)?.data.map(
              (item) => {
                return { value: item.id, label: item.name };
              }
            )}
            component={FormCustomSelect}
            isMulti={false}
            onChangeFunction={async (value) => {
              console.log({ value });
              setTechnologyId2(value);
            }}
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.4 Service
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneServiceId1`}
            title=" "
            // options={[
            //   { value: "man", label: "maskulino" },
            //   { value: "vrouw", label: "femenino" },
            // ]}
            options={getFilteredServices(dataServices).map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            // placeholder="Selektá sekso..."
            isMulti={false}
            onChangeFunction={async (value) => {
              setServiceId(value);
              console.log({ value });
            }}
          />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneServiceId2`}
            title=" "
            // options={[
            //   { value: "man", label: "maskulino" },
            //   { value: "vrouw", label: "femenino" },
            // ]}
            options={getFilteredServices2(dataServices).map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            // placeholder="Selektá sekso..."
            isMulti={false}
            onChangeFunction={async (value) => {
              setServiceId2(value);
              console.log({ value });
            }}
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.4 Frequency Band
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneFrequencyBandId1`}
            title=" "
            options={getFilteredFrequencies(dataFrequencies)?.map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            isMulti={false}
            // onChangeFunction={async (value) => {
            //   console.log({ value });
            // }}
          />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneFrequencyBandId2`}
            title=" "
            options={getFilteredFrequencies2(dataFrequencies).map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            isMulti={false}
            // onChangeFunction={async (value) => {
            //   console.log({ value });
            // }}
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.5 Frequency Code
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneFrequencyCodeId1`}
            title=" "
            options={getFilteredFrequencyCodes(dataContracts).map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            isMulti={false}
            // onChangeFunction={async (value) => {
            //   console.log({ value });
            // }}
          />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          {/* Form Col*/}
          <Field
            style={{ backgroundColor: "red" }}
            name={`AntenneFrequencyCodeId2`}
            title=" "
            options={getFilteredFrequencyCodes2(dataContracts).map((item) => {
              return { value: item.id, label: item.name };
            })}
            component={FormCustomSelect}
            isMulti={false}
            // onChangeFunction={async (value) => {
            //   console.log({ value });
            // }}
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.6 Antenne overeenkomstig lijst met antenneprofielen
          </label>
        </div>
        {/* Column 2 */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-4">Station 1</div>
          <fieldset>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntenneOvereenkomstigLijstMetAntenneprofielen1"
                value="Ja"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Ja
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntenneOvereenkomstigLijstMetAntenneprofielen1"
                value="Nee"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Nee
              </label>
              <div className="ml-4">Technische parameters toevoegen</div>
            </div>
          </fieldset>
          <ErrorMessage
            name="AntenneOvereenkomstigLijstMetAntenneprofielen1"
            component="div"
          />
        </div>
        {/* Column 3 */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-4">Station 2</div>
          <fieldset>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntenneOvereenkomstigLijstMetAntenneprofielen2"
                value="Ja"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Ja
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntenneOvereenkomstigLijstMetAntenneprofielen2"
                value="Nee"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Nee
              </label>
              <div className="ml-4">Technische parameters toevoegen</div>
            </div>
          </fieldset>
          <ErrorMessage
            name="AntenneOvereenkomstigLijstMetAntenneprofielen2"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.7 Polarisatie
          </label>
        </div>
        {/* Column 2 */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-4">Station 1</div>
          <fieldset className="flex gap-10">
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntennePolarisatie1"
                value="horizontaal"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                horizontaal
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntennePolarisatie1"
                value="verticaal"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                verticaal
              </label>
            </div>
          </fieldset>
          <ErrorMessage name="AntennePolarisatie1" component="div" />
        </div>
        {/* Column 3 */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-4">Station 2</div>
          <fieldset className="flex gap-10">
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntennePolarisatie2"
                value="horizontaal"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                horizontaal
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="AntennePolarisatie2"
                value="verticaal"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                verticaal
              </label>
            </div>
          </fieldset>
          <ErrorMessage name="AntennePolarisatie2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.8 Antennehoogte (boven maaiveld)
          </label>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="Antennehoogte1"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="Antennehoogte1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="Antennehoogte2"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="Antennehoogte2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.9 Hoofdstraalrichting (met 1 cijfer nauwkeurig achter de komma)
          </label>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneHoofdstraalrichting1"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneHoofdstraalrichting1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneHoofdstraalrichting2"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneHoofdstraalrichting2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.10 (Kabel)verliezen
          </label>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneKabelVerliezen1"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneKabelVerliezen1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneKabelVerliezen2"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneKabelVerliezen2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.11 Openingshoek (-3 dB)
          </label>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneOpeningshoek1"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneOpeningshoek1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneOpeningshoek2"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneOpeningshoek2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.12 Gain
          </label>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneGain1"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneGain1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneGain2"
            />
            <div className="mr-10 col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneGain2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.13 Zendfrequentie
          </label>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneZendfrequentie1"
            />
            <div className="mr-10 col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneZendfrequentie1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneZendfrequentie2"
            />
            <div className="mr-10 col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneZendfrequentie2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            9.14 Zendvermogen
          </label>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneZendvermogen1"
            />
            <div className="mr-10 col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneZendvermogen1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="items-center grid grid-cols-12 gap-6">
            <Field
              className="col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="AntenneZendvermogen2"
            />
            <div className="mr-10 col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
              Meter
            </div>
          </div>
          <ErrorMessage name="AntenneZendvermogen2" component="div" />
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
