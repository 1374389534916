import React from "react";
import { Link } from "react-router-dom";
import Background from "../assets/landing.jpeg";

const LandingPage = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: 0,
          width: "100%",
          height: "100vh",
          minHeight: "100vh",
        }}
      >
        <div
          id="home"
          style={{
            width: "100%",
            minHeight: "100vh",
            margin: 0,
            position: "absolute",
            zIndex: 0,
            top: 0,
            background: "#f7f7f7",
            display: "table",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${Background})`,
              position: "absolute",
              zIndex: 0,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              background:
                "linear-gradient(to right, rgba(2, 8, 37, 0.82), rgba(4, 11, 49, 0.69), rgba(11, 17, 53, 0.3), rgba(8, 15, 53, 0.76), rgba(6, 13, 51, 0.77))",
            }}
          ></div>
          <div
            style={{
              paddingRight: "50vw",
              padding: "0 40vw 0 5vw",
              position: "relative",
              zIndex: 5,
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <h1
              style={{
                color: "white",
                fontWeight: "600",
                textShadow: "0 5px 20px black",
                fontSize: "4vmax",
                lineHeight: "1.2",
              }}
            >
              Welcome
            </h1>

            <br />
            <p
              style={{
                color: "white",
                textShadow: "0 2px 5px #000, 0 2px 7px #000, 0 2px 11px #000",
              }}
            >
              Welcome to ISP. Spectrum management is the process of regulating
              the use of radio frequencies to promote efficient use and gain a
              net social benefit.
            </p>
            <Link to="/dashboard-overall" className="btn btn-primary">
              Click here to Enter!
            </Link>
          </div>
        </div>
        <div className="countdown">
          <a href="index.html">
            <img
              src={require("../assets/logo.png").default}
              style={{ display: "block", maxWidth: "100%", height: "auto" }}
              alt="logo"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
