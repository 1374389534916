import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionWoonAddress({setFieldValue}) {
  return (
    <>
      <HeadingForm title="4 &nbsp;&nbsp;&nbsp;&nbsp;Woon-/Vestigingsadres" />
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            4.1 Straat
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="WoonVestigingsadresStraat"
          />
          <ErrorMessage name="WoonVestigingsadresStraat" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            4.2 Huisnummer
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            name="WoonVestigingsadresHuisnummer"
            onChange={(e) => {
              setFieldValue(
                "WoonVestigingsadresHuisnummer",
                e.target.value.toString()
              );
            }}
          />
          <ErrorMessage name="WoonVestigingsadresHuisnummer" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            4.3 Huisnummertoevoeging
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="WoonVestigingsadresHuisnummertoevoeging"
          />
          <ErrorMessage
            name="WoonVestigingsadresHuisnummertoevoeging"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      {/* <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            4.4 Postcode
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="WoonVestigingsadresPostcode"
          />
          <ErrorMessage name="WoonVestigingsadresPostcode" component="div" />
        </div>
      </div> */}
      {/* End Row */}

      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            4.4 Plaats
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="WoonVestigingsadresPlaats"
          />
          <ErrorMessage name="WoonVestigingsadresPlaats" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            4.5 Land
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="WoonVestigingsadresLand"
          />
          <ErrorMessage name="WoonVestigingsadresLand" component="div" />
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
