import React from "react";
import DashboardCard from "./DashboardCard";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const getDashboardQuery = gql`
  query {
    totalActiveTelecomProviders {
      total
    }
  }
`;
const TelecomProvidersCard = () => {
  const { data, loading } = useQuery(getDashboardQuery, {
    fetchPolicy: "network-only",
  });
  return (
    <DashboardCard
      title="Active Telecom Providers"
      data={
        loading ? <p>Loading...</p> : data.totalActiveTelecomProviders.total
      }
      border="border-left-info"
      icon="clipboard-list"
    />
  );
};

export default TelecomProvidersCard;
