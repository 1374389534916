import React, { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import {
  updateCompanyMutation,
  getCompanyQuery,
  deleteCompanyMutation,
} from "../../queries/queries";

import { useNavigate } from "react-router-dom";

import { Context } from "../../contexts/CompanyContext";
import CompanyModal from "./../PageCompanies/ModalCompany";

const CompanyCard = ({ user }) => {
  let navigate = useNavigate();

  const { company, countries } = useContext(Context);

  const [companyTemp, setCompanyTemp] = useState({});
  const [updateCompany] = useMutation(updateCompanyMutation);
  const [deleteCompany] = useMutation(deleteCompanyMutation);

  useEffect(() => {
    setCompanyTemp(company);
  }, [company]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      setCompanyTemp({ ...companyTemp, country: { id: value } });
    } else {
      setCompanyTemp({ ...companyTemp, [name]: value });
    }
  };
  const openModal = (e) => {
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const saveItem = async () => {
    updateCompany({
      variables: {
        id: companyTemp.id,
        name: companyTemp?.name,
        address: companyTemp.address,
        code: companyTemp.code,
        coc: companyTemp.coc,
        quickBooksId: companyTemp.quickBooksId,
        countryId: companyTemp.country ? parseInt(companyTemp.country.id) : 0,
        comments: companyTemp.comments,
      },
      refetchQueries: [
        { query: getCompanyQuery, variables: { id: companyTemp.id } },
      ],
    });
    closeModal();
  };
  const closeModal = () => {
    setCompanyTemp(company);
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownCompanyMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownCompanyMenuLink"]')
      .classList.remove("show");
  };

  const deleteItem = () => {
    deleteCompany({
      variables: {
        id: company?.id,
      },
    });
    navigate("/companies");
  };

  return (
    <React.Fragment>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Company</h6>
          {user.role?.name !== "OPERATOR" && (
            <div className="dropdown no-arrow">
              <button
                className="button-link"
                id="dropdownCompanyMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleDropdownMenu}
                onBlur={closeDropdown}
              >
                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                aria-labelledby="dropdownCompanyMenuLink"
                x-placement="bottom-end"
                style={{
                  position: "absolute",
                  willChange: "transform",
                  top: 0,
                  left: 0,
                  transform: "translate3d(-156px, 19px, 0px)",
                }}
              >
                <div className="dropdown-header">Actions:</div>
                <button
                  className="dropdown-item"
                  onMouseDown={openModal}
                  data-target="#companyModal"
                >
                  Edit
                </button>
                <button
                  className="dropdown-item"
                  onMouseDown={() =>
                    window.confirm(
                      `Are you sure you wish to delete '${company?.name}'?`
                    ) && deleteItem()
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          )}
          {/* <i className="fas fa-fw fa-edit" onClick={openModal} style={{ cursor: 'pointer', float: 'right' }} data-target="#companyModal"></i> */}
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-4">Company Name:</div>
            <div className="col-8">{company?.name}</div>
          </div>
          <div className="row">
            <div className="col-4">Zip/Postal code:</div>
            <div className="col-8">{company?.code}</div>
          </div>
          <div className="row">
            <div className="col-4">Address:</div>
            <div className="col-8">{company?.address}</div>
          </div>
          <div className="row">
            <div className="col-4">Country:</div>
            <div className="col-8">
              {company?.country ? company?.country?.name : null}
            </div>
          </div>
          <div className="row">
            <div className="col-4">Chambers of Commerce:</div>
            <div className="col-8">{company?.coc}</div>
          </div>
          <div className="row">
            <div className="col-4">QuickBooks ID:</div>
            <div className="col-8">{company?.quickBooksId}</div>
          </div>
          <div className="row">
            <div className="col-4">Comments:</div>
            <div className="col-8">{company?.comments}</div>
          </div>
        </div>
      </div>
      <CompanyModal
        company={companyTemp}
        closeModal={closeModal}
        countries={countries ? countries : []}
        handleChange={handleChange}
        saveItem={saveItem}
      />
    </React.Fragment>
  );
};

export default CompanyCard;
