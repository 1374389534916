import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ErrorMessage from "./ErrorMessage";
import FormFieldTitle from "./FormFieldTitle";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default function FormDropzonee({
  title,
  span = 6,
  files,
  setFiles,
  field,
}) {
  //StateVariables
  // const [files, setFiles] = useState([]);

  //StateFunctions
  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const removeAll = () => {
    setFiles([]);
  };

  //Constants
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files?.map((file) => (
    <div style={thumb} key={file.name} className="relative">
      <div >
        <iframe
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <button
        onClick={removeFile(file)}
        className="absolute -top-2 -right-44 bg-gray-300 text-white rounded-full hover:scale-125 hover:bg-gray-500 transition-all opacity-80"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    const props = getInputProps();
    console.log({ props: props });
    return () => files?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      <div
        className={`bg-gray-100 p-5 rounded-md col-span-${span} lg:col-span-${span} md:col-span-${span} sm:col-span-${span} xl:col-span-${span}`}
      >
        <FormFieldTitle title={title} />
        <div
          {...getRootProps()}
          className="bg-white mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 sm:mx-auto "
        >
          <div className="space-y-1 text-center ">
            <svg
              className="mx-auto h-12 w-12 text-green-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
              >
                <span>Select an image</span>
                <input {...getInputProps()} />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">PDF, PNG, JPG up to 10 MB</p>
          </div>
        </div>
        {files?.length > 0 && (
          <>
            <div className="mt-6 text-black">
              <h4>{"Image(s): "}</h4>
              <ul>
                {files.map((file, i) => {
                  return (
                    <li key={i}>
                      <h3>{file.name}</h3>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="mt-6 text-black">
              <h4>{"Preview: "}</h4>
              <ul>{thumbs}</ul>
            </div>
          </>
        )}
        {/* <ErrorMessage name={field.name} component="div" /> */}
        {/* {files.length > 0 && <button onClick={removeAll}>Remove All</button>} */}
      </div>
    </>
  );
}
