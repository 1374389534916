import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useStateValue } from "./formContextProvider";

import FormSection5Total from "./FormSection5Total";
import { NEXT_URL } from "../../config/index";

import success from "../../assets/success.png";

export default function FormStep5({ handleNext, handleBack, handleReset }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, setState } = useStateValue();
  const [pageStateRouting, setPageStateRouting] = useState("filling");

  const addItem = async function (data) {
    let result = null;
    result = await fetch(`${NEXT_URL}/api/regulatoryTrackerForm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return result;
  };

  const saveRequest = async (values) => {
    let result = null;
    const isAddMode = true;

    if (!isAddMode) {
      // result = await updateItem({ ...values }, id);
      // console.log({ result });
    } else {
      result = await addItem({ ...values });
      console.log({ result });
    }
    if (result.ok) {
      return result;
    } else {
      const error = await result.json();
      console.log({ error });
      throw { message: `Oops algu a bai robes: ${result.statusText}` };
    }
  };

  const handleSubmit = async () => {
    let testing = false;
    setLoading(true);
    //Create NewState

    const newState = { ...state };

    if (testing) {
      alert(JSON.stringify(newState, null, 2));
      console.log(JSON.stringify(newState, null, 2));
      setLoading(false);
      return;
    }
    try {
      setState(newState);
      await saveRequest(newState);
      setPageStateRouting("success");
      //resetForm();
      setError(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function processValues(values) {
    let processedValues = {
      ...values,
    };

    return processedValues;
  }

  return (
    <>
      {pageStateRouting === "filling" && (
        <>
          <FormSection5Total
            raBenchmark={state.raBenchmark}
            rmBenchmark={state.rmBenchmark}
            rrBenchmark={state.rrBenchmark}
            cfBenchmark={state.cfBenchmark}
          />

          <div className="mt-5">
            <h2>ICT Regulatory Tracker Score Breakdown</h2>
            <br />
            <p>G1: ≥ 0</p>
            <p>G2: ≥ 40</p>
            <p>G3: ≥ 70</p>
            <p>G4: ≥ 85 ≤ 100</p>
            <br />
            <p>
              G1: Regulated public monopolies - command and control approach
            </p>
            <p>
              G2: Basic reform - partial liberalization and privatization across
              the layers
            </p>
            <p>
              G3: Enabling investment, innovation and access - dual focus on
              stimulating competition in service and contend delivery, and consu
              er protection
            </p>
            <p>
              G4: Interated regulation - let by economic and social policy goals
            </p>
          </div>

          <div className="flex justify-end p-4 gap-6">
            <button
              disabled={loading}
              onClick={handleBack}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 -mr-1 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              Previous
            </button>

            <button
              onClick={handleSubmit}
              disabled={loading}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {loading ? "Submitting" : "Submit"}
            </button>
          </div>
        </>
      )}

      {pageStateRouting === "success" && (
        <div>
          <div className="flex flex-col justify-center items-center">
            <h3 className="font-medium leading-tight text-3xl mt-0 mb-2 ">
              Form submited successfully
            </h3>

            <img src={success} className="h-90 w-36 h-36" />
          </div>
          <div className="flex justify-end p-2 pt-4">
            {" "}
            <button
              disabled={loading}
              onClick={() => {
                setPageStateRouting("filling");
                handleReset();
              }}
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Go back
            </button>
          </div>
        </div>
      )}
    </>
  );
}
