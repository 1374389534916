import React, { createContext, useReducer } from "react";

const initialState = {
  companies: [],
  company: {
    name: "",
    state: "",
    address: "",
    code: "",
    coc: "",
    country: { id: 0 },
    quickBooksId: "",
    comments: "",
  },
  contracts: [],
  contract: {
    id: 0,
    name: "",
    company: { id: 0 },
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    uplinkLow: 0,
    uplinkMid: 0,
    uplinkHigh: 0,
    downlinkLow: 0,
    downlinkMid: 0,
    downlinkHigh: 0,
    division: {},
    frequency: {
      id: 0,
      band: { id: 0, service: { id: 0, technology: { id: 0 } } },
    },
    station: { id: 0, description: "" },
    lineFee: {},
    jurisdiction: {},
    attachments: [],
  },
  contacts: [],
  contact: {
    company: { id: 0 },
    firstName: "",
    lastName: "",
    function: "",
    phone: "",
    email: "",
  },
  countries: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_companies":
      return { ...state, companies: action.payload.companies };
    case "set_company":
      return { ...state, company: action.payload.company };
    case "reset_company":
      return { ...state, company: initialState.company };
    case "set_contracts":
      return { ...state, contracts: action.payload.contracts };
    case "set_contract":
      return { ...state, contract: action.payload.contract };
    case "reset_contract":
      return { ...state, contract: initialState.contract };
    case "set_contacts":
      return { ...state, contacts: action.payload.contacts };
    case "set_contact":
      return { ...state, contact: action.payload.contact };
    case "reset_contact":
      return { ...state, contact: initialState.contact };
    case "set_countries":
      return { ...state, countries: action.payload.countries };
    default:
      return state;
  }
};

const Context = createContext();
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    companies: state.companies,
    company: state.company,
    contracts: state.contracts,
    contract: state.contract,
    contacts: state.contacts,
    contact: state.contact,
    countries: state.countries,
    setCompanies: (companies) => {
      dispatch({ type: "set_companies", payload: { companies } });
    },
    setCompany: (company) => {
      dispatch({ type: "set_company", payload: { company } });
    },
    resetCompany: () => {
      dispatch({ type: "reset_company" });
    },
    setContracts: (contracts) => {
      dispatch({ type: "set_contracts", payload: { contracts } });
    },
    setContract: (contract) => {
      dispatch({ type: "set_contract", payload: { contract } });
    },
    resetContract: () => {
      dispatch({ type: "reset_contract" });
    },
    setContacts: (contacts) => {
      dispatch({ type: "set_contacts", payload: { contacts } });
    },
    setContact: (contact) => {
      dispatch({ type: "set_contact", payload: { contact } });
    },
    resetContact: () => {
      dispatch({ type: "reset_contact" });
    },
    setCountries: (countries) => {
      dispatch({ type: "set_countries", payload: { countries } });
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
