import React from 'react'
import SitesOverview from './../components/PageSettings/PageSettingsTransmissionFacility/Sites';
import StationTypeOverview from './../components/PageSettings/PageSettingsTransmissionFacility/StationTypes';
import TowerTypeOverview from './../components/PageSettings/PageSettingsTransmissionFacility/TowerTypes';

const TransmissionFacility = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-4">
                    <SitesOverview />
                </div>
                <div className="col-lg-4">
                    <StationTypeOverview />
                </div>
                <div className="col-lg-4">
                    <TowerTypeOverview />
                </div>
            </div>
        </div>
    )
}

export default TransmissionFacility;