import { gql } from "@apollo/client";

const getServicesQuery = gql`
  {
    services {
      id
      name
      technology {
        id
        name
      }
    }
    technologies {
      id
      name
    }
  }
`;

const getServiceQuery = gql`
  query ($id: Int) {
    service(id: $id) {
      id
      name
      technology {
        id
        name
      }
    }
  }
`;

const addServiceMutation = gql`
  mutation AddService($technologyId: Int!, $name: String!) {
    addService(technologyId: $technologyId, name: $name) {
      id
    }
  }
`;
const updateServiceMutation = gql`
  mutation UpdateService($id: Int!, $technologyId: Int!, $name: String!) {
    updateService(id: $id, technologyId: $technologyId, name: $name) {
      id
      name
    }
  }
`;
const deleteServiceMutation = gql`
  mutation DeleteService($id: Int!) {
    deleteService(id: $id) {
      id
    }
  }
`;

export const serviceService = {
  getServicesQuery,
  getServiceQuery,
  addServiceMutation,
  updateServiceMutation,
  deleteServiceMutation,
};
