import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";

export default function RowRegulatoryTracker({
    title = "Title",
    nameCoding = "nameCoding",
    namePoints = "namePoints"
}) {
  return (
    <>{/* Form Row */}
    <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
      {/* Form Input area*/}
      <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
        <label className="block uppercase tracking-wide text-xs font-bold my-2">
          {title}
        </label>
      </div>
      {/* Col */}
      <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
        <div
          className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        >
            2
        </div>
      </div>
      {/* Col */}
      <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
        <Field
          className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          type="text"
          name={nameCoding}
        />
        <ErrorMessage name={nameCoding} component="div" />
      </div>
      {/* Col */}
      <fieldset className="col-span-3">
        <div className="grid grid-cols-12 gap-6 ">
          {/* Col */}
          <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
            <Field
              type="radio"
              value="0"
              name={namePoints}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <ErrorMessage name={namePoints} component="div" />
          </div>
          {/* Col */}
          <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
            <Field
              type="radio"
              value="1"
              name={namePoints}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <ErrorMessage name={namePoints} component="div" />
          </div>
          {/* Col */}
          <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
            <Field
              type="radio"
              value="2"
              name={namePoints}
              className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
            />
            <ErrorMessage name={namePoints} component="div" />
          </div>
        </div>
      </fieldset>
    </div>
    {/* End Row 3*/}</>
  )
}
