import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionLocatieZender({ setFieldValue }) {
  return (
    <>
      <HeadingForm
        title="8 &nbsp;&nbsp;&nbsp;&nbsp;Locatie zender"
        subTitle="Coördinaten volgens het WGS 84 coördinatenstelsel. Kunt u de coördinaten niet bepalen, stuur dan een plattegrond mee
          waarop de locatie duidelijk is aangegeven."
      />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            8.1 Stationsnaam
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="ZenderStationsnaam1"
            />
          </div>
          <ErrorMessage name="ZenderStationsnaam1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="ZenderStationsnaam2"
            />
          </div>
          <ErrorMessage name="ZenderStationsnaam2" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            8.2 Straat
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 1</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="ZenderStraat1"
            />
          </div>
          <ErrorMessage name="ZenderStraat1" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <div className="mb-1">Station 2</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              name="ZenderStraat2"
            />
          </div>
          <ErrorMessage name="ZenderStraat2" component="div" />
        </div>
      </div>
      {/* End Row */}
     
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            8.4 Coördinaten oosterlengte
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <div className="mb-4">Station 1</div>
          <div className="border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3">
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Graden
              </div>
              <div className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 flex">
                <div className=" resize-none mr-2 border rounded-md appearance-none block w-10 bg-gray-50 border-gray-200 py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  0
                </div>
                <Field
                  className=" resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="number"
                  name="ZenderCoordinatenOosterlengteGraden1"
                />
              </div>
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                vul alleen het 2e cijfer in
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenOosterlengteGraden1"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Minuten
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenOosterlengteMinuten1"
              />
            </div>
            <ErrorMessage
              name="ZenderCoordinatenOosterlengteMinuten1"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Seconden
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenOosterlengteSeconden1"
              />
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                tot 1 cijfer nauwkeurig achter de komma
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenOosterlengteSeconden1"
              component="div"
            />
          </div>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <div className="mb-4">Station 2</div>
          <div className="border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3">
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Graden
              </div>
              <div className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 flex">
                <div className=" resize-none mr-2 border rounded-md appearance-none block w-10 bg-gray-50 border-gray-200 py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  0
                </div>
                <Field
                  className=" resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="number"
                  name="ZenderCoordinatenOosterlengteGraden2"
                />
              </div>
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                vul alleen het 2e cijfer in
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenOosterlengteGraden2"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Minuten
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenOosterlengteMinuten2"
              />
            </div>
            <ErrorMessage
              name="ZenderCoordinatenOosterlengteMinuten2"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Seconden
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenOosterlengteSeconden2"
              />
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                tot 1 cijfer nauwkeurig achter de komma
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenOosterlengteSeconden2"
              component="div"
            />
          </div>
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-2">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            8.5 Coördinaten noorderbreedte
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <div className="mb-4">Station 1</div>
          <div className="border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3">
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Graden
              </div>
              <div className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 flex">
                <div className=" resize-none mr-2 border rounded-md appearance-none block w-10 bg-gray-50 border-gray-200 py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  0
                </div>
                <Field
                  className=" resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="number"
                  name="ZenderCoordinatenNoorderbreedteGraden1"
                />
              </div>
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                vul alleen het 2e cijfer in
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenNoorderbreedteGraden1"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Minuten
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenNoorderbreedteMinuten1"
              />
            </div>
            <ErrorMessage
              name="ZenderCoordinatenNoorderbreedteMinuten1"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Seconden
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenNoorderbreedteSeconden1"
              />
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                tot 1 cijfer nauwkeurig achter de komma
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenNoorderbreedteSeconden1"
              component="div"
            />
          </div>
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <div className="mb-4">Station 2</div>
          <div className="border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3">
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Graden
              </div>
              <div className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 flex">
                <div className=" resize-none mr-2 border rounded-md appearance-none block w-10 bg-gray-50 border-gray-200 py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  0
                </div>
                <Field
                  className=" resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="number"
                  name="ZenderCoordinatenNoorderbreedteGraden2"
                />
              </div>
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                vul alleen het 2e cijfer in
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenNoorderbreedteGraden2"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Minuten
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenNoorderbreedteMinuten2"
              />
            </div>
            <ErrorMessage
              name="ZenderCoordinatenNoorderbreedteMinuten2"
              component="div"
            />
            <div className="grid grid-cols-12 gap-6">
              <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3 ">
                Seconden
              </div>
              <Field
                className="col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4 resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                name="ZenderCoordinatenNoorderbreedteSeconden2"
              />
              <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5 ">
                tot 1 cijfer nauwkeurig achter de komma
              </div>
            </div>
            <ErrorMessage
              name="ZenderCoordinatenNoorderbreedteSeconden2"
              component="div"
            />
          </div>
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
