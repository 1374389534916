import { gql } from "@apollo/client";

const getTechnologiesQuery = gql`
  {
    technologies {
      id
      name
    }
  }
`;

const getTechnologyQuery = gql`
  query ($id: Int) {
    technology(id: $id) {
      id
      name
    }
  }
`;

const addTechnologyMutation = gql`
  mutation AddTechnology($name: String!) {
    addTechnology(name: $name) {
      id
    }
  }
`;

const updateTechnologyMutation = gql`
  mutation UpdateTechnology($id: Int!, $name: String!) {
    updateTechnology(id: $id, name: $name) {
      id
    }
  }
`;

const deleteTechnologyMutation = gql`
  mutation DeleteTechnology($id: Int!) {
    deleteTechnology(id: $id) {
      id
    }
  }
`;

export const technologyService = {
  getTechnologiesQuery,
  getTechnologyQuery,
  addTechnologyMutation,
  updateTechnologyMutation,
  deleteTechnologyMutation,
};
