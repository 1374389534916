import React from 'react'
import FormStraalVerbindingWithStepper from '../components/FormStraalVerbindingPage/FormStraalVerbindingWithStepper';

export default function FormPageStraalVerbinding() {
  return (
    <div className="p-4  min-h-screen">
      {/* Form Container Card */}
      <div className="rounded overflow-hidden shadow-lg">
        <div className="px-10 py-10">
      {/* Title */}
      <h2 className="text-3xl font-normal leading-normal mt-0 mb-10 mb-16 mb-20 text-gray-600 ">
        Straal Verbinding Aanvraag
      </h2>
        {/* <FormStraalVerbinding /> */}
        <FormStraalVerbindingWithStepper />
        </div>
      </div>
    </div>
  );
}
