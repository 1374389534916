import React, { useState } from "react";

import Stepper2 from "../Stepper";

import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";

import { ItemProvider } from "./formContextProvider";
import LoadingGears from "../LoadingGears";

import { useQuery } from "react-query";
import axios from "axios";
import { NEXT_URL } from "../../config/index";

export default function FormRegulatoryTrackerWithStepper() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    const steps = [
      {
        id: "Step 1",
        name: "Regulatory Authority",
        href: "#",
        status: `${activeStep > 0 ? "complete" : "current"}`,
      },
      {
        id: "Step 2",
        name: "Regulatory Mandate",
        href: "#",
        status: `${
          activeStep > 1
            ? "complete"
            : activeStep < 1
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 3",
        name: "Regulatory Regime",
        href: "#",
        status: `${
          activeStep > 2
            ? "complete"
            : activeStep < 2
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 4",
        name: "Compitition Framework",
        href: "#",
        status: `${
          activeStep > 3
            ? "complete"
            : activeStep < 3
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 5",
        name: "Total",
        href: "#",
        status: `${
          activeStep > 4
            ? "complete"
            : activeStep < 4
            ? "upcomming"
            : "complete"
        }`,
      },
    ];
    return steps;
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <FormStep1 handleNext={handleNext} />;
      case 1:
        return <FormStep2 handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        return <FormStep3 handleNext={handleNext} handleBack={handleBack} />;
      case 3:
        return <FormStep4 handleNext={handleNext} handleBack={handleBack} />;
      case 4:
        return (
          <FormStep5
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
          />
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <>
      <ItemProvider>
        <div className="overflow-x-auto mb-10 mt-10">
          <Stepper2 steps={getSteps()} />
        </div>
        <div>{getStepContent(activeStep)}</div>
      </ItemProvider>
    </>
  );
}
