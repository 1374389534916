import React, { useState } from "react";
import HeadingForm from "./HeadingForm";
import RowRegulatoryTracker from "./RowRegulatoryTracker";

export default function FormSection3RegulatoryRegime() {
  return (
    <>
      <HeadingForm title="3 &nbsp;&nbsp;&nbsp;&nbsp;Regulatory Regime" />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-5 sm:col-span-5 xl:col-span-5">
          <label className="block uppercase tracking-wide text-xs font-bold my-2"></label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Max Score
          </label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Coding
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            0 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            1 pts
          </label>
        </div>
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2 pts
          </label>
        </div>
      </div>
      {/* End Row 3*/}
      <RowRegulatoryTracker
        title="22 Types of licenses provided"
        nameCoding={"rrTypesOfLicencesCoding"}
        namePoints={"rrTypesOfLicencesPoints"}
      />
      <RowRegulatoryTracker
        title="23 License exempt"
        nameCoding={"rrLicenseExemptCoding"}
        namePoints={"rrLicenseExemptPoints"}
      />
      <RowRegulatoryTracker
        title="24 Operators required to publish Reference Interconnection Offer"
        nameCoding={"rrInterconnectionOfferCoding"}
        namePoints={"rrInterconnectionOfferPoints"}
      />
      <RowRegulatoryTracker
        title="25 Interconnection prices made public"
        nameCoding={"rrInterconnectionPricesCoding"}
        namePoints={"rrInterconnectionPricesPoints"}
      />
      <RowRegulatoryTracker
        title="26 Quality of Service monitoring required"
        nameCoding={"rrQualityServiceCoding"}
        namePoints={"rrQualityServicePoints"}
      />
      <RowRegulatoryTracker
        title="27 Infrastructure sharing for mobile operators permitted"
        nameCoding={"rrInfrastructureMobileCoding"}
        namePoints={"rrInfrastructureMobilePoints"}
      />
      <RowRegulatoryTracker
        title="28 Ifrastructure sharing mandated"
        nameCoding={"rrInfrastructureMandateCoding"}
        namePoints={"rrInfrastructureMandatePoints"}
      />
      <RowRegulatoryTracker
        title="29 Co-location/site sharing mandate"
        nameCoding={"rrColocationMandateCoding"}
        namePoints={"rrColocationMandatePoints"}
      />
      <RowRegulatoryTracker
        title="30 Unbundled access to the local loop required"
        nameCoding={"rrUnbundledAccessCoding"}
        namePoints={"rrUnbundledAccessPoints"}
      />
      <RowRegulatoryTracker
        title="31 Secondary trading allowed"
        nameCoding={"rrSecondaryTradingCoding"}
        namePoints={"rrSecondaryTradingPoints"}
      />
      <RowRegulatoryTracker
        title="32 Band migration allowed"
        nameCoding={"rrBandMigrationCoding"}
        namePoints={"rrBandMigrationPoints"}
      />
      <RowRegulatoryTracker
        title="33 number portability available to consumers and required from fixed-line operators"
        nameCoding={"rrNumberPortabilityFixedLineCoding"}
        namePoints={"rrNumberPortabilityFixedLinePoints"}
      />
      <RowRegulatoryTracker
        title="34 number portability available to consumers and required from mobile operators"
        nameCoding={"rrNumberPortabilityMobileCoding"}
        namePoints={"rrNumberPortabilityMobilePoints"}
      />
      <RowRegulatoryTracker
        title="35 Individual user allowed to use VoIP"
        nameCoding={"rrIndividualUserVoipCoding"}
        namePoints={"rrIndividualUserVoipPoints"}
      />
      <RowRegulatoryTracker
        title="36 National plan that involves broadband"
        nameCoding={"rrNationalPlanCoding"}
        namePoints={"rrNationalPlanPoints"}
      />
    </>
  );
}
