import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionToepassing({ setFieldValue }) {
  const [isLessThan5Years, setIslessThan5Years] = useState(false);

  return (
    <>
      <HeadingForm
        title="7 &nbsp;&nbsp;&nbsp;&nbsp;Toepassing Straalverbindingen"
        subTitle="Ingeval passieve reflector onderdeel 10 en 11 geheel invullen."
      />
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.1 Link ID aanvrager
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="StraalverbindingLinkIDAanvrager"
          />
          <ErrorMessage
            name="StraalverbindingLinkIDAanvrager"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.2 Soort Verbinding
          </label>
        </div>
        {/* Second Column */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="StraalverbindingSoortVerbinding"
                value="uni-directioneel"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                uni-directioneel
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="StraalverbindingSoortVerbinding"
                value="bi-directioneel"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                bi-directioneel
              </label>
            </div>
            <div className="hidden items-center mb-4">
              <Field
                type="checkbox"
                name="StraalverbindingInclusiefPassieveReflector"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                onChange={(e) => {
                  setFieldValue(
                    "StraalverbindingInclusiefPassieveReflector",
                    +e.target.checked
                  );
                }}
              />
              {/* <label className="text-sm font-medium text-gray-900 ml-2 block">
                incl. passieve reflector
              </label> */}
            </div>
          </fieldset>
          {/* <ErrorMessage
            name="StraalverbindingInclusiefPassieveReflector"
            component="div"
          /> */}
          <ErrorMessage
            name="StraalverbindingSoortVerbinding"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.3 Modulatiesoort
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="StraalverbindingModulatiesoort"
          />
          <ErrorMessage name="StraalverbindingModulatiesoort" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.4 Padlengte
          </label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="StraalverbindingPadlengte"
              onChange={(e) => {
                setFieldValue(
                  "StraalverbindingPadlengte",
                  parseFloat(e.target.value)
                );
              }}
            />
            Km
          </div>
          <ErrorMessage name="StraalverbindingPadlengte" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.5 Fabricaat/type zendontvanger
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="StraalverbindingFabricaat_typeZendontvanger"
          />
          <div className="mb-2">
            De apparatuur moet in overeenstemming zijn met de essentiële
            vereisten.
          </div>
          <ErrorMessage
            name="StraalverbindingFabricaat_typeZendontvanger"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.6 Frequentieband (onderband)
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3">
          <div className="mb-1">Ondergrens</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="StraalverbindingFrequentiebandOndergrens"
            />
            <div className="mr-10">MHz</div>
          </div>
          <ErrorMessage
            name="StraalverbindingFrequentiebandOndergrens"
            component="div"
          />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3">
          <div className="mb-1">Bovengrens</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="StraalverbindingFrequentiebandBovengrens"
            />
            <div className="mr-10">MHz</div>
          </div>
          <ErrorMessage
            name="StraalverbindingFrequentiebandBovengrens"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-7">
        {/* Column 1*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.7 Bandbreedte / datasnelheid
          </label>
        </div>
        {/* Column 2*/}
        <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3">
          <div className="mb-1">Bandbreedte</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="StraalverbindingBandbreedte"
            />
            <div className="mr-10">MHz</div>
          </div>
          <ErrorMessage name="StraalverbindingBandbreedte" component="div" />
        </div>
        {/* Column 3*/}
        <div className=" col-span-full md:col-span-3 sm:col-span-3 xl:col-span-3">
          <div className="mb-1">Datasnelheid</div>
          <div className="flex items-center">
            <Field
              className="resize-none mr-2 border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              name="StraalverbindingDatasnelheid"
            />
            <div className="mr-10">Mbit/s</div>
          </div>
          <ErrorMessage name="StraalverbindingDatasnelheid" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.8 Automatic Transmitted Power Control (ATPC)
          </label>
        </div>
        {/* Second Column */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset className="flex gap-10">
            <div className="flex items-center mb-4">
              <div className="flex items-center mb-4">
                <Field
                  type="checkbox"
                  name="StraalverbindingAutomaticTransmittedPowerControl"
                  className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                  onChange={(e) => {
                    setFieldValue(
                      "StraalverbindingAutomaticTransmittedPowerControl",
                      +e.target.checked
                    );
                  }}
                />
                <label className="text-sm font-medium text-gray-900 ml-2 block">
                  Ja
                </label>
              </div>
            </div>
          </fieldset>
          <ErrorMessage
            name="StraalverbindingAutomaticTransmittedPowerControl"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.9 Adaptieve modulator
          </label>
        </div>
        {/* Second Column */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset className="flex gap-10">
            <div className="flex items-center mb-4">
              <Field
                type="checkbox"
                name="StraalverbindingAdaptieveModulator"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                onChange={(e) => {
                  setFieldValue(
                    "StraalverbindingAdaptieveModulator",
                    +e.target.checked
                  );
                }}
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Ja
              </label>
            </div>
          </fieldset>
          <ErrorMessage
            name="StraalverbindingAdaptieveModulator"
            component="div"
          />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Column 1 */}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            7.10 Gewenste beschikbaarheid
          </label>
        </div>
        {/* Column 2 */}
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="StraalverbindingGewensteBeschikbaarheid"
                value="99.99"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                onClick={() => setIslessThan5Years(false)}
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Standaard 99,99%
              </label>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="StraalverbindingGewensteBeschikbaarheid"
                value="hello"
                onClick={() => {
                  setIslessThan5Years(true);
                }}
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label
                className="text-sm font-medium text-gray-900 ml-2 block"
                value="99.98"
              >
                Anders.
              </label>
            </div>
            <ErrorMessage
              name="StraalverbindingGewensteBeschikbaarheid"
              component="div"
            />
            {/* Hidden Row */}
            {isLessThan5Years && (
              <>
                <div className="grid grid-cols-12 gap-4">
                  <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
                    Percentage:
                  </div>{" "}
                  <div className=" col-span-full md:col-span-8 sm:col-span-8 xl:col-span-8">
                    <Field
                      className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="number"
                      name="StraalverbindingGewensteBeschikbaarheid"
                    />
                    <ErrorMessage
                      name="StraalverbindingGewensteBeschikbaarheid"
                      component="div"
                    />
                  </div>
                </div>
              </>
            )}
            {/* End Hidden Row */}
          </fieldset>
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
