import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import { Tooltip as ReactTooltip } from "react-tooltip";

import FormFieldTitle from "./FormFieldTitle";

export default function FormField({
  title,
  tooltip,
  name,
  span = 6,
  type,
  onChange,
  placeholder,
  disabled = false,
  prefix = false,
}) {
  return (
    <div
      className={`col-span-${span} md:col-span-${span} sm:col-span-${span} xl:col-span-${span}`}
    >
      <FormFieldTitle title={title} tooltip={tooltip} />
      <div className="flex">
        {prefix && <div className="p-2">{prefix}</div>}
        <Field
          className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          type={type}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
        />
      </div>
      <ErrorMessage name={name} component="div" />
    </div>
  );
}
