import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";
import FormDropzone from "../FormDropzone";
import FormDropzonee from "../FormDropZonee";
import imageCompression from "browser-image-compression";

export default function FormSectionRechtsPersoon({
  setFieldValue,
  KVKFile,
  setKVKFile,
}) {
  const onDropHandler = (files, category, images, setImages) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    files.forEach((file) => {
      let img = {};
      if (file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = (event) => {
          img.base64 = event.target.result.split(",")[1];
          img.type = event.target.result.split(",")[0];
        };
        img.name = file.name;
        img.categoryId = category;
        setImages([...images, img]);
        return reader.readAsDataURL(file);
      } else {
        imageCompression(file, options).then((compressedFile) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            img.base64 = event.target.result.split(",")[1];
            img.type = event.target.result.split(",")[0];
          };
          img.name = compressedFile.name;
          img.categoryId = category;

          setImages([img, ...images]);
          return reader.readAsDataURL(compressedFile);
        });
      }
    });
  };

  const onDeleteHandler = (file, category, images, setImages) => {
    setImages(
      images.filter(
        (image) => image.name !== file.name && image.categoryId !== category
      )
    );
  };

  return (
    <>
      <HeadingForm title="2 &nbsp;&nbsp;&nbsp;&nbsp;Vergunning op naam van rechtspersoon" />
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2.1 Statutaire naam
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="rechtspersoonStatutaireNaam"
          />
          <ErrorMessage name="rechtspersoonStatutaireNaam" component="div" />
        </div>
      </div>
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2.2 Handelsnaam
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="rechtspersoonHandelsnaam"
          />
          <ErrorMessage name="rechtspersoonHandelsnaam" component="div" />
        </div>
      </div>
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6  pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2.3 Ingeschreven bij de Kamer van Koophandel in
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <fieldset>
            {/* <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="rechtspersoonKVKLocatie"
                value="Buitenland"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />

              <label className="text-sm font-medium text-gray-900 ml-2 block">
                Buitenland
              </label>
            </div> */}
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                name="rechtspersoonKVKLocatie"
                value="St. Maarten"
                className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
              />
              <label className="text-sm font-medium text-gray-900 ml-2 block">
                St. Maarten
              </label>
            </div>
          </fieldset>
          <ErrorMessage name="rechtspersoonKVKLocatie" component="div" />
        </div>

        {/* Side information*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          {/* <div className="mb-4">Stuur een bewijs van inschrijving mee</div> */}
          <div>
            BTP St. Maarten verifieert uw gegevens in het handelsregister.
          </div>
        </div>
      </div>
      {/* End Row */}

      {/* Form Row 3*/}
      {/* <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2.4 KvK-nummer
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            name="rechtspersoonKvK_nummer"
            onChange={(e) => {
              setFieldValue(
                "rechtspersoonKvK_nummer",
                e.target.value.toString()
              );
            }}
          />
          <ErrorMessage name="rechtspersoonKvK_nummer" component="div" />
        </div>
      </div> */}

      {/* Form Row */}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Col*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2.4 KVK File
          </label>
        </div>
        {/* Form Col*/}
        <Field
          title={"kvkFile"}
          name={"kvkFile"}
          span={6}
          component={FormDropzonee}
          files={KVKFile}
          setFiles={setKVKFile}
        />
        {/* <Field
          title={""}
          name={"kvkFile"}
          span={6}
          component={FormDropzone}
          onChangeFunction={(value) => {}}
          onDropHandler={onDropHandler}
          onDeleteHandler={onDeleteHandler}
          images={KVKFile}
          setImages={setKVKFile}
        /> */}
      </div>
      {/* End Row */}

      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            2.5 Vestigingsnummer
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="number"
            name="rechtspersoonVestigingsnummer"
            onChange={(e) => {
              setFieldValue(
                "rechtspersoonVestigingsnummer",
                e.target.value.toString()
              );
            }}
          />
          <ErrorMessage name="rechtspersoonVestigingsnummer" component="div" />
        </div>
        {/* Side information*/}
        <div className=" col-span-full md:col-span-6 sm:col-span-6 xl:col-span-6">
          <div className="mb-4">Indien van toepassing</div>
        </div>
      </div>
    </>
  );
}
