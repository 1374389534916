import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { gql } from "@apollo/client";
import { jsPDF } from "jspdf";
import { NEXT_URL, REACT_APP_DEV_MODE } from "../../config/index";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  useQuery as useApolloQuery,
  useLazyQuery,
  useEffect,
} from "@apollo/client";
import { useQuery } from "react-query";

import CompanyReportCard from "./CardCompanyReport";

const getCompaniesQuery = gql`
  {
    companies {
      id
      name
      address
      coc
    }
  }
`;

const getCompanyReportQuery = gql`
  query ($companyId: Int) {
    totalCompanyCostPerBandwidth(companyId: $companyId) {
      lineFee
      name
      downlink
      uplink
      totalBandwidth
      unitPrice
      totalGuilders
      totalDollars
    }
    totalCompanyCost(companyId: $companyId) {
      totalGuilders
      totalDollars
    }
  }
`;

const getAllCompaniesReportQuery = gql`
  query {
    AllCompaniesTotalCostPerBandwidth {
      lineFee
      name
      downlink
      uplink
      totalBandwidth
      unitPrice
      totalGuilders
      totalDollars
    }
    totalAllCompaniesCost {
      totalGuilders
      totalDollars
    }
  }
`;

const CompanyReport = () => {
  //QueryStateVariables
  const [getReport, { loading, data }] = useLazyQuery(getCompanyReportQuery);
  const [
    getAllCompaniesReport,
    { loading: allCompaniesReportLoading, data: allCompaniesReportData },
  ] = useLazyQuery(getAllCompaniesReportQuery);
  const { loading: companyloading, data: companydata } =
    useApolloQuery(getCompaniesQuery);

  //StateVariables
  const [isDisabled, setIsDisabled] = useState(true);
  const [company, setCompany] = useState("");
  const [isYearly, setIsYearly] = useState(true);
  const [companyId, setCompanyId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  //Constants
  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  //FetchFunctions
  const fetchReportsByYearNMonth = async () => {
    return axios.get(
      `${NEXT_URL}/api/companyFinancialReports/byYearNMonth?year=${currentYear}&month=${currentMonth}`
    );
  };

  const fetchAllTotalCompanyCost = async () => {
    return axios.get(`${NEXT_URL}/api/companies/totalCompanyCostPerBandwidth`);
  };

  //QueryFunctions
  const {
    data: dataReportByYearNMonths,
    status: statusReportByYearNMonths,
    error: errorReportByYearNMonths,
  } = useQuery([`CompanyReportReportsByYearNMonth`], fetchReportsByYearNMonth, {
    keepPreviousData: true,
    refetchOnMount: true,
    onSuccess: (data) => {
      if (data.data.alreadySaved === false) {
        setIsDisabled(false);
      }
    },
  });

  const {
    data: dataTotalCompanyCost,
    status: statusTotalCompanyCost,
    error: errorTotalCompanyCost,
  } = useQuery([`CompanyTotalCompanyCost`], fetchAllTotalCompanyCost, {
    keepPreviousData: true,
    refetchOnMount: true,
  });

  //CompanyReportQueryResultsCheck
  console.log({ CompanyReportQueryResultsCheck: dataTotalCompanyCost });

  //FormStateFunctions
  const handleChange = (e) => {
    if (e.target.value !== "0") {
      setCompany(
        companydata.companies.find(
          (company) => parseInt(e.target.value) === company.id
        )
      );
      getReport({ variables: { companyId: parseInt(e.target.value) } });
      setCompanyId(parseInt(e.target.value));
    }
    if (e.target.value === "-1") {
      setCompany({
        id: -1,
        name: "All Companies",
        address: "",
        coc: "",
        __typename: "Company",
      });
      getAllCompaniesReport();
    }
  };

  const handlePeriodChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "false") {
      setIsYearly(false);
    } else {
      setIsYearly(true);
    }
  };

  //FormUtils
  const handlePrint2 = (divName) => {
    const w = window.open();
    w.document.write(document.getElementById(divName).innerHTML);
    w.print();
    w.close();
  };

  const handleSaveToPDF = (divName) => {
    const doc = new jsPDF();

    // doc.html(document.getElementById(divName).innerHTML, document.getElementById(divName));

    doc.html(document.getElementById(divName), {
      callback: function (doc) {
        doc.save("Company-Report-" + company.name + " (" + getDate() + ").pdf");
      },
      x: 0,
      y: 0,
      width: 210,
      windowWidth: 1000,
    });
  };

  const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <h1>Company Report</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6"></div>
      </div>
      <div className="row">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="col-lg-12">
          <div className="card mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                <div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="flex gap-2">
                      {/* Select Company */}
                      <select
                        className="form-control"
                        id="company"
                        name="company"
                        onChange={handleChange}
                        value={company.id}
                        style={{ width: "initial" }}
                      >
                        <option value="0">Select Company</option>
                        <option value="-1">All Companies</option>
                        {!companyloading && companydata.companies
                          ? companydata.companies.map((company, index) => (
                              <option key={index} value={company.id}>
                                {company.name}
                              </option>
                            ))
                          : null}
                      </select>
                      {/* Select Date Mode */}
                      <select
                        className="form-control"
                        id="company"
                        name="company"
                        value={isYearly}
                        onChange={(e) => handlePeriodChange(e)}
                        style={{ width: "initial" }}
                      >
                        <option value={true}>{"Yearly"}</option>
                        <option value={false}>{"Monthly"}</option>
                      </select>
                    </div>
                    <div className="">
                      {/* pdf download button */}
                      <button
                        className="btn btn-outline-secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSaveToPDF("printableArea");
                        }}
                      >
                        <i
                          className="fas ms-2 fa-file-download"
                          style={{ marginRight: "10px" }}
                        ></i>
                        Pdf
                      </button>

                      {/* print file button */}
                      <button
                        className="btn btn-outline-secondary mx-2"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePrint2("printableArea");
                        }}
                      >
                        <i
                          className="fas ms-2 fa-print"
                          style={{ marginRight: "10px" }}
                        ></i>
                        Print
                      </button>

                      {/* Save file button */}
                      {isDisabled && statusTotalCompanyCost && (
                        <ReactTooltip id="statusTip" place="top" effect="solid">
                          Reports for the current month have already been saved
                        </ReactTooltip>
                      )}

                      <a href="#" data-tip data-for="statusTip">
                        <button
                          disabled={
                            isLoading ||
                            (isDisabled && statusTotalCompanyCost !== "loading")
                          }
                          className="btn btn-outline-secondary mx-2"
                          onClick={(e) => {
                            setIsLoading(true);
                            toast.promise(
                              () => {
                                return axios
                                  .post(
                                    `${NEXT_URL}/api/companyFinancialReports`,
                                    dataTotalCompanyCost.data
                                  )
                                  .then(function (response) {
                                    console.log(response);
                                    setIsLoading(false);
                                    setIsDisabled(true);
                                  })
                                  .catch(function (error) {
                                    console.log(error);
                                  });
                              },
                              {
                                pending: "Saving...",
                                success: "Saved Successfully",
                                error: "Saving Failed",
                              }
                            );
                            e.preventDefault();
                          }}
                        >
                          {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                          />
                        </svg> */}
                          {isLoading ? "Saving..." : "Save All"}
                        </button>
                      </a>

                      {/* transfer file button */}
                      <button
                        className="btn btn-primary"
                        disabled
                        onClick={(e) => {
                          e.preventDefault();
                          console.log("transfer qb pressed");
                        }}
                      >
                        Transfer to Quickbooks
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            {/* Company Report Card */}

            <div className="card-body" style={{ backgroundColor: "#F0F0F0" }}>
              {companyId !== 0 && (
                <div
                  id="printableArea"
                  style={{
                    width: "85%",
                    minHeight: 100,
                    overflow: "hidden",
                    padding: 10,
                    backgroundColor: "rgb(255, 255, 255)",
                    margin: "0 auto",
                    boxShadow: "0 0 10px rgba(0,0,0,0.6)",
                  }}
                >
                  {companyId !== -1 && (
                    <CompanyReportCard
                      company={company}
                      companyId={companyId}
                      isYearly={isYearly}
                      loading={loading}
                      data={data}
                    />
                  )}
                  {companyId === -1 &&
                    !companyloading &&
                    companydata &&
                    console.log(companydata)}
                  {companyId === -1 &&
                    !companyloading &&
                    companydata &&
                    companydata.companies.map((company) => {
                      return (
                        <CompanyReportCard
                          company={company}
                          companyId={company.id}
                          isYearly={isYearly}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyReport;
