import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";
import RowRegulatoryTrackerTotal from "./RowRegulatoryTrackerTotal";
export default function FormSection5Total({
  setFieldValue,
  raBenchmark,
  rmBenchmark,
  rrBenchmark,
  cfBenchmark,
}) {
  return (
    <>
      <HeadingForm title="5 &nbsp;&nbsp;&nbsp;&nbsp;Totals" />
      {/* Form Row */}
      <div className="grid grid-cols-12 gap-10 pt-4 pb-7">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
          <label className="block uppercase tracking-wide text-xs font-bold my-2"></label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Max Score
          </label>
        </div>
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            Benchmark
          </label>
        </div>
      </div>
      {/* End Row 3*/}

      <RowRegulatoryTrackerTotal
        title={"1"}
        maxScore={"20"}
        benchmark={raBenchmark}
      />
      <RowRegulatoryTrackerTotal
        title={"2"}
        maxScore={"22"}
        benchmark={rmBenchmark}
      />
      <RowRegulatoryTrackerTotal
        title={"3"}
        maxScore={"30"}
        benchmark={rrBenchmark}
      />
      <RowRegulatoryTrackerTotal
        title={"4"}
        maxScore={"28"}
        benchmark={cfBenchmark}
      />
    </>
  );
}
