import React from 'react';


const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage, size }) => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginationSize = size === '' || size === undefined ? '' : ` pagination-${size}`;

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className={`pagination${paginationSize}`} style={{ justifyContent: 'flex-end' }}>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button onClick={() => paginate(currentPage - 1)} className='page-link'>
                    &lt;&lt;&nbsp;{size === '' || size === undefined ? 'previous' : ''}
                </button>
            </li>
            {pageNumbers.map(number => (
                <li key={number} className={`page-item ${currentPage === number ? 'active' : null}`}>
                    <button onClick={() => paginate(number)} className='page-link'>
                        {number}
                    </button>
                </li>
            ))}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button onClick={() => paginate(currentPage + 1)} className='page-link'>{size === '' || size === undefined ? 'next' : ''}&nbsp;&gt;&gt;</button>
            </li>
        </nav >
    )
}

export default Pagination;