import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { colors } from "../utils/constants";
import FrequencyColorLegenda from "./../components/PageFrequency/FrequencyColorLegenda";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getDashboardFrequencyQuery = gql`
  query {
    frequenciesPerCompanies {
      name
      serviceId
      service
      downlinkStart
      downlinkEnd
      uplinkStart
      uplinkEnd
      company
      companyId
      uplinkLow
      uplinkHigh
      downlinkLow
      downlinkHigh
    }
    services {
      id
      name
    }
  }
`;

const getCompanyColor = (id) => {
  if (id === 2) return colors.smitComs;
  if (id === 3) return colors.criticalComunicationsCaribbean;
  if (id === 1002) return colors.antelecom;
  if (id === 1003) return colors.wirelessTelecommunicationNetwork;
  if (id === 1004) return colors.coralTelecom;
  if (id === 1005) return colors.radComm;
  if (id === 1006) return colors.newTechnologyGroup;
  if (id === 1007) return colors.telCell;
  if (id === 1008) return colors.netStar;
  if (id === 1010) return colors.caribbeanBroadcastingSystem;
  if (id === 1024) return colors.freeSpectrum;
  if (id === 2) return colors.smitComs;
  if (id === 2) return colors.smitComs;
  if (id === 2) return colors.smitComs;

  if (id === 1010) return colors.CaribbeanBroadcastingSystem;
  if (id === 1011) return colors.Dimisio;
  if (id === 1012) return colors.MediaOneCorparation;
  if (id === 1013) return colors.Laser101;
  if (id === 1014) return colors.Oissis;
  if (id === 1015) return colors.LighthouseBroadcstingNetwork;
  if (id === 1016) return colors.SintMaartenBroadcastingNetwork;
  if (id === 1017) return colors.TheVoiceofSintMaarten;
  if (id === 1018) return colors.Hi5Broadcasting;
  if (id === 1019) return colors.JamesBroadcasting;
  if (id === 1020) return colors.SintMaartenGovernment;
  if (id === 1021) return colors.trueCommunications;
  if (id === 1023) return colors.WilsonBroadcasting;

  return "#F00";
};

const DashboardFrequency = ({ user }) => {
  const [selectedServiceId, setSelectedServiceId] = useState(0);
  const [frequencies, setFrequencies] = useState([]);
  const { loading, data } = useQuery(getDashboardFrequencyQuery, {
    fetchPolicy: "network-only",
  });

  console.log({ frequencies });

  useEffect(() => {
    if (data) {
      let filteredData =
        user.role.name === "OPERATOR"
          ? data.frequenciesPerCompanies.filter(
              (freq) => freq.companyId === user.u_company
            )
          : data.frequenciesPerCompanies;

      transfilterObj(filteredData, selectedServiceId).then((x) => {
        setFrequencies(x);
      });
    }
  }, [data, selectedServiceId]);

  const transfilterObj = (data, serviceId) => {
    var promise = new Promise(function (resolve, reject) {
      setTimeout(() => {
        let arr = [];
        data.map((item) => {
          let frequenciesPerCompanies = {
            name: item.name,
            downlinkStart: item.downlinkStart,
            downlinkEnd: item.downlinkEnd,
            uplinkStart: item.uplinkStart,
            uplinkEnd: item.uplinkEnd,
            serviceId: item.serviceId,
            companies: [],
          };
          if (arr.find((a) => a.name === item.name))
            return arr
              .find((a) => a.name === item.name)
              .companies.push({
                company: item.company,
                uplinkLow: item.uplinkLow,
                uplinkHigh: item.uplinkHigh,
                downlinkLow: item.downlinkLow,
                downlinkHigh: item.downlinkHigh,
                backgroundColor: getCompanyColor(item.companyId),
              });
          else
            return arr.push({
              ...frequenciesPerCompanies,
              companies: [
                {
                  company: item.company,
                  uplinkLow: item.uplinkLow,
                  uplinkHigh: item.uplinkHigh,
                  downlinkLow: item.downlinkLow,
                  downlinkHigh: item.downlinkHigh,
                  backgroundColor: getCompanyColor(item.companyId),
                },
              ],
            });
        });
        resolve(
          parseInt(serviceId) > 0
            ? arr.filter((a) => a.serviceId === parseInt(serviceId))
            : arr
        );
      }, 1000);
    });
    return promise;
  };

  return loading ? (
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Frequencies</h1>
      <div className="row">
        <div className="col-lg-12 mb-12">
          <div className="card shadow mb-4">
            <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">Frequencies</h6>

              <div className="d-flex justify-content-between ">
                {/* <h1 className="h3 mb-0 text-gray-800">Frequencies</h1> */}
                <select
                  className="form-select rounded p-1 px-2 text-secondary m-2 "
                  id="services"
                  name="services"
                  aria-label="Select Service"
                  onChange={(e) => setSelectedServiceId(e.target.value)}
                  value={selectedServiceId}
                  // onChange={(e) => setService(e.target.value)}
                >
                  <option value={0}>All Frequencies</option>
                  {data.services.map((service) => (
                    <option value={service.id} key={service.id}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="card-body" style={{ padding: "20px" }}>
              <FrequencyColorLegenda />
              <div className="row">
                <div className="col-lg-12 mb-12">
                  {frequencies.map((item, index) => {
                    let datasets = item.companies.reduce(
                      (array, val, index) => {
                        array.push({
                          label: val.company,
                          data: [[val.uplinkLow, val.uplinkHigh]],
                          backgroundColor: val.backgroundColor,
                        });
                        array.push({
                          label: val.company,
                          data: [[val.downlinkLow, val.downlinkHigh]],
                          backgroundColor: val.backgroundColor,
                        });
                        return array;
                      },
                      []
                    );

                    return (
                      <div className="mb-4 p-2" key={item.name}>
                        <h4
                          style={{ color: "white", fontSize: ".8rem" }}
                          className="small font-weight-bold mx-auto badge p-1 bg-primary "
                        >
                          {item.name}
                        </h4>
                        <span className="badge bg-info text-dark"></span>
                        {
                          <Bar
                            height={50}
                            data={{
                              labels: [item.name],
                              datasets: datasets,
                            }}
                            options={{
                              maintainAspectRatio: true,
                              indexAxis: "y",
                              scales: {
                                x: {
                                  min: Math.min(
                                    item.uplinkStart,
                                    item.downlinkStart
                                  ),
                                  max: Math.max(
                                    item.uplinkEnd,
                                    item.downlinkEnd
                                  ),
                                },
                                y: {
                                  stacked: true,
                                },
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                title: {
                                  display: false,
                                  text: "",
                                },
                              },
                            }}
                          />
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFrequency;
