import React, { useState, useEffect, useRef } from "react";
import { BarChartMonthlyCostPerCompany } from "../components/PageAnalyticsFinancial/BarChartMonthlyCostPerCompany";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import { NEXT_URL } from "../config/index";
import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const PageAnalyticsFinancial = () => {
  //StateVariables
  const [companyId, setCompanyId] = useState(-1);

  //ConstantVariables
  //   const router = useRouter();
  const queryClient = useQueryClient();

  //StateVariables
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSearch, setPageSearch] = useState("");
  const [pageSortBy, setPageSortBy] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [pageYear, setPageYear] = useState(null);
  const [selected, setSelected] = useState(-1);
  const [startDate, setStartDate] = useState(new Date());

  //Constants
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  //FetchFunctions
  const fetchReportsByYearNMonth = async () => {
    return axios.get(
      `${NEXT_URL}/api/companyFinancialReports/byYearNMonth?year=${startDate.getFullYear()}`
    );
  };

  //QueryFunctions
  const {
    data: dataReportByYearNMonths,
    status: statusReportByYearNMonths,
    error: errorReportByYearNMonths,
    refetch: refetchReportByYearNMonths,
  } = useQuery([`CompanyReportReportsByYearNMonth`], fetchReportsByYearNMonth, {
    keepPreviousData: true,
    refetchOnMount: true,
    onSuccess: (data) => {
      console.log("Fetched");
    },
  });
  //QueryResultsCheck
  // console.log({
  //   PageAnalyticsFinancialQueryResultsCheck: { dataCompanyReports },
  // });

  return statusReportByYearNMonths === "loading" ? (
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Financial Analytics</h1>
      <div class="grid grid-cols-6">
        <div className="w-48 mt-1 border-2 border-blue-500 mb-4">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showYearPicker
            dateFormat="yyyy"
          />
        </div>
        <div>
          <button
            className=" items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => refetchReportByYearNMonths()}
          >
            Search
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-12">
          <div className="card shadow mb-4">
            {/* Card Header */}
            <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">
                Total Monthly Costs per Company - {startDate.getFullYear()}
              </h6>

              <div className="d-flex justify-content-between "></div>
            </div>
            {/* Card Body */}
            <div className="card-body" style={{ padding: "20px" }}>
              <BarChartMonthlyCostPerCompany data={dataReportByYearNMonths} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAnalyticsFinancial;
