import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  addCountryMutation,
  updateCountryMutation,
  deleteCountryMutation,
  getCountriesQuery,
} from "../../../queries/queries";

import Pagination from "../../Layout/Pagination";
import CountryModal from "./ModalCountry";

const initialValues = { id: 0, name: "", code: "" };

const Country = () => {
  const { loading, data } = useQuery(getCountriesQuery);
  const [addCountry] = useMutation(addCountryMutation);
  const [updateCountry] = useMutation(updateCountryMutation);
  const [deleteCountry] = useMutation(deleteCountryMutation);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = countries.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = countries.length;

  useEffect(() => {
    if (!loading && data) {
      setCountries(data.countries);
    }
  }, [loading, data]);

  const deleteItem = (id) => {
    deleteCountry({
      variables: {
        id,
      },
      refetchQueries: [{ query: getCountriesQuery }],
    });
  };

  const saveItem = () => {
    country.id
      ? updateCountry({
          variables: {
            id: country.id,
            name: country.name,
            code: country.code,
          },
          refetchQueries: [{ query: getCountriesQuery }],
        })
      : addCountry({
          variables: {
            name: country.name,
            code: country.code,
          },
          refetchQueries: [{ query: getCountriesQuery }],
        });
    closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCountry({ ...country, [name]: value });
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const openModal = (e, country) => {
    setCountry(country);
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };

  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownCountryMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownCountryMenuLink"]')
      .classList.remove("show");
  };

  return (
    <div className="card mb-4">
      <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
        <h6 className="m-0 font-weight-bold text-primary">Country</h6>
        <div className="dropdown no-arrow">
          <button
            className="button-link"
            id="dropdownCountryMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={toggleDropdownMenu}
            onBlur={closeDropdown}
          >
            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
            aria-labelledby="dropdownCountryMenuLink"
            x-placement="bottom-end"
            style={{
              position: "absolute",
              willChange: "transform",
              top: 0,
              left: 0,
              transform: "translate3d(-156px, 19px, 0px)",
            }}
          >
            <div className="dropdown-header">Actions:</div>
            <button
              className="dropdown-item"
              onMouseDown={(e) => openModal(e, initialValues)}
              data-target="#countryModal"
            >
              New
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-striped table-condensed table-hover table-sm">
              <thead>
                <tr className="bg-primary text-white">
                  <td>name</td>
                  <td>code</td>
                  <td>
                    <span style={{ float: "right" }}>actions</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="2">Loading...</td>
                  </tr>
                ) : (
                  currentItems.map((country, index) => {
                    return (
                      <tr key={index}>
                        <td>{country.name}</td>
                        <td>{country.code}</td>
                        <td>
                          <div style={{ float: "right" }}>
                            <i
                              className="fas fa-fw fa-edit"
                              data-target="#countryModal"
                              onClick={(e) => openModal(e, country)}
                            ></i>
                            <i
                              className="fas fa-fw fa-trash"
                              onClick={() =>
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                ) && deleteItem(country.id)
                              }
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="dataTable_paginate"
            >
              {totalItems > itemsPerPage ? (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <CountryModal
        country={country}
        handleChange={handleChange}
        closeModal={closeModal}
        saveItem={saveItem}
      />
    </div>
  );
};

export default Country;
