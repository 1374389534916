import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  getCompaniesQuery,
  getAllUsersQuery,
  updateUserMutation,
  addUserMutation,
  deleteUserMutation,
} from "../queries/queries";
import UserModal from "./../components/PageSettings/PageSettingsUsers/ModalUsers";
import Pagination from "./../components/Layout/Pagination";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  role: { id: 0 },
};

const UsersOverview = () => {
  const { loading, error, data } = useQuery(getAllUsersQuery);
  const { data: companiesData, loading: companiesDataLoading } =
    useQuery(getCompaniesQuery);

  const [user, setUser] = useState(initialValues);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = !loading && data.users ? data.users.length : 0;

  const currentItems =
    !loading && data.users
      ? data.users.slice(indexOfFirstItem, indexOfLastItem)
      : null;

  const [updateUser] = useMutation(updateUserMutation, {
    variables: {
      id: parseInt(user.id),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roleId: parseInt(user.role.id),
      u_company: parseInt(user.u_company),
    },
    refetchQueries: [{ query: getAllUsersQuery }],
  });
  const [addUser] = useMutation(addUserMutation, {
    variables: {
      id: parseInt(user.id),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roleId: parseInt(user.role.id),
    },
    refetchQueries: [{ query: getAllUsersQuery }],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "role"
      ? setUser({ ...user, role: { id: parseInt(value) } })
      : setUser({ ...user, [name]: value });
  };

  const openModal = (e, user) => {
    setUser(user);
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const [deleteUser] = useMutation(deleteUserMutation);

  const deleteItem = async (id) => {
    try {
      await deleteUser({
        variables: {
          id: parseInt(id),
        },
        refetchQueries: [{ query: getAllUsersQuery }],
      });
    } catch (error) {}
  };
  const saveItem = async () => {
    user.id ? updateUser() : addUser();
    closeModal();
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownUserMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownUserMenuLink"]')
      .classList.remove("show");
  };

  if (loading && companiesDataLoading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (data && companiesData) {
    return (
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Users</h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownUserMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownUserMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#userModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-sm">
                <thead className="bg-primary text-white">
                  <tr>
                    <td>First Name</td>
                    <td>Last Name</td>
                    <td>Email</td>
                    <td>Role</td>
                    <td>
                      <span style={{ float: "right" }}>actions</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    currentItems.map((user) => {
                      return (
                        <tr key={user.id}>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>{user.email}</td>
                          <td>{user.role.name}</td>
                          <td>
                            <div style={{ float: "right" }}>
                              <i
                                className="fas fa-fw fa-edit"
                                data-target="#userModal"
                                onClick={(e) => openModal(e, user)}
                              ></i>
                              <i
                                className="fas fa-fw fa-trash"
                                onClick={(e) =>
                                  window.confirm(
                                    "Are you sure you wish to delete this item?"
                                  ) && deleteItem(e, user.id)
                                }
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3">Loading...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {data ? (
          <UserModal
            user={user}
            roles={data.roles}
            companies={companiesData.companies}
            handleChange={handleChange}
            closeModal={closeModal}
            saveItem={saveItem}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default UsersOverview;
