import { gql } from "@apollo/client";

const getStationsQuery = gql`
  {
    companies {
      id
      name
    }
    services {
      id
      name
    }
    stationTypes {
      id
      name
      type
    }
    sites {
      id
      description
    }
    towerTypes {
      id
      name
    }
    stations {
      id
      description
      latitude
      longitude
      height
      remarks
      company {
        id
        name
      }
      site {
        id
        description
      }
      stationType {
        id
        name
        type
      }
      towerType {
        id
        name
      }
      sectors {
        id
        description
      }
      sketchfabModels {
        name
        modelUID
      }
      siteStatus
      siteIDUMTS
      siteIDLTE
      antennas {
        id
        code
        pix4dLink
        description
        company {
          name
        }
        frequency {
          name
          band {
            name
            service {
              id
              name
              technology {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const getStationQuery = gql`
  query ($id: Int) {
    station(id: $id) {
      id
      description
      latitude
      longitude
      height
      remarks
      company {
        id
        name
      }
      site {
        id
        description
      }
      towerType {
        id
        name
      }
      stationType {
        id
        name
        type
      }
      sectors {
        id
        description
      }
      siteStatus
      siteIDUMTS
      siteIDLTE
    }
  }
`;

const addStationMutation = gql`
  mutation AddStation(
    $description: String!
    $latitude: Float
    $longitude: Float
    $height: Int
    $remarks: String
    $companyId: Int
    $siteId: Int
    $towerTypeId: Int
    $stationTypeId: Int
    $sectors: [SectorInput]
    $siteStatus: String
    $siteIDUMTS: String
    $siteIDLTE: String
  ) {
    addStation(
      description: $description
      latitude: $latitude
      longitude: $longitude
      height: $height
      remarks: $remarks
      companyId: $companyId
      siteId: $siteId
      towerTypeId: $towerTypeId
      stationTypeId: $stationTypeId
      sectors: $sectors
      siteStatus: $siteStatus
      siteIDUMTS: $siteIDUMTS
      siteIDLTE: $siteIDLTE
    ) {
      id
      description
    }
  }
`;

const updateStationMutation = gql`
  mutation UpdateStation(
    $id: Int!
    $description: String
    $latitude: Float
    $longitude: Float
    $height: Int
    $remarks: String
    $companyId: Int
    $siteId: Int
    $towerTypeId: Int
    $stationTypeId: Int
    $sectors: [SectorInput]
    $siteStatus: String
    $siteIDUMTS: String
    $siteIDLTE: String
  ) {
    updateStation(
      id: $id
      description: $description
      latitude: $latitude
      longitude: $longitude
      height: $height
      remarks: $remarks
      companyId: $companyId
      siteId: $siteId
      towerTypeId: $towerTypeId
      stationTypeId: $stationTypeId
      sectors: $sectors
      siteStatus: $siteStatus
      siteIDUMTS: $siteIDUMTS
      siteIDLTE: $siteIDLTE
    ) {
      id
      description
    }
  }
`;

const deleteStationMutation = gql`
  mutation DeleteStation($id: Int!) {
    deleteStation(id: $id) {
      id
      description
    }
  }
`;

export const stationService = {
  getStationsQuery,
  getStationQuery,
  addStationMutation,
  updateStationMutation,
  deleteStationMutation,
};
