import * as Yup from "yup";

export const validationVergunning = {
  Relatienummer: Yup.number().nullable().required("This field is required"),
  VergunningPersoonType: Yup.string()
    .nullable()
    .required("This field is required"),
  Vergunningsperiode: Yup.string()
    .nullable()
    .required("Please select a valid period"),
  VergunningsperiodeVan: Yup.string()
    .nullable()
    .notRequired()
    .when("Vergunningsperiode", {
      is: (val) => val !== "5 Jaar" && val !== undefined,
      then: Yup.string().nullable().required("This field is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  VergunningsperiodeTot: Yup.string()
    .nullable()
    .notRequired()
    .when("Vergunningsperiode", {
      is: (val) => val !== "5 Jaar" && val !== undefined,
      then: Yup.string().nullable().required("This field is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
};

export const validationRechtsPersoon = {
  rechtspersoonStatutaireNaam: Yup.string()
    .nullable()
    .nullable()
    .required("This field is required"),
  rechtspersoonHandelsnaam: Yup.string()
    .nullable()
    .required("This field is required"),
  rechtspersoonKVKLocatie: Yup.string()
    .nullable()
    .required("This field is required"),
  rechtspersoonKvK_nummer: Yup.string()
    .nullable()
    .required("This field is required"),
  rechtspersoonVestigingsnummer: Yup.string()
    .nullable()
    .required("This field is required"),
};

export const validationNatuurlijkPersoon = {
  natuurlijkPersoonBurgerservicenummerInNederland: Yup.string()
    .nullable()
    .required("This field is required"),
  natuurlijkPersoonVoornamen: Yup.string()
    .nullable()
    .required("This field is required"),
  natuurlijkPersoonVoorvoegsel: Yup.string()
    .nullable()
    .required("This field is required"),
  natuurlijkPersoonGeslachtsnaam: Yup.string()
    .nullable()
    .required("This field is required"),
  natuurlijkPersoonGeboortedatum: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationWoonVestingAddress = {
  WoonVestigingsadresStraat: Yup.string()
    .nullable()
    .required("This field is required"),
  WoonVestigingsadresHuisnummer: Yup.string()
    .nullable()
    .required("This field is required"),
  WoonVestigingsadresHuisnummertoevoeging: Yup.string()
    .nullable()
    .required("This field is required"),
  // WoonVestigingsadresPostcode: Yup.string()
  //   .nullable()
  //   .required("This field is required"),
  WoonVestigingsadresPlaats: Yup.string()
    .nullable()
    .required("This field is required"),
  WoonVestigingsadresLand: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationCorrespondentie = {
  CorrespondentieStraatOfPostbus: Yup.string()
    .nullable()
    .required("This field is required"),
  CorrespondentieHuisOfPostbusnummer: Yup.string()
    .nullable()
    .required("This field is required"),
  CorrespondentieHuisnummertoevoeging: Yup.string()
    .nullable()
    .required("This field is required"),
  // CorrespondentiePostcode: Yup.string()
  //   .nullable()
  //   .required("This field is required"),
  CorrespondentiePlaats: Yup.string()
    .nullable()
    .required("This field is required"),
  CorrespondentieLand: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationContact = {
  Contactpersoon: Yup.string().nullable().required("This field is required"),
  Telefoonnummer: Yup.string().nullable().required("This field is required"),
  mailadres: Yup.string().email().nullable().required("This field is required"),
  // URLVanUwWebsite: Yup.string()
  //   .nullable()
  //   .required("This field is required")
  //   .matches(
  //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //     "Enter correct url!"
  //   )
  //   .required("Please enter website"),
};
export const validationStraalVerbinding = {
  StraalverbindingLinkIDAanvrager: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingSoortVerbinding: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingInclusiefPassieveReflector: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingModulatiesoort: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingPadlengte: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingFabricaat_typeZendontvanger: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingFrequentiebandOndergrens: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingFrequentiebandBovengrens: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingBandbreedte: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingDatasnelheid: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingAutomaticTransmittedPowerControl: Yup.string().nullable(),
  StraalverbindingAdaptieveModulator: Yup.string()
    .nullable()
    .required("This field is required"),
  StraalverbindingGewensteBeschikbaarheid: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationZender = {
  ZenderStationsnaam1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderStationsnaam2: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderStraat1: Yup.string().nullable().required("This field is required"),
  ZenderStraat2: Yup.string().nullable().required("This field is required"),
  ZenderPostcodeEnPlaats1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderPostcodeEnPlaats2: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenOosterlengteGraden1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenOosterlengteGraden2: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenOosterlengteMinuten1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenOosterlengteMinuten2: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenOosterlengteSeconden1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenOosterlengteSeconden2: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenNoorderbreedteGraden1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenNoorderbreedteGraden2: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenNoorderbreedteMinuten1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenNoorderbreedteMinuten2: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenNoorderbreedteSeconden1: Yup.string()
    .nullable()
    .required("This field is required"),
  ZenderCoordinatenNoorderbreedteSeconden2: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationAntenne = {
  AntenneFabricaatType1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneFabricaatType2: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneOvereenkomstigLijstMetAntenneprofielen1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneOvereenkomstigLijstMetAntenneprofielen2: Yup.string()
    .nullable()
    .required("This field is required"),
  AntennePolarisatie1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntennePolarisatie2: Yup.string()
    .nullable()
    .required("This field is required"),
  Antennehoogte1: Yup.string().nullable().required("This field is required"),
  Antennehoogte2: Yup.string().nullable().required("This field is required"),
  AntenneHoofdstraalrichting1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneHoofdstraalrichting2: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneKabelVerliezen1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneKabelVerliezen2: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneOpeningshoek1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneOpeningshoek2: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneGain1: Yup.string().nullable().required("This field is required"),
  AntenneGain2: Yup.string().nullable().required("This field is required"),
  AntenneZendfrequentie1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneZendfrequentie2: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneZendvermogen1: Yup.string()
    .nullable()
    .required("This field is required"),
  AntenneZendvermogen2: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationPassieveReflector = {
  PassieveReflectorStationsnaam: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorStraat: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorPostcodeEnPlaats: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorCoordinatenOosterlengteGraden: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorCoordinatenOosterlengteMinuten: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorCoordinatenOosterlengteSeconden: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorCoordinatenNoorderbreedteGraden: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorCoordinatenNoorderbreedteMinuten: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorCoordinatenNoorderbreedteSeconden: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationPassiverReflectorAntenna = {
  PassieveReflectorAntenneFabricaatType1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneFabricaatType2: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen2: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntennePolarisatie1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntennePolarisatie2: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneAntennehoogte1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneAntennehoogte2: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneHoofdstraalrichting1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneHoofdstraalrichting2: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneKabelverliezen1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneKabelverliezen2: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneOpeningshoek1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneOpeningshoek2: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneGain1: Yup.string()
    .nullable()
    .required("This field is required"),
  PassieveReflectorAntenneGain2: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationFacturatie = {
  FacturatieContactpersoonOfAfdeling: Yup.string()
    .nullable()
    .required("This field is required"),
  FacturatieFactuuradres: Yup.string()
    .nullable()
    .required("This field is required"),
  // FacturatiePostcode: Yup.string()
  //   .nullable()
  //   .required("This field is required"),
  FacturatiePlaats: Yup.string().nullable().required("This field is required"),
  FacturatieLand: Yup.string().nullable().required("This field is required"),
  FacturatieInternOrdernummer: Yup.string()
    .nullable()
    .required("This field is required"),
  FacturatieBetalingswijze: Yup.string()
    .nullable()
    .required("This field is required"),
};
export const validationBijlagen = {
  BijlagenKopieInschrijvingBuitenlandseKvK: Yup.string()
    .nullable()
    .required("This field is required"),
  BijlagenKopieIdentificatiebewijs: Yup.string()
    .nullable()
    .required("This field is required"),
  BijlagenPlattegrondAntennelocatie: Yup.string()
    .nullable()
    .required("This field is required"),
  BijlagenTechnischeParametersAntenne: Yup.string()
    .nullable()
    .required("This field is required"),
  BijlagenTechnischeParametersReflectorAntenne: Yup.string()
    .nullable()
    .required("This field is required"),
  MachtigingVertegenwoordigingDoorDerden: Yup.string()
    .nullable()
    .required("This field is required"),
  ExtraToelichting: Yup.string().nullable().required("This field is required"),
};
export const validationOndertekening = {
  OndertekeningNaam: Yup.string().nullable().required("This field is required"),
  OndertekeningDatum: Yup.string()
    .nullable()
    .required("This field is required"),
  OndertekeningPlaats: Yup.string()
    .nullable()
    .required("This field is required"),
  OndertekeningHandtekening: Yup.string()
    .nullable()
    .required("This field is required"),
};

export const validationSchema = Yup.object().shape({
  ...validationVergunning,
  ...validationRechtsPersoon,
  ...validationNatuurlijkPersoon,
  ...validationWoonVestingAddress,
  ...validationCorrespondentie,
  ...validationContact,
  ...validationStraalVerbinding,
  ...validationZender,
  ...validationAntenne,
  ...validationPassieveReflector,
  ...validationPassiverReflectorAntenna,
  ...validationFacturatie,
  ...validationBijlagen,
  ...validationOndertekening,
});
