import React, { useMemo } from "react";
import Select from "react-select";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const getCompaniesQuery = gql`
  query {
    companies {
      id
      name
    }
  }
`;

const CompaniesSelect = ({ setSelectedCompany }) => {
  const { data, loading } = useQuery(getCompaniesQuery);

  const companiesData = useMemo(() => {
    const companies =
      data &&
      data.companies &&
      data.companies.map((company) => ({
        label: company.name,
        value: company.id,
      }));
    if (companies) {
      companies.unshift({ label: "All Companies", value: -1 });
    }

    return companies;
  }, [data]);

  return (
    <Select
      isLoading={loading}
      id="companies"
      name="companies"
      className="form-select rounded px-2 text-secondary"
      // value={-1}
      onChange={(option) => {
        setSelectedCompany(option.value);
      }}
      placeholder={"All Companies"}
      options={companiesData}
    />
  );
};

export default CompaniesSelect;
