import React from "react";
import "./company-modal.styles.css";

const CompanyModal = ({
  company,
  countries,
  handleChange,
  saveItem,
  closeModal,
}) => {
  return (
    <div
      className="modal fade"
      id="companyModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              company
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeModal}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label forhtml="company_name" className="col-form-label">
                  Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company_name"
                  name="name"
                  onChange={(e) => handleChange(e)}
                  value={company.name || ""}
                />
                <label forhtml="country" className="col-form-label">
                  Country:
                </label>
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  onChange={(e) => handleChange(e)}
                  value={company.country ? company.country.id : 0}
                >
                  <option value="0">-- choose--</option>
                  {countries.map((country, index) => (
                    <option key={index} value={parseInt(country.id)}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <label forhtml="address" className="col-form-label">
                  Address:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  onChange={(e) => handleChange(e)}
                  value={company.address || ""}
                />
                <label forhtml="code" className="col-form-label">
                  Zip/Postalcode:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="code"
                  name="code"
                  onChange={(e) => handleChange(e)}
                  value={company.code || ""}
                />
                <label forhtml="coc" className="col-form-label">
                  Chamber of Commerce:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="coc"
                  name="coc"
                  onChange={(e) => handleChange(e)}
                  value={company.coc || ""}
                />
                <label forhtml="quickBooksId" className="col-form-label">
                  QuickBooks ID:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="quickBooksId"
                  name="quickBooksId"
                  onChange={(e) => handleChange(e)}
                  value={company.quickBooksId || ""}
                />
                <label forhtml="comments" className="col-form-label">
                  comments:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="comments"
                  name="comments"
                  onChange={(e) => handleChange(e)}
                  value={company.comments || ""}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={saveItem}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyModal;
