import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  getBrandsQuery,
  updateBrandMutation,
  addBrandMutation,
  deleteBrandMutation,
} from "../../../queries/queries";

import Pagination from "../../Layout/Pagination";
import BrandModal from "./ModalBrand";

const initialValues = { id: 0, name: "" };

const BrandsOverview = () => {
  const [brand, setBrand] = useState(initialValues);
  const { loading, data } = useQuery(getBrandsQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalItems = !loading && data.brands ? data.brands.length : 0;

  const currentItems =
    !loading && data.brands
      ? data.brands.slice(indexOfFirstItem, indexOfLastItem)
      : null;
  const [updateBrand] = useMutation(updateBrandMutation, {
    variables: {
      id: parseInt(brand.id),
      name: brand.name,
    },
    refetchQueries: [{ query: getBrandsQuery }],
  });
  const [addBrand] = useMutation(addBrandMutation, {
    variables: {
      name: brand.name,
    },
    refetchQueries: [{ query: getBrandsQuery }],
  });

  const [deleteBrand] = useMutation(deleteBrandMutation);

  const deleteItem = async (id) => {
    try {
      await deleteBrand({
        variables: {
          id: parseInt(id),
        },
        refetchQueries: [{ query: getBrandsQuery }],
      });
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrand({ ...brand, [name]: value });
  };
  const openModal = (e, brand) => {
    setBrand(brand);
    //document.querySelector('body').classList.add('modal-open');
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };
  const saveItem = async () => {
    brand.id ? updateBrand() : addBrand();
    closeModal();
  };
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    document.querySelector(".modal-backdrop").remove();
  };
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownBrandMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownBrandMenuLink"]')
      .classList.remove("show");
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Brands</h6>
          <div className="dropdown no-arrow">
            <button
              className="button-link"
              id="dropdownBrandMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={toggleDropdownMenu}
              onBlur={closeDropdown}
            >
              <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownBrandMenuLink"
              x-placement="bottom-end"
              style={{
                position: "absolute",
                willChange: "transform",
                top: 0,
                left: 0,
                transform: "translate3d(-156px, 19px, 0px)",
              }}
            >
              <div className="dropdown-header">Actions:</div>
              <button
                className="dropdown-item"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#brandModal"
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-condensed table-hover table-sm">
                <thead>
                  <tr className="bg-primary text-white">
                    <td>Brand name</td>
                    <td style={{ float: "right" }}>actions</td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="2">Loading...</td>
                    </tr>
                  ) : currentItems.length > 0 ? (
                    currentItems.map((brand, index) => {
                      return (
                        <tr key={index}>
                          <td>{brand.name}</td>
                          <td style={{ float: "right" }}>
                            <i
                              className="fas fa-fw fa-edit"
                              data-target="#brandModal"
                              onClick={(e) => openModal(e, brand)}
                            ></i>
                            <i
                              className="fas fa-fw fa-trash"
                              onClick={() =>
                                window.confirm(
                                  "Are you sure you wish to delete this item?"
                                ) && deleteItem(brand.id)
                              }
                            ></i>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2">No Brands available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                    size="sm"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BrandModal
        brand={brand}
        handleChange={handleChange}
        closeModal={closeModal}
        saveItem={saveItem}
      />
    </>
  );
};
export default BrandsOverview;
