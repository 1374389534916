import React from 'react';


const UserModal = ({ user, roles, companies, handleChange, closeModal, saveItem }) => {
    return (
      <div
        className="modal fade"
        id="userModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                User
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-row">
                  <div className="form-group col-sm-12">
                    <label forhtml="firstName" className="col-form-label">
                      First Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      onChange={(e) => handleChange(e)}
                      value={user.firstName}
                    />
                  </div>
                  <div className="form-group col-sm-12">
                    <label forhtml="lastName" className="col-form-label">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      onChange={(e) => handleChange(e)}
                      value={user.lastName}
                    />
                  </div>
                  <div className="form-group col-sm-12">
                    <label forhtml="email" className="col-form-label">
                      Email:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      onChange={(e) => handleChange(e)}
                      value={user.email}
                    />
                  </div>
                  <div className="form-group col-sm-12">
                    <label forhtml="email" className="col-form-label">
                      Password:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={(e) => handleChange(e)}
                      value={user.password}
                    />
                  </div>
                  <div className="form-group col-sm-12">
                    <label forhtml="role" className="col-form-label">
                      Role:
                    </label>
                    <select
                      className="form-control"
                      id="role"
                      name="role"
                      onChange={(e) => handleChange(e)}
                      value={user.role ? user.role.id : ""}
                    >
                      <option value="0">--choose--</option>
                      {roles.map((role, index) => (
                        <option key={index} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {user.role.name === "OPERATOR" && <div className="form-group col-sm-12">
                    <label forhtml="role" className="col-form-label">
                      Company:
                    </label>
                    <select
                      className="form-control"
                      id="u_company"
                      name="u_company"
                      onChange={(e) => handleChange(e)}
                      value={user.u_company ? user.u_company : ""}
                    >
                      <option value="0">--choose--</option>
                      {companies.map((company, index) => (
                        <option key={index} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </select>
                  </div>}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={saveItem}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default UserModal;