export const COLUMNS = [
  // {
  //   maxWidth: 400,
  //   minWidth: 90,
  //   width: 90,
  //   Header: "Id",
  //   Footer: "Id",
  //   accessor: "id",
  //   disableFilters: true,
  //   sticky: "left",
  // },
  {
    maxWidth: 140,
    minWidth: 140,
    width: 140,
    Header: "Company",
    Footer: "Company",
    accessor: "company.name",
    sticky: "left",
  },
  {
    maxWidth: 140,
    minWidth: 140,
    width: 140,
    Header: "Site",
    Footer: "Company",
    accessor: "station.description",
    sticky: "left",
  },
  {
    maxWidth: 140,
    minWidth: 140,
    width: 140,
    Header: "Cell Name",
    Footer: "Company",
    accessor: "cellName",
    sticky: "left",
  },
  {
    maxWidth: 400,
    minWidth: 170,
    width: 170,
    Header: "H. Abv. Grnd",
    Footer: "Last Name",
    accessor: "heightAboveGround",
    sticky: "left",
  },
  {
    maxWidth: 400,
    minWidth: 140,
    width: 200,
    Header: "Model",
    Footer: "Last Name",
    accessor: "antennaType.model.name",
    sticky: "left",
  },
  {
    maxWidth: 400,
    minWidth: 160,
    width: 200,
    Header: "Antenna Type",
    Footer: "Last Name",
    accessor: "antennaType.name",
    sticky: "left",
  },
  {
    maxWidth: 400,
    minWidth: 140,
    width: 200,
    Header: "Frequency",
    Footer: "First Name",
    accessor: "contract.name",
    sticky: "left",
  },

  // {
  //   maxWidth: 400,
  //   minWidth: 140,
  //   width: 200,
  //   Header: "Description",
  //   Footer: "Last Name",
  //   accessor: "description",
  //   sticky: "left",
  // },
  // {
  //   maxWidth: 400,
  //   minWidth: 140,
  //   width: 200,
  //   Header: "Serial Nr",
  //   Footer: "Last Name",
  //   accessor: "serialNumber",
  //   sticky: "left",
  // },
  // {
  //   maxWidth: 400,
  //   minWidth: 140,
  //   width: 200,
  //   Header: "Freq Band",
  //   Footer: "Last Name",
  //   accessor: "frequency.name",
  //   sticky: "left",
  // },
];
