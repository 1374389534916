import React from 'react';


const AntennaTypeLookUpModal = ({ antennaType, handleChange, closeModal, saveItem }) => {
    return (
        <div className="modal fade" id="antennaTypeLookUpModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">AntennaType LookUp</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group col-sm-12">
                                <label forhtml="antennaTypeLookUp_name" className="col-form-label">Antenna Type Look Up name:</label>
                                <input type="text" className="form-control" id="antennaTypeLookUp_name" name="name" onChange={(e) => handleChange(e)} value={antennaType.name} />
                            </div>

                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AntennaTypeLookUpModal;