import { format } from "date-fns";

export const COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    disableFilters: true,
    sticky: "left",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    sticky: "left",
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
  {
    Header: "Report Year ",
    accessor: "createdYear",
    sticky: "left",
    maxWidth: 190,
    minWidth: 190,
    width: 190,
  },

  {
    Header: "Report Month",
    accessor: "createdMonth",
    sticky: "left",
    maxWidth: 160,
    minWidth: 160,
    width: 160,
    Cell: ({ value }) => {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[value - 1];
    },
  },
  {
    Header: "Total Guilders Yearly",
    accessor: "totalGuildersAllContractsYearly",
    sticky: "left",
    maxWidth: 160,
    minWidth: 160,
    width: 160,
  },
];
