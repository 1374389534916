import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";

import { useQuery, useLazyQuery } from "@apollo/client";

import Logo from "../../assets/isp_logo_black.png";
const company = {};

export default function CompanyReportCard({ data }) {
  //PropsCheck
  console.log({ CompanyReportCardPropsCheck: data });

  return (
    <>
      {true && (
        <div
          style={{
            margin: "80px auto",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "flex-end",
                borderBottom: "solid 1px black",
              }}
              className={`justify-between`}
            >
              <div style={{}}>
                <img alt="" src={Logo} style={{ width: 140, height: "auto" }} />
              </div>

              <div style={{}}>
                <h1>{data.companyName}</h1>
              </div>
              <div className="" style={{ width: 140, height: "auto" }}></div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                borderBottom: "solid 1px black",
              }}
              className="p-1"
            >
              <div style={{ color: "black", flex: 1 }}>
                <table>
                  <tbody>
                    {/* <tr>
                      <td>Company:</td>
                      <td>{company.name}</td>
                    </tr> */}
                    <tr>
                      <td>C.o.C:</td>
                      <td>{data.companyName}</td>
                    </tr>
                    <tr>
                      <td>Address:</td>
                      <td>{data.companyAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ color: "black", flex: 1, textAlign: "right" }}>
                Date: {new Date(data.createdDate).toDateString()}
              </div>
            </div>
            <div style={{ flex: 14 }}>
              <table
                style={{ width: "100%", color: "black" }}
                className="mb-5 mt-4"
              >
                <thead className="">
                  <tr
                    className={`bg-blue-500 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0`}
                  >
                    <th className="p-2 text-left">Fequency</th>
                    <th>LineFee</th>
                    <th>Down Link</th>
                    <th>Up Link</th>
                    <th>Bandwidth</th>
                    {/* <th>Unit Price</th> */}
                    <th>Price Guilders</th>
                    <th>Price Dollars</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Single Company Report */}
                  {true
                    ? data.calculatedCostsPerContract?.map((cost, index) => (
                        <tr key={index}>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.Frequency}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.lineFee}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.downlink}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.uplink}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {cost.totalBandwidth}
                          </td>
                          {/* <td>{new Intl.NumberFormat('nl-US', { style: 'currency', currency: 'ANG' }).format(cost.unitPrice)}</td> */}
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "ANG",
                            }).format(
                              true
                                ? cost.totalGuildersYearly
                                : cost.totalGuilders / 12
                            )}
                          </td>
                          <td className="border-grey-light border hover:bg-gray-100 p-2">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              true
                                ? cost.totalDollarsYearly
                                : cost.totalDollars / 12
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                borderTop: "solid 1px black",
              }}
            >
              <p>
                Totals:&nbsp;&nbsp;-&nbsp;
                {/* Single Company Report */}
                {!false && data && company.id !== -1
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "ANG",
                    }).format(data.totalGuildersAllContractsYearly)
                  : null}
                &nbsp;-&nbsp;
                {!false && data && company.id !== -1
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(data.totalDollarsAllContractsYearly)
                  : null}
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
