import React from 'react';


const StationTypeModal = ({ stationType, handleChange, closeModal, saveItem }) => {
    const types = ['Studio', 'Concrete Shelter', 'Mobile Shelter', 'Tower', 'Equipment Room'];
    const names = ['Tower', 'Structure']
    return (
        <div className="modal fade" id="stationTypeModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Station Type</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label forhtml="name" className="col-form-label">Name:</label>
                                    <select className={'form-control'} id="name" name="name" onChange={handleChange} value={stationType.name}>
                                        <option value="0" >--choose--</option>
                                        {

                                            names.map((name, index) => <option key={index} value={name} >{name}</option>)

                                        }
                                    </select>
                                </div>
                                <div className="form-group col-sm-12">
                                    <label forhtml="type" className="col-form-label">Type:</label>
                                    <select className={'form-control'} id="type" name="type" onChange={handleChange} value={stationType.type}>
                                        <option value="0" >--choose--</option>
                                        {

                                            types.map((type, index) => <option key={index} value={type} >{type}</option>)

                                        }
                                    </select>

                                </div>
                            </div>

                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StationTypeModal;