import React, { useState, useRef, useEffect } from "react";
import Transition from "../../utils/Transition";

export default function ModalPdf({
  contractPdf,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const trigger = useRef(null);
  const modalContent = useRef(null);
  const url = contractPdf.attachment;

  console.log({ contractPdf });

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !modalOpen ||
        modalContent.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setModalOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div>
      {/* Trigger Button */}
      <button
        ref={trigger}
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
        aria-controls="modal"
        className={` ${
          modalOpen && "bg-gray-400 text-white"
        } bg-transparent hover:bg-gray-400 my-1 text-gray-400 font-semibold hover:text-white py-2 px-4 border border-gray-400 hover:border-transparent rounded-md `}
      >
        {"PDF "} {contractPdf.attachment && <i className="fas fa-file-pdf"></i>}
      </button>

      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      {/* Modal dialog */}
      <Transition
        id="add-parcel-modal"
        className="fixed outline-black inset-0 z-50 flex items-center w-full h-screen justify-center transform px-32 py-12 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          className="bg-white overflow-hidden h-full w-full mx-20 my-20 rounded shadow-lg "
          ref={modalContent}
        >
          <div
            className="h-full"
            onFocus={() => setModalOpen(true)}
            // onBlur={() => setModalOpen(false)}
          >
            <div className="relative h-full">
              <div className="bg-white rounded shadow-lg border flex flex-col overflow-hidden relative h-full">
                {/* Modal Header */}
                <div className="py-3 px-4 text-xl border-b font-medium flex justify-between text-gray-400 bg-white w-full">
                  <h3 className="mb-0">PDF</h3>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setModalOpen(!modalOpen);
                    }}
                    className="fill-current font-3xl font-bold"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                {/* Modal Body */}
                <div className="p-4 py-4 flex-grow text-gray-600 overflow-y-auto flex outline-black h-full">
                  <iframe src={url} width="100%" height="100%"></iframe>
                  <div className="px-2 h-full flex flex-col justif justify-between">
                    <div className="w-96 bg-gray-200 rounded p-2 h-full">
                      <div className="bg-white p-2 rounded shadow-sm my-3">
                        <div>admin</div>
                        <div>this is a comment</div>
                      </div>
                      <div className="bg-white p-2 rounded shadow-sm my-3">
                        <div>admin</div>
                        <div>this is another comment</div>
                      </div>
                    </div>
                    <div>Submit</div>
                  </div>
                </div>

                {/* Modal Footer */}
                {/* <div className="p-4 border-t bg-white w-full">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className=" bg-transparent hover:bg-gray-400 text-gray-400 font-semibold 
                        hover:text-white py-2 px-4 mr-2 border border-gray-400 hover:border-transparent 
                        rounded-md"
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className=" bg-gray-700 text-gray-100 rounded px-4 py-2"
                      onClick={() => {
                        saveItem();
                        setModalOpen(!modalOpen);
                      }}
                    >
                      Save Changes
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
