import React, { useState, useEffect } from "react";
import { NEXT_URL } from "../config/index";

import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";

// import DebouncedSearchInput from "../components/dateOverview/DebouncedSearchInput";
// import TableUsersPaginated from "../components/dateOverview/TablePerceelOwnershipsPaginated";
// import CustomSelect2 from "@/components/dateOverview/CustomSelect2";

import TableStraalVerbindingen from "../components/PageStraalVerbindingen/TableStraalVerbindingen";

const getYears = (startYear) => {
  startYear = typeof startYear == "undefined" ? 1980 : startYear;
  var currentYear = new Date().getFullYear();
  var years = [];
  for (var i = startYear; i <= currentYear; i++) {
    years.push({ id: i, label: i, value: i });
  }
  return years;
};

const years = getYears(1700);

export default function PageStraalVerbindingen(props) {
  //PropsCheck
  console.log({ PageStraalVerbindingenPropsCheck: { props } });

  //StateVariables
  const [companyId, setCompanyId] = useState(-1);


  //Constants
  const user = props.user;

  //ConstantVariables
  //   const router = useRouter();
  const queryClient = useQueryClient();

  //StateVariables
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSearch, setPageSearch] = useState("");
  const [pageSortBy, setPageSortBy] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [pageYear, setPageYear] = useState(null);
  const [selected, setSelected] = useState(-1);

  const fetchTasks = async (
    pageIndex,
    pageSize,
    pageSortBy,
    pageSearch,
    pageYear
  ) => {
    const sortByString = pageSortBy?.id
      ? `sortBy=${pageSortBy.id}&sortDesc=${pageSortBy.desc}&`
      : "";
    const searchString = pageSearch ? `search=${pageSearch}` : "";
    const limitString = `&limit=${pageSize}`;
    const pageString = `&page=${pageIndex + 1}`;
    const yearString = pageYear ? `&year=${pageYear}` : "";

    return axios.get(
      `${NEXT_URL}/api/aanvraagStraalVerbindingen?${searchString}${sortByString}${limitString}${pageString}${yearString}`
    );
  };

  //QueryFunctions
  const {
    data: dataAddresses,
    status: statusUsers,
    error: errorAddresses,
    isPreviousData: isPreviousDataAddresses,
  } = useQuery(
    [
      `ManagmentAddresses`,
      pageIndex,
      pageSize,
      pageSortBy,
      pageSearch,
      pageYear,
    ],
    () => fetchTasks(pageIndex, pageSize, pageSortBy, pageSearch, pageYear),
    {
      keepPreviousData: true,
      staleTime: Infinity,
      enabled: true,
    }
  );

  useEffect(() => {
    console.log("invalidating query");
    queryClient.invalidateQueries(
      "ManagmentAddresses",
      pageIndex,
      pageSize,
      pageSortBy,
      pageSearch,
      pageYear
    );
  }, [pageSearch, pageIndex, pageSize, pageSortBy, pageYear]);

  //QueryResultsCheck
  console.log({ PageStraalVerbindingenQueryResultsCheck: { dataAddresses } });

  return (
    <>
      <div className="card mb-4 mx-3">
        {/* Card Row */}
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">
            Straal Verbinding Aanvragen
          </h6>
          <div className="d-flex justify-content-center align-items-center">
            {/* Actions Col */}
            {/* <div className="w-96 z-10">
              {user.role.name !== "OPERATOR" && (
                <Select
                  className="form-select rounded p-1 px-2 text-secondary m-2 "
                //   value={-1}
                    onChange={(option) => {
                      setCompanyId(option.value);
                    }}
                  placeholder={"All Equipment"}
                  options={(() => {
                    const companies = dataCompanies.data.map((company) => {
                      return { label: company.name, value: company.id };
                    });
                    // return [{ label: "All Equipment", value: 0 }];
                    return [{ label: "All Equipment", value: -1 }, ...companies];
                  })()}
                />
              )}
            </div> */}
            {/* End Col */}
            {/* <button
                className="btn btn-outline-primary my-2"
                onMouseDown={(e) => openModal(e, initialValues)}
                data-target="#antennaModal"
                style={{ fontSize: ".9rem" }}
              >
                <i
                  className="fas fa-plus"
                  style={{ marginRight: "10px", fontSize: ".8rem" }}
                ></i>
                Add New Equipment Old
              </button> */}
            {user.role.name !== "OPERATOR" && (
              <div>
                <Link
                  to={{
                    pathname: "/FormPageStraalverbinding",
                  }}
                  className="btn btn-outline-primary my-2"
                >
                  <i
                    className="fas fa-plus"
                    style={{ marginRight: "10px", fontSize: ".8rem" }}
                  ></i>
                  Add New Aanvraag
                </Link>
              </div>
            )}
          </div>
        </div>
        {/* End Card Row */}
        {/* Card Row */}
        <div className="card-body">
          <div className="">
            {true && (
              <div>
                {statusUsers === "loading" && <div>Loading...</div>}
                {statusUsers === "error" && <div>Error: Couldn't fetch</div>}
                {statusUsers === "success" && (
                  <TableStraalVerbindingen
                    users={dataAddresses.data.items}
                    queryData={dataAddresses.data}
                    statusUsers={statusUsers}
                    queryPageIndex={pageIndex}
                    queryPageSize={pageSize}
                    setPage={setPageIndex}
                    setLimit={setPageSize}
                    setSearch={setPageSearch}
                    setPageSortBy={setPageSortBy}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {/* End Card Row */}
      </div>
    </>
  );
}
