import React from "react";

import AccordionRowContract from "./RowAccordionContract";

export default function TableContracts({
  paginatedItems,
  toggleAccordion,
  selectedModel,
  setSelectedModel,
  openModal,
  deleteItem,
  openPdfModal,
  setContractwithParsedDate,
}) {
  return (
    <table className="table table-striped table-sm">
      <thead className="bg-primary text-white">
        <tr>
          <td>Frequency</td>
          <td>Start Date</td>
          <td>End Date</td>
          <td style={{ textAlign: "right" }}>Uplink Low</td>
          <td style={{ textAlign: "right" }}>Uplink Med</td>
          <td style={{ textAlign: "right" }}>Uplink High</td>
          <td style={{ textAlign: "right" }}>Downlink Low</td>
          <td style={{ textAlign: "right" }}>Downlink Med</td>
          <td style={{ textAlign: "right" }}>Downlink High</td>
          <td>Division</td>
          <td>Frequency</td>
          <td>LineFee</td>
          <td>Jurisdiction</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        {paginatedItems.map((contract, index) => {
          return (
            <AccordionRowContract
              key={index}
              contract={contract}
              index={index}
              toggleAccordion={toggleAccordion}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              openModal={openModal}
              deleteItem={deleteItem}
              openPdfModal={openModal}
              setContractwithParsedDate={setContractwithParsedDate}
            />
          );
        })}
      </tbody>
    </table>
  );
}
