export const initialValuesVergunning = {
  Relatienummer: null,
  Vergunningsperiode: null,
  VergunningsperiodeVan: null,
  VergunningsperiodeTot: null,
  VergunningPersoonType: null,
};

export const initialValuesRechtPersoon = {
  rechtspersoonStatutaireNaam: null,
  rechtspersoonHandelsnaam: null,
  rechtspersoonKVKLocatie: "St. Maarten",
  rechtspersoonKvK_nummer: null,
  kvkFile: null,
  rechtspersoonVestigingsnummer: null,
};
export const initialValuesNatuurlijkPersoon = {
  natuurlijkPersoonBurgerservicenummerInNederland: null,
  natuurlijkPersoonVoornamen: null,
  natuurlijkPersoonVoorvoegsel: null,
  natuurlijkPersoonGeslachtsnaam: null,
  natuurlijkPersoonGeboortedatum: null,
};
export const initialValuesWoonVestingAddress = {
  WoonVestigingsadresStraat: null,
  WoonVestigingsadresHuisnummer: null,
  WoonVestigingsadresHuisnummertoevoeging: null,
  WoonVestigingsadresPostcode: null,
  WoonVestigingsadresPlaats: null,
  WoonVestigingsadresLand: "St. Maarten",
};
export const initialValuesCorrespondentie = {
  CorrespondentieStraatOfPostbus: null,
  CorrespondentieHuisOfPostbusnummer: null,
  CorrespondentieHuisnummertoevoeging: null,
  CorrespondentiePostcode: null,
  CorrespondentiePlaats: null,
  CorrespondentieLand: 'St. Maarten',
};
export const initialValuesContact = {
  Contactpersoon: null,
  Telefoonnummer: null,
  mailadres: null,
  URLVanUwWebsite: null,
};
export const initialValuesStraalVerbinding = {
  StraalverbindingLinkIDAanvrager: null,
  StraalverbindingSoortVerbinding: null,
  StraalverbindingInclusiefPassieveReflector: 0,
  StraalverbindingModulatiesoort: null,
  StraalverbindingPadlengte: null,
  StraalverbindingFabricaat_typeZendontvanger: null,
  StraalverbindingFrequentiebandOndergrens: null,
  StraalverbindingFrequentiebandBovengrens: null,
  StraalverbindingBandbreedte: null,
  StraalverbindingDatasnelheid: null,
  StraalverbindingAutomaticTransmittedPowerControl: 0,
  StraalverbindingAdaptieveModulator: 0,
  StraalverbindingGewensteBeschikbaarheid: null,
};
export const initalValuesZender = {
  ZenderStationsnaam1: null,
  ZenderStationsnaam2: null,
  ZenderStraat1: null,
  ZenderStraat2: null,
  ZenderPostcodeEnPlaats1: null,
  ZenderPostcodeEnPlaats2: null,
  ZenderCoordinatenOosterlengteGraden1: null,
  ZenderCoordinatenOosterlengteGraden2: null,
  ZenderCoordinatenOosterlengteMinuten1: null,
  ZenderCoordinatenOosterlengteMinuten2: null,
  ZenderCoordinatenOosterlengteSeconden1: null,
  ZenderCoordinatenOosterlengteSeconden2: null,
  ZenderCoordinatenNoorderbreedteGraden1: null,
  ZenderCoordinatenNoorderbreedteGraden2: null,
  ZenderCoordinatenNoorderbreedteMinuten1: null,
  ZenderCoordinatenNoorderbreedteMinuten2: null,
  ZenderCoordinatenNoorderbreedteSeconden1: null,
  ZenderCoordinatenNoorderbreedteSeconden2: null,
};
export const initialValuesAntenne = {
  AntenneFabricaatType1: null,
  AntenneFabricaatType2: null,
  AntenneCompanyId1: null,
  AntenneCompanyId2: null,
  AntenneAccessTypeId1: null,
  AntenneAccessTypeId2: null,
  AntenneServiceId1: null,
  AntenneServiceId2: null,
  AntenneFrequencyBandId1: null,
  AntenneFrequencyBandId2: null,
  AntenneFrequencyCodeId1: null,
  AntenneFrequencyCodeId2: null,
  AntenneOvereenkomstigLijstMetAntenneprofielen1: 0,
  AntenneOvereenkomstigLijstMetAntenneprofielen2: 0,
  AntennePolarisatie1: null,
  AntennePolarisatie2: null,
  Antennehoogte1: null,
  Antennehoogte2: null,
  AntenneHoofdstraalrichting1: null,
  AntenneHoofdstraalrichting2: null,
  AntenneKabelVerliezen1: null,
  AntenneKabelVerliezen2: null,
  AntenneOpeningshoek1: null,
  AntenneOpeningshoek2: null,
  AntenneGain1: null,
  AntenneGain2: null,
  AntenneZendfrequentie1: null,
  AntenneZendfrequentie2: null,
  AntenneZendvermogen1: null,
  AntenneZendvermogen2: null,
};
export const initialValuesPassieveReflector = {
  PassieveReflectorStationsnaam: null,
  PassieveReflectorStraat: null,
  PassieveReflectorPostcodeEnPlaats: null,
  PassieveReflectorCoordinatenOosterlengteGraden: null,
  PassieveReflectorCoordinatenOosterlengteMinuten: null,
  PassieveReflectorCoordinatenOosterlengteSeconden: null,
  PassieveReflectorCoordinatenNoorderbreedteGraden: null,
  PassieveReflectorCoordinatenNoorderbreedteMinuten: null,
  PassieveReflectorCoordinatenNoorderbreedteSeconden: null,
};
export const initialValuesPassiverReflectorAntenna = {
  PassieveReflectorAntenneFabricaatType1: null,
  PassieveReflectorAntenneFabricaatType2: null,
  PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen1: 0,
  PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen2: 0,
  PassieveReflectorAntennePolarisatie1: null,
  PassieveReflectorAntennePolarisatie2: null,
  PassieveReflectorAntenneAntennehoogte1: null,
  PassieveReflectorAntenneAntennehoogte2: null,
  PassieveReflectorAntenneHoofdstraalrichting1: null,
  PassieveReflectorAntenneHoofdstraalrichting2: null,
  PassieveReflectorAntenneKabelverliezen1: null,
  PassieveReflectorAntenneKabelverliezen2: null,
  PassieveReflectorAntenneOpeningshoek1: null,
  PassieveReflectorAntenneOpeningshoek2: null,
  PassieveReflectorAntenneGain1: null,
  PassieveReflectorAntenneGain2: null,
};
export const initialValuesFacturatie = {
  FacturatieContactpersoonOfAfdeling: null,
  FacturatieFactuuradres: null,
  FacturatiePostcode: null,
  FacturatiePlaats: null,
  FacturatieLand: "St. Maarten",
  FacturatieInternOrdernummer: null,
  FacturatieBetalingswijze: null,
};
export const initialValuesBijlagen = {
  BijlagenKopieInschrijvingBuitenlandseKvK: 0,
  BijlagenKopieIdentificatiebewijs: 0,
  BijlagenPlattegrondAntennelocatie: 0,
  BijlagenTechnischeParametersAntenne: 0,
  BijlagenTechnischeParametersReflectorAntenne: 0,
  MachtigingVertegenwoordigingDoorDerden: 0,
  ExtraToelichting: null,
};
export const initialValuesOndertekening = {
  OndertekeningNaam: null,
  OndertekeningDatum: null,
  OndertekeningPlaats: null,
  OndertekeningHandtekening: null,
};

export const initialValues = {
  ...initialValuesVergunning,
  ...initialValuesRechtPersoon,
  ...initialValuesNatuurlijkPersoon,
  ...initialValuesWoonVestingAddress,
  ...initialValuesCorrespondentie,
  ...initialValuesContact,
  ...initialValuesStraalVerbinding,
  ...initalValuesZender,
  ...initialValuesAntenne,
  ...initialValuesPassieveReflector,
  ...initialValuesPassiverReflectorAntenna,
  ...initialValuesFacturatie,
  ...initialValuesBijlagen,
  ...initialValuesOndertekening,
};