import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  getFrequenciesQuery,
  getContractsByCompanyQuery,
  addContractMutation,
  updateContractMutation,
  deleteContractMutation,
  getAttachmentQuery,
} from "../../queries/queries";

import { Context } from "../../contexts/CompanyContext";

import TableContracts from "./TableContracts";
import Pagination from "../Layout/Pagination";
import ContractModal from "./ModalContracts";
import Modal from "../Layout/Modal";

const ContractsCard = ({ user }) => {
  //DataStateVariables
  const { loading, data } = useQuery(getFrequenciesQuery);

  const [modalData, setModalData] = useState(data);

  const [getAttachment, { loading: loadingAttachment, data: dataAttachment }] =
    useLazyQuery(getAttachmentQuery);
  const [frequencies, setFrequencies] = useState([]);
  const [services, setServices] = useState([]);
  const [lineFees, setLineFees] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [inputKey, setInputKey] = useState("");
  const [selectedContract, setSelectedContract] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  //GlobalDataStateVariables
  const { company, contracts, contract, setContract, resetContract } =
    useContext(Context);

  useEffect(() => {
    if (!loading && data) {
      setFrequencies(data.frequencies);
      setServices(data.services);
      setLineFees(data.lineFees);
      setJurisdictions(data.jurisdictions);
      setDivisions(data.divisions);
      setAllocations(data.allocations);
    }
  }, [loading, data]);

  useEffect(() => {
    if (dataAttachment) {
      const downloadLink = document.createElement("a");
      downloadLink.href = dataAttachment.attachment.base64;
      downloadLink.download = dataAttachment.attachment.name;
      downloadLink.click();
    }
  }, [dataAttachment]);

  //PaginationStateVariables
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedItems = contracts.slice(indexOfFirstItem, indexOfLastItem);
  const totalItems = contracts.length;

  //PaginationStateFunctions
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //ContractServices
  const [addContract] = useMutation(addContractMutation);
  const [updateContract] = useMutation(updateContractMutation);
  const [deleteContract] = useMutation(deleteContractMutation);

  const deleteItem = async (e, id) => {
    console.log({id})
    deleteContract({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: getContractsByCompanyQuery,
          variables: { companyId: company.id },
        },
      ],
    });
  };

  const saveItem = (contract) => {
    contract.id > 0
      ? updateContract({
          variables: {
            id: contract.id,
            name: contract.name,
            companyId: company.id,
            startDate: new Date(contract.startDate),
            endDate: new Date(contract.endDate),
            uplinkLow: parseFloat(contract.uplinkLow),
            uplinkMid: parseFloat(contract.uplinkMid),
            uplinkHigh: parseFloat(contract.uplinkHigh),
            downlinkLow: parseFloat(contract.downlinkLow),
            downlinkMid: parseFloat(contract.downlinkMid),
            downlinkHigh: parseFloat(contract.downlinkHigh),
            attachments: contract.attachments,
            divisionId: contract.division ? parseInt(contract.division.id) : 0,
            frequencyId:
              typeof contract.frequency === "object"
                ? parseInt(contract.frequency.id)
                : parseInt(contract.frequency),
            lineFeeId: contract.lineFee ? parseInt(contract.lineFee.id) : 0,
            jurisdictionId: contract.jurisdiction
              ? parseInt(contract.jurisdiction.id)
              : null,
          },
          refetchQueries: [
            {
              query: getContractsByCompanyQuery,
              variables: { companyId: company.id },
            },
          ],
        })
      : addContract({
          variables: {
            name: contract.name,
            companyId: company.id,
            startDate: new Date(contract.startDate),
            endDate: new Date(contract.endDate),
            uplinkLow: parseFloat(contract.uplinkLow),
            uplinkMid: parseFloat(contract.uplinkMid),
            uplinkHigh: parseFloat(contract.uplinkHigh),
            downlinkLow: parseFloat(contract.downlinkLow),
            downlinkMid: parseFloat(contract.downlinkMid),
            downlinkHigh: parseFloat(contract.downlinkHigh),
            attachments: contract.attachments,
            divisionId: contract.division ? parseInt(contract.division.id) : 0,
            frequencyId:
              typeof contract.frequency === "object"
                ? parseInt(contract.frequency.id)
                : parseInt(contract.frequency),
            lineFeeId: contract.lineFee ? parseInt(contract.lineFee.id) : 0,
            jurisdictionId: contract.jurisdiction
              ? parseInt(contract.jurisdiction.id)
              : 0,
          },
          refetchQueries: [
            {
              query: getContractsByCompanyQuery,
              variables: { companyId: company.id },
            },
          ],
        });
  };

  //ModalStatefunctions
  const closeModal = () => {
    var modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.style.display = "none";
      modal.classList.remove("show");
    });
    setContract({ ...contract, attachments: [] });
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
    document.querySelector(".modal-backdrop").remove();
  };
  const openModal = (e) => {
    const modal = document.querySelector(e.target.dataset.target);
    modal.style.display = "inline-block";
    modal.classList.add("show");
    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    backdrop.classList.add("show");

    if (modal.classList.contains("fade")) {
      backdrop.classList.add("fade");
    }

    document.body.appendChild(backdrop);
  };

  //DropdownStatefunctions
  const toggleDropdownMenu = () => {
    document
      .querySelector('[aria-labelledby="dropdownContractMenuLink"]')
      .classList.toggle("show");
  };

  const closeDropdown = () => {
    document
      .querySelector('[aria-labelledby="dropdownContractMenuLink"]')
      .classList.remove("show");
  };

  //FormStateFunctions
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "technology") {
      setContract({
        ...contract,
        frequency: {
          band: { service: { technology: { id: parseInt(value) } } },
        },
      });
      setServices(
        data.services.filter(
          (service) => service.technology.id === parseInt(value)
        )
      );
    } else if (name === "service") {
      setContract({
        ...contract,
        frequency: {
          band: {
            service: {
              id: parseInt(value),
              technology: contract.frequency.band.service.technology,
            },
          },
        },
      });
      setFrequencies(
        data.frequencies.filter(
          (frequency) => frequency?.band?.service?.id === parseInt(value)
        )
      );
    } else if (name === "frequency") {
      setContract({
        ...contract,
        frequency: {
          id: parseInt(value),
          band: {
            id: parseInt(value),
            service: contract.frequency.band.service,
          },
        },
      });
      //setFrequency(data.frequency)
    } else if (
      name === "lineFee" ||
      name === "jurisdiction" ||
      name === "division"
    ) {
      setContract({ ...contract, [name]: { id: parseInt(value) } });
    } else {
      setContract({ ...contract, [name]: value });
    }
  };
  const handleImageReset = () => {
    setContract({ ...contract, attachments: [] });
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  };

  const removePdf = (e, index) => {
    e.preventDefault();
    let attachments = [...contract.attachments];
    attachments.splice(index, 1);
    setContract({ ...contract, attachments });
  };

  const handleImageChange = (e) => {
    setUploadLoading(true);
    let attachment = {};
    let promise = [...e.target.files].map((file) =>
      uploadFile(file).then((result) => {
        attachment = { name: file.name, base64: result };
        return attachment;
      })
    );
    Promise.all(promise).then((result) => {
      let attachments = [...contract.attachments, ...result];
      console.log({ attachments });
      setContract({ ...contract, attachments });
      setUploadLoading(false);
    });
  };

  function uploadFile(file) {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(this.result);
      };
    });
  }

  const setContractwithParsedDate = (contract) => {
    setContract({
      ...contract,
      startDate: new Date(parseInt(contract.startDate)),
      endDate: new Date(parseInt(contract.endDate)),
    });
  };

  //ModalStateFunctions
  const openPdfModal = (e, attachment) => {
    e.preventDefault();
    const result = getAttachment({
      variables: { id: parseInt(attachment.id) },
    });
  };

  //AccordiionStateFunctions
  const toggleAccordion = (e) => {
    e.preventDefault();
    const container = document.querySelector(
      e.target.parentNode.dataset.target
    );
    const row = e.target.parentNode;
    row.classList?.toggle("collapsed");
    container?.classList.toggle("show");
  };

  return loading && !data && !modalData ? (
    <div className="spinner-border m-5" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <>
      <div className="card mb-4">
        <div className="card-header py-2 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Contracts</h6>
          {user.role.name !== "OPERATOR" && (
            <div className="dropdown no-arrow">
              <button
                className="button-link"
                id="dropdownContractMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleDropdownMenu}
                onBlur={closeDropdown}
              >
                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                aria-labelledby="dropdownContractMenuLink"
                x-placement="bottom-end"
                style={{
                  position: "absolute",
                  willChange: "transform",
                  top: 0,
                  left: 0,
                  transform: "translate3d(-156px, 19px, 0px)",
                }}
              >
                <div className="dropdown-header">Actions:</div>
                <button
                  className="dropdown-item"
                  type="button"
                  onMouseDown={(e) => {
                    resetContract();
                    openModal(e);
                  }}
                  data-target="#contractModal"
                >
                  New
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="card-body table-responsive">
          <div className="row">
            <TableContracts
              paginatedItems={paginatedItems}
              toggleAccordion={toggleAccordion}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              openModal={openModal}
              deleteItem={deleteItem}
              openPdfModal={openModal}
              setContractwithParsedDate={setContractwithParsedDate}
            />
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="dataTable_paginate"
              >
                {totalItems > itemsPerPage ? (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContractModal
        data={{
          ...data,
          services,
          frequencies,
          lineFees,
          jurisdictions,
          divisions,
          allocations,
        }}
        closeModal={closeModal}
        saveItem={saveItem}
        handleChange={handleChange}
        handleImageReset={handleImageReset}
        handleImageChange={handleImageChange}
        inputKey={inputKey}
        loading={uploadLoading}
        removePdf={removePdf}
      />

      <Modal
        id="pdfModal"
        open={open}
        setOpen={setOpen}
        title={selectedContract?.name}
      >
        <iframe
          title={selectedContract?.name}
          src={selectedContract?.attachment}
          width="100%"
          height="560px"
        ></iframe>
      </Modal>
    </>
  );
};

export default ContractsCard;
