import React from "react";
import ModalViewAttachment from "./ModalViewAttachment";
import ModalViewBlob from "./ModalViewBlob";

export default function CollapsibleRowPerceelOwnership({
  antenna,
  openPdfModal,
}) {
  //PropsCheck
  console.log({
    CollapsibleRowPerceelOwnershipPropsCheck: { antenna, openPdfModal },
  });

  const baseUrl =
    "https://ispstorage143.blob.core.windows.net/identitycards/";

  return (
    <tr id={`collapse${antenna.id}`} className="collapse">
      <td colSpan={8}>
        <table
          className="table table-nostriped table-borderless"
          style={{ width: "100%" }}
        >
          <tbody>
            {/* <tr>
              <td>Akte: </td>
              <td>
                <ModalViewAttachment antenna={antenna} />
                {antenna.antennaType?.model?.hasAttachment && (
                  <i
                    className="fas fa-file-pdf"
                    onClick={(e) =>
                      openPdfModal(e, antenna.antennaType.model.id)
                    }
                  ></i>
                )}
              </td>
            </tr> */}
            <tr>
              <td>Relatienummer:</td>
              <td> {antenna.Relatienummer || "-"}</td>
              <td>Vergunningsperiode: </td>
              <td>{antenna.Vergunningsperiode || "-"}</td>
            </tr>
            <tr>
              <td>VergunningsperiodeVan: </td>
              <td>{antenna?.VergunningsperiodeVan}</td>
              <td>VergunningsperiodeTot: </td>
              <td>{antenna.VergunningsperiodeTot}</td>
            </tr>
            <tr>
              <td>VergunningPersoonType:</td>
              <td> {antenna.VergunningPersoonType || "-"}</td>
              <td>rechtspersoonStatutaireNaam: </td>
              <td>{antenna.rechtspersoonStatutaireNaam || "-"}</td>
            </tr>
            <tr>
              <td>rechtspersoonHandelsnaam: </td>
              <td>{antenna?.rechtspersoonHandelsnaam}</td>
              <td>rechtspersoonKVKLocatie: </td>
              <td>{antenna.rechtspersoonKVKLocatie}</td>
            </tr>
            <tr>
              <td>rechtspersoonKvK_nummer:</td>
              <td> {antenna.rechtspersoonKvK_nummer || "-"}</td>
              <td>rechtspersoonVestigingsnummer: </td>
              <td>{antenna.rechtspersoonVestigingsnummer || "-"}</td>
            </tr>
            <tr>
              <td>natuurlijkPersoonBurgerservicenummerInStMaarten: </td>
              <td>
                {antenna?.natuurlijkPersoonBurgerservicenummerInNederland}
              </td>
              <td>natuurlijkPersoonVoornamen: </td>
              <td>{antenna.natuurlijkPersoonVoornamen}</td>
            </tr>
            <tr>
              <td>natuurlijkPersoonVoorvoegsel:</td>
              <td> {antenna.natuurlijkPersoonVoorvoegsel || "-"}</td>
              <td>natuurlijkPersoonGeslachtsnaam: </td>
              <td>{antenna.natuurlijkPersoonGeslachtsnaam || "-"}</td>
            </tr>
            <tr>
              <td>IdentiteitsKaart:</td>
              <td>
                <ModalViewBlob
                  antenna={antenna}
                  imgName={antenna.identityCardFile[0]?.name}
                  imgUrl={`${baseUrl}${antenna.identityCardFile[0]?.url}`}
                />
              </td>
            </tr>
            <tr>
              <td>WoonVestigingsadresStraat: </td>
              <td>{antenna?.WoonVestigingsadresStraat}</td>
              <td>WoonVestigingsadresHuisnummer: </td>
              <td>{antenna.WoonVestigingsadresHuisnummer}</td>
            </tr>
            <tr>
              <td>WoonVestigingsadresHuisnummertoevoeging:</td>
              <td> {antenna.WoonVestigingsadresHuisnummertoevoeging || "-"}</td>
              <td>WoonVestigingsadresPostcode: </td>
              <td>{antenna.WoonVestigingsadresPostcode || "-"}</td>
            </tr>
            <tr>
              <td>WoonVestigingsadresPlaats: </td>
              <td>{antenna?.WoonVestigingsadresPlaats}</td>
              <td>WoonVestigingsadresLand: </td>
              <td>{antenna.WoonVestigingsadresLand}</td>
            </tr>
            <tr>
              <td>CorrespondentieStraatOfPostbus:</td>
              <td> {antenna.CorrespondentieStraatOfPostbus || "-"}</td>
              <td>CorrespondentieHuisOfPostbusnummer: </td>
              <td>{antenna.CorrespondentieHuisOfPostbusnummer || "-"}</td>
            </tr>
            <tr>
              <td>CorrespondentieHuisnummertoevoeging: </td>
              <td>{antenna?.CorrespondentieHuisnummertoevoeging}</td>
              <td>CorrespondentiePostcode: </td>
              <td>{antenna.CorrespondentiePostcode}</td>
            </tr>
            <tr>
              <td>CorrespondentiePlaats:</td>
              <td> {antenna.CorrespondentiePlaats || "-"}</td>
              <td>CorrespondentieLand: </td>
              <td>{antenna.CorrespondentieLand || "-"}</td>
            </tr>
            <tr>
              <td>Contactpersoon: </td>
              <td>{antenna?.Contactpersoon}</td>
              <td>Telefoonnummer: </td>
              <td>{antenna.Telefoonnummer}</td>
            </tr>
            <tr>
              <td>mailadres:</td>
              <td> {antenna.mailadres || "-"}</td>
              <td>URLVanUwWebsite: </td>
              <td>{antenna.URLVanUwWebsite || "-"}</td>
            </tr>
            <tr>
              <td>StraalverbindingLinkIDAanvrager: </td>
              <td>{antenna?.StraalverbindingLinkIDAanvrager}</td>
              <td>StraalverbindingSoortVerbinding: </td>
              <td>{antenna.StraalverbindingSoortVerbinding}</td>
            </tr>
            <tr>
              <td>StraalverbindingInclusiefPassieveReflector:</td>
              <td>
                {" "}
                {antenna.StraalverbindingInclusiefPassieveReflector || "-"}
              </td>
              <td>StraalverbindingModulatiesoort: </td>
              <td>{antenna.StraalverbindingModulatiesoort || "-"}</td>
            </tr>
            <tr>
              <td>StraalverbindingPadlengte: </td>
              <td>{antenna?.StraalverbindingPadlengte}</td>
              <td>StraalverbindingFabricaat_typeZendontvanger: </td>
              <td>{antenna.StraalverbindingFabricaat_typeZendontvanger}</td>
            </tr>
            <tr>
              <td>StraalverbindingFrequentiebandOndergrens:</td>
              <td>
                {" "}
                {antenna.StraalverbindingFrequentiebandOndergrens || "-"}
              </td>
              <td>StraalverbindingFrequentiebandBovengrens: </td>
              <td>{antenna.StraalverbindingFrequentiebandBovengrens || "-"}</td>
            </tr>
            <tr>
              <td>StraalverbindingBandbreedte: </td>
              <td>{antenna?.StraalverbindingBandbreedte}</td>
              <td>StraalverbindingDatasnelheid: </td>
              <td>{antenna.StraalverbindingDatasnelheid}</td>
            </tr>
            <tr>
              <td>StraalverbindingAutomaticTransmittedPowerControl:</td>
              <td>
                {" "}
                {antenna.StraalverbindingAutomaticTransmittedPowerControl ||
                  "-"}
              </td>
              <td>StraalverbindingAdaptieveModulator: </td>
              <td>{antenna.StraalverbindingAdaptieveModulator || "-"}</td>
            </tr>
            <tr>
              <td>StraalverbindingGewensteBeschikbaarheid: </td>
              <td>{antenna?.StraalverbindingGewensteBeschikbaarheid}</td>
              <td>KVK File: </td>
              <td>
                <ModalViewAttachment antenna={antenna} />
              </td>
            </tr>
            <tr>
              <td>ZenderStationsnaam1:</td>
              <td> {antenna.ZenderStationsnaam1 || "-"}</td>
              <td>ZenderStationsnaam2: </td>
              <td>{antenna.ZenderStationsnaam2 || "-"}</td>
            </tr>
            <tr>
              <td>ZenderStraat1: </td>
              <td>{antenna?.ZenderStraat1}</td>
              <td>ZenderStraat2: </td>
              <td>{antenna.ZenderStraat2}</td>
            </tr>
            <tr>
              <td>ZenderPostcodeEnPlaats1:</td>
              <td> {antenna.ZenderPostcodeEnPlaats1 || "-"}</td>
              <td>ZenderPostcodeEnPlaats2: </td>
              <td>{antenna.ZenderPostcodeEnPlaats2 || "-"}</td>
            </tr>
            <tr>
              <td>ZenderCoordinatenOosterlengteGraden1: </td>
              <td>{antenna?.ZenderCoordinatenOosterlengteGraden1}</td>
              <td>ZenderCoordinatenOosterlengteGraden2: </td>
              <td>{antenna.ZenderCoordinatenOosterlengteGraden2}</td>
            </tr>
            <tr>
              <td>ZenderCoordinatenOosterlengteMinuten1:</td>
              <td> {antenna.ZenderCoordinatenOosterlengteMinuten1 || "-"}</td>
              <td>ZenderCoordinatenOosterlengteMinuten2: </td>
              <td>{antenna.ZenderCoordinatenOosterlengteMinuten2 || "-"}</td>
            </tr>
            <tr>
              <td>ZenderCoordinatenOosterlengteSeconden1: </td>
              <td>{antenna?.ZenderCoordinatenOosterlengteSeconden1}</td>
              <td>ZenderCoordinatenOosterlengteSeconden2: </td>
              <td>{antenna.ZenderCoordinatenOosterlengteSeconden2}</td>
            </tr>
            <tr>
              <td>ZenderCoordinatenNoorderbreedteGraden1:</td>
              <td> {antenna.ZenderCoordinatenNoorderbreedteGraden1 || "-"}</td>
              <td>ZenderCoordinatenNoorderbreedteGraden2: </td>
              <td>{antenna.ZenderCoordinatenNoorderbreedteGraden2 || "-"}</td>
            </tr>
            <tr>
              <td>ZenderCoordinatenNoorderbreedteMinuten1: </td>
              <td>{antenna?.ZenderCoordinatenNoorderbreedteMinuten1}</td>
              <td>ZenderCoordinatenNoorderbreedteMinuten2: </td>
              <td>{antenna.ZenderCoordinatenNoorderbreedteMinuten2}</td>
            </tr>
            <tr>
              <td>ZenderCoordinatenNoorderbreedteSeconden1:</td>
              <td>
                {" "}
                {antenna.ZenderCoordinatenNoorderbreedteSeconden1 || "-"}
              </td>
              <td>ZenderCoordinatenNoorderbreedteSeconden2: </td>
              <td>{antenna.ZenderCoordinatenNoorderbreedteSeconden2 || "-"}</td>
            </tr>
            <tr>
              <td>AntenneFabricaatType1: </td>
              <td>{antenna?.AntenneFabricaatType1}</td>
              <td>AntenneFabricaatType2: </td>
              <td>{antenna.AntenneFabricaatType2}</td>
            </tr>
            <tr>
              <td>AntenneOvereenkomstigLijstMetAntenneprofielen1:</td>
              <td>
                {" "}
                {antenna.AntenneOvereenkomstigLijstMetAntenneprofielen1 || "-"}
              </td>
              <td>AntenneOvereenkomstigLijstMetAntenneprofielen2: </td>
              <td>
                {antenna.AntenneOvereenkomstigLijstMetAntenneprofielen2 || "-"}
              </td>
            </tr>
            <tr>
              <td>AntennePolarisatie1: </td>
              <td>{antenna?.AntennePolarisatie1}</td>
              <td>AntennePolarisatie2: </td>
              <td>{antenna.AntennePolarisatie2}</td>
            </tr>
            <tr>
              <td>Antennehoogte1:</td>
              <td> {antenna.Antennehoogte1 || "-"}</td>
              <td>Antennehoogte2: </td>
              <td>{antenna.Antennehoogte2 || "-"}</td>
            </tr>
            <tr>
              <td>AntenneHoofdstraalrichting1: </td>
              <td>{antenna?.AntenneHoofdstraalrichting1}</td>
              <td>AntenneHoofdstraalrichting2: </td>
              <td>{antenna.AntenneHoofdstraalrichting2}</td>
            </tr>
            <tr>
              <td>AntenneKabelVerliezen1:</td>
              <td> {antenna.AntenneKabelVerliezen1 || "-"}</td>
              <td>AntenneKabelVerliezen2: </td>
              <td>{antenna.AntenneKabelVerliezen2 || "-"}</td>
            </tr>
            <tr>
              <td>AntenneOpeningshoek1: </td>
              <td>{antenna?.AntenneOpeningshoek1}</td>
              <td>AntenneOpeningshoek2: </td>
              <td>{antenna.AntenneOpeningshoek2}</td>
            </tr>
            <tr>
              <td>AntenneGain1:</td>
              <td> {antenna.AntenneGain1 || "-"}</td>
              <td>AntenneGain2: </td>
              <td>{antenna.AntenneGain2 || "-"}</td>
            </tr>
            <tr>
              <td>AntenneCompanyId1: </td>
              <td>{antenna?.AntenneCompanyId1}</td>
              <td>AntenneCompanyId2: </td>
              <td>{antenna.AntenneCompanyId2}</td>
            </tr>
            <tr>
              <td>AntenneAccessTypeId1:</td>
              <td> {antenna.AntenneAccessTypeId1 || "-"}</td>
              <td>AntenneAccessTypeId2: </td>
              <td>{antenna.AntenneAccessTypeId2 || "-"}</td>
            </tr>
            <tr>
              <td>AntenneServiceId1:</td>
              <td> {antenna.AntenneServiceId1 || "-"}</td>
              <td>AntenneServiceId2: </td>
              <td>{antenna.AntenneServiceId2 || "-"}</td>
            </tr>
            <tr>
              <td>AntenneFrequencyBandId1:</td>
              <td> {antenna.AntenneFrequencyBandId1 || "-"}</td>
              <td>AntenneFrequencyBandId2: </td>
              <td>{antenna.AntenneFrequencyBandId2 || "-"}</td>
            </tr>
            <tr>
              <td>AntenneFrequencyCodeId1:</td>
              <td> {antenna.AntenneFrequencyCodeId1 || "-"}</td>
              <td>AntenneFrequencyCodeId2: </td>
              <td>{antenna.AntenneFrequencyCodeId2 || "-"}</td>
            </tr>
            <tr>
              <td>PassieveReflectorStationsnaam: </td>
              <td>{antenna?.PassieveReflectorStationsnaam}</td>
              <td>PassieveReflectorStraat: </td>
              <td>{antenna.PassieveReflectorStraat}</td>
            </tr>
            <tr>
              <td>PassieveReflectorPostcodeEnPlaats:</td>
              <td> {antenna.PassieveReflectorPostcodeEnPlaats || "-"}</td>
              <td>PassieveReflectorCoordinatenOosterlengteGraden: </td>
              <td>
                {antenna.PassieveReflectorCoordinatenOosterlengteGraden || "-"}
              </td>
            </tr>
            <tr>
              <td>PassieveReflectorCoordinatenOosterlengteMinuten: </td>
              <td>
                {antenna?.PassieveReflectorCoordinatenOosterlengteMinuten}
              </td>
              <td>PassieveReflectorCoordinatenOosterlengteSeconden: </td>
              <td>
                {antenna.PassieveReflectorCoordinatenOosterlengteSeconden}
              </td>
            </tr>
            <tr>
              <td>PassieveReflectorCoordinatenNoorderbreedteGraden:</td>
              <td>
                {" "}
                {antenna.PassieveReflectorCoordinatenNoorderbreedteGraden ||
                  "-"}
              </td>
              <td>PassieveReflectorCoordinatenNoorderbreedteMinuten: </td>
              <td>
                {antenna.PassieveReflectorCoordinatenNoorderbreedteMinuten ||
                  "-"}
              </td>
            </tr>
            <tr>
              <td>PassieveReflectorCoordinatenNoorderbreedteSeconden: </td>
              <td>
                {antenna?.PassieveReflectorCoordinatenNoorderbreedteSeconden}
              </td>
              {/* <td>Empty: </td>
              <td>{antenna.Empty}</td> */}
            </tr>
            <tr>
              <td>PassieveReflectorAntenneFabricaatType1:</td>
              <td> {antenna.PassieveReflectorAntenneFabricaatType1 || "-"}</td>
              <td>PassieveReflectorAntenneFabricaatType2: </td>
              <td>{antenna.PassieveReflectorAntenneFabricaatType2 || "-"}</td>
            </tr>
            <tr>
              <td>
                PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen1:{" "}
              </td>
              <td>
                {
                  antenna?.PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen1
                }
              </td>
              <td>
                PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen2:{" "}
              </td>
              <td>
                {
                  antenna.PassieveReflectorAntenneOvereenkomstigLijstMetAntenneprofielen2
                }
              </td>
            </tr>
            <tr>
              <td>PassieveReflectorAntennePolarisatie1:</td>
              <td> {antenna.PassieveReflectorAntennePolarisatie1 || "-"}</td>
              <td>PassieveReflectorAntennePolarisatie2: </td>
              <td>{antenna.PassieveReflectorAntennePolarisatie2 || "-"}</td>
            </tr>
            <tr>
              <td>PassieveReflectorAntenneAntennehoogte1: </td>
              <td>{antenna?.PassieveReflectorAntenneAntennehoogte1}</td>
              <td>PassieveReflectorAntenneAntennehoogte2: </td>
              <td>{antenna.PassieveReflectorAntenneAntennehoogte2}</td>
            </tr>
            <tr>
              <td>PassieveReflectorAntenneHoofdstraalrichting1:</td>
              <td>
                {" "}
                {antenna.PassieveReflectorAntenneHoofdstraalrichting1 || "-"}
              </td>
              <td>PassieveReflectorAntenneHoofdstraalrichting2: </td>
              <td>
                {antenna.PassieveReflectorAntenneHoofdstraalrichting2 || "-"}
              </td>
            </tr>
            <tr>
              <td>PassieveReflectorAntenneKabelverliezen1: </td>
              <td>{antenna?.PassieveReflectorAntenneKabelverliezen1}</td>
              <td>PassieveReflectorAntenneKabelverliezen2: </td>
              <td>{antenna.PassieveReflectorAntenneKabelverliezen2}</td>
            </tr>
            <tr>
              <td>PassieveReflectorAntenneOpeningshoek1:</td>
              <td> {antenna.PassieveReflectorAntenneOpeningshoek1 || "-"}</td>
              <td>PassieveReflectorAntenneOpeningshoek2: </td>
              <td>{antenna.PassieveReflectorAntenneOpeningshoek2 || "-"}</td>
            </tr>
            <tr>
              <td>PassieveReflectorAntenneGain1: </td>
              <td>{antenna?.PassieveReflectorAntenneGain1}</td>
              <td>PassieveReflectorAntenneGain2: </td>
              <td>{antenna.PassieveReflectorAntenneGain2}</td>
            </tr>
            <tr>
              <td>FacturatieContactpersoonOfAfdeling:</td>
              <td> {antenna.FacturatieContactpersoonOfAfdeling || "-"}</td>
              <td>FacturatieFactuuradres: </td>
              <td>{antenna.FacturatieFactuuradres || "-"}</td>
            </tr>
            <tr>
              <td>FacturatiePostcode: </td>
              <td>{antenna?.FacturatiePostcode}</td>
              <td>FacturatiePlaats: </td>
              <td>{antenna.FacturatiePlaats}</td>
            </tr>
            <tr>
              <td>FacturatieLand:</td>
              <td> {antenna.FacturatieLand || "-"}</td>
              <td>FacturatieInternOrdernummer: </td>
              <td>{antenna.FacturatieInternOrdernummer || "-"}</td>
            </tr>
            <tr>
              <td>FacturatieBetalingswijze: </td>
              <td>{antenna?.FacturatieBetalingswijze}</td>
              <td>BijlagenKopieInschrijvingBuitenlandseKvK: </td>
              <td>{antenna.BijlagenKopieInschrijvingBuitenlandseKvK}</td>
            </tr>
            <tr>
              <td>BijlagenKopieIdentificatiebewijs:</td>
              <td> {antenna.BijlagenKopieIdentificatiebewijs || "-"}</td>
              <td>BijlagenPlattegrondAntennelocatie: </td>
              <td>{antenna.BijlagenPlattegrondAntennelocatie || "-"}</td>
            </tr>
            <tr>
              <td>BijlagenTechnischeParametersAntenne: </td>
              <td>{antenna?.BijlagenTechnischeParametersAntenne}</td>
              <td>BijlagenTechnischeParametersReflectorAntenne: </td>
              <td>{antenna.BijlagenTechnischeParametersReflectorAntenne}</td>
            </tr>
            <tr>
              <td>MachtigingVertegenwoordigingDoorDerden:</td>
              <td> {antenna.MachtigingVertegenwoordigingDoorDerden || "-"}</td>
              <td>ExtraToelichting: </td>
              <td>{antenna.ExtraToelichting || "-"}</td>
            </tr>
            <tr>
              <td>OndertekeningNaam: </td>
              <td>{antenna?.OndertekeningNaam}</td>
              <td>OndertekeningDatum: </td>
              <td>{antenna.OndertekeningDatum}</td>
            </tr>
            <tr>
              <td>OndertekeningPlaats: </td>
              <td>{antenna?.OndertekeningPlaats}</td>
              <td>OndertekeningHandtekening: </td>
              <td>{antenna.OndertekeningHandtekening}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
