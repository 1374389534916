import { gql } from "@apollo/client";

const getCompanyQuery = gql`
  query ($id: Int) {
    company(id: $id) {
      id
      name
      address
      code
      coc
      quickBooksId
      comments
      country {
        id
        name
        code
      }
    }
  }
`;

const getCompaniesQuery = gql`
  {
    companies {
      id
      name
      address
      code
      coc
      quickBooksId
      country {
        id
        name
        code
      }
    }
    countries {
      id
      name
      code
    }
  }
`;

const addCompanyMutation = gql`
  mutation AddCompany(
    $name: String!
    $address: String
    $code: String
    $coc: String
    $quickBooksId: String
    $comments: String
    $countryId: Int
  ) {
    addCompany(
      name: $name
      address: $address
      code: $code
      coc: $coc
      quickBooksId: $quickBooksId
      comments: $comments
      countryId: $countryId
    ) {
      id
      name
      address
      code
      coc
      quickBooksId
      updatedBy
    }
  }
`;

const updateCompanyMutation = gql`
  mutation UpdateCompany(
    $id: Int!
    $name: String!
    $address: String
    $code: String
    $coc: String
    $quickBooksId: String
    $comments: String
    $countryId: Int
  ) {
    updateCompany(
      id: $id
      name: $name
      address: $address
      code: $code
      coc: $coc
      quickBooksId: $quickBooksId
      comments: $comments
      countryId: $countryId
    ) {
      id
      name
      address
      code
      coc
      quickBooksId
      comments
      updatedBy
    }
  }
`;

const deleteCompanyMutation = gql`
  mutation DeleteCompany($id: Int!) {
    deleteCompany(id: $id) {
      id
    }
  }
`;

export const companyService = {
  getCompanyQuery,
  getCompaniesQuery,
  addCompanyMutation,
  updateCompanyMutation,
  deleteCompanyMutation,
};
