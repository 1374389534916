//Google Map Functions
const fitBounds = (map, assets, center) => {
  // Iterate myPlaces to size, center, and zoom map to contain all markers
  if (assets.length > 0) {
    const bounds = new window.google.maps.LatLngBounds(center);
    assets.map((asset) => {
      bounds.extend({
        lat: parseFloat(asset.latitude),
        lng: parseFloat(asset.longitude),
      });
      return asset.id;
    });
    map.fitBounds(bounds);
    var noPoi = [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
    ];

    map.setOptions({ styles: noPoi });
  }
};

export const loadHandler = ({ map, assets, center }) => {
  //setMapRef(map);
  fitBounds(map, assets, center);
};

export const markerLoadHandler = (marker, asset, setMarkerMap) => {
  return setMarkerMap((prevState) => {
    return { ...prevState, [asset.id]: marker };
  });
};

export const markerClickHandler = (
  event,
  asset,
  setSelectedPlace,
  infoOpen,
  setInfoOpen,
  zoom,
  setZoom,
  setCenter
) => {
  // Remember which place was clicked
  setSelectedPlace(asset);

  // Required so clicking a 2nd marker works as expected
  if (infoOpen) {
    setInfoOpen(false);
  }

  setInfoOpen(true);

  // If you want to zoom in a little on marker click
  if (zoom < 13) {
    setZoom(13);
  }

  // if you want to center the selected Marker
  setCenter({
    lat: parseFloat(asset.latitude),
    lng: parseFloat(asset.longitude),
  });
};
