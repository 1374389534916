import React, { useState } from "react";

import Stepper2 from "../Stepper";

import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";
import FormStep6 from "./FormStep6";
import FormStep7 from "./FormStep7";
import FormStep8 from "./FormStep8";
import FormStep9 from "./FormStep9";
import FormStep10 from "./FormStep10";
import FormStep11 from "./FormStep11";
import FormStep12 from "./FormStep12";
import FormStep13 from "./FormStep13";
import FormStep14 from "./FormStep14";

import { ItemProvider } from "./formContextProvider";
import LoadingGears from "../LoadingGears";

import { useQuery } from "react-query";
import axios from "axios";
import { NEXT_URL } from "../../config/index";

export default function FormStraalVerbindingWithStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [aanNaamVan, setAanNaamVan] = useState("Rechtspersoon");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleNextt = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  //FetchFunctions
  const fetchCompanies = async () => {
    return axios.get(`${NEXT_URL}/api/companies`);
  };

  const fetchTechnologies = async () => {
    return axios.get(`${NEXT_URL}/api/technologies`);
  };

  const fetchServices = async () => {
    return axios.get(`${NEXT_URL}/api/services`);
  };

  const fetchFrequencies = async () => {
    return axios.get(`${NEXT_URL}/api/frequencies`);
  };

  const fetchContracts = async () => {
    return axios.get(`${NEXT_URL}/api/contracts`);
  };

  //QueryFunctions

  const {
    data: dataCompanies,
    status: statusCompanies,
    error: errorCompanies,
    isPreviousData: isPreviousDataCompanies,
  } = useQuery([`Step9Companies`], fetchCompanies, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataTechnologies,
    status: statusTechnologies,
    error: errorTechnologies,
    isPreviousData: isPreviousDataTechnologies,
  } = useQuery([`Step9Technologies`], fetchTechnologies, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataServices,
    status: statusServices,
    error: errorServices,
    isPreviousData: isPreviousDataServices,
  } = useQuery([`Step9Services`], fetchServices, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataFrequencies,
    status: statusFrequencies,
    error: errorFrequencies,
    isPreviousData: isPreviousDataFrequencies,
  } = useQuery([`Step9Frequencies`], fetchFrequencies, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const {
    data: dataContracts,
    status: statusContracts,
    error: errorContracts,
    isPreviousData: isPreviousDataContracts,
  } = useQuery([`Contracts`], fetchContracts, {
    keepPreviousData: true,
    refetchOnMount: true,
  });

  function getSteps() {
    const steps = [
      {
        id: "Step 1",
        name: "Vergunning Aanvragen",
        href: "#",
        status: `${activeStep > 0 ? "complete" : "current"}`,
      },
      {
        id: "Step 2",
        name:
          aanNaamVan === "Rechtspersoon"
            ? "Vergunning op naam van rechtspersoon"
            : "Vergunning op naam van natuurlijk persoon",
        href: "#",
        status: `${
          activeStep > 1
            ? "complete"
            : activeStep < 1
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 3",
        name: "Woon- /Vestigings adres",
        href: "#",
        status: `${
          activeStep > 2
            ? "complete"
            : activeStep < 2
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 4",
        name: "Correspondentie adres",
        href: "#",
        status: `${
          activeStep > 3
            ? "complete"
            : activeStep < 3
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 5",
        name: "Contact gegevens",
        href: "#",
        status: `${
          activeStep > 4
            ? "complete"
            : activeStep < 4
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 6",
        name: "Toepassing Straalverbindingen",
        href: "#",
        status: `${
          activeStep > 5
            ? "complete"
            : activeStep < 5
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 7",
        name: "Locatie zender",
        href: "#",
        status: `${
          activeStep > 6
            ? "complete"
            : activeStep < 6
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 8",
        name: "Antenne gegevens",
        href: "#",
        status: `${
          activeStep > 7
            ? "complete"
            : activeStep < 7
            ? "upcomming"
            : "complete"
        }`,
      },
      // {
      //   id: "Step 9",
      //   name: "Locatie passieve reflector",
      //   href: "#",
      //   status: `${
      //     activeStep > 8
      //       ? "complete"
      //       : activeStep < 8
      //       ? "upcomming"
      //       : "complete"
      //   }`,
      // },
      // {
      //   id: "Step 10",
      //   name: "Antenne passieve reflector",
      //   href: "#",
      //   status: `${
      //     activeStep > 9
      //       ? "complete"
      //       : activeStep < 9
      //       ? "upcomming"
      //       : "complete"
      //   }`,
      // },
      {
        id: "Step 11",
        name: "Facturatie",
        href: "#",
        status: `${
          activeStep > 10
            ? "complete"
            : activeStep < 10
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 12",
        name: "De volgende bijlagen zijn bijgevoegd",
        href: "#",
        status: `${
          activeStep > 11
            ? "complete"
            : activeStep < 11
            ? "upcomming"
            : "complete"
        }`,
      },
      {
        id: "Step 13",
        name: "Verklaring en ondertekening",
        href: "#",
        status: `${
          activeStep > 12
            ? "complete"
            : activeStep < 12
            ? "upcomming"
            : "complete"
        }`,
      },
    ];
    return steps;
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormStep1 handleNext={handleNext} setAanNaamVan={setAanNaamVan} />
        );
      case 1:
        if (aanNaamVan === "Rechtspersoon") {
          return <FormStep2 handleNext={handleNext} handleBack={handleBack} />;
        } else {
          return <FormStep3 handleNext={handleNext} handleBack={handleBack} />;
        }
      case 2:
        return <FormStep4 handleNext={handleNext} handleBack={handleBack} />;
      case 3:
        return <FormStep5 handleNext={handleNext} handleBack={handleBack} />;
      case 4:
        return <FormStep6 handleNext={handleNext} handleBack={handleBack} />;
      case 5:
        return <FormStep7 handleNext={handleNext} handleBack={handleBack} />;
      case 6:
        return <FormStep8 handleNext={handleNext} handleBack={handleBack} />;
      case 7:
        return (
          <>
            {statusCompanies === "loading" ||
            statusTechnologies === "loading" ||
            statusServices === "loading" ||
            statusContracts === "loading" ||
            statusFrequencies === "loading" ? (
              <LoadingGears />
            ) : (
              <FormStep9
                handleNext={handleNext}
                handleBack={handleBack}
                dataCompanies={dataCompanies}
                dataTechnologies={dataTechnologies}
                dataServices={dataServices}
                dataFrequencies={dataFrequencies}
                dataContracts={dataContracts}
              />
            )}

            {statusCompanies === "error" && (
              <div>Error: Couldn't fetch data</div>
            )}
          </>
        );
      // case 8:
      //   return <FormStep10 handleNext={handleNext} handleBack={handleBack} />;
      // case 9:
      //   return <FormStep11 handleNext={handleNext} handleBack={handleBack} />;
      case 8:
        return <FormStep12 handleNext={handleNext} handleBack={handleBack} />;
      case 9:
        return <FormStep13 handleNext={handleNext} handleBack={handleBack} />;
      case 10:
        return <FormStep14 handleNext={handleNext} handleReset={handleReset} />;
      default:
        return "Unknown step";
    }
  }

  return (
    <>
      <ItemProvider>
        <div className="overflow-x-auto mb-10 mt-10">
          <Stepper2 steps={getSteps()} />
        </div>
        <div>{getStepContent(activeStep)}</div>
      </ItemProvider>
    </>
  );
}
