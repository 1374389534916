import { gql } from "@apollo/client";

const getAllocationsQuery = gql`
  {
    allocations {
      id
      frequency
      country {
        id
        name
        code
      }
    }
    countries {
      id
      name
      code
    }
  }
`;

const getAllocationQuery = gql`
  query ($id: Int) {
    allocation(id: $id) {
      id
      frequency
      country {
        id
        name
        code
      }
    }
  }
`;

const addAllocationMutation = gql`
  mutation AddAllocation($countryId: Int!, $frequency: Float!) {
    addAllocation(countryId: $countryId, frequency: $frequency) {
      frequency
    }
  }
`;
const updateAllocationMutation = gql`
  mutation UpdateAllocation($id: Int!, $countryId: Int!, $frequency: Float!) {
    updateAllocation(id: $id, countryId: $countryId, frequency: $frequency) {
      id
      frequency
    }
  }
`;
const deleteAllocationMutation = gql`
  mutation DeleteAllocation($id: Int!) {
    deleteAllocation(id: $id) {
      id
    }
  }
`;

export const allocationService = {
  getAllocationsQuery,
  getAllocationQuery,
  addAllocationMutation,
  updateAllocationMutation,
  deleteAllocationMutation,
};
