import React, { useState } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";

import { Link, useNavigate } from "react-router-dom";
import OverviewPage3DInspect from "../../pages/PageInspector";

export default function StationInfoWindow({
  selectedPlace,
  anchor,
  onCloseClick,
}) {
  const [pix4dLink, setPix4dLink] = useState("");

  const onAntenna3dClick = (pix4dLink) => {
    console.log("im here", pix4dLink);
    return <OverviewPage3DInspect link={pix4dLink} />;
  };
  return (
    <InfoWindow
      anchor={anchor}
      onCloseClick={onCloseClick}
      data-bs-spy="scroll"
    >
      <div className="">
        <div className="lg:grid lg:grid-cols-2">
          {/* Column */}
          <div className=" pr-3  mx-auto max-w-full w-full h-full">
            <div className="w-full">
              <div className="list-group">
                {/* Station Name */}
                <div className="list-group-item list-group-item-danger">
                  <h5>{selectedPlace.description}</h5>
                </div>
                {/* Station Type */}
                <div className="list-group-item list-group-item-secondary">
                  <div className="d-flex justify-content-between">
                    <h5>{selectedPlace.stationType?.name}</h5>
                    <span style={{ marginLeft: 10 }}>
                      {selectedPlace.site?.description}
                    </span>
                  </div>
                </div>
                {/* Equipment */}
                <div className="h-60 overflow-y-auto">
                  {selectedPlace.antennas?.map((antenna, index) => {
                    return (
                      <div className="list-group-item" key={index}>
                        <div className="d-flex justify-content-between">
                          <strong>Company:</strong>{" "}
                          <span>{antenna.company?.name}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <strong>Frequency:</strong>{" "}
                          <span>{antenna.code}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <strong>Freq.Band:</strong>{" "}
                          <span>{antenna.frequency?.band?.name}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <strong>Service:</strong>{" "}
                          <span>{antenna.frequency?.band?.service?.name}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <strong>Technogy:</strong>{" "}
                          <span>
                            {antenna.frequency?.band?.service?.technology?.name}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <strong>Inspect:</strong>
                          {antenna?.pix4dLink && (
                            <span
                              className="cursor-pointer underline text-blue-500"
                              onClick={() => setPix4dLink(antenna?.pix4dLink)}
                            >
                              Inspect
                            </span>
                          )}
                          {/* <span className="cursor-pointer" onClick= {() => onAntenna3dClick(antenna?.pix4dLink)}>
                          click me
                          </span> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* Column */}
          {/* <div className="w-full h-full"> */}
          {/* MastModel */}
          {/* <Viewer MODEL_UID={"216f7e7774484ec3b6e2ee2290568c56"} /> */}
          <iframe
            className="w-full h-full"
            // src="https://cloud.pix4d.com/embed/bim/mesh/459842?shareToken=4c18ce7b62744fd8a691551b77a9c152"
            // src="https://inspect.pix4d.com/embed/745411?shareToken=f3458cea-971a-40ef-aeeb-5266d6bc4270&origin=dashboard"
            src={pix4dLink}
            frameborder="0"
            allowFullscreen
          ></iframe>

          {/* Wiltraco Building */}
          {/* <
             MODEL_UID={"270baa758cbc4380812d25ec461afaa0"} /> */}
          {/* Wood Planks */}
          {/* <Viewer MODEL_UID={"77062b51a58a446fa0b5ba5461db8b52"} /> */}
          {/* DamenShip */}
          {/* <Viewer MODEL_UID={"263a763a97bc4b9b8fb6165f173819ae"} /> */}
          {/* </div> */}
          {/* <a
            // onClick={() => {

            //   window.location.assign("https://cloud.pix4d.com/dataset/1066298/model?shareToken=c89dd28e-1e23-4167-9c22-deb9e96beada");
            // }}
            href="https://cloud.pix4d.com/site/52619/dataset/459842/model?shareToken=ac9f8c2c-9291-42c5-bd8d-c566f3870267"
            className="absolute right-7 hover:no-underline rounded top-6 cursor-pointer bg-gray-900 hover:bg-gray-100 text-white p-1"
          >
            Annotate
          </a> */}
          {pix4dLink && (
            <Link
              to={{
                pathname: "/3DEditor",
                state: { id: pix4dLink },
              }}
              className="absolute right-7 hover:no-underline rounded top-6 cursor-pointer bg-blue-500 hover:bg-gray-200 text-black p-1"
            >
              Full Screen
            </Link>
          )}
        </div>
      </div>
    </InfoWindow>
  );
}
