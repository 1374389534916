import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";

export default function RowRegulatoryTrackerTotal({
    title = "Title",
    maxScore = "20",
    benchmark = "benchmark",
    
}) {
  return (
    <>{/* Form Row */}
    <div className="grid grid-cols-12 gap-6">
      {/* Form Input area*/}
      <div className=" col-span-full md:col-span-1 sm:col-span-1 xl:col-span-1">
        <label className="block uppercase tracking-wide text-sm font-bold my-2">
          {title}
        </label>
      </div>
      {/* Col */}
      <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
        <div
          className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        >
            {maxScore}
        </div>
      </div>
      {/* Col */}
      <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
        <div
          className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        >
            {benchmark}
        </div>
      </div>
    </div>
    {/* End Row 3*/}</>
  )
}
