import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "./ErrorMessage";
import HeadingForm from "./HeadingForm";

export default function FormSectionContactGegevens({ setFieldValue }) {
  return (
    <>
      <HeadingForm title="6 &nbsp;&nbsp;&nbsp;&nbsp;Contactgegevens" />
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            6.1 Contactpersoon
          </label>
        </div>
        <div className=" col-span-full md:col-span-9 sm:col-span-9 xl:col-span-9">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="Contactpersoon"
          />
          <ErrorMessage name="Contactpersoon" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            6.2 Telefoonnummer
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="Telefoonnummer"
          />
          <ErrorMessage name="Telefoonnummer" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            6.3 E-mailadres
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="email"
            name="mailadres"
          />
          <ErrorMessage name="mailadres" component="div" />
        </div>
      </div>
      {/* End Row */}
      {/* Form Row 3*/}
      <div className="grid grid-cols-12 gap-6 pt-4 pb-1">
        {/* Form Input area*/}
        <div className=" col-span-full md:col-span-2 sm:col-span-2 xl:col-span-2">
          <label className="block uppercase tracking-wide text-xs font-bold my-2">
            6.4 URL van uw website
          </label>
        </div>
        <div className=" col-span-full md:col-span-4 sm:col-span-4 xl:col-span-4">
          <Field
            className="resize-none border rounded-md appearance-none block w-full bg-gray-50 border-gray-200 py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="URLVanUwWebsite"
          />
          <ErrorMessage name="URLVanUwWebsite" component="div" />
        </div>
      </div>
      {/* End Row */}
    </>
  );
}
