import { gql } from "@apollo/client";

const getContractPdfsQuery = gql`
  {
    contractPdfs {
      id
      contract {
        id
        name
      }
      attachment
      contractDate
      name
    }
  }
`;

const getContractPdfsByContractQuery = gql`
  query ($contractId: Int!) {
    contractPdfsByContract(contractId: $contractId) {
      name
      attachment
      contractDate
    }
  }
`;

const addContractPdfMutation = gql`
  mutation AddContractPdf(
    $contractId: Int!
    $name: String!
    $attachment: String!
  ) {
    addContractPdf(
      contractId: $contractId
      name: $name
      attachment: $attachment
    ) {
      name
      attachment
    }
  }
`;

export const contractPdfService = {
  getContractPdfsQuery,
  getContractPdfsByContractQuery,
  addContractPdfMutation,
};
