import { gql } from "@apollo/client";

const getBandsQuery = gql`
  {
    bands {
      id
      name
      service {
        id
        name
      }
    }
    services {
      id
      name
    }
  }
`;

const getBandQuery = gql`
  query ($id: Int) {
    band(id: $id) {
      id
      name
      service {
        id
        name
      }
    }
  }
`;

const addBandMutation = gql`
  mutation AddBand($name: String!, $serviceId: Int) {
    addBand(name: $name, serviceId: $serviceId) {
      id
      name
    }
  }
`;

const updateBandMutation = gql`
  mutation UpdateBand($id: Int!, $name: String!, $serviceId: Int) {
    updateBand(id: $id, name: $name, serviceId: $serviceId) {
      id
      name
    }
  }
`;

const deleteBandMutation = gql`
  mutation DeleteBand($id: Int!) {
    deleteBand(id: $id) {
      id
      name
    }
  }
`;

export const bandService = {
  getBandsQuery,
  getBandQuery,
  addBandMutation,
  updateBandMutation,
  deleteBandMutation,
};
