import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import React from "react";

const getDashboardQuery = gql`
  query {
    totalBandwidthUsed {
      name
      total
      used
    }
  }
`;

const getFrequenciesWithBandsQuery = gql`
  query {
    frequencies {
      name
      band {
        name
        service {
          name
          id
        }
      }
    }
  }
`;
const FrequencyBands = ({ selectedService }) => {
  const { data: frequenciesQueryData, loading: frequenciesQueryLoading } =
    useQuery(getFrequenciesWithBandsQuery);
  const { data, loading } = useQuery(getDashboardQuery, {
    fetchPolicy: "network-only",
  });

  if (frequenciesQueryLoading || loading) {
    return Array(4)
      .fill({})
      .map((_, index) => {
        return (
          <div key={index}>
            <h4 className="small font-weight-bold">
              loading...
              <span className="float-right"> 0%</span>
            </h4>
            <div className="progress mb-4">
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `0%`,
                }}
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        );
      });
  }

  let filtered = () => {
    var filter = [];

    data.totalBandwidthUsed.forEach((frequency) => {
      let match = frequenciesQueryData.frequencies.find(
        (freq) => freq.name === frequency.name
      );
      if (
        match?.band?.service &&
        Number(selectedService) === match.band.service.id
      ) {
        console.log(frequency);
        filter.push(frequency);
      }
    });
    return filter;
  };
  console.log("filtered", filtered());

  return filtered().map((frequency, index) => {
    return (
      <div key={index}>
        <h4 className="small font-weight-bold">
          {frequency.name}
          <span className="float-right">
            {" "}
            {Math.round((frequency.used * 100) / frequency.total)}%
          </span>
        </h4>
        <div className="progress mb-4">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${(frequency.used * 100) / frequency.total}%`,
            }}
            aria-valuenow={(frequency.used * 100) / frequency.total}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    );
  });
};

export default FrequencyBands;
