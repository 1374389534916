import React from 'react';


const LineFeeModal = ({ lineFee, technologies, handleChange, closeModal, saveItem }) => {
    return (
        <div className="modal fade" id="lineFeeModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Line Fee</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <label forhtml="name" className="col-form-label">Name:</label>
                                    <input type="text" className="form-control" id="name" name="name" onChange={(e) => handleChange(e)} value={lineFee.name || ''} />
                                </div>
                                <div className="form-group col-sm-12">
                                    <label forhtml="price" className="col-form-label">Price:</label>
                                    <input type="text" className="form-control" id="price" name="price" onChange={(e) => handleChange(e)} value={lineFee.price || ''} />
                                </div>
                                <div className="form-group col-sm-12">
                                    <label forhtml="fbFactor" className="col-form-label">Factor:</label>
                                    <input type="text" className="form-control" id="fbFactor" name="fbFactor" onChange={(e) => handleChange(e)} value={lineFee.fbFactor || ''} />
                                </div>
                                <div className="form-group col-sm-12">
                                    <label forhtml="technology" className="col-form-label">Technology:</label>
                                    <select className="form-control" id="technology" name="technology" onChange={e => handleChange(e)} value={lineFee.technology ? lineFee.technology.id : ''}>
                                        <option value="0" >--choose--</option>
                                        {

                                            technologies.map((technology, index) => <option key={index} value={technology.id} >{technology.name}</option>)

                                        }
                                    </select>

                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LineFeeModal;