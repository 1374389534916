import React from 'react';


const ModelModal = ({ model, brands, handleImageChange, handleImageReset, handleChange, inputKey, closeModal, saveItem }) => {
    return (
        <div className="modal fade" id="modelModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Model</h5>
                        <button type="button" className="close" data-dismiss="modal" onClick={closeModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group col-sm-12">
                                <label forhtml="model_name" className="col-form-label">Model types:</label>
                                <input type="text" className="form-control" id="model_name" name="name" onChange={handleChange} value={model.name} />
                            </div>

                            <div className="form-group col-sm-12">
                                <label forhtml="model_brand" className="col-form-label">Brand name:</label>
                                <select className="form-control" id="model_brand" name="brand" onChange={handleChange} value={model.brand ? (model.brand.id ? model.brand.id : '') : ''}>
                                    <option value="0" >--choose--</option>
                                    {

                                        brands ? brands.map((brand, index) => <option key={index} value={brand.id} >{brand.name}</option>) : null

                                    }
                                </select>

                            </div>
                            <div className="form-group col-sm-12">
                                <label forhtml="model_attachment" className="col-form-label">attachment:</label>
                                <input type="file" className="form-control" id="model_attachment" key={inputKey || ''} name="attachment" onChange={handleImageChange} />
                            </div>
                            {
                                model.attachment && (<div className="pdfWrapper">
                                    <div className="overlay"></div>
                                    <button type="button" className="btn btn-secondary" onClick={handleImageReset}>Reset</button>
                                    <iframe title={model.name} src={model.attachment} width="100%" height="400px"></iframe>
                                </div>)
                            }

                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={saveItem}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModelModal;