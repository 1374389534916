import { gql } from "@apollo/client";

const getTowerTypeQuery = gql`
  query ($id: Int) {
    towerType(id: $id) {
      id
      name
    }
  }
`;
const getTowerTypesQuery = gql`
  {
    towerTypes {
      id
      name
    }
  }
`;

const addTowerTypeMutation = gql`
  mutation AddTowerType($name: String!) {
    addTowerType(name: $name) {
      id
      name
    }
  }
`;

const updateTowerTypeMutation = gql`
  mutation UpdateTowerType($id: Int!, $name: String!) {
    updateTowerType(id: $id, name: $name) {
      id
      name
    }
  }
`;

const deleteTowerTypeMutation = gql`
  mutation DeleteTowerType($id: Int!) {
    deleteTowerType(id: $id) {
      id
      name
    }
  }
`;

export const towerTypeService = {
  getTowerTypeQuery,
  getTowerTypesQuery,
  addTowerTypeMutation,
  updateTowerTypeMutation,
  deleteTowerTypeMutation,
};
