import { gql } from "@apollo/client";

const getCountriesQuery = gql`
  {
    countries {
      id
      name
      code
    }
  }
`;

const addCountryMutation = gql`
  mutation AddCountry($name: String!, $code: String) {
    addCountry(name: $name, code: $code) {
      id
    }
  }
`;

const deleteCountryMutation = gql`
  mutation DeleteCountry($id: Int!) {
    deleteCountry(id: $id) {
      id
    }
  }
`;
const updateCountryMutation = gql`
  mutation UpdateCountry($id: Int!, $name: String!, $code: String!) {
    updateCountry(id: $id, name: $name, code: $code) {
      id
    }
  }
`;

export const countryService = {
  getCountriesQuery,
  addCountryMutation,
  deleteCountryMutation,
  updateCountryMutation,
};
